/**
 * `sessionStorageExpiryBase` is an object that provides methods to interact with the Session Storage.
 * It allows to set, get and check if the data is stale.
 * 
 * @property {number} defaultExpiryDuration - The default expiry duration for the data in milliseconds.
 * 
 * @method get - This method retrieves the data from the Session Storage.
 * @param {Object} obj - The object key to retrieve the data.
 * @returns {any} - The data stored in the Session Storage or null if the data is not found or is stale.
 * 
 * @method set - This method sets the data in the Session Storage.
 * @param {Object} obj - The object key to store the data.
 * @param {any} value - The data to be stored.
 * @param {number} [expiryDuration=defaultExpiryDuration] - The expiry duration for the data in milliseconds.
 * 
 * @method _getKey - This method converts the object key to a string.
 * @param {Object} obj - The object key to be converted.
 * @returns {string} - The stringified object key.
 * 
 * @method _isDataStale - This method checks if the data is stale.
 * @param {number} timestamp - The timestamp when the data was stored.
 * @param {number} expiryDuration - The expiry duration for the data in milliseconds.
 * @returns {boolean} - True if the data is stale, false otherwise.
 */

export const sessionStorageExpiryBase = {
  defaultExpiryDuration: 5 * 60 * 1000,

  get: function(obj) {
    const key = this._getKey(obj);
    const item = sessionStorage.getItem(key);

    if (!item) {
      return null;
    }

    const data = JSON.parse(item);
    if (this._isDataStale(data.timestamp, data.expiryDuration)) {
      sessionStorage.removeItem(key);
      return null;
    }

    return data.value;
  },

  set: function(obj, value, expiryDuration = this.defaultExpiryDuration) {
    const key = this._getKey(obj);
    const data = {
      value,
      timestamp: Date.now(),
      expiryDuration,
    };
    sessionStorage.setItem(key, JSON.stringify(data));
  },

  remove: function(obj) {
    const key = this._getKey(obj);
    sessionStorage.removeItem(key);
  },

  _getKey: function(obj) {

    const returnV = JSON.stringify(obj)
    return returnV;
  },

  _isDataStale: function(timestamp, expiryDuration) {
    return Date.now() - timestamp > expiryDuration;
  },
};
