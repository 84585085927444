import React, { useState, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { LocationContext } from '../../util/providers/locationContext.jsx';
import useAlignment from '../../hooksDatabase/useAlignment';

import JoyRideStarter from '../joyRideStarter.jsx';
import ConfirmDialog from '../confirmDialog.jsx';
import EquipmentPicker from './equipmentPicker.jsx';
import EquipmentSubPicker from './equipmentSubPicker.jsx';
import ConnectionPicker from './connectionPicker.jsx';

// broken summary link
import LocationSummary from '../../pages/project/locationSummary.jsx';
import EquipmentMapper from '../map/equipmentMapper.jsx';

import useSnackbarContext from '../../util/providers/snackbarProvider.jsx';
import { useTutorial } from '../../util/providers/useTutorialContext.jsx';
import { CollapsibleConnectionTabler } from './connectionTabler';
import EquipmentTabler from './equipTabler';
import { Typography } from '@mui/material';


export function LocationBuilder({
  projectPermissions,
  defaultEquipmentList,
  equipmentOptionsLoading,
  locationArrayLoading,
  handleSaveEquipmentState
}) {

  // State variables for search parameters
  const { projID } = useParams();
  const [searchParams] = useSearchParams();
  let instanceid = searchParams.get("instanceid")

  let navigate = useNavigate();

  // State variables for equipment type options
  const [selectedNewEquipment, setSelectedNewEquipment] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const [sortOption, setSortOption] = useState('locationid');
  const [sortAsc, setSortAsc] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const { handleProgress } = useTutorial();
  const { openSnackbar } = useSnackbarContext();

  const { LocationArray, reRenderLocationArray } = useContext(LocationContext);
  const [
    
    alignmentArray,
    setAlignmentArray,
    handleSaveAlignmentArray,
    alignmentArrayLoading
  ] = useAlignment(instanceid, projectPermissions);

  const connectionKeys = ["pcable", "scable", "pspan", "sspan"];
  const baseKeyMap = new Map(defaultEquipmentList.map((equipment) => [equipment.equip_key, equipment.base_key]));

  const steps = [
    {
      target: '.equipment-picker',
      content: 'Select a new type of Equipment Here.  This will appear in the list below.  The location number will be generated automatically. The number will either be the next available number for that type or simply increment from the highest number used.',
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: '.sort-controls',
      content: "You can sort the locations here.  Creation will sort by the order that each location was created, locNum by location numbers, and Type by the type of equipment.",
      placement: "bottom",
    },
    {
      target: '.toggle-map-button',
      content: "Open a map to view your project as well as an alternative way to add and edit equipment.",
      placement: "left",
    },
    {
      target: '.post-data-button',
      content: "To save your changes, you must post the data to the server.  This will create a new instance.",
      placement: "right",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleMap = (event) => {
    setShowMap(!showMap);
    handleProgress(event.target.className)
  };

  function matchObjectKey(object, key) {
    for (var i in object) {
      if (object[i].equip_key === key) {
        // return {...object[i]};
        return structuredClone(object[i]);
      }
    }
    return {}
  }

  function handleGenerateEquipment(selectedEquipment, props) {
    const selectedObject = matchObjectKey(defaultEquipmentList, selectedEquipment);

    if (Object.keys(selectedObject).length !== 0) {
      let newEquipmentOptions = Object.assign({}, selectedObject);
      let newID = LocationArray.generateNewID();
      let newLoc = LocationArray.getMaxLocOfType(newEquipmentOptions.equip_key);


      newEquipmentOptions = {
        ...newEquipmentOptions,
        loc_number: newLoc,
        ...props,
        locationid: newID,
      };
      return newEquipmentOptions;
    } else {
      return null;
    }
  }

  function handleAddEquipment() {
    const newEquipmentOptions = handleGenerateEquipment(selectedNewEquipment);

    if (newEquipmentOptions !== null) {
      LocationArray.add(newEquipmentOptions);
      reRenderLocationArray();
    }
    handleProgress("tutorial-locations-create-location")
  }

  function handleAddDepEquipment(selectedNewEquipment, base_id) {
    const newLocation = handleGenerateEquipment(selectedNewEquipment, { base_id });

    if (newLocation !== null) {
      LocationArray.add(newLocation)
      reRenderLocationArray();
    }

  }


  function handleAddConnection(connectionType, ssd_id, lsd_id) {
    const newLocation = handleGenerateEquipment(connectionType, { ssd_id, lsd_id });

    if (newLocation !== null) {
      LocationArray.add(newLocation)
      reRenderLocationArray();
    }

    handleProgress("tutorial-locations-create-location")

  }

  function handleSaveToServer(event) {
    /* 
    perserving sequence, 

    1. Save the equipment state to the server
    2. Save the alignment state to the server
    3. Redirect to the home page
    */

    const handleRedirectHome = () => {
      // redirect to the root home page
      openSnackbar("Alignments Saved.");
      const myHome = `/project/${projID}`;

      navigate(`${myHome}`);
    }

    const handleAfterSaveEquipment = (response) => {
      // save the alignment to the returned instanceid
      const newInstanceId = response.data[instanceid]
      const idMap = new Map(response.data["loc_map"]);

      // Iterate over LocationArray to update locationid, ssd_id, and lsd_id
      LocationArray.getAllLocations().forEach(location => {
        // Update locationid if present in idMap
        if (idMap.has(location.locationid)) {
          location.locationid = idMap.get(location.locationid);
        }

        // Update ssd_id if present in idMap
        if (location.ssd_id && idMap.has(location.ssd_id)) {
          location.ssd_id = idMap.get(location.ssd_id);
        }

        // Update lsd_id if present in idMap
        if (location.lsd_id && idMap.has(location.lsd_id)) {
          location.lsd_id = idMap.get(location.lsd_id);
        }
      });

      // update alignment elements with new locationids
      alignmentArray.forEach((alignment) => {
        alignment.points.forEach((point) => {
          if (point.items) {
            point.items.forEach((item) => {
              // Check if idMap has the current item's locationid and update it
              if (idMap.has(item.locationid)) {
                item.locationid = idMap.get(item.locationid);
              }
            });
          }
        });
      });


      if (alignmentArray.length === 0 || !projectPermissions?.other_permissions?.includes('alignment')) {
        openSnackbar("Locations Saved!.");
        handleRedirectHome()
      } else {
        openSnackbar("Locations Saved! Saving Alignment...");
        handleSaveAlignmentArray(handleRedirectHome, newInstanceId, handleSaveError);
      }
      //end afterSave
    }

    const handleSaveError = (error) => {
      console.log(error);
      setDialogOpen(false);
      openSnackbar(error?.data?.detail || error?.statusText || "An error occurred");
    }


    handleOpenDialog(
      <ConfirmDialog
        title="Update Data"
        message="Are you sure you want to update the data?"
        onConfirm={() => {
          handleProgress(event.target.className)

          // Chain saving alignments -> redirect home
          handleSaveEquipmentState(
            handleAfterSaveEquipment,
            handleSaveError
          )
        }}
        onCancel={() => setDialogOpen(false)}
      />
    );
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleSortChange = (event) => {

    setSortOption(event.target.value);
    //let payload = [];
    // if (event.target.value === "key") {
    //const defaultList = defaultEquipmentList.map((equipment) => equipment.name);
    //payload = defaultList;
    // }
    LocationArray.sortBy(event.target.value, sortAsc);
  };

  const handleAscendingOrderChange = () => {
    setSortAsc(!sortAsc);

    LocationArray.sortBy(sortOption, !sortAsc);
  };

  return (
    <>
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Container className="location-builder-container">
        <JoyRideStarter steps={steps} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            className="tutorial-post-data-button post-data-button"
            variant="contained"
            color="primary"
            onClick={handleSaveToServer}
          >
            Save Locations
          </Button>
          {projectPermissions?.saves_left !== null && (
            <Typography sx={{ pl: 1 }}>
              {projectPermissions.saves_left > 0 ? (
                `You have ${projectPermissions.saves_left} saves left. Consider upgrading your project.`
              ) : (
                'You have no saves left. Please upgrade your project to continue saving.'
              )}
            </Typography>
          )}
        </Box>
        <AppBar position="sticky" color="background" className="location-builder-appbar">
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
              {equipmentOptionsLoading ? <span>Loading Defaults...</span> : (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Tabs value={activeTab} onChange={handleTabChange} orientation="vertical" sx={{ borderRight: 1, borderColor: 'divider' }}>
                    <Tab label="Add Equipment" sx={{ fontSize: '12px' }} />
                    <Tab label="Add Sub Equipment" sx={{ fontSize: '12px' }} />
                    <Tab label="Add Connection" sx={{ fontSize: '12px' }} />
                  </Tabs>
                  {activeTab === 0 && (
                    <EquipmentPicker
                      className="equipment-picker"
                      defaultEquipmentList={defaultEquipmentList.filter((item) => !connectionKeys.includes(item.equip_key) && item?.base_key === undefined)}
                      selectedNewEquipment={selectedNewEquipment}
                      setSelectedNewEquipment={setSelectedNewEquipment}
                      handleSubmitEquipment={() => handleAddEquipment(selectedNewEquipment)}
                    />
                  )}
                  {activeTab === 1 && (
                    <EquipmentSubPicker
                      className="equipment-sub-picker"
                      baseEquipmentState={LocationArray.getFilteredLocations().filter((item) => !connectionKeys.includes(item.equip_key) && item?.base_key === undefined)}
                      subEquipmentDefList={defaultEquipmentList.filter((item) => !connectionKeys.includes(item.equip_key) && item.base_key != null)}
                      selectedNewEquipment={selectedNewEquipment}
                      setSelectedNewEquipment={setSelectedNewEquipment}
                      handleSubmitEquipment={(base_id) => handleAddDepEquipment(selectedNewEquipment, base_id)}
                      baseKeyMap={baseKeyMap}
                    />
                  )}
                  {activeTab === 2 && (
                    <ConnectionPicker
                      className="connection-picker"
                      equipmentState={LocationArray.getFilteredLocations().filter((equipment) => !connectionKeys.includes(equipment?.equip_key))}
                      onConnectionSubmit={handleAddConnection}
                    />
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button variant="outlined" onClick={handleAscendingOrderChange} className="sort-order-button">
                {sortAsc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              </Button>
              <Box className="sort-controls" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                <RadioGroup value={sortOption} onChange={handleSortChange} className="sort-radio-group">
                  <FormControlLabel value="locationid" control={<Radio />} label="New" />
                  <FormControlLabel value="loc_number" control={<Radio />} label="Loc" />
                  <FormControlLabel value="equip_key" control={<Radio />} label="Type" />
                </RadioGroup>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                <Button variant="outlined" onClick={handleToggleMap} className="tutorial-locations-map-overview toggle-map-button">
                  {showMap ? 'Hide Map' : 'Show Map'}
                  {showMap ? <ArrowUpward /> : <ArrowDownward />}
                </Button>
              </Box>
            </Box>
          </Toolbar>
          <Container sx={{ maxHeight: "80vh" }} className="equipment-mapper-container">
            {showMap && (alignmentArrayLoading ? <span>Loading Map...</span> : (
              <EquipmentMapper
                projectPermissions={projectPermissions}
                onGenerateEquipment={handleGenerateEquipment}
                alignmentArray={alignmentArray}
                setAlignmentArray={setAlignmentArray}
                handleSaveAlignmentArray={handleSaveAlignmentArray}
                alignmentArrayLoading={alignmentArrayLoading}
                defaultEquipmentList={defaultEquipmentList}
              />
            ))}
          </Container>
        </AppBar>
        <Modal open={openModal} onClose={handleCloseModal} className="location-summary-modal">
          <Box>
            <LocationSummary locationList={LocationArray} />
          </Box>
        </Modal>
        <Container className="equipment-tabler-container">
          {locationArrayLoading ? (
            <div>Loading State...</div>
          ) : LocationArray === null ? (
            <div>Equipment State is null</div>
          ) : (
            <>
              <Paper elevation={2} sx={{ p: 0, m: 2 }}>
                <CollapsibleConnectionTabler
                  connectionKeys={connectionKeys}
                />
              </Paper>
              <Paper elevation={2} sx={{ p: 2, m: 0 }}>
                <EquipmentTabler  
                  baseKeyMap={baseKeyMap}
                  handleGenerateEquipment={handleGenerateEquipment}
                />
              </Paper>
            </>
          )}

          <Box sx={{ minHeight: '20vh' }} />
        </Container>
      </Container >
    </>
  );
}


