
import React, { useState } from 'react';
import {
  Popup,
  FeatureGroup,
  Polygon,
  LayersControl,
} from 'react-leaflet';
import 'leaflet-control-geocoder';

import Button from '@mui/material/Button';

import { loadBuildingDataFromOverpass } from '../../../util/overpassFetch';

export function CreateBuildingLayer({ className, parentMap, isLoaded, setBuildingState }) {
  let isButtonDisabled = false;

  const handleLoadBuildingDataClick = () => {
    if (!isButtonDisabled) {
      isButtonDisabled = true;
      loadBuildingDataFromOverpass(parentMap,
        (res) => {
          // Create Road Layer using data
          setBuildingState(res.data.elements);
          setTimeout(() => {
            isButtonDisabled = false;
          }, 30000); // Set the time interval to 1000 milliseconds (1 second)
        },
        (error) => {
          console.error(error?.response?.data || error);
          setTimeout(() => {
            isButtonDisabled = false;
          }, 30000); // Set the time interval to 1000 milliseconds (1 second)
        }
      );
    }
  };

  if (!isLoaded) {
    return (
      <div className={className} >
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <>
      <Button disabled={isButtonDisabled} className={className} variant="contained" onClick={handleLoadBuildingDataClick}>Load Building Data</Button>
    </>
  );
}

export function BuildingLayer({
  buildingState,
  checked,
  className = "building-group",
  name = "Buildings",
  ...props
}) {
  const [roadPopups, setRoadPopups] = useState([]);

  const handlePolylineClick = (e, tags) => {
    // Display information about the road using the tags object
    console.log(e, tags);
    const latlng = e.latlng;
    const tagsString = Object.keys(tags).map((key, index) => {
      return `${key}: ${tags[key]}`;
    }).join("\n");


    const content = `Information about building: \n${tagsString}`; // Modify this to display actual information
    const popup = (
      <Popup key={Math.random()} position={latlng} onClose={() => setRoadPopups(roadPopups.filter(p => p !== popup))}>
        {content}
      </Popup>
    );
    setRoadPopups([...roadPopups, popup]);
  };

  const roads = buildingState.map((roadElement, index) => {
    const points = roadElement.geometry;
    const tags = roadElement.tags;

    return (
      <Polygon
        key={index}
        pathOptions={{ color: "blue" }}
        positions={points}
        eventHandlers={{
          click: (e) => {
            handlePolylineClick(e, tags);
          }
        }}
      >
      </Polygon>
    );
  });

  return (
    <LayersControl.Overlay checked className={className} name={name} {...props}>
      <FeatureGroup>{roads}</FeatureGroup>
      {roadPopups}
    </LayersControl.Overlay>
  );
}
