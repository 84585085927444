import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useTutorial } from '../../util/providers/useTutorialContext';
import { UseServerData, PostServerData } from '../../util/dbfetch';

import ConfirmDialog from "../../components/confirmDialog";

export default function UserSettings() {
  const [userData, setUserData] = useState(null);
  const [isManager, setIsManager] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const snackRef = useRef(null);

  const handleOpenDialog = useCallback((content) => {
    setDialogContent(content);
    setDialogOpen(true);
  }, [setDialogContent, setDialogOpen]);

  const handleServerError = useCallback((err) => {
    handleOpenDialog(
      <ConfirmDialog
        title={`Error Code: ${err.status}`}
        message={err?.data.detail || err?.data.message || err?.data}
        onConfirm={() => {
          setDialogOpen(false);
        }}
        onCancel={() => setDialogOpen(false)}
        delayTime={0}
      />
    );
  }, [handleOpenDialog, setDialogOpen]);

  useEffect(() => {
    const handleSetUserData = (data) => {
      setUserData(data);
  
      // Check if the user is a manager and if any organization has a "paid" plan tier
      const isManagerOrPaid = data.organizations.some(org => 
        org.managerid === data.userid && org.plan_tier === "paid"
      );
  
      if (isManagerOrPaid) {
        setIsManager(true);
      }
    };
  
    UseServerData('user', handleSetUserData, () => null, handleServerError);
  }, [handleServerError]);



  const createSnack = (message) => {
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleFieldUpdate = (field, oldValue, newValue) => {
    const newDict = {
      field: field,
      newValue: newValue,
      oldValue: oldValue,
    }
    PostServerData('user-update', newDict, () => createSnack("Successfully Updated User"), handleServerError);
  };

  return (
    <Box flexGrow={1} p={2}>
      {userData && (
        <>
          <ChangeUserName field={"display_name"} onNameUpdate={handleFieldUpdate} initialName={userData.settings.display_name} createSnack={createSnack} />
          <Divider />
          <ChangePassword field={"password"} onPasswordUpdate={handleFieldUpdate} createSnack={createSnack} />
          <Divider />
          <ChangeEmail userid = {userData.userid} initialEmail={userData.email} onServerError={handleServerError} createSnack={createSnack} />
          <Divider />
          <StripePortalLinks isManager= {isManager} createSnack ={createSnack}/>
          <Divider />
          <TransactionHistory />
          <Divider />
          <ChangeUserWorkStream field={"workstreams"} onWorkStreamUpdate={handleFieldUpdate} initialWorkStream={userData?.workstreams || []} onServerError={handleServerError} createSnack={createSnack} />
          {/* <PlanDisplay field = {"plan_tier"} initialPlan = {userData.plan_tier} onServerError={handleServerError} createSnack={createSnack} /> */}
          <Divider />
          <UserTransfers />
          <Divider />
          <EnableTutorial />
          <Divider />
          <DeleteAllCookies />
        </>
      )}
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        message={snackMessage}
        ref={snackRef}
      />
    </Box>
  );
}

function ChangeUserName({ field, onNameUpdate, initialName, createSnack }) {
  const [name, setName] = useState(initialName);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNameSubmit = () => {


    onNameUpdate(field, initialName, name);
  };

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">Change Display Name</Typography>
      <Box display="flex" alignItems="center" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
        <TextField key="name" label="Display Name" value={name} onChange={handleNameChange} margin="normal" sx={{ width: 300 }} />
        <Button
          sx={{ borderRadius: "0.5rem", marginLeft: '1rem' }}
          variant="contained"
          color="primary"
          disabled={initialName === name}
          onClick={handleNameSubmit}
        >Update Name</Button>
      </Box>
    </Paper>
  );
}

function ChangePassword({ field, onPasswordUpdate, createSnack }) {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handlePasswordUpdate = () => {
    if (newPassword !== confirmNewPassword) {
      createSnack('New passwords do not match');
      return;
    }
    onPasswordUpdate(field, currentPassword, newPassword);
  };

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">Change Password</Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="current-password-input">Current Password</InputLabel>
          <OutlinedInput 
            id="current-password-input" 
            type={showCurrentPassword ? 'text' : 'password'} 
            value={currentPassword} 
            onChange={(event) => setCurrentPassword(event.target.value)} 
            label="Current Password" 
            autoComplete='no'
            endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowCurrentPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          } />
        </FormControl>
        <Box sx={{ p: 1 }}>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="new-password-input">New Password</InputLabel>
            <OutlinedInput id="new-password-input" type={showNewPassword ? 'text' : 'password'} value={newPassword} onChange={(event) => setNewPassword(event.target.value)} label="New Password" endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            } />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="confirm-new-password-input">Confirm New Password</InputLabel>
            <OutlinedInput id="confirm-new-password-input" type={showConfirmNewPassword ? 'text' : 'password'} value={confirmNewPassword} onChange={(event) => setConfirmNewPassword(event.target.value)} label="Confirm New Password" endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmNewPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            } />
          </FormControl>
        </Box>
        <Button variant="contained" color="primary" onClick={handlePasswordUpdate} sx={{ borderRadius: "0.5rem", marginTop: '1rem' }}>Update Password</Button>
      </Box>
    </Paper>
  );
}

export function ChangeEmail({ userid, initialEmail, onServerError, createSnack }) {
  const [currentEmail, setCurrentEmail] = useState(initialEmail);
  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');

  const [verificationCode, setVerificationCode] = useState('');

  function EmailChanged() {
    createSnack("Email Verified! Refresh the page.")
    setCurrentEmail(newEmail)
    setNewEmail('')
    setConfirmNewEmail('')
    setVerificationCode('')
  }

  const handleEmailUpdate = () => {
    if (newEmail !== confirmNewEmail) {
      createSnack('New emails do not match');
      return;
    }

    PostServerData('verify-update-email', { verification_email: newEmail, verification_code: verificationCode }, () => EmailChanged(), (err) => onServerError(err));
  };

  const handleSendVerificationCode = () => {
    if (newEmail !== confirmNewEmail) {
      createSnack('New emails do not match');
      return;
    }

    PostServerData('send-verification', { userid: userid, verification_email: newEmail }, () => createSnack("Verification email sent!"), (err) => onServerError(err));
  };

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">Change/Verify Email</Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start">

        <Box sx={{ mt: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.23)', padding: '10px' }}>
          <Typography variant="body">
            Current Email: {currentEmail ? currentEmail : '<No Email>'}
          </Typography>
        </Box>
        <Box sx={{ p: 1 }}>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="new-email-input">New Email</InputLabel>
            <OutlinedInput id="new-email-input" type='email' value={newEmail} onChange={(event) => setNewEmail(event.target.value)} label="New Email"
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="confirm-new-email-input">Confirm New Email</InputLabel>
            <OutlinedInput id="confirm-new-email-input" type='email' value={confirmNewEmail} onChange={(event) => setConfirmNewEmail(event.target.value)} label="Confirm New Email" />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="verification-code-input">Verification Code</InputLabel>
            <OutlinedInput id="verification-code-input" type='text' value={verificationCode} onChange={(event) => setVerificationCode(event.target.value)} label="Verification Code" />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant="contained" color="primary" onClick={handleSendVerificationCode} sx={{ borderRadius: "0.5rem", marginTop: '1rem' }}>Request Verification Code</Button>
          <Button variant="contained" color="primary" disabled={verificationCode ? false : true} onClick={handleEmailUpdate} sx={{ borderRadius: "0.5rem", marginTop: '1rem' }}>Update Email</Button>
        </Box>
      </Box>
    </Paper>
  );
}

function DeleteAllCookies() {
  const handleDeleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  };



  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">Delete All Cookies</Typography>
      <Box>
        <Button variant="contained" onClick={handleDeleteAllCookies}>Delete All Cookies</Button>
      </Box>
    </Paper>
  );
}

function ChangeUserWorkStream({ field, onWorkStreamUpdate, initialWorkStream, onServerError, createSnack }) {
  const workstreamsBase = ["Electric(general)", "Gas(general)"];
  const [selectedWorkstreams, setSelectedWorkstreams] = useState(initialWorkStream);

  const workstreams = initialWorkStream.reduce((acc, current) => {
    if (!workstreamsBase.includes(current.utility)) {
      acc.push(current.utility);
    }
    return acc;
  }, [...workstreamsBase]);


  const handleWorkstreamChange = (event) => {
    const selectedValues = event.target.value;
    const newSelectedWorkstreams = selectedValues.map((utility) => {
      const existingWorkstream = selectedWorkstreams.find((workstream) => workstream.utility === utility);
      if (existingWorkstream) {
        return existingWorkstream;
      } else {
        return { workstreamid: 0, utility };
      }
    });
    setSelectedWorkstreams(newSelectedWorkstreams);
  };

  const handleWorkStreamSubmit = () => {
    PostServerData('user-update-workstreams', selectedWorkstreams, () => createSnack("Workstreams updated"), (err) => onServerError(err));
  };

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">Change Workstreams</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ m: 1, width: '25ch' }} >
          <InputLabel id="workstream-select-label">Workstreams</InputLabel>
          <Select
            labelId="workstream-select-label"
            id="workstream-select"
            label="Workstreams"
            multiple
            value={selectedWorkstreams.map((workstream) => workstream.utility)}
            onChange={handleWorkstreamChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {workstreams.map((workstream) => (
              <MenuItem key={workstream} value={workstream}>
                {workstream}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleWorkStreamSubmit}>Update Workstreams</Button>
        {/* Add any other components here */}
      </Box>
    </Paper>
  );
}

function EnableTutorial() {
  // create a tutorial object in localstorage

  const { handleStartTutorial } = useTutorial();

  const handleCreateTutorial = () => {
    handleStartTutorial('initialize-tutorial')

  }

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 1 }}>
      <Button variant="contained" onClick={() => handleCreateTutorial()}>Reset/Enable Tutorial</Button>
    </Paper>
  )
}

function UserTransfers() {
  const navigate = useNavigate();

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">View and Manage Your Transfers</Typography>
      <Button variant="contained" onClick={() => navigate("/user/transfers")}>Transfers</Button>
    </Paper>
  );
}

function TransactionHistory() {
  const navigate = useNavigate();

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">View Your Transaction History</Typography>
      <Button variant="contained" onClick={() => navigate("/user/transaction-history")}>Transaction History</Button>
    </Paper>
  );
}

function StripePortalLinks({ isManager, createSnack }) {
  const navigate = useNavigate();

  const [connectedAccountId, setConnectedAccountId] = useState();
  const [customerId, setCustomerId] = useState();
  const [manUrl, setManUrl] = useState();

  const tooltipMessage = "Stripe Accounts require a paid plan for one of your managed organizations";

  useEffect(() => {

    const handleOpenPortal = (responseData) => {
      setConnectedAccountId(responseData.stripe_id);
      setCustomerId(responseData.customer_id);
      setManUrl(responseData?.management_url);
    };

    UseServerData(
      'stripe_id',
      handleOpenPortal,
      () => {},
      (err) => {
        createSnack(err?.detail || "Error fetching Stripe account management link");
        console.error('Error fetching Stripe account management link:', err);
      }
    );
  }, [isManager, createSnack]);


  const handleCustomerProfile = () => {
    const handleCustomerId = (req) => {
      createSnack("Customer Created");
      setCustomerId(req.data.customer_id);
    };

    PostServerData('create-customer', {}, handleCustomerId, (err) => createSnack(err.data.detail || err.data.message || err.data));
  }

  return (
    <Paper elevation={2} sx={{ my: 1, p: 2, pt: 0 }}>
      <Typography variant="h3">Manage Your Payments</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <Box id="customer-functions">
          {customerId ? (
            <Button variant="contained" onClick={() => navigate(`/user/purchases`)}>
              View Customer Profile
            </Button>
          ) : (
            <Button variant="contained" onClick={() => handleCustomerProfile()}>
            Create Customer
            </Button>
          )}
        </Box>
        {manUrl && connectedAccountId ? (
          <Box id="affiliate-functions" sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(manUrl, '_blank')}
            >
              Open Stripe Account Management
            </Button>
          </Box>
        ) : (
          <Box id="affiliate-functions" sx={{ display: 'flex', gap: 2 }}>
            <Tooltip title={!isManager ? tooltipMessage : ""}>
              <span>
                <Button variant='outlined' disabled>
                  Stripe Account Management
                </Button>
              </span>
            </Tooltip>
            <Tooltip title={!isManager ? tooltipMessage : ""}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/user/stripe-portal/create")}
                  disabled = {!isManager}
                >
                  Create Stripe Account
                </Button>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Paper>
  );
}