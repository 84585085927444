import { useParams, useSearchParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import { LocationBuilder } from '../locationItems/locationBuilder';

import useEquipmentDefaults from '../../hooksDatabase/useEquipmentDefaults';
import useLocationArrayWithOptions from '../../hooksDatabase/useLocationArrayWithOptions';
import useProjectPermissions from '../../hooksDatabase/useProjectPermissions.jsx';

import { LocationContext } from '../../util/providers/locationContext.jsx';

export function InstanceNew() {
  // const { projID } = useParams();

  return (
    <div>
      <h1 className="head-section-header">New Instance</h1>
    </div>

  )
}

export function InstanceLocations() {
  const { projID } = useParams();
  const [searchParams] = useSearchParams(); // setSearchParams
  let instanceid = searchParams.get("instanceid")

  const [permissions, permissionsLoading] = useProjectPermissions(projID);

  const [defaultEquipmentList, equipmentOptionsLoading, getDefaultCopy] = useEquipmentDefaults();
  const [
    LocationArray,
    reRenderLocationArray,
    locationArrayLoading,
    handleSaveEquipmentState
  ] = useLocationArrayWithOptions(instanceid, projID, defaultEquipmentList, getDefaultCopy);


  return (
    <LocationContext.Provider value={{ LocationArray, reRenderLocationArray }}>
      {permissionsLoading && (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      )}
      <LocationBuilder
        projectPermissions={permissions}
        defaultEquipmentList={defaultEquipmentList}
        equipmentOptionsLoading={equipmentOptionsLoading}
        locationArrayLoading={locationArrayLoading}
        handleSaveEquipmentState={handleSaveEquipmentState}
      />
    </LocationContext.Provider>
  )
}