import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

import { UseServerData } from '../dbfetch.js';

export const UserDataContext = createContext();

export function UserDataProvider({ children, jwt = null }) {
  const [user, setUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshData = useCallback(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  useEffect(() => {
    refreshData();
  }, [jwt, refreshData]);



  useEffect(() => {
    const processProjects = (data) => {
      const projectsWithRoles = data.map((project) => {
        let role;
        if (project.roles.includes('sponsor')) {
          role = 'sponsor';
        } else if (project.roles.includes('editor')) {
          role = 'editor';
        } else if (project.roles.includes('viewer')) {
          role = 'viewer';
        } else {
          role = 'other';
        }
        return { ...project, role };
      });

      projectsWithRoles.sort((a, b) => {
        const dateA = new Date(a.instanceCurrent?.date_update);
        const dateB = new Date(b.instanceCurrent?.date_update);

        if (isNaN(dateA.getTime()) || !a.instanceCurrent) {
          return -1;
        }
        if (isNaN(dateB.getTime()) || !b.instanceCurrent) {
          return 1;
        }
        return dateB.getTime() - dateA.getTime();
      });

      return projectsWithRoles;
    };

    function setUserData(data) {
      setUser(data.user);
      setProjects(processProjects(data.projects));
      setOrganizations(data.organizations);
    }

    UseServerData(
      "user_details",
      setUserData,  // Set
      () => { },  // loading
      () => { }  // error , Parameters
    );

  }, [refreshKey]);

  const value = {
    user,
    projects,
    organizations,
    refreshData
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}

export default function useUserDataContext() {
  return useContext(UserDataContext);
}