import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { UseServerData } from "../util/dbfetch.js";
import useSnackbarContext from '../util/providers/snackbarProvider';

function InvoiceList() {
  const { orgID } = useParams();
  const { projID } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invoices, setInvoices] = useState([]);

  const navigate = useNavigate();
  const { openSnackbar } = useSnackbarContext();

  useEffect(() => {
    if (orgID) {
      UseServerData(
        `organizations/${orgID}/invoices`,
        (data) => {
          setInvoices(data.invoices);
        },
        setLoading,
        (err) => {
          setError(err?.detail || "Error fetching invoices");
          openSnackbar(err?.detail || "Error fetching invoices");
          console.error('Error fetching invoices:', err);
        }
      );
    }
    else if (projID) {
      UseServerData(
        `projects/${projID}/invoices`,
        (data) => {
          setInvoices(data.invoices);
        },
        setLoading,
        (err) => {
          setError(err?.detail || err || "Error fetching invoices");
          openSnackbar(err?.detail || "Error fetching invoices");
          console.error('Error fetching invoices:', err);
        }
      );
    }
  }, [orgID, projID, openSnackbar]);


  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (error) {
    return (
      <Box>
        <Typography variant="h3">Error</Typography>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {projID &&
        <Button onClick ={()=> navigate("/user/purchases")}>Open Customer Account and Open Invoices</Button>
      }

      {invoices.length === 0 ? (
        <Typography>No invoices found.</Typography>
      ) : (
        invoices.map((invoice) => (
          <InvoiceElement key = {invoice.invoiceid} invoice = { invoice } />
        ))
      )}
    </Box>
  );
}

function InvoiceElement({ invoice }) {
  const org = invoice.organization;
  const project = invoice.project;

  const { openSnackbar } = useSnackbarContext();

  const handleOpenStripe = (stripeid) => {
    const stripeInvoiceUrl = `https://dashboard.stripe.com/invoices/${stripeid}`;
    window.open(stripeInvoiceUrl, '_blank');
  };

  const handleOpenReceipt = (stripeid) => {
    UseServerData(
      `invoices/${stripeid}/receipt`,
      (data) => {
        window.open(data.receipt_url, '_blank');
      },
      () => {},
      (err) => {
        openSnackbar(err?.detail || err || "Error fetching receipt");
        console.error('Error fetching receipt:', err);
      });
    };

  return (
    <Paper key={invoice.invoiceid} sx={{ my: 2, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h3">Project: {project.proj_name}</Typography>
          <Typography variant="h3">Designer Org: {org.organization_name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={() => handleOpenReceipt(invoice.stripeid)}>
            Open Receipt
        </Button>
          <Button variant="contained" color="primary" onClick={() => handleOpenStripe(invoice.stripeid)}>
            Open in Stripe
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body">Status: {invoice.invoice_status}</Typography>
          <Typography variant="body1">Amount: ${invoice.amount / 100}</Typography>
          <Typography variant="body2">Due Date: {new Date(invoice.due_date).toLocaleDateString()}</Typography>
          <Typography variant="body2">Posted Date: {new Date(invoice.posted_date).toLocaleDateString()}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body">Description:</Typography>
          <Typography variant="body1" sx={{ pl: 1 }}>{invoice.description}</Typography>
          <Typography variant="body2" sx={{ ml: 1 }}>{`(${invoice.description_line})`}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}




export default InvoiceList;