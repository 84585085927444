import React from "react";
import { useNavigate } from "react-router-dom"

import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import useUserDataContext from '../../util/providers/userDataProvider.jsx';

function NotificationBar() {
  const navigate = useNavigate();
  const { user: userData} = useUserDataContext();

  if (userData === null || userData === undefined) {
    return null; // Display nothing while userData is loading
  }

  if (!userData.verified) {
    return (
      <Box id="notification-bar" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40, bgcolor: 'warning.main', color: 'warning.contrastText', py:2 }}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="body2" align="center">
              Your email is not verified. Please verify/change your email in user settings.
            </Typography>
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" onClick={() => navigate('/user')}>
              User Settings
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return null;
}


export default NotificationBar