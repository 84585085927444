import { v4 as uuidv4 } from 'uuid';
import { 
  polygon,
  lineArc,
  bearing, 
  circle, 
  destination, 
  lineString, 
  featureCollection
} from '@turf/turf';

import { 
  FeatureCollection, 
  Geometry, 
  Feature,
  Point,
  LineString, 
  Polygon, 
  GeoJsonProperties
 } from 'geojson';
 
import { 
  BearingMode, 
  Item,
  IAlignmentPydantic, 
  AlignmentPerpendicular, 
  AlignmentPerpendicularItemized
 } from './alignmentPerpendicular.ts';

import { combineAndOrderPoints } from '../util/geoSpatial.ts';


interface IPydanticAlignment {
  alignmentid: number | null;
  name: string;
  points:  IAlignmentPydantic[]; 
}

interface IPydanticOffsetAlignment extends IPydanticAlignment{
  conduitOffset: number;
}

/**
 * The Alignment class represents an alignment, which is a series of points that define a path.
 * Each point in the alignment has a width and a bearing, which are used 
 *    to create a right-of-way polygon around the alignment.
 *
 * @property {number} alignmentid - The ID of the alignment.
 * @property {string} name - The name of the alignment.
 * @property {AlignmentPerpendicular[]} points - The points that make up the alignment.
 * @property {Feature<LineString, GeoJsonProperties>} centerLine - The center line of the alignment.
 * @property {Feature<Polygon, GeoJsonProperties>} rightOfWay - The right-of-way polygon around the alignment.
 * @property {Feature<LineString, GeoJsonProperties>[]} pointFeatures - The point features of the alignment.
 * 
 * @method {Alignment} fromPydantic - Creates a new Alignment object from a Pydantic API object.
 * @method {Alignment} addPoints - Adds new points to the alignment and returns a new Alignment object.
 * @method {Feature<Polygon, GeoJsonProperties>} createRightOfWayPolygon - Creates a right-of-way polygon around the alignment.
 * @method {Feature<LineString, GeoJsonProperties>} createCenterLine - Creates a center line for the alignment.
 * @method {Feature<LineString, GeoJsonProperties>[]} createAlignmentPoints - Creates a series of LineString features representing the alignment points.
 * @method {void} updateFeatures - Updates the features of the alignment based on its points.
 * @method {FeatureCollection<Geometry, GeoJsonProperties>} toGeoJSON - Converts the alignment to a GeoJSON FeatureCollection.
 * @method {any} toPydanticAPI - Converts the alignment to a format that can be used with the Pydantic API.
 *          TODO: Extend the toPydanticAPI to its own object that is one to one with the API.
 *                Other objects to follow like TODO: OverpassData, etc.
 * 
 */
export class Alignment {
  alignmentid: number | null;
  name: string;
  points: AlignmentPerpendicular[];
  centerLine: Feature<LineString, GeoJsonProperties> | null = null;
  rightOfWay: Feature<Polygon, GeoJsonProperties> | null = null;
  pointFeatures: Feature<LineString, GeoJsonProperties>[] = [];

  /**
   * Constructs a new Alignment object.
   *
   * @param {AlignmentPerpendicular[]} points - The points that make up the alignment.
   * @param {string} name - The name of the alignment.
   * @param {number} alignmentid - The ID of the alignment.
   */
  constructor(
    points: AlignmentPerpendicular[], 
    name: string = 'default', 
    alignmentid: number | null = null
  ) {
    this.alignmentid = alignmentid;
    this.name = name;
    this.points = points.map((point, index) => {
      if (!point.alignmentpointid) {
        point.alignmentpointid = `new-${uuidv4()}`;
      }
      point.order = index;
      return point;
    });
    this.updateFeatures();
  }
  

  static fromPydanticAPI(pydantic: IPydanticAlignment): Alignment {
    const points = pydantic.points.map(AlignmentPerpendicular.fromPydantic); // Assuming AlignmentPerpendicular also has a fromPydantic method
    return new Alignment(points, pydantic.name, pydantic.alignmentid);
  }

  /**
   * Converts the alignment to a format that can be used with the Pydantic API.
   *
   * @returns {any} An object that represents the alignment in a format that can be used with the Pydantic API.
   */
  toPydanticAPI(): any {
    return {
      alignmentid: this.alignmentid,
      name: this.name,
      points: this.points.map(point => {
        return point.toPydanticAPI(); // gets widths, all equipment here
      })
    };
  }

  getPointFeatures(): Feature<LineString, GeoJsonProperties>[] {
    return this.pointFeatures;
  }

  /**
   * Adds new points to the alignment and returns a new Alignment object.
   *
   * @param {AlignmentPerpendicular[]} newPoints - The new points to add to the alignment.
   * @returns {Alignment} A new Alignment object with the added points.
   */
  addPoints(newPoints: AlignmentPerpendicular[]): this {
    // Prepare the points for combination
    const listsOfPoints = [this.points, newPoints];
  
    // Use combineAndOrderPoints to combine and order the points
    const combinedAndOrderedPoints = combineAndOrderPoints(listsOfPoints);
  
    // Assign IDs and order to the combined and ordered points
    combinedAndOrderedPoints.forEach((point, index) => {
      if (!point.alignmentpointid) {
        point.alignmentpointid = `placeholder-${uuidv4()}`;
      }
      point.order = index;
    });
  
    // Create a new alignment with the updated points and return it
    const newAlignment = new (this.constructor as any)(combinedAndOrderedPoints, this.name, this.alignmentid);
    return newAlignment as this;
  }

  /**
   * Creates a center line for the alignment.
   *
   * @returns {Feature<LineString, GeoJsonProperties>} A GeoJSON LineString feature that represents the center line.
   *                                            Loads properties 
   */
  createCenterLine(): Feature<LineString, GeoJsonProperties>  | null {
    if (this.points.length < 2) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [[]]
        }
      };
    } else {
      // Map over the points and extract the lat and lon properties
      const coordinates: any[] = [];
      const properties = {};
  
      this.points.forEach((point, index) => {
        // Ensure the point is an instance of AlignmentPerpendicularItemized
        if (point instanceof AlignmentPerpendicularItemized) {
          // Load all properties from the point
          const { lat, lon, width, bearingMode, alignmentpointid, nodeOSM, items, bends } = point;
          // Use the lat and lon for the lineString, but you can use the other properties as needed
          coordinates.push([lon, lat]);
  
          // Add the properties to the properties object
          properties[index] = { width, bearingMode, alignmentpointid, nodeOSM, items, bends };
        } else {
          coordinates.push([point.lon, point.lat]);
        }
      });
  
      this.centerLine = lineString(coordinates, properties);
  
      return this.centerLine;
    }
  }

  /**
   * Creates a right-of-way polygon around the alignment.
   *
   * @returns {Feature<Polygon, GeoJsonProperties>} A GeoJSON Polygon feature that represents the right-of-way.
   */
  createRightOfWayPolygon(): Feature<Polygon, GeoJsonProperties> {
    if (this.points.length < 2) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[]]
        }
      };
    } else if (this.points.length === 1) {
      const thisPoint  = this.points[0];
      // draw a circle
      const circleRow = circle(
        [thisPoint.lon, thisPoint.lat], 
        Math.max(thisPoint.width.right,thisPoint.width.right), 
        { units: 'feet' });

        return circleRow
    }

    // Create arrays of points for the left and right sides of the polygon
    const rightSide = this.points.map((point, index) => {
      const previousPoint = index > 0 ? this.points[index - 1] : undefined;
      const nextPoint = index < this.points.length - 1 ? this.points[index + 1] : undefined;
      return point.rightPoint(point.width.right, previousPoint, nextPoint).geometry.coordinates;
    });
    

    const leftSide = this.points.map((point, index) => {
      const previousPoint = index > 0 ? this.points[index - 1] : undefined;
      const nextPoint = index < this.points.length - 1 ? this.points[index + 1] : undefined;
      return point.leftPoint(point.width.left, previousPoint, nextPoint).geometry.coordinates;
    }).reverse();

    // Create lineArcs for the first and last points
    const firstPoint = this.points[0];
    const lastPoint = this.points[this.points.length - 1];
    const firstLineArc = lineArc(
      [firstPoint.lon, firstPoint.lat],
      Math.max(firstPoint.width.left, firstPoint.width.right),
      bearing([firstPoint.lon, firstPoint.lat], rightSide[0]),
      bearing([firstPoint.lon, firstPoint.lat], leftSide[leftSide.length-1]),
      { units: 'feet' },
    ).geometry.coordinates;

    const lastLineArc = lineArc(
      [lastPoint.lon, lastPoint.lat],
      Math.max(lastPoint.width.left, lastPoint.width.right),
      bearing([lastPoint.lon, lastPoint.lat], leftSide[0]),
      bearing([lastPoint.lon, lastPoint.lat], rightSide[rightSide.length-1]),
      { units: 'feet' },
    ).geometry.coordinates;

    // Combine the points and lineArcs into a single array of coordinates
    const polygonPoints = [
      ...rightSide, 
      ...lastLineArc,
      ...leftSide, 
      ...firstLineArc,
      rightSide[0]
    ];

    // Create the polygon
    const rightOfWayPolygon = polygon([polygonPoints]);

    return rightOfWayPolygon;
  }


  /**
   * Creates a series of LineString features representing the alignment points.
   * Each LineString feature represents a segment of the alignment with a specific width.
   *
   * @returns {Feature<LineString, GeoJsonProperties>[]} An array of GeoJSON LineString features. Each feature represents an alignment point.
   */
  createAlignmentPoints(): Feature<LineString, GeoJsonProperties>[] {
    return this.points.map((point, index) => {
      const previousPoint = index > 0 ? this.points[index - 1] : undefined;
      const nextPoint = index < this.points.length - 1 ? this.points[index + 1] : undefined;
      const bearing0 = BearingMode.getBearing(point.bearingMode, point, previousPoint, nextPoint);
  
      const start = destination([point.lon, point.lat], point.width.left, bearing0 - 90, { units: 'feet' });
      const end = destination([point.lon, point.lat], point.width.right, bearing0 + 90, { units: 'feet' });
      const line = lineString([start.geometry.coordinates, end.geometry.coordinates]);
  
      return {
        type: 'Feature',
        properties: {
          id: `${index}`,
          widthLeft: point.width.left,
          widthRight: point.width.right,
          nodeOSM: point.nodeOSM,
        },
        geometry: line.geometry
      } as Feature<LineString, GeoJsonProperties>;
    });
  }

  /**
   * Updates the features of the alignment based on its points.
   */
  updateFeatures(): void {
    if (this.points.length === 0 ) {
      // no points
      this.pointFeatures = []
      this.centerLine = null
      this.rightOfWay = null

    } else if (this.points.length === 1) {
      this.pointFeatures = this.createAlignmentPoints();
      this.rightOfWay = this.createRightOfWayPolygon();

    } else {
      this.pointFeatures = this.createAlignmentPoints();
      this.centerLine = this.createCenterLine();
      this.rightOfWay = this.createRightOfWayPolygon();
    }
  }

  /**
   * Converts the alignment to a GeoJSON FeatureCollection.
   *
   * @returns {FeatureCollection<Geometry, GeoJsonProperties>} A GeoJSON FeatureCollection that represents the alignment.
   */
  toGeoJSON(): FeatureCollection<Geometry, GeoJsonProperties> {
    if (!this.points) {
      return featureCollection([]);
    }

    const features = [this.centerLine, this.rightOfWay, ...this.pointFeatures].filter(feature => feature !== null) as Feature<Geometry, GeoJsonProperties>[];
    return featureCollection(features);
  }

}

export class AlignmentConduitOffset extends Alignment {
  conduitOffset: number;

  constructor(
    points: AlignmentPerpendicular[], 
    name: string = 'default', 
    alignmentid: number | null = null,
    conduitOffset: number = 0
  ) {
    super(points, name, alignmentid);
    this.conduitOffset = conduitOffset;
  }

  static fromPydanticAPI(pydantic: IPydanticOffsetAlignment): AlignmentConduitOffset {
    const points = pydantic.points.map(AlignmentPerpendicular.fromPydantic); // Assuming AlignmentPerpendicular also has a fromPydantic method
    return new AlignmentConduitOffset(points, pydantic.name, pydantic.alignmentid, pydantic.conduitOffset);
  }

  toPydanticAPI(): any {
    const pydanticAPI = super.toPydanticAPI();
    pydanticAPI.conduitOffset = this.conduitOffset;

    return pydanticAPI;
  }
}

export class AlignmentItemized extends AlignmentConduitOffset {
  points: AlignmentPerpendicularItemized[];
  items: Item[];
  bends: any[];

  constructor(
    points: AlignmentPerpendicularItemized[], 
    name: string = 'default', 
    alignmentid: number | null = null,
    items: Item[] = [],
    bends: any[] = [],
    conduitOffset: number = 0
  ) {
    super(points, name, alignmentid, conduitOffset);
    this.points = points;
    this.items = items;
    this.bends = bends;
  }

  createCenterLine(): Feature<LineString, GeoJSON.GeoJsonProperties> {
    if (this.points.length < 2) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [[]]
        }
      };
    } else {
      // Map over the points and extract the lat and lon properties
      const coordinates = this.points.map(point => [point.lon, point.lat]);
  
      // Create properties object
      const properties = this.points.reduce((acc, point, index) => {
        acc[`${index}`] = {
          width: point.width,  // TODO:  currently returning undefined
          bearingMode: point.bearingMode,
          alignmentpointid: point.alignmentpointid,
          nodeOSM: point.nodeOSM,
          items: point.items,
          bends: point.bends
        };
        return acc;
      }, {});

      this.centerLine = {
        type: 'Feature',
        properties: properties,
        geometry: {
          type: 'LineString',
          coordinates: coordinates
        }
      };
  
      return this.centerLine;
    }
  }

  static fromAlignment(alignment: Alignment | AlignmentConduitOffset): AlignmentItemized {
    // Convert each AlignmentPerpendicular to AlignmentPerpendicularItemized

  let conduitOffset = 0;
  if (alignment instanceof AlignmentConduitOffset) {
    conduitOffset = alignment.conduitOffset;
  }
    

    const points = alignment.points.map((point) => {
      if (point instanceof AlignmentPerpendicularItemized) {
        return new AlignmentPerpendicularItemized(
          point.lat,
          point.lon,
          point.width,
          point.bearingMode,
          point.alignmentpointid,
          point.nodeOSM,
          point.items,
          point.order,
        );
      } else if (point instanceof AlignmentPerpendicular) {
        return AlignmentPerpendicularItemized.fromAlignmentPerpendicular(point);
      } else {
        throw new Error('AlignmentItemized.fromAlignment() - point is not an instance of AlignmentPerpendicular or AlignmentPerpendicularItemized');
      }
    });
      
    return new AlignmentItemized(
      points, 
      alignment.name, 
      alignment.alignmentid,
      [],
      [],
      conduitOffset,

    );
  }

  static fromPydanticAPI(pydantic: IPydanticOffsetAlignment): AlignmentItemized {
    const baseAlignment = super.fromPydanticAPI(pydantic);
    
    const points = pydantic.points.map(AlignmentPerpendicularItemized.fromPydantic);
    return new AlignmentItemized(
      points, 
      baseAlignment.name, 
      baseAlignment.alignmentid,
    );
  }

  toPydanticAPI(): any {
    const pydanticAPI = super.toPydanticAPI();
    pydanticAPI.items = this.items;
    pydanticAPI.bends = this.bends;
    return pydanticAPI;
  }

  getItemPosition(locationid: number): Feature<Point, GeoJsonProperties> | null {
    let foundPoint: AlignmentPerpendicularItemized | null = null;
    let prevPoint: AlignmentPerpendicular | undefined = undefined;
    let nextPoint: AlignmentPerpendicular | undefined = undefined;
    for (let i = 0; i < this.points.length; i++) {
      const point = this.points[i];
      if (point.items.some(item => item.locationid === locationid)) {
        foundPoint = point;
        prevPoint = i === 0 ? undefined : this.points[i - 1];
        nextPoint = i === this.points.length - 1 ? undefined : this.points[i + 1];
        break;
      }
    }
    //TODO: We should simply render no Feature
    if (!foundPoint) {
      return null;
    }
  
    const item = foundPoint.items.find(item => item.locationid === locationid);
    if (!item) {
      return null;
    }
  
    const offset = item.offset;
    const itemPosition = offset < 0 ? foundPoint.leftPoint(Math.abs(offset), prevPoint, nextPoint) : foundPoint.rightPoint(offset, prevPoint, nextPoint);
  
    // 3. Return the calculated item position
    return itemPosition;
  }


  updatePointItem(item: Item, pointIndex: number) {
    const point = this.points[pointIndex];
  
    // Find the index of the item with the same locationid
    const itemIndex = point.items.findIndex(existingItem => existingItem.locationid === item.locationid);
  
    if (itemIndex !== -1) {
      point.items[itemIndex] = item;
    } else {
      point.addItem(item);
    }
  }

  removePointItem(pointIndex: number, locationid: number) {
    const point = this.points[pointIndex];
    point.removeItem(locationid);
    this.updateFeatures();

    return null
  }

  removeItem(itemId: number) {
    this.items = this.items.filter(item => item.locationid !== itemId);
    this.updateFeatures();

    return null
  }

}