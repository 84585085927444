
import React from 'react';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import IconFactory, { iconPaths } from '../../graphics/iconFactory';

export default function IconPicker({ className, selectedIcon, onSelectIcon }) {
  const icons = Object.keys(iconPaths);

  return (
    <RadioGroup className={className} value={selectedIcon} onChange={(e) => { onSelectIcon(e.target.value) }}>
      <Grid container spacing={1}>
        {icons.map((name) => {
          const icon = IconFactory.createReactIcon(name);
          if (!icon) {
            return null;
          }
          return (
            <Grid key={name} item xs={12} sm={6}>
              <FormControlLabel
                value={name}
                control={<Radio color="primary" />}
                label={icon}
              />
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
