import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';

import CreateOrganization from "./organization/createOrganization";
import { ProjectIndex } from '../components/managementItems/projectIndex';
import JoinByCode from '../components/transfers/joinByCode';


export default function ReturningUser() {

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setLoginModalOpen(true);
  };

  const handleCloseDialog = (resData) => {
    setLoginModalOpen(false);
  };
  function handleJoinByCode() {
    handleOpenDialog(<JoinByCode onClose={handleCloseDialog} />);
  }


  return (
    <>
      <Paper
        sx={{
          m: 1,
          p: 2,
          border: "1px solid grey",
          borderRadius: "0.5rem",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        }}
      >
        <Dialog open={loginModalOpen} onClose={handleCloseDialog}>
          <Box sx={{ p: 4 }}>{dialogContent}</Box>
        </Dialog>
        <Button variant= "outlined" className="create-org-button" onClick={() => handleOpenDialog(<CreateOrganization onClose={handleCloseDialog} />)}>Create Organization</Button>
        <Button variant= "outlined" onClick ={handleJoinByCode}>Join By Code</Button>
        <ProjectIndex />
      </Paper>
      <Box sx={{ mt: 2 }}>
        <Button
          className="org-button"
          variant="contained"
          color="primary"
          component={RouterLink} 
          to="/organizations" 
          sx={{ borderRadius: "0.5rem" }}
        >
          Go to Organizations
        </Button>
      </Box>
    </>
  );
}
