
import { React, useState, useReducer, useEffect } from "react";
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

import { UseServerData, PostServerData, DeleteServerData } from '../../util/dbfetch';
import { stateListReducer, STATE_ACTION } from "../../components/stateReducer";

import ConfirmDialog from '../../components/confirmDialog';
import JoyRideStarter from "../../components/joyRideStarter";
import LocationOptionTabler from "./locationOptionTabler";

import useSnackBarContext from '../../util/providers/snackbarProvider';

/* Function to display options for some instance

  1.  Get the Server Choices from the database
  2.  for each choice, reduce the database table by the initial choices
  3.  Populate options from the remaining choices

  4.  If User wants everything... give them everything


  X.  Get the rowID of the selection, and add to properties
  x.1.Server to use rowID if exists ( How do we clear this  without wiping out all properties)

*/


export default function LocationOptionSelector() {
  const { instanceID } = useParams();

  const [choices, dispatch] = useReducer((state, action) => stateListReducer(state, action, 'selectionid'), []);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const { openSnackbar } = useSnackBarContext();

  const steps = [
    {
      target: '.location-choice-box',
      content: 'These are selections the calculator tools could not automatically select',
      disableBeacon: true,
    },
    {
      target: '.location-choices-table-container',
      content: 'Select an option to set that location and for the calculator to use',
    },
    {
      target: '.save-button',
      content: 'Save your selections to the database.  These will selections will be used for future calculations, even after deleting calculations.',
    },
    {
      target: '.purge-selections-button',
      content: 'Reset calculations completely by resetting selections.',
    },
  ];



  useEffect(() => {
    UseServerData(`instance/${instanceID}/tables`, setInitialState, setLoading, setError);
  }, [instanceID]);

  function setInitialState(data) {
    // interpret table data to desired format
    function interpretData(innerData) {
      const tableRaw = JSON.parse(innerData);
      const tableData = tableRaw.data.map((row, index) => {
        let rowObject = { index: tableRaw.index[index] };
        tableRaw.columns.forEach((header, i) => {
          rowObject[header] = row[i];
        });
        return rowObject;
      });
      return tableData.reduce((acc, row) => {
        acc[row.index] = row;
        return acc;
      }, {});
    }

    dispatch({
      type: STATE_ACTION.LOAD_INITIAL,
      payload: data.map((selection) => ({
        ...selection,
        rows: interpretData(selection.data)
      }))
    });
  }


  function handleSelection({ selectionid, index }) {
    dispatch({
      type: STATE_ACTION.UPDATE,
      selectionid: selectionid,
      payload: {
        selection: index
      }
    });
  }


  const simplifiedChoices = choices.map(({ location_source, tables, ...choice }) => ({
    locid: location_source.locationid,
    loc_number: location_source.loc_number,
    equip_key: location_source.equip_key,
    tables,
    property_list: Object.fromEntries(location_source.property_list.map(({ key, value }) => [key, value])),
    ...choice
  }));


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  function handlePurgeChoices() {
    DeleteServerData(`instance/${instanceID}/purge_options`,
      () => { openSnackbar("Selections Purged. Rerun Calculations.") },
      (err) => { openSnackbar(err) }, { liableParty: 'client' });
  }

  function handleSubmitTableState() {
    const missingSelections = choices.filter((selectObject) => selectObject.selection === null);
    if (missingSelections.length > 0) {
      const missingSelectionsString = missingSelections.map((selectObject) => `location ${selectObject.location_source.loc_number}-${selectObject.table_name}`);

      const MessageComponent = () => (
        <div>
          Please select a table for the following:
          {missingSelectionsString.map((missingSelection, index) => (
            <div key={index}>{missingSelection}</div>
          ))}
        </div>
      );

      handleOpenDialog(
        <ConfirmDialog
          message={<MessageComponent />}
          onConfirm={() => {
            setDialogOpen(false)
          }}
          onCancel={() => setDialogOpen(false)}
          delayTime={0}
        />
      );
      return;
    }

    const locationTableChoice = choices.map((loc) => ({ locationid: loc.location_source.locationid, table_name: loc.table_name, loc_number: loc.location_source.loc_number, selectionid: loc.selectionid, selection: loc.selection }));

    if (locationTableChoice.length === 0) {
      return;
    }

    handleOpenDialog(
      <ConfirmDialog
        message="After saving these choices you will need to reset and rerun the calculations for this instance."
        onConfirm={() => {
          // Other Functions
          PostServerData(`instance/${instanceID}/table_selects`, locationTableChoice, () => { openSnackbar("Selections Saved. Rerun Calculations.") }, (err) => { openSnackbar(err) }, { liableParty: 'client' });
          setDialogOpen(false)
        }}
        onCancel={() => setDialogOpen(false)}
      />
    )
  }

  return (
    <Box className="location-choices-container" sx={{ paddingBottom: '20px' }} >
      <JoyRideStarter steps={steps} />
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Button className="purge-selections-button" variant="contained" color="primary"
        onClick={() => {
          handleOpenDialog(
            <ConfirmDialog
              message="This will purge all selections for this instance.  Are you sure you want to continue?"
              onConfirm={() => {
                // Other Functions
                handlePurgeChoices()
                setDialogOpen(false)
              }}
              onCancel={() => setDialogOpen(false)}
              delayTime={1000}
            />
          )
        }
        }>Purge Selections</Button>

      <Button className="save-button" variant="contained" color="primary"
        onClick={handleSubmitTableState}>Save Selections</Button>
      {loading ? (
        <Typography className="loading-text" component="span">Loading Defaults...</Typography>
      ) : (
        error !== "" ? (
          <Typography className="error-text" component="span">{error}</Typography>
        ) : (
          simplifiedChoices.length > 0 && (
            <Box>
              {simplifiedChoices.sort((a, b) => a.locid - b.locid).map((choice) => (
                <Box
                  className="location-choice-box"
                  sx={{ overflow: 'auto', maxHeight: '400px', maxWidth: '100%', border: '1px solid black' }}
                  display="flex"
                  flexWrap="wrap"
                  key={`${choice.locid}-${choice.selectionid}`}>

                  <LocationOptionTabler className="table-picker" choice={choice} onSelection={(e) => handleSelection(e)} />

                </Box>
              ))}
            </Box>
          )
        )
      )}
    </Box>
  );
}
