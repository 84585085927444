import React, { useState, useEffect, useRef } from 'react';

import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Pane,
} from 'react-leaflet';
import { bearing } from '@turf/turf';

import 'leaflet-rotatedmarker';
import IconFactory from '../graphics/iconFactory';

import AlignmentFeatureEditControl from './alignmentFeatureEditControl.jsx';

export default function EditedAlignmentMap({
  renderKey,
  rerenderTrigger,
  originalAlignment,
  origPointFeatures,
  setOriginalAlignment,
  locationArray,
  currentLocationid,
  handleMapFeatureClick,
  selectedIndex,
  setPendingChanges,
}) {

  const [selectedPointFeature, setSelectedPointFeature] = useState(null);
  const [markers, setMarkers] = useState([]);

  const middleIndex = Math.floor(originalAlignment.points.length / 2);
  const centerPoint = originalAlignment.points[middleIndex];

  const mapRef = useRef(null);

  useEffect(() => {
    if (originalAlignment) {
      const correspondingPointFeature = origPointFeatures[selectedIndex];
      setSelectedPointFeature(correspondingPointFeature);

      const newMarkers = originalAlignment.points.flatMap((point, index) => {
        if (point?.items) {
          return point.items.map((item) => {
            const itemPosition = originalAlignment.getItemPosition(item.locationid);
            const pointRotation = bearing(
              [point.lon, point.lat],
              [itemPosition.geometry.coordinates[0], itemPosition.geometry.coordinates[1]]
            );

            let itemRotation = 0

            const finalRotation = itemRotation + pointRotation + Number(item.angle);

            const equipmentLoc = locationArray.find(equip => String(equip.locationid) === String(item.locationid));

            const equipIcon = equipmentLoc ? (IconFactory.createLeafletIcon(equipmentLoc.equip_key)) : null;

            // I should edit the key to handle duplicate item.locationid keys
            
            return ( equipIcon &&
              <Marker
                icon={equipIcon}
                rotationAngle={finalRotation}
                key={`${item.locationid}-${index}`}
                position={[itemPosition.geometry.coordinates[1], itemPosition.geometry.coordinates[0]]}
              />
            );
          });
        }
        return [];
      });

      setMarkers(newMarkers);
    }
  }, [locationArray, originalAlignment, origPointFeatures, selectedIndex]);


  const handleSaveEditChanges = (editedAlignment) => {
    setOriginalAlignment(editedAlignment);
    setPendingChanges(true);
    rerenderTrigger();
  }

  const equip = locationArray.find(equip => String(equip.locationid) === String(currentLocationid));

  const icon = equip ? (IconFactory.createLeafletIcon(equip.equip_key, 'leaflet-icon-purple')) : null;
  const selectedIcon = equip && icon ? (
    <Marker
      icon={icon}
      key={equip.locationid}
      position={[equip.position.lat, equip.position.lng]}
    />
  ) : null;

  return (
    <MapContainer
      style={{ height: "400px", width: "100%", paddingLeft: "10px", paddingRight: "10px" }}
      center={[centerPoint.lat, centerPoint.lon]}
      zoom={16}
      maxZoom={24}
      ref={mapRef}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <AlignmentFeatureEditControl
        originalAlignment={originalAlignment}
        handleSaveEditChanges={handleSaveEditChanges}
      />
      <Pane name="selectionPane" style={{ zIndex: 301 }} >
        {selectedIcon}
      </Pane>
      {origPointFeatures.map((pointFeature, index) => (
        <GeoJSON
          key={`${renderKey}-${index}`}
          data={pointFeature}
          style={() => ({
            color: pointFeature === selectedPointFeature ? 'red' : '#3388ff',
          })}
          onEachFeature={(feature, layer) => {
            layer.on({
              click: (e) => {
                setSelectedPointFeature(feature);
                handleMapFeatureClick(index, feature)
              },
            });
          }}
        />
      ))}
      {markers}
    </MapContainer>
  )
}