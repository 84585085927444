import {useState, useEffect} from 'react';

import ProjectPermissions from '../models/projectPermissions.ts';

import { UseServerData } from "../util/dbfetch.js";


function useProjectPermissions(projID) {
  const [permissions, setPermissions] = useState(new ProjectPermissions());
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  useEffect(() => {
    if (!projID) {
      return;
    }

    UseServerData(
      `project/${projID}/permissions`, 
      (data) => {
        const { plan_tier, saves_left, other_permissions } = data;
        setPermissions(new ProjectPermissions(plan_tier, saves_left, other_permissions));
      },
      setPermissionsLoading, 
      (err)=> {console.log(err)}
  )
  }, [projID]);

  return [permissions, permissionsLoading];
}

export default useProjectPermissions;