import React, { useState } from "react";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useStripeConnect } from "./useStripeConnect";
import { PostServerData } from '../../util/dbfetch';


export default function StripeCreate() {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);


  const handleGetAccount = () => {
    PostServerData(
      'stripe-onboarding',
      {},
      (response) => {
        setAccountCreatePending(false);
        setConnectedAccountId(response.data.account);
      },
      (err) => {
        setAccountCreatePending(false);
        setError(err);
      }
    );
  }

  return (
    <Box className="container">
      <Box className="banner">
        <Typography variant="h2">Utility Design App By Sherman Procedural Designs LLC</Typography>
        <Typography variant="h4">Organization Registration</Typography>
      </Box>
      <Box className="content">
        {!accountCreatePending && !connectedAccountId && (
          <Box>
            <Button
              variant = "outlined"
              onClick={handleGetAccount}
            >
              Sign up
            </Button>
          </Box>
        )}
        {stripeConnectInstance && (
          <Paper sx={{width: "80vh", px:4, py:2, background: "white"}}>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding
                onExit={() => {}}
              />
            </ConnectComponentsProvider>
          </Paper>
        )}
        {error && <p className="error">Something went wrong!</p>}
      </Box>
    </Box>
  );
}