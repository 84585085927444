import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Modal from 'react-modal'

import './index.css';

Modal.setAppElement('#root')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// const theme = createTheme({
//   palette: {
//     type: darkMode ? 'dark' : 'light',
//     primary: {
//       main: '#0066cc',
//       light: '#3385ff',
//       dark: '#0047b3',
//     },
//     secondary: {
//       main: '#ff9900',
//       light: '#ffc233',
//       dark: '#b26500',
//     },
//   },
//   typography: {
//     fontFamily: 'sans-serif',
//     fontSize: 16,
//     h1: {
//       fontSize: '2.4rem',
//       fontWeight: 700,
//     },
//     h2: {
//       fontSize: '2rem',
//       fontWeight: 500,
//     },
//     body2: {
//       fontSize: '1.2rem',
//       lineHeight: 1.2,
//     },
//   },

//   shape: {
//     borderRadius: 8,
//   },
//   spacing: 8,
//   components: {
//     MuiInputBase: {
//       styleOverrides: {
//         root: {
//           fontSize: '1rem',
//         },
//       }
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           fontSize: '1rem',
//         },
//       }
//     },
//   },
// });
