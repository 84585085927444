import React, { useState, useEffect } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


import { PostServerData } from '../../util/dbfetch';

export default function StripePurchases() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noCustomerId, setNoCustomerId] = useState(false);

  useEffect(() => {
    const root = `${window.location.protocol}//${window.location.host}`;
    const redirects = {
      refresh_url: `${root}/user/purchases`,
      return_url: `${root}`,
    };

    const handleOpenPortal = (response) => {
      setLoading(false);
      window.location.href = response.data.url;
    };

    PostServerData(
      'customer-portal',
      redirects,
      handleOpenPortal,
      (err) => {
        setLoading(false);
        if (err.status === 441) {
          setNoCustomerId(true);
        } else {
          setError(err.response.data.detail);
        }
        console.error('Error fetching Stripe account management link:', err);
      }
    );
  }, []);

  const handleCreateCustomer = () => {
    setLoading(true);
    PostServerData(
      'create-customer',
      {},
      () => {
        setLoading(false);
        window.location.reload();
      },
      (err) => {
        setLoading(false);
        setError(err.response.data.detail);
        console.error('Error creating Stripe customer:', err);
      }
    );
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (error) {
    return (
      <Box>
        <Typography>Error</Typography>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  if (noCustomerId) {
    return (
      <Box>
        <Typography variant = "h3">No Stripe Account</Typography>
        <Typography>Your Stripe account was not found. Please create an account.</Typography>
        <Button variant="contained" color="primary" onClick={handleCreateCustomer}>
          Create Stripe Account
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h3">Manage Stripe Account</Typography>
    </Box>
  );
}