import React, { useEffect, useState } from 'react';
import {
  useNavigate, useParams
} from "react-router-dom"

import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { DeleteServerData, PostServerData, UseServerData } from "../../util/dbfetch";
import { Accordion, AccordionSummary, AccordionDetails, Box, FormControl, InputLabel, Select, MenuItem, TextField, Dialog } from '@mui/material';
import { STATE_ACTION } from '../stateReducer';

import { RowSelectContainerObject } from '../rowSelectContainer';
import TransferResponsibilityProject from '../transfers/transferResponsibilityProject';

import JoyRideStarter from '../joyRideStarter';
import ConfirmDialog from '../confirmDialog';

import { useTutorial } from '../../util/providers/useTutorialContext';
import useUserDataContext from '../../util/providers/userDataProvider';
import useSnackbarContext from '../../util/providers/snackbarProvider';


export default function ProjectHeader({ projectData, onDispatch }) {
  const { projID } = useParams();
  const navigate = useNavigate();
  const { handleProgress } = useTutorial();
  const { openSnackbar } = useSnackbarContext();


  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const [expanded, setExpanded] = useState(false);
  const [editingEnabled, setEditingEnabled] = useState(false);

  const [globalSelects, setGlobalSelects] = useState({});
  const [forceChange, setForceChange] = useState(false);
  const [contactEmail, setContactEmail] = useState("editors");
  const [projectOriginal, setProjectOriginal] = useState({});
  

  const { refreshData } = useUserDataContext();


  const projEditors = projectData?.editors || [];


  const steps = [
    {
      target: '.project-detail-fields',
      content: "Here are the project's name, PM, notification number, and description. Enabling edit mode in the Project Settings will allow you to edit these fields.",
      disableBeacon: true,
    },
    {
      target: '.global-selects-container',
      content: 'Here are various global settings for the project.',
    },
    {
      target: '.enable-editing-button',
      content: 'You can activate edit mode here.  This will allow you to edit the project name, description, and globals. You can also add editors here if you are an editor already.',
      placement: 'right',

    },
    {
      target: '.new-instance-placeholder',
      content: 'Below Here you can create a new instance in the project or edit an existing one.',
      placement: 'top',

    },
  ];

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  const handleGlobalsChange = (event, key) => {
    onDispatch({
      type: STATE_ACTION.UPDATE,
      payload: { globals: { [key]: event.target.value } },
    });

    //For some reason the useEffect never sees the projectData update. Force it here
    setForceChange(!forceChange)
  };

  useEffect(() => {
    setGlobalSelects({
      selections: {
        climate_zone: {
          selection: projectData.globals?.climate_zone || "",
          options: ["R", "X", "P", "Y", "Z", "T", "Q", "W", "V", "S"],
        },
        insulation_district: {
          selection: projectData.globals?.insulation_district || "",
          options: ["D", "C", "B", "A", "AA", "AAA"],
        },
        fire_area: {
          selection: projectData.globals?.fire_area || "",
          options: ["LRA", "SRA"],
        },
        fire_threat_district: {
          selection: projectData.globals?.fire_threat_district || "",
          options: [1, 2, 3],
        },
        voltage_district: {
          selection: projectData.globals?.voltage_district || "",
          options: [1, 2, 3],
        },
        corrosion_area: {
          selection: projectData.globals?.corrosion_area || "",
          options: ["NON", "MODERATE", "SEVERE"],
        },
        snow_loading: {
          selection: projectData.globals?.snow_loading || "",
          options: ["LIGHT", "MEDIUM", "HEAVY"],
        },
        surge_protection: {
          selection: projectData.globals?.surge_protection || "",
          options: [1, 2, 3],
        },
      }
    });
  }, [projectData, forceChange]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const handleEnableEditing = () => {
    if (!editingEnabled) {
      setProjectOriginal(JSON.parse(JSON.stringify(projectData)));
    } else {
      onDispatch({
        type: STATE_ACTION.LOAD_INITIAL,
        payload: projectOriginal,
      });
    }
    setEditingEnabled(!editingEnabled);
  };

  const handleTextChange = (field, e) => onDispatch(
    {
      type: STATE_ACTION.UPDATE,
      payload: { [field]: e.target.value }
    });

  const handleSaveChanges = (event) => {
    // Save changes to database

    setEditingEnabled(false);
    PostServerData(`project/${projID}/update`,
      projectData,
      () => { openSnackbar("Project Updated"); refreshData(); },
      (response) => openSnackbar(response)
    );

    handleProgress(event.target.className)
  };

  const handleEditorChanges = (e) => {
    const selectedUserIds = e.target.value;
    const selectedUsers = projectData.organization.members.filter((user) =>
      selectedUserIds.includes(user.userid)
    );

    onDispatch({
      type: STATE_ACTION.UPDATE,
      payload: { "editors": selectedUsers },
    });
  };

  const handleEmailContact = (contact) => {
    UseServerData(`${projID}/getEmail/${contact}`, 
      (data) => {
        const emails = data.emails.join(',');
        const subject = encodeURIComponent(`Regarding Project ${projectData.proj_name}`);
        const mailtoUrl = `mailto:${emails}?subject=${subject}`;
        window.open(mailtoUrl);
      },
      () => {},
      (err) => {
        console.error('Error fetching email:', err);
      });
  }

  return (
    <>
      <Box className="project-detailer">
        <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
        </Dialog>
        <JoyRideStarter steps={steps} onStart={() => setExpanded(true)} sx={{ height: '0px', display: 'flex', justifyContent: 'flex-end' }} />
        <Box className="project-detail-fields" sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
          {editingEnabled ? (
            <>
              <TextField
                sx={{ py: 1 }}
                className="project-name-input"
                label="Project Name"
                value={projectData.proj_name || ''}
                onChange={(e) => handleTextChange("proj_name", e)}
                inputProps={{
                  maxLength: 30,
                }}
              />
              <TextField
                sx={{ py: 1 }}
                className="project-pm-input"
                label="Project Management Number"
                value={projectData.pm || ''}
                onChange={(e) => handleTextChange("pm", e)}
                inputProps={{
                  maxLength: 8,
                }}
              />
              <TextField
                sx={{ py: 1 }}
                className="project-notification-input"
                label="Notification Number"
                value={projectData.notification || ''}
                onChange={(e) => handleTextChange("notification", e)}
                multiline
                inputProps={{
                  maxLength: 12,
                  type: 'number',
                }}
              />
              <TextField
                sx={{ py: 1 }}
                className="project-description-input"
                label="Project Description"
                value={projectData.proj_desc || ''}
                onChange={(e) => handleTextChange("proj_desc", e)}
                multiline
                inputProps={{
                  maxLength: 255,
                  type: 'number',
                }}
              />
            </>
          ) : (
            <>
              <Typography sx={{ py: 1 }} className="project-name" variant="h1">{projectData.proj_name || 'Project Name'}</Typography>
              <Typography sx={{ py: 1 }} className="project-pm" >PM: {projectData.pm || 'TBD'}</Typography>
              <Typography sx={{ py: 1 }} className="project-notification" >Notif: {projectData.notification || 'TBD'}</Typography>
              <Typography sx={{ py: 1 }} className="project-description" variant="h6">{projectData.proj_desc || 'Project Description TBD'}</Typography>
            </>
          )}
        </Box>
        {Object.keys(projectData).length > 0 && (

          <Accordion className={expanded ? "project-settings" : "project-settings tutorial-save-changes-button"} expanded={expanded} onChange={handleAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Project Settings and Functions</Typography>
            </AccordionSummary>
            {Object.keys(projectData).length > 0 && (
              <>
                {editingEnabled ? (
                  <FormControl fullWidth margin="normal">
                    <InputLabel className="editors-label" id="editors-label">Editors</InputLabel>
                    <Select
                      className="editors-select"
                      labelId="editors-label"
                      id="editors"
                      value={projEditors.map(editor => editor.userid)}
                      onChange={(e) => handleEditorChanges(e)}
                      multiple
                    >
                      {projectData.organization.members.map((user) => (
                        <MenuItem key={user.userid} value={user.userid}>{user.username}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth margin="normal">
                    <InputLabel className="editors-label-disabled" id="editors-label-disabled">Editors</InputLabel>
                    <Select
                      className="editors-select-disabled"
                      labelId="editors-label-disabled"
                      id="editors-disabled"
                      value={projEditors.map(editor => editor.userid)}
                      disabled
                      multiple
                    >
                      {projEditors.map((editor) => (
                        <MenuItem key={editor.userid} value={editor.userid}>{editor.username}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <AccordionDetails className="project-globals" style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className="project-globals-title" variant="h6">Project Globals</Typography>
                  <Box className="global-selects-container" sx={{ border: '1px solid black', borderRadius: '0.5rem', m: 1, p: 2 }}>
                    {Object.keys(globalSelects).length === 0 ? (
                      <Typography>Loading....</Typography>
                    ) : (
                      <RowSelectContainerObject
                        item={globalSelects}
                        otherReqKeys={[]}
                        handleOptionChange={handleGlobalsChange}
                        disabled={!editingEnabled}
                      />
                    )}
                  </Box>
                  <Box sx={{ m: 2 }}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ py: 1 }} className="project-design-organization" variant="body1">
                          Design Organization: {projectData?.organization?.organization_name || "None Set"}
                        </Typography>
                        <Tooltip title="Go to Organization Page">
                          <IconButton
                            className="tutorial-parent-org-button"
                            sx={{ pl: 2 }}
                            disabled={!projectData?.organization}
                            onClick={(event) => {
                              navigate(`/organizations/${projectData?.organization?.organizationid}`);
                              handleProgress("tutorial-parent-org-button");
                            }}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Button variant="outlined" onClick={() => handleEmailContact(contactEmail)}>
                          Contact:
                        </Button>
                        <Select
                          className="project-status-select"
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                          sx={{ ml: 1 }}
                        >
                          <MenuItem value="editors">Editors</MenuItem>
                          <MenuItem value="manager">Org Manager</MenuItem>
                          <MenuItem value="sponsor">Sponsor</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                    
                  </Box>
                  <Box className="project-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Button className="delete-project-button" variant="contained" color="secondary" onClick={() =>
                        handleOpenDialog(
                          <ConfirmDialog
                            title="Delete Project"
                            message={`Are you sure you want to delete project ${projID}?`}
                            onConfirm={() => {
                              DeleteServerData(`project/${projID}/delete`,
                                () => { navigate(`/project`); refreshData(); },
                                (response) => { openSnackbar(response) });
                              setDialogOpen(false);
                            }}
                            onCancel={() => setDialogOpen(false)}
                            delayTime={3000}
                          />
                        )}>Delete Project</Button>
                    <Button variant="contained" onClick={() => navigate(`/project/${projID}/invoices`)}>View Invoices</Button>

                    <Button variant="outlined" onClick={() =>
                      handleOpenDialog(
                        <TransferResponsibilityProject project={projectData} />
                      )}>Grant or Transfer Access</Button>

                    <Box>
                      <Button className={editingEnabled ? "enable-editing-button" : "tutorial-save-changes-button enable-editing-button"} variant="contained" color="primary" onClick={handleEnableEditing}>
                        {editingEnabled ? 'Disable Editing' : 'Enable Editing'}
                      </Button>
                      {editingEnabled && (
                        <Button className="tutorial-save-changes-button save-changes-button" variant="contained" color="primary" onClick={handleSaveChanges}>
                          Save Changes
                        </Button>
                      )}
                    </Box>

                  </Box>
                </AccordionDetails>
              </>
            )}
          </Accordion>
        )}
        <Box className="new-instance-placeholder" sx={{ display: 'flex', width: "100px", border: "1px black" }} />
      </Box>
    </>
  );
}