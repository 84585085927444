import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { parseISO, isValid} from 'date-fns';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from '@mui/material/DialogActions';


import { UseServerData, PostServerData, DeleteServerData } from "../../util/dbfetch";

import useUserDataContext from '../../util/providers/userDataProvider.jsx';
import CheckoutFormPrice from '../stripe/checkoutFormPrice.jsx';

import ConfirmDialog from "../../components/confirmDialog";
import useSnackbarContext from "../../util/providers/snackbarProvider";

import TransferResponsibilityOrganization from "../../components/transfers/transferResponsibilityOrganization";


export default function OrganizationManagerView({
  organization, users,
  activeProjects,
  onOpenDialog, onCloseDialog,
  isManagerVerified, setIsManagerVerified
}) {
  const navigate = useNavigate();
  const { orgID } = useParams();
  const [manager, setManager] = useState(false)

  const [selectedNewManager, setSelectedNewManager] = useState(null);

  const [selectedWorkstreams, setSelectedWorkstreams] = useState([]);
  const allWorkstreams = [...organization.workstreams, ...organization.workstreams_workable];
  const uniqueWorkstreams = Array.from(new Set(allWorkstreams.map(w => w.utility)))
    .map(utility => {
      return allWorkstreams.find(w => w.utility === utility)
    });

  const { refreshData } = useUserDataContext();

  const { openSnackbar } = useSnackbarContext();

  const handledDeletedOrganization = () => {
    onCloseDialog();
    navigate('/organizations');
    openSnackbar("Organization deleted");
  }


  useEffect(() => {
    if (organization && users) {
      setManager(users?.find(user => user.userid.toString() === organization?.managerid.toString()))
      let man = users?.find(user => user.userid.toString() === organization?.managerid.toString())
      setSelectedNewManager(man)

      setSelectedWorkstreams(organization?.workstreams)
    }

  }, [organization, users])

  useEffect(() => {
    if (orgID) {
      UseServerData(`organizations/${orgID}/verify-manager`,
        resp => setIsManagerVerified(resp), () => { }, () => setIsManagerVerified(false),);
    }
  }, [orgID, setIsManagerVerified])


  const handleTransferManager = () => {
    onOpenDialog(
      <ConfirmDialog
        title="Transfer Management"
        message="Are you sure you want to transfer the management of this organization?"
        onConfirm={() => {
          PostServerData(`organizations/${orgID}/transfer-management`, selectedNewManager, () => window.location.reload(), (resp) => openSnackbar(resp));
          onCloseDialog();
          openSnackbar("Organization successfully transferred.");
        }}
        onCancel={() => onCloseDialog()}
      />
    );
  };


  const handleWorkstreamChange = (event) => {
    if (!isManagerVerified) {
      return;
    }
    const selectedValues = event.target.value;
    const newSelectedWorkstreams = selectedValues.map((utility) => {
      return uniqueWorkstreams.find((workstream) => workstream.utility === utility);
    });
    setSelectedWorkstreams(newSelectedWorkstreams);
  };

  const handleWorkstreamSubmit = () => {
    if (selectedWorkstreams.length === 0) {
      onOpenDialog(
        <ConfirmDialog
          title="No Workstreams Selected"
          message="You have not selected any workstreams. This means new potential clients will not be able to request quotes from your organization. Are you sure you want to continue?"
          onConfirm={() => {
            PostServerData(`organizations/${orgID}/update-workstreams`, selectedWorkstreams, () => { }, (resp) => openSnackbar(resp));
            refreshData();
            onCloseDialog();
          }}
          onCancel={() => onCloseDialog()}
        />
      );
    } else {
      onOpenDialog(
        <ConfirmDialog
          title="Update Workstreams"
          message="New potential clients on this platform will be able to request quotes from your organization for these selected workstreams. Are you sure you want continue?"
          onConfirm={() => {
            PostServerData(`organizations/${orgID}/update-workstreams`, selectedWorkstreams, () => { }, (resp) => openSnackbar(resp));
            refreshData();
            onCloseDialog();
          }}
          onCancel={() => onCloseDialog()}
        />
      );
    }
  };

  function handleDeleteOrganization(organizationid) {
    onOpenDialog(
      <ConfirmDialog
        title="Delete Organization"
        message="Are you sure you want to delete this organization?"
        onConfirm={() => {
          DeleteServerData(
            `organizations/delete/${organizationid}`,
            handledDeletedOrganization,
            (err) => {openSnackbar(err); onCloseDialog()}
          );
          refreshData();
        }}
        onCancel={() => onCloseDialog()}
        delay={1500}
      />
    );
  }


  return (
    <Paper className="tutorial-org-overview manager-view" sx={{ mb: 4, border: 1 }}>
      {manager && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Managed By: {manager.username}</Typography>
              {isManagerVerified ? (
                <Icon className="manager-icon" title="You are the Manager"> <AdminPanelSettingsIcon /></Icon>
              ) : (
                null
              )}
            </AccordionSummary>
            {isManagerVerified && (
              <AccordionDetails>
                <RegisterOrganizationBox 
                  plan_tier={organization.plan_tier} 
                  registration_due={organization.registration_due}
                  onOpenDialog={onOpenDialog} 
                  params={{organizationid : organization.organizationid}}
                />
                {["paid", "sub"].includes(organization.plan_tier) && (
                  <Box className="organization-workstream-change" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl sx={{ m: 1, width: '25ch' }}>
                      <InputLabel id="workstream-select-label">Accepting Workstreams</InputLabel>
                      <Select
                        labelId="workstream-select-label"
                        label="Accepting Workstreams"
                        id="workstream-select"
                        multiple
                        value={selectedWorkstreams.map((workstream) => workstream.utility)}
                        onChange={handleWorkstreamChange}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {uniqueWorkstreams.map((workstream) => (
                          <MenuItem key={workstream.workstreamid} value={workstream.utility}
                            disabled={!organization.workstreams_workable.some(w => w.utility === workstream.utility)}>
                            <ListItemText primary={workstream.utility} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button variant="contained" disabled={isManagerVerified ? false : true} onClick={handleWorkstreamSubmit}>
                      Save
                    </Button>
                  </Box>
                )}
                {manager && (
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start', justifyContent: 'space-between', gap: 2 }}>
                    <Box className="leftContainer">
                      <Button
                        variant="contained"
                        className="generate-invite-button"
                        onClick={() => onOpenDialog(
                          <TransferResponsibilityOrganization
                            organization={organization}
                          />
                        )}
                      >
                        Generate Organization Invite
                      </Button>
                      <Box sx={{ my: 2 }}>
                        <Typography>Transfer Management to:</Typography>
                        <Select
                          labelId="user-select-label"
                          id="user-select"
                          value={selectedNewManager ? selectedNewManager.username : ''}
                          onChange={(event) => setSelectedNewManager(event.target.value)}
                        >
                          {users.map((user) => (
                            <MenuItem key={user.userid} value={user.username}>
                              {user.username}
                            </MenuItem>
                          ))}
                        </Select>
                        <Button variant="contained" onClick={handleTransferManager}>Transfer</Button>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 4 }}>
                      <Button variant="contained" className="open-invoices-button" onClick={() => navigate(`/organizations/${orgID}/invoices`)}>View Invoices</Button>
                      <Button variant="contained" color= "secondary" className="delete-button" onClick={() => handleDeleteOrganization(orgID)}>Delete Organization</Button>
                    </Box>
                  </Box>
                )}

              </AccordionDetails>
            )}
          </Accordion>
        </>
      )}
    </Paper>
  )
}

function RegisterOrganizationBox({ plan_tier, registration_due, onOpenDialog, params}) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const org_price_id = process.env.REACT_APP_STRIPE_ORG_PRICE_ID

  const freeTierMessage = "This organization is not registered. Register to accept workstreams and become available for people to send you work.";
  const demoTierMessage = "This is a demo organization. This and all projects will be deleted after 30 days.";
  
  const formattedRegistrationDue = registration_due && isValid(parseISO(registration_due))
    ? parseISO(registration_due).toLocaleDateString()
    : null;

  const paidTierMessage = formattedRegistrationDue
    ? `Registered until ${formattedRegistrationDue}`
    : "Registered, but something went wrong on the server.";


  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  function handleRegisterOrganization() {
    setOpenConfirm(false);

    onOpenDialog(
      <CheckoutFormPrice
        targetURL= "create-payment-session-organization"
        price_id = {org_price_id}
        params = {params}
      />
    );
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderRadius: 1 }}>
      {plan_tier === 'free' && (
        <>
          <Typography variant="h6" sx={{ flexGrow: 1, whiteSpace: 'pre-line', maxWidth: "70%" }}>
            {freeTierMessage}
          </Typography>
          <Button variant="contained" onClick={handleOpenConfirm} >
            Register
          </Button>
        </>
      )}
      {plan_tier === 'demo' && (
        <>
          <Typography variant="h6" sx={{ flexGrow: 1, whiteSpace: 'pre-line', maxWidth: "70%" }}>
            {demoTierMessage}
          </Typography>
          <Button variant="contained" onClick={handleOpenConfirm} >
            Register
          </Button>
        </>
      )}
      {plan_tier === 'paid' && (
        <>
          <Typography variant="h6" sx={{ flexGrow: 1, whiteSpace: 'pre-line', maxWidth: "70%" }}>
            {paidTierMessage}
          </Typography>
        </>
      )}
      {!plan_tier && (
        <>
          <Button variant="contained" onClick={handleOpenConfirm} >
            Register
          </Button>
        </>
      )}

      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Confirm Registration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Registering your organization will allow others on the platform to solicit work form you.
            You will be discoverable through this platform's Request for Proposal system through workstreams.
            Workstream registration is handled on a case-by-case basis and an admin will contact you within the next week.
            <br /><br />
            Individual projects will still need to be funded separately from this registration.
            <br /><br />
            Contact us at <a href="mailto:questions@utilitydesign.app">questions@utilitydesign.app</a> with any questions.
            <br /><br />
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRegisterOrganization} color="primary" variant="contained">
            Register Now
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
