import React, { useRef } from 'react';
import {
  FeatureGroup,
} from 'react-leaflet';

import { booleanPointInPolygon, booleanContains } from '@turf/turf';

import 'leaflet-control-geocoder';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';


import extendedBooleanOverlap from '../../util/extendedBooleanOverlap';

export default function MapFeatureSelector({
  handleSelected,
  LocationArray,
  roadStateRef,
  buildingState }) {

  const selectorRef = useRef(null);
  const selectShape = useRef(null);


  const isFeatureInSelection = (feature, selection) => {
    if (feature.type === 'FeatureCollection') {
      return feature.features.some(f => {
        if (f.geometry.type === 'Point') {
          return booleanPointInPolygon(f, selection);
        } else {
          return extendedBooleanOverlap(selection, f) || booleanContains(selection, f);
        }
      });

    } else if (feature?.geometry?.type === 'Point') {
      return booleanPointInPolygon(feature, selection);

    } else {
      const Contains = booleanContains(selection, feature);
      const Overlap = extendedBooleanOverlap(selection, feature);

      return Overlap || Contains;
    }
  };


  const onShapeCreated = (e) => {
    const { layerType, layer } = e;
    let selectionLayer = layer;

    if (layerType === 'rectangle' || layerType === 'polygon') {
      const selection = selectionLayer.toGeoJSON();
      selectShape.current = selection;

      let selectedFeatures = [];

      // Locations if layer is enabled
      LocationArray.getAllLocations().forEach((location) => {
        let locGeoJSON = LocationArray.toGeoJSON(location)
        if (locGeoJSON) {
          if (isFeatureInSelection(locGeoJSON, selection)) {
            selectedFeatures.push(location);
          }
        }
      });

      // Roads if layer is enabled
      roadStateRef.current.forEach((road) => {
        let roadGeoJSON = road.toGeoJSON();
        if (roadGeoJSON) {
          if (isFeatureInSelection(roadGeoJSON, selection)) {
            selectedFeatures.push(road);
          }
        }
      });

      // Buildings if layer is enabled
      buildingState.forEach((building) => {
        let buildingGeoJSON = building.toGeoJSON();
        if (buildingGeoJSON) {
          if (isFeatureInSelection(buildingGeoJSON, selection)) {
            selectedFeatures.push(building);
          }
        }
      });

      handleSelected(selectedFeatures);
    }
  };

  return (
    <FeatureGroup ref={selectorRef}>
      <EditControl
        position='topleft'
        onCreated={onShapeCreated}
        // onEdited={onShapeCreated}  // Does not correctly call onShapeCreated, event mismatch?
        onDrawStart={() => {
          if (selectorRef.current) {
            selectorRef.current.clearLayers();
          }
        }}
        draw={{
          rectangle: true,
          polygon: true,
          polyline: false,
          circle: false,
          marker: false,
          circlemarker: false
        }}
        edit={{
          edit: false,
          remove: false
        }}
      />
    </FeatureGroup>
  );
}