import { useEffect } from 'react';
import 'leaflet-control-geocoder';

export default function ZoomClickController({ parentMap, isLoaded }) {

  useEffect(() => {
    // Enable/Disable scroll wheel when clicking into and out of map
    if (parentMap) {
      parentMap.on('focus', function() {
        parentMap.scrollWheelZoom.enable();
      });

      parentMap.on('blur', function() { 
        parentMap.scrollWheelZoom.disable(); 
      });

      parentMap.on('click', function() {
        if (!parentMap.scrollWheelZoom.enabled()) {
          parentMap.scrollWheelZoom.enable();
        }
      });
    }
  }, [parentMap]);
  return null
}