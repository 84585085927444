import { Feature, LineString, Point } from 'geojson';

import ConduitTrace from './conduitTrace.ts';
import { IConduitElement } from './conduitTrace.ts';

export interface Position {
  lat: number;
  lng: number;
}

interface Selection {
  key: string;
  options: string[];
  selection: string;
}

// Calculation Specific Models

interface Loading {
  load: number;
  count: number;
  diversity: number;
  phase: number;
  volt_sec_ll: number;
  volt_sec_ln: number;
  timing: string;
  power_factor: number;
}

interface VoltageDrop {
  peak_load: number;
  power_factor: number;
  line: string;
  k_factor: number;
  drop_individual: number;
  drop_total: number;
}

interface PullCalcs {
  order: number;
  pullcalcid: number;
  conduitid: number;
  tension_forward: number;
  tension_reverse: number;
  swbp_forward: number;
  swbp_reverse: number;
}

interface PositionCalcs {
  order: number;
  lat: number;
  lng: number;
}

interface CableConduit {
  conduit_size: number;
  conduit_horz_rad: number;
  conduit_vert_rad: number;
  conduit_type: string;
  minimum_inner_dia: number;
  minimum_outer_dia: number;
  construction_type: null | string;
  conductor_type: string;
  cable_type: string;
  wires_line: number;
  wires_neutral: null | number;
  cable_size: string;
  voltage_rating_kv: number;
  cable_weight_linear_kfoot: number;
  cable_length: number;
  conduit_length: number;
  conduit_sweep: number;
  jam_ratio: number;
  conduit_fill: number;
  eye_tension: number;
  grip_tension: number;
  sbp_max: number;
}

interface Calculations {
  loading: null | Loading;
  cable_conduit: null | CableConduit;
  voltage_drop: VoltageDrop[];
  pull_calcs: PullCalcs[];
  position_calcs: PositionCalcs[];
  materials: any[];
}

// Total Location Modelling

export interface LocationData {
  locationid: string | number;
  loc_number: string;
  remove?: boolean;
  position: Position;
  ssd_id: string | number | null;
  lsd_id: string | number | null;
  equip_key: string;
  name: string;
  otherReq: string[];
  selections: Selection[];
  base_id: string | null;

  conduit_trace: IConduitElement[] | null;

  calculations?: Calculations;
  position_calcs?: Position[];

}

export default class Location {
  locationid: string | number;
  loc_number: string;
  remove: boolean;
  position: Position;
  ssd_id: string | number | null;
  lsd_id: string | number | null;
  equip_key: string;
  name: string;
  otherReq: string[];
  selections: Selection[];
  base_id: string | null;

  conduit_trace: ConduitTrace | null;

  calculations: Calculations;
  position_calcs: Position[];
  connectionKeys: string[];

  constructor(locationData: LocationData) {
    this.locationid = locationData.locationid;
    this.loc_number = locationData.loc_number;
    this.remove = locationData.remove || false;
    this.position = locationData.position;
    this.ssd_id = locationData.ssd_id;
    this.lsd_id = locationData.lsd_id;
    this.equip_key = locationData.equip_key;
    this.name = locationData.name;
    this.otherReq = locationData.otherReq;
    this.selections = locationData.selections;
    this.base_id = locationData?.base_id || null;
    this.conduit_trace = locationData.conduit_trace ? new ConduitTrace(locationData.conduit_trace) : null;

    this.calculations = locationData.calculations || {
      loading: null,
      cable_conduit: null,
      voltage_drop: [],
      pull_calcs: [],
      position_calcs: [],
      materials: []
    };
    this.position_calcs = locationData.position_calcs || [];

    this.connectionKeys = ["pcable", "scable", "pspan", "sspan"];
  }

  isKey(key: string, value: any): boolean {
    return this.hasOwnProperty(key) && this[key] === value;
  }

  toString(short: boolean = false): string {
    if (short) return `${this.loc_number}- ${this.name}`;

    return `Location(#${this.loc_number}- ${this.name})`;
  }

  toGeoJSON(ssdPos: Position | null = null, lsdPos: Position | null = null): Feature<LineString | Point, any> | null {
    // simple GeoJSON 

    if (this.position_calcs && this.position_calcs.length > 0) {
      // render the calculated positions
      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: this.position_calcs.map(pos => [pos.lng, pos.lat])
        },
        properties: this.getProperties()
      }

    } else if (ssdPos && lsdPos) {
      // Both ssd and lsd are defined, return a Polyline
      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [ssdPos.lng, ssdPos.lat],
            [lsdPos.lng, lsdPos.lat]
          ]
        },
        properties: this.getProperties()
      }
    } else if (ssdPos || lsdPos) {
      // Only one of ssd or lsd is defined, return null
      return null;
    } else {
      // Neither ssd nor lsd is defined, return a Point
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [this.position.lng, this.position.lat]
        },
        properties: this.getProperties()
      };
    }
  }

  toLocationData(): LocationData {
    return {
      locationid: this.locationid,
      loc_number: this.loc_number,
      remove: this.remove,
      position: this.position,
      ssd_id: this.ssd_id,
      lsd_id: this.lsd_id,
      equip_key: this.equip_key,
      name: this.name,
      otherReq: this.otherReq,
      selections: this.selections,
      base_id: this.base_id,
      conduit_trace: this.conduit_trace ? this.conduit_trace.conduits : null,
      position_calcs: this.position_calcs
    };
  }

  getProperties(): LocationData {
    return {
      locationid: this.locationid.toString(),
      loc_number: this.loc_number,
      remove: this.remove,
      ssd_id: this.ssd_id ? this.ssd_id.toString() : null,  // convert to numbers to strings for sorting/etc
      lsd_id: this.lsd_id ? this.lsd_id.toString() : null,
      equip_key: this.equip_key,
      name: this.name,
      otherReq: this.otherReq,
      selections: this.selections,
      base_id: this.base_id,
      conduit_trace: this.conduit_trace ? this.conduit_trace.conduits : null,
      position: this.position,
      calculations: this.calculations
    }
  }

  isConnection(superConnectionKeys: string[] = []): boolean {
    if (superConnectionKeys.length > 0) {
      return superConnectionKeys.includes(this.equip_key);
    }
    return this.connectionKeys.includes(this.equip_key);
  }

  update(payload: any): Location {
    const updateNestedObject = (obj: any, payload: any): any => {
      for (let key in payload) {
        if (obj.hasOwnProperty(key)) {
          if (Array.isArray(obj[key]) && Array.isArray(payload[key])) {
            // if the payload is an array, update the selection where the key matches
            obj[key] = obj[key].map((selection: any) => {
              const matchingPayload = payload[key].find((payloadSelection: any) => payloadSelection.key === selection.key);
              if (matchingPayload) {
                return { ...selection, selection: matchingPayload.selection };
              } else {
                return selection;
              }
            });
          } else if (typeof obj[key] === 'object' && typeof payload[key] === 'object') {
            // if the payload is an object, recursively call the function on the payload
            obj[key] = updateNestedObject(obj[key], payload[key]);
          } else {
            // otherwise, update the value
            obj[key] = payload[key];
          }
        }
      }
      return obj;
    };

    const updatedEquipment = updateNestedObject(this, payload);
    return new Location(updatedEquipment);
  }

  replaceField(key: string, value: any): void {
    if (this.hasOwnProperty(key)) {
      this[key] = value;
    }
  }
}