import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

import { connectionType } from '../../models/util/connectionType';
import { sortNumAlpha } from '../../util/sortNumAlpha';
import useSnackbarContext from '../../util/providers/snackbarProvider';

export default function ConnectionPicker({ className, equipmentState, onConnectionSubmit }) {

  const { openSnackbar } = useSnackbarContext();

  const [selectedSSD, setSelectedSSD] = useState('');
  const [selectedLSD, setSelectedLSD] = useState('');


  const reducedEquipment = equipmentState
    .sort((a, b) => sortNumAlpha(a.loc_number, b.loc_number));


  function handleConnectionSubmit() {
    let ssdType = null
    let lsdType = null

    if (selectedSSD !== "")
      ssdType = reducedEquipment.find((equipment) => equipment.locationid === selectedSSD);

    if (selectedLSD !== "")
      lsdType = reducedEquipment.find((equipment) => equipment.locationid === selectedLSD);


    if (!ssdType || !lsdType) {
      openSnackbar("Invalid SSD-LSD Connection.")
      return
    }
    
    let cableType = connectionType(ssdType, lsdType)

    if (!cableType) {
      openSnackbar("Invalid SSD-LSD Connection.")
    } else {
      onConnectionSubmit(cableType, selectedSSD, selectedLSD)
    }

  }

  return (
    <Box className={className} display="flex" flexDirection="column" sx={{ p: 1 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        <FormControl className="ssd-options-input" variant="standard" sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="ssd-select">Select SSD:</InputLabel>
          <Select
            sx={{ width: 160 }}
            value={selectedSSD}
            onChange={(event) => setSelectedSSD(event.target.value)}
            data-testid="ssd-select"
          >
            <MenuItem value="">Select a SSD</MenuItem>
            {reducedEquipment.map((option) => (
              <MenuItem key={option.locationid} value={option.locationid}>
                {`${option.loc_number}-${option.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="lsd-options-input" variant="standard" sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="lsd-select">Select LSD:</InputLabel>
          <Select
            sx={{ width: 160 }}
            value={selectedLSD}
            onChange={(event) => setSelectedLSD(event.target.value)}
            data-testid="lsd-select"
          >
            <MenuItem value="">Select a LSD</MenuItem>
            {reducedEquipment.map((option) => (
              <MenuItem key={option.locationid} value={option.locationid}>
                {`${option.loc_number}-${option.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Button className="tutorial-locations-create-location" variant="contained" onClick={() => handleConnectionSubmit()}>
        Create Connection
      </Button>
    </Box>
  );
}