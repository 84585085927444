import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useSearchParams } from 'react-router-dom';

import { postResetPassword } from '../../util/dbfetch.js';


export default function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const resetCode = searchParams.get('reset_code');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const passwordsMatch = password === confirmPassword;

  // TODO: Make a better password checker
  const getPasswordStrength = (password) => {
    let strength = 0;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    if (password.length > 5) strength += 1;
    if (password.length > 8) strength += 1;

    if (password.length < 5) strength = 0;

    switch (strength) {
      case 0: return 'Very Weak';
      case 1: return 'Weak';
      case 2: return 'Moderate';
      case 3: return 'Strong';
      case 4:
      case 5: return 'Very Strong';
      default: return 'Very Weak';
    }
  };

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    setIsSubmitting(true);

    postResetPassword(resetCode, email, password,
      () => {
        setSuccess('Password has been reset successfully.');
        setError('');
        // Redirect or update UI accordingly
      },
      (err) => {
        setError('Failed to reset password. ' + err);
        setSuccess('');
        setIsSubmitting(false);
      }
    );
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  return (
    <Box p={2}>
      <Typography variant="h6" sx={{ mb: 2 }}>Reset Your Password</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={7}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="caption" sx={{ mt: 1 }}>
            Password Strength: {getPasswordStrength(password)}
          </Typography>
          <TextField
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!passwordsMatch && (
            <Typography color="error" sx={{ mt: 2 }}>
              Passwords do not match.
            </Typography>
          )}
        </Grid>
        <Grid item xs={5} display="flex" flexDirection="column" alignItems="start">
          <Button
            variant="contained"
            onClick={handleResetPassword}
            disabled={isSubmitting || !passwordsMatch || getPasswordStrength(password) === "Very Weak"}
          >
            Reset Password
          </Button>
          {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
          {success && <Typography color="primary" sx={{ mt: 2 }}>{success}</Typography>}
        </Grid>
      </Grid>
    </Box>
  );
}