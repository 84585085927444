import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import useUserDataContext from '../../util/providers/userDataProvider.jsx';

export function OrganizationIndex() {

  const { organizations } = useUserDataContext();

  if (organizations.length === 0) {
    return (
      <Typography variant="body1">No organizations found.</Typography>
    );
  }

  return (
    <Grid sx={{ maxWidth: '70vw' }} container spacing={2}>
      {organizations.map(organization => (
        <Grid item key={organization.organizationid} xs={12} sm={6} md={6}>
          <Card>
            <CardHeader title={organization.organization_name} subheader={organization.organization_desc} />
            <CardContent>
              <Link to={`/organizations/${organization.organizationid}`}>View Organization</Link>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}