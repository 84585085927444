
import React, { useState, useContext } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { EquipTableRowMUI, EquipDepTableRowMUI } from './tableRow.jsx';
import useDragDrop from '../../util/useDragDrop.jsx';
import JoyRideStarter from '../joyRideStarter.jsx';
import ConfirmDialog from '../confirmDialog.jsx';

import { LocationContext } from '../../util/providers/locationContext.jsx';


export default function EquipmentTabler({ baseKeyMap, handleGenerateEquipment }) {
  // Can make a container to reorder the equipment
  const { LocationArray, reRenderLocationArray } = useContext(LocationContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const sourceLocs = LocationArray.getAllEquipment(true).filter((item) => item.base_id === null);
  const depLocs = LocationArray.getAllEquipment(true).filter((item) => item.base_id !== null);
  const depSourceLess = depLocs.filter((item) => !sourceLocs.map((sourceItem) => sourceItem.locationid).includes(item.base_id));

  const steps = [
    {
      target: '.equip-table-row',
      content: "In each equipment row you can change individual properties for the location",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: '.drag-icon-cell',
      content: "Besides the global sorts in the app bar, you can manually drag and drop locations to reorder.",
      placement: "top",
    },
    {
      target: '.row-select-container-cell, .expand-row-button',
      content: 'Each equipment has unique properties to define itself.  Cable connections and links to other equipment are entirely handled by selecting a SSD.',
      placement: "top",
    },
    {
      target: '.cell-ssd-header',
      content: "The Source Side Device (SSD) is determined by it's cable connection.  You must create a connection to define the SSD.",
      placement: "top",
    },
    {
      target: '.cell-connector-header',
      content: 'This is the read onlyconnection reference for the SSD.',
      placement: "top",
    },
  ];

  const { handleDragStart, handleDrop } = useDragDrop((newOrder) => {
    LocationArray.reorder(newOrder);
    reRenderLocationArray();
  }, "loc12345");

  function handleRowDelete(id) {
    const isReferencedBy = LocationArray.getFilteredLocations()
      .filter(item => item.ssd_id === id || item.lsd_id === id)
      .map(item => item.loc_number);

    if (isReferencedBy.length > 0) {
      alert("This equipment cannot be deleted until it's unreferrenced from the following locations: " + isReferencedBy.join(", "));
      return;
    }

    handleOpenDialog(
      <ConfirmDialog
        message="Are you sure you want to delete this location?"
        onConfirm={() => {
          LocationArray.flagRemove(id);
          reRenderLocationArray();
          setDialogOpen(false);
        }}
        onCancel={() => setDialogOpen(false)}
        delayTime={1000}
      />
    );
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  function handleCreateLocation(thisEquipKey, copyProps) {
    const newLoc = handleGenerateEquipment(thisEquipKey, copyProps);
    LocationArray.add(newLoc);
    reRenderLocationArray();

  }

  function handleLocationUpdate(id, newProps) {
    LocationArray.update(id, newProps);
    reRenderLocationArray();
  }

  const listItems = sourceLocs.map((item, index) => {
    const cableLoc = LocationArray.getFilteredLocations().find((e) => e.lsd_id === item.locationid);
    const targetLoc = LocationArray.getFilteredLocations().find((e) => e.locationid === cableLoc?.ssd_id);

    const myDepLocs = depLocs.filter((depItem) => depItem.base_id === item.locationid);
    const myDepRows = myDepLocs.map((depItem, depIndex) => {

      const myDepCableLoc = LocationArray.getFilteredLocations().find((e) => e.lsd_id === depItem.locationid);
      const myDepTargetLoc = LocationArray.getFilteredLocations().find((e) => e.locationid === myDepCableLoc?.ssd_id);

      const depEquipKey = depItem.equip_key;
      const baseEquipKey= baseKeyMap.get(depEquipKey);

      const validBases = sourceLocs.filter((sourceItem) => (
        sourceItem.equip_key === baseEquipKey && sourceItem.locationid !== item.locationid)
      );      

      const depList = validBases.map(
        (item) => ({ key: item.locationid, id: item.locationid, name: `${item.loc_number}-${item.name}` })
      );

      return <EquipDepTableRowMUI
        key={depItem.locationid}
        id={depItem.locationid}
        index={depIndex}
        item={depItem}
        onRowDelete={handleRowDelete}
        onLocationUpdate={handleLocationUpdate}
        onCreateLocation = {handleCreateLocation}
        newBaseList={depList}
        cableLoc={myDepCableLoc}
        targetLoc={myDepTargetLoc}
      />
    });

    return (
      <EquipTableRowMUI
        key={item.locationid}
        id={item.locationid}
        index={index}
        item={item}
        onRowDelete={handleRowDelete}
        onLocationUpdate={handleLocationUpdate}
        onDragStart={handleDragStart}
        onDrop={(e) => handleDrop(e, index,
          LocationArray.getAllLocations(),
          LocationArray.getAllEquipment(true)
        )}
        onDragOver={(e) => e.preventDefault()}
        cableLoc={cableLoc}
        targetLoc={targetLoc}
        children={myDepRows}
      />
    );
  });

  const sourceLessDepRows = depSourceLess.map((item, index) => {
    const myDepCableLoc = LocationArray.getFilteredLocations().find((e) => e.lsd_id === item.locationid);
    const myDepTargetLoc = LocationArray.getFilteredLocations().find((e) => e.locationid === myDepCableLoc?.ssd_id);

    const depEquipKey = item.equip_key;
    const baseEquipKey= baseKeyMap.get(depEquipKey);

    const validBases = sourceLocs.filter((sourceItem) => (
      sourceItem.equip_key === baseEquipKey)
    );

    const depList = validBases.map(
      (item) => ({ key: item.locationid, id: item.locationid, name: `${item.loc_number}-${item.name}` })
    );

    return <EquipDepTableRowMUI
      key={item.locationid}
      id={item.locationid}
      index={index}
      item={item}
      LocationArray={LocationArray}
      reRenderLocationArray={reRenderLocationArray}
      onRowDelete={handleRowDelete}
      onLocationUpdate={handleLocationUpdate}
      onCreateLocation = {handleCreateLocation}
      newBaseList={depList}
      cableLoc={myDepCableLoc}
      targetLoc={myDepTargetLoc}
    />
  });

  // TODO: Drag reorder broken 
  return (
    <>
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <JoyRideStarter steps={steps} anchorVert={-8} disableScrolling={true} />
      <TableContainer>
        <Table aria-label="equipments table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "5%" }} className="cell-command-header">&nbsp;</TableCell>
              <TableCell sx={{ width: "8%" }} className="cell-loc-header">Loc#</TableCell>
              <TableCell sx={{ width: "15%" }} className="cell-type-header">Type</TableCell>
              <TableCell sx={{ width: "16%" }} className="cell-ssd-header">SSD</TableCell>
              <TableCell sx={{ width: "16%" }} className="cell-connector-header">Connector</TableCell>
              <TableCell sx={{ width: "5%" }} className="cell-del-header">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          {LocationArray.getAllEquipment(true).length ? (
            listItems
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  None
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* Table for invalid dep locations */}
      {depSourceLess.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mt: 2 }}>Source-Less Equipment</Typography>
          <TableContainer>
            <Table aria-label="source-less equipments table">
              <TableHead>
                <TableRow>
                <TableCell sx={{ width: "5%" }} className="cell-command-header">&nbsp;</TableCell>
                <TableCell sx={{ width: "8%" }} className="cell-loc-header">Loc#</TableCell>
                <TableCell sx={{ width: "15%" }} className="cell-type-header">Type</TableCell>
                <TableCell sx={{ width: "16%" }} className="cell-ssd-header">SSD</TableCell>
                <TableCell sx={{ width: "16%" }} className="cell-connector-header">Connector</TableCell>
                <TableCell sx={{ width: "5%" }} className="cell-del-header">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sourceLessDepRows}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
