import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';

import AlignmentEditor from '../alignmentEditor';

import useSnackbarContext from '../../../util/providers/snackbarProvider.jsx';
import { useTutorial } from '../../../util/providers/useTutorialContext.jsx';

import { AlignmentItemized } from '../../../models/alignment.ts';
import {
  AlignmentPerpendicular,

} from '../../../models/alignmentPerpendicular.ts';

import { combineAndOrderPoints } from '../../../util/geoSpatial.ts';

// Constants
const units = [
  { value: 'feet', label: 'ft.' },
  { value: 'meters', label: 'm.' },
];

const bearingModes = [
  { value: 'bisect', label: 'Bisect' },
  { value: 'headAngle', label: 'Head Angle' },
  { value: 'tailAngle', label: 'Tail Angle' },
];

/**
 * AlignmentMaker is a component that allows users to create, update, and delete alignments.
 * TODO: Extend this renderer to check against besides alignment, LocationArray, and selectedObjects.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.className - The CSS class name for the component.
 * @param {AlignmentItemized[]} props.alignment - An array of Alignment objects.
 * @param {Function} props.setAlignment - A function to set the current alignment.
 * @param {Array} props.LocationArray - An array of location data.
 * @param {Array} props.selectedObjects - An array of selected objects.
 *
 * @returns {JSX.Element} A component that allows users to interact with alignments.
 */
export default function AlignmentMaker({
  className,
  alignment,
  setAlignment,
  LocationArray,
  selectedObjects,
  onDeselectObjects,
  handleOpenDialog,
  mapRef
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [currAlignment, setCurrAlignment] = useState(null);

  const { openSnackbar } = useSnackbarContext();
  const { handleProgress } = useTutorial();


  /**
   * This function returns an array of AlignmentPerpendicular objects from the selectedObjects array.
   * TODO: this should be handled externally, not in this component
   *
   * @param {Array} newObjects - An array of new objects.
   * @returns {AlignmentPerpendicular[]} An array of AlignmentPerpendicular objects.
   */
  const getCenterlineData = (newObjects, width = 20, bearingMode='headAngle') => {
    const pointArrays = newObjects
      .filter(obj => obj.type === 'way' && obj?.geometry && obj?.nodes)
      .map(obj => {
        return obj.geometry.map((point, index) => {
          const nodeId = obj?.nodes[index] || null;
          return new AlignmentPerpendicular(
            point.lat,
            point.lon,
            width,
            bearingMode,
            null,
            nodeId,
          );
        });
      })

    // for each list of perpendiculars I should find the adjacent points
    // and combine them into a single list one list by one list

    const combList = combineAndOrderPoints(pointArrays);

    return combList;
  };

  const genTempCenterLine = (width = 20, bearingMode='headAngle') => {
    const bounds = mapRef.current.getBounds();

    const bottomLeft = bounds.getSouthWest();
    const topRight = bounds.getNorthEast();

    const thirdLat = (topRight.lat - bottomLeft.lat) / 3;
    const thirdLng = (topRight.lng - bottomLeft.lng) / 3;

    const point1 = [bottomLeft.lat + thirdLat, bottomLeft.lng + thirdLng];
    const point2 = [topRight.lat - thirdLat, topRight.lng - thirdLng];

    const alignmentPerpendicular1 = new AlignmentPerpendicular(
      point1[0],
      point1[1],
      width,
      bearingMode,
      null,
      null
    );

    const alignmentPerpendicular2 = new AlignmentPerpendicular(
      point2[0],
      point2[1],
      width,
      bearingMode,
      null,
      null
    );

    return [alignmentPerpendicular1, alignmentPerpendicular2];
  };

  // Event method


  const handleUpdateAlignment = () => {
    const newAlignments = getCenterlineData(selectedObjects);

    if (newAlignments.length > 0) {
      setAlignment(prevAlignment => {
        const existingAlignIndex = prevAlignment.findIndex(align => align.name === currAlignment.name);
        if (existingAlignIndex !== -1) {
          const updatedAlignment = prevAlignment[existingAlignIndex].addPoints(newAlignments);
          prevAlignment[existingAlignIndex] = updatedAlignment;
        } else {
          openSnackbar('Alignment not found.')
        }
        openSnackbar(`Alignment  ${currAlignment.name} updated.`)

        onDeselectObjects();
        return [...prevAlignment];
      });
    } else {
      openSnackbar('Select a road to add to an alignment.')
    }
  };

  const handleCreateAlignment = (newAlignment) => {
    // check if alignment name already exists
    if (newAlignment.name === '') {
      openSnackbar('Alignment name cannot be empty.');
      return;
    } else {
      const alignmentExists = alignment.find(align => align.name === newAlignment.name);
      if (alignmentExists) {
        openSnackbar('Alignment name already exists.');
        return;
      }
    }
    const parsedAlignment = new AlignmentItemized(
      newAlignment.points,
      newAlignment.name,
      newAlignment.alignmentid,
      [], // items
      [], // bends
      newAlignment.conduitOffset
    );

    setAlignment(prevAlignment => [...prevAlignment, parsedAlignment]);
    setCurrAlignment(parsedAlignment);
    setDialogOpen(false);
  };


  const handleDeleteAlignment = () => {
    setAlignment(prevAlignment => prevAlignment.filter(align => align.name !== currAlignment.name));
    setCurrAlignment(null);
  };

  const openAlignmentEditor = (event) => {
    if (!currAlignment) {
      openSnackbar('Select an alignment to edit.');
      return;
    }

    handleProgress(event.target.className);

    const existingAlignment = alignment.find(alignmentElement => alignmentElement.name === currAlignment.name);

    const alignmentToEdit = AlignmentItemized.fromAlignment(existingAlignment);

    function onSaveAlignment(oldAlignment, newAlignment) {
      const filteredAlignments = alignment.filter(alignmentElement => alignmentElement.alignmentid !== oldAlignment.alignmentid)

      const newAlignmentItemized = new AlignmentItemized(
        newAlignment.points,
        newAlignment.name,
        newAlignment.alignmentid,
        newAlignment.items,
        newAlignment.bends,
        newAlignment.conduitOffset
      );

      // Update the state with the new array
      setAlignment([...filteredAlignments, newAlignmentItemized]);
      setCurrAlignment(null);
    }

    handleOpenDialog(
      <AlignmentEditor
        alignment={alignmentToEdit}
        handleSaveAlignment={(newAlignment) => onSaveAlignment(alignmentToEdit, newAlignment)}
      />
    );
  };

  function handleOpenCreateAlignment(event, selectedObjects) {
    const pointArrays = selectedObjects.filter(obj => obj.type === 'way' && obj?.geometry && obj?.nodes)

    if (pointArrays.length < 1) {
    }

    handleProgress(event.target.className);
    setDialogOpen(true);
  }

  const onGenerateLine = (selectedObjects, width, bearingMode) => {
    let newPoints = getCenterlineData(selectedObjects, width, bearingMode);

    if (newPoints.length < 2) {
      newPoints = genTempCenterLine(width, bearingMode);
    }

    return newPoints
  };


  return (
    <Box className={className} sx={{ width: '100%' }}>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <CreateAlignment onCreateAlignment = {handleCreateAlignment} handleGenerateLine = {(width, bearingMode) => onGenerateLine(selectedObjects, width, bearingMode)}/>
      </Dialog>
      <Box display="flex" flexDirection="row" sx= {{py:2}}>
        <Select
          sx={{minWidth: '200px'}}
          value={currAlignment ? currAlignment.name : ''}
          onChange={(event) => {
            const selectedAlignment = alignment.find(align => align.name === event.target.value);
            setCurrAlignment(selectedAlignment || null);
          }}
        >
          <MenuItem value="">Select an alignment</MenuItem>
          {alignment.map((align) => (
            <MenuItem key={align.name} value={align.name}>{align.name}</MenuItem>
          ))}
        </Select>
        <Button 
          variant="outlined" 
          onClick={openAlignmentEditor}
          className="tutorial-locations-map-alignment-edit-alignment"
        >Edit Alignment</Button>
      </Box>
      

      <Box display="flex" flexDirection="column" gap={2}>
        <Button
          variant="contained"
          onClick={() => handleUpdateAlignment()}
        >
          Add Selected Line to Alignment
        </Button>
        <Button 
          variant="contained" 
          color ="secondary" 
          onClick={(event) => handleOpenCreateAlignment(event, selectedObjects)} 
          className="tutorial-locations-map-joyride-create-alignment"
          sx = {{width: '70%'}}
        >New Alignment</Button>
        <Button variant="outlined" onClick={handleDeleteAlignment}>Delete Alignment</Button>
      </Box>
    </Box >
  );
}

function CreateAlignment({ onCreateAlignment, handleGenerateLine }) {
  const [alignmentName, setAlignmentName] = useState('default');

  const [width, setWidth] = useState(20);  // always feet
  const [renderedWidth, setRenderedWidth] = useState(20);  // feet or meters, converted from width

  const [bearingMode, setBearingMode] = useState('headAngle'); // head, tail, or bisect
  const [bearingModeIndex, setBearingModeIndex] = useState(0);
  const [unitIndex, setUnitIndex] = useState(0); // Initialize with the index of the default unit

  const [conduitOffset, setConduitOffset] = useState(0);


  useEffect(() => {
    if (units[unitIndex].value === 'meters') {
      setWidth(renderedWidth * 3.281);
    } else if (units[unitIndex].value === 'feet') {
      setWidth(renderedWidth);
    }

  }, [unitIndex, renderedWidth]);

  useEffect(() => {
    setBearingMode(bearingModes[bearingModeIndex].value);
  }, [bearingModeIndex]);


  const handleWidthChange = (event) => {
    let widthInFeet = Number(event.target.value);
    if (units[unitIndex].value === 'meters') {
      widthInFeet *= 3.281;
    } else if (units[unitIndex].value === 'feet') {
      // do nothing, keep in feet
    }
    setWidth(widthInFeet);
    setRenderedWidth(parseFloat(Number(event.target.value).toFixed(3)));
  };

  const handleUnitChange = () => {
    // Cycle through the units array
    const newIndex = (unitIndex + 1) % units.length;
    setUnitIndex(newIndex);

    // Update the renderedWidth
    if (units[newIndex].value === 'meters') {
      setRenderedWidth(parseFloat((width / 3.281).toFixed(3)));
    } else if (units[newIndex].value === 'feet') {
      setRenderedWidth(parseFloat(width.toFixed(3)));
    }
  };

  function handleCreateAlignment() {
    const centerline = handleGenerateLine(width, bearingMode);

    onCreateAlignment({
      name: alignmentName,
      points: centerline,
      conduitOffset: conduitOffset
    })
  }


  return (
    <Paper sx={{p:2}}>
      <FormControl variant="filled">
        <TextField 
          value = {alignmentName}
          onChange = {(event) => setAlignmentName(event.target.value)}
          label = "Name"
        />
        <Box display="flex" alignItems="center">
          <TextField
            id="width-input"
            value={renderedWidth}
            onChange={handleWidthChange}
            type="number"
            sx={{ width: "10em" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="This is the initial width for the alignment">
                    <Typography color="text.secondary">width:</Typography>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={handleUnitChange} sx={{ width: "1em", marginLeft: ".25em" }}>
            <Typography>{units[unitIndex].label}</Typography>
          </IconButton>
          <IconButton onClick={() => setBearingModeIndex((bearingModeIndex + 1) % bearingModes.length)}>
            <Tooltip title="This mode is how equipment will be placed with respect to the alignment line">
              <Typography>{bearingModes[bearingModeIndex].label}</Typography>
            </Tooltip>
          </IconButton>
        </Box>
        <TextField
          id="conduit-offset"
          type="number"
          value={conduitOffset}
          onChange={(event) => setConduitOffset(parseFloat(Number(event.target.value).toFixed(3)))}
          sx={{ width: "12em" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="text.secondary">conduit offset:</Typography>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Button variant ="outlined" onClick={() => handleCreateAlignment()}>Create Alignment</Button>
    </Paper>
  );
}