
import React, { useState } from 'react';
import {
  Link as RouterLink,
  useNavigate
} from "react-router-dom"
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Collapse, Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { PostServerData } from "../../util/dbfetch.js";
import JoyRideStarter from '../joyRideStarter.jsx';

import useUserDataContext from '../../util/providers/userDataProvider.jsx';

import { useTutorial } from '../../util/providers/useTutorialContext.jsx';
import { OrganizationSelector } from '../organizationSelector.jsx';
import useSnackbarContext from '../../util/providers/snackbarProvider.jsx';

export function ProjectIndex() {
  const { handleProgress } = useTutorial();

  const { projects, refreshData } = useUserDataContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const navigate = useNavigate();
  const { openSnackbar } = useSnackbarContext();

  const steps = [
    {
      target: '.create-project-button',
      content: 'You can create a new project here. If you have no organization you can create one while creating a project.',

      disableBeacon: true,
    },
    {
      target: '.create-demo-project-button',
      content: 'If you want a guide experience, create a demo project.  This will have limited functionality but will show you the full range of features of this app. This will create a demo organization as well as a demo project.',
    },
    {
      target: '.project-list-item, error-message',
      content: 'These are all projects that you either are an editor or have viewer access to. You can go to the project home page or jump to the current instance of the project.',
      placement: "top",
    },
  ];

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  function createNewProject() {
    navigate(`/project/new`);
  }


  function createDemoProject(event, organizationid) {
    const handleSuccess = (resp) => {
      openSnackbar("Demo project created");
      handleProgress(event.target.className, {
        projectid: resp.data.projectid,
        organizationid: resp.data.organizationid
      });
      refreshData();
      navigate(`/project/${resp.data?.projectid}`);
    }

    const handleError = (err) => {
      openSnackbar(err?.data?.detail || "An error occurred")
    }

    PostServerData("demo", {}, 
      handleSuccess, 
      handleError,
      { "organizationid": organizationid });
  }

  function handleCreateDemoProject(event) {
    handleOpenDialog(
      <OrganizationSelector
        onSubmit={(organizationid) => { createDemoProject(event, organizationid) }}
        onClose={() => setDialogOpen(false)}
        onError={() => { }}
      />
    );
  }

  return (
    <Box className="project-index">
      <JoyRideStarter steps={steps} anchorVert={-8} />
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Typography className="project-index-title" variant="h3">
        Projects
      </Typography>
      <Button className="create-project-button" variant="outlined" onClick={createNewProject}>
        Create New Project
      </Button>
      <Button className="create-demo-project-button" variant="outlined" onClick={handleCreateDemoProject}>
        Create Demo Project
      </Button>
      {projects.length === 0 ? (
        <CircularProgress className="loading-spinner" />
      ) : (
        ['sponsor', 'editor', 'viewer', 'other'].map((role, index) => (
          <ProjectByRole key={index} role={role} projects={projects} />
        ))
      )}
    </Box>
  );
}


function ProjectByRole({ role, projects }) {
  const [showAll, setShowAll] = useState(false);
  const projectsForRole = projects.filter((project) => project.role === role);

  const roleDisplayNameMap = {
    editor: "Edited Projects",
    sponsor: "Your Projects",
    viewer: "Viewable Projects",
    other: "Other Projects"
  }

  if (projectsForRole.length === 0) {
    return null;
  }

  const projectsToRender = showAll ? projectsForRole : projectsForRole.slice(0, 4);

  return (
    <Card sx={{ my: 1 }} key={role}>
      <Typography className={`${role}-project-index-subtitle`} sx ={{px: 1}} variant= "h4">
        {roleDisplayNameMap[role]}
      </Typography>
      <List className={`${role}-project-list`}>
        {projectsToRender.map((project) => (
          <ProjectElement key={project.projectid} project={project} role={role} />
        ))}
      </List>
      {projectsForRole.length > 4 && !showAll && (
        <IconButton onClick={() => setShowAll(true)} style={{ width: '100%' }}>
          <ExpandMore />
        </IconButton>
      )}
      {showAll && (
        <IconButton onClick={() => setShowAll(false)} style={{ width: '100%' }}>
          <ExpandLess />
        </IconButton>
      )}
    </Card>
  );
}


function ProjectElement({ project }) {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  // project.instanceCurrent.date_update = 2023-05-16T15:29:43 
  const lastUpdate = new Date(project?.instanceCurrent?.date_update)


  return (
    <Box className="project-element">
      <ListItem className="project-list-item" key={project.projectid} onClick={handleClick}>
        <ListItemText
          className="project-list-item-text"
          primary={project.proj_name}
          secondary={`Last Change: ${lastUpdate.toLocaleDateString()} @ ${lastUpdate.toLocaleTimeString()}`} />
        {open ? <ExpandLess className="expand-icon" /> : <ExpandMore className="expand-icon" />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className="project-element-list" component="div" disablePadding>
          <ListItem className="project-element-list-item">
            <Button
              className="open-project-button"
              component={RouterLink}
              variant="contained"
              to={`/project/${project.projectid}`}>
              Open Project Home
            </Button>
          </ListItem>
          <ListItem className="project-element-list-item">
            {project?.instanceCurrent?.instanceid ?
              <Button
                className="edit-instance-button"
                component={RouterLink}
                variant="contained"
                to={`/project/${project.instanceCurrent.projectid}/instance/edit?instanceid=${project.instanceCurrent.instanceid}`}>
                Edit Current Instance
              </Button>
              :
              <Button
                className="create-instance-button"
                component={RouterLink}
                variant="contained"
                to={`/project/${project.projectid}/instance/edit`}>
                Create New Instance
              </Button>
            }
          </ListItem>
        </List>
      </Collapse>
      <Divider className="project-element-divider" />
    </Box>
  )
}