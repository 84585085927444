import { booleanOverlap as turfBooleanOverlap, lineIntersect,  polygonToLineString } from '@turf/turf';

export default function extendedBooleanOverlap(feature1, feature2) {
  var type1 = feature1.geometry.type;
  var type2 = feature2.geometry.type;

  if (type1 === 'LineString' && type2 === 'Polygon') {
    const line = polygonToLineString(feature2);
    const intersects = lineIntersect(feature1, line);
    return intersects.features.length > 0;
  } else if (type1 === 'Polygon' && type2 === 'LineString') {
    const line = polygonToLineString(feature1);
    const intersects = lineIntersect(line, feature2);
    return intersects.features.length > 0;
  } else {
    const overlap =  turfBooleanOverlap(feature1, feature2);
    return overlap;
  }
}