import React, { useState, useRef } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Snackbar, SnackbarContent, Typography } from '@mui/material';

import ExpandMore from '@mui/icons-material/ExpandMore'; 

import JoyRideStarter from '../joyRideStarter';

import InstanceContent from './instanceContent';

export default function InstanceLister({ projID, instances, setInstances }) {
  const firstAccordionRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const sortedInstances = [...instances].sort((a, b) => {
    // Sort by date
    const dateA = new Date(a.date_update);
    const dateB = new Date(b.date_update);
    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    }

    return 0;
  });

  const steps = [
    {
      target: '.create-instance-button',
      content: 'Create a new instance here.  A new instance will automatically Inherit from the latest Instance.',
      placement: "right",
      disableBeacon: true,
    },
    {
      target: '.edit-instance-button, .save-instance-button',
      content: "This button will enable editing a specific instance. You can change the 'project phase' to create a new phase.  The lastest description will describe the instance and provided to the Organization manager.",
      placement: "bottom",
    },
    {
      target: '.edit-locations-button',
      content: "You can go straight to any instance to view their locations.  Any Changes made will create a new instance.",
      placement: "right",
    },
    {
      target: '.run-calculations-button, .reset-calculations-button',
      content: "Run the calculations on this instance.  If the instance  was already ran, you can reset them here. If there are errors with the calculations there will be options provided here to resolve.",
      placement: "top",
    },
    {
      target: '.options-button',
      content: "If the calculator has errors, it will offer resolution options here.  These options will not be deleted between future calculations.",
      placement: "top",
    },
    {
      target: '.download-map-button',
      content: "Download an abstract location map.  This will give you a simple schematic of the project.",
      placement: "bottom",
    },
    {
      target: '.download-report-button',
      content: "If there are files available, they can be downloaded here.  The report will generate regardless of if there are unresolved options.",
      placement: "bottom",
    },
  ];


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  
  function handleUpdateInstances(newInstance) {
    
    if (!instances.some((instance) => instance.instanceid === newInstance.instanceid)) {
      setInstances([...instances, newInstance]);
    } else {
      setInstances(instances.map((instance) => {
        if (instance.instanceid === newInstance.instanceid) {
          return newInstance;
        } else {
          return instance;
        }
      }));
    }
  }


  const groupedInstances = sortedInstances.reduce((acc, instance, index) => {
    const phase = instance.project_phase;
    const desc = instance.instance_desc;
    const date = new Date(instance.date_update);
    const existingInstance = acc[acc.length - 1]?.instances[0];
    if (!existingInstance || existingInstance.project_phase !== phase) {
      acc.push({ phase, desc, date, instances: [instance] });
    } else {
      acc[acc.length - 1].instances.push(instance);
    }
    return acc;
  }, []);


  function handleCalculationInitialized(instanceID, response, newStatus = "calculating") {
    // update the state of the instance to calculating
    setInstances(
      instances.map((instance) => {
        if (instance.instanceid === instanceID) {
          instance.instance_stage = newStatus;
        }
        return instance; 
      })
    );

    const message = response?.data?.message;

    // show a Snackbar with the message
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }

  function handleSuccessDeleteInstance(response) {
    const instancesNew = instances.filter(instance => 
      instance.instanceid.toString() !== response.data.instanceid.toString())

    setInstances(instancesNew)
  }

  function OpenFirstGroupedinstance() {
    if (firstAccordionRef.current) {
      // to-do remove this?  Develop this?
      // firstAccordionRef.current.handleToggle(); // call the handleToggle method to expand the first Accordion component
    }
  }

  return (
    <div className="instance-lister-container">
      <JoyRideStarter 
      steps={steps} onStart={OpenFirstGroupedinstance} sx={{ 
        height: '0px',
        display: 'flex', 
        justifyContent: 'flex-end',
      }} />
      <h2>Instances</h2>
      {groupedInstances.map((group, index) => (
        <Accordion key={group.phase} ref={index === 0 ? firstAccordionRef : null}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box>
              <Typography>Phase: {group.phase} Last Update: {group.date.toLocaleDateString()}</Typography>
              <Typography>{group.desc}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {group.instances.map((instance) => (
                <InstanceContent
                  key={instance.instanceid}
                  projID={projID}
                  instance={instance}
                  onCalculationInitialized={handleCalculationInitialized}
                  onSuccessDeleteInstance={handleSuccessDeleteInstance}
                  onUpdateInstances={handleUpdateInstances}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <SnackbarContent message={snackbarMessage} />
      </Snackbar>
    </div>
  );
}