/**
 * @class
 * @classdesc Represents a tutorial.
 */
export default class Tutorial {
  constructor(name, displayName, phases, closingComment = "" ) {
    this.name = name;
    this.displayName = displayName;
    this.phases = phases;
    this.closingComment = closingComment;

  }

  /**
   * Returns an array of all unique parameter names required by the phases in this tutorial.
   */
  getRequiredParameters() {
    const parameters = this.phases.flatMap(phase => phase.requiredParameters);
    const filteredParameters = parameters.filter(Boolean);
    return [...new Set(filteredParameters)];
  }
}
