import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { InputLabel } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ConduitTraceInput from "./conduitTraceInput.jsx"

import { RowSelectContainerArray } from '../rowSelectContainer';

import useSnackbarContext from '../../util/providers/snackbarProvider.jsx';

export function ConnectionTableRowSimple({ id, index, item, locList, onLocationUpdate, ...props }) {

  function handleOptionChange(event, name) {
    onLocationUpdate(item.locationid, { selections: [{ key: name, selection: event.target.value }] });
  }

  function buildSSDOptions(name, value) {
    if (value === null) {
      value = -1;
    }
    const optionItems = locList
      .filter((option) => option.id !== item.locationid)
      .map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.value}
        </MenuItem>
      ));

    return (
      <FormControl className="connection-ssd-options" variant="outlined" sx={{ width: "100%" }}>
        <Select
          value={value}
          onChange={(event) => onLocationUpdate(item.locationid, { ssd_id: event.target.value })}
          name={name}
        >
          {optionItems}
        </Select>
      </FormControl>
    );
  }

  function buildLSDOptions(name, value) {
    if (value === null) {
      value = -1;
    }
    const optionItems = locList
      .filter((option) => option.id !== item.locationid)
      .map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.value}
        </MenuItem>
      ));

    return (
      <FormControl className="connection-lsd-options" variant="outlined" sx={{ width: "100%" }}>
        <Select
          value={value}
          onChange={(event) => onLocationUpdate(item.locationid, { lsd_id: event.target.value })}
          name={name}
        >
          {optionItems}
        </Select>
      </FormControl>
    );
  }

  return (
    <TableBody>
      <TableRow className="tutorial-locations-connections connection-table-row" key={item.locationid} {...props}>
        <TableCell className="connection-loc-cell" sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          <TextField
            className="connection-loc-text-field"
            placeholder="Loc"
            defaultValue={item.loc_number}
            onBlur={(e) => onLocationUpdate(item.locationid, { loc_number: e.target.value })}
          />
        </TableCell>
        <TableCell className="connection-name-cell" sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          <Typography variant='body2'>{item.name}</Typography>
        </TableCell>
        <TableCell className="connection-ssd-cell" sx={{ p: 0, mx: 1, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          {buildSSDOptions("ssd_id", item.ssd_id)}
        </TableCell>
        <TableCell className="connection-lsd-cell" sx={{ px: 1, py: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          {buildLSDOptions("lsd_id", item.lsd_id)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="connection-row-select-container-cell" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <RowSelectContainerArray
            item={item}
            handleOptionChange={handleOptionChange}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}


    
export function ConnectionTableRowMUI({ id, index, item, onRowDelete, locList, onLocationUpdate, onDragStart, ...props }) {
  // for each key item, add a select element to the row 
  const [open, setOpen] = useState(false);  // for the collapsable row
  const [modalOpen, setModalOpen] = useState(false);  // for the modal
  const [modalContent, setModalContent] = useState(null);

  const modalMap = {
    'conduit-trace': {
      componentName: ConduitTraceInput,
      name: "Conduit Trace",
      keys: ["conduit_size", "conduit_type", "conduit_horz_rad", "conduit_vert_rad"]
    },
  }
  let otherReqKeys;
  if (item.otherReq.length > 0) {
    otherReqKeys = item.otherReq.map((req) => modalMap[req].keys).flat();
  } else {
    otherReqKeys = [];
  }

  const handleOpenModal = (req) => {
    const ModalComponent = modalMap[req].componentName;

    setModalContent(() => <ModalComponent
      item={item}
      modalKeys={modalMap[req].keys}
      onClose={() => setModalOpen(false)} />);
    setModalOpen(true);
  }

  function buildOtherOptions(otherReqs) {
    if (otherReqs === undefined || otherReqs.length === 0) {
      return (
        <Box className="tutorial-locations-other-options connection-other-options"></Box>
      );
    }
    return (
      <Box className="tutorial-locations-other-options connection-other-options">
        {otherReqs.map((req, index) => {
          const { name: reqName } = modalMap[req] || {};
          return (
            <Button className="connection-other-option-button" variant="outlined" key={index} onClick={() => handleOpenModal(req)}>
              {reqName}
            </Button>
          );
        })}
        <Dialog className="connection-modal-dialog" maxWidth="md" open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box sx={{ p: 4 }}>
            {modalContent}
          </Box>
        </Dialog>
      </Box>
    );
  }

  function handleOptionChange(event, name) {
    onLocationUpdate(item.locationid, { selections: [{ key: name, selection: event.target.value }] });
  }

  function handleSSDChange(event) {
    onLocationUpdate(item.locationid, { ssd_id: event.target.value });
  }

  function buildSSDOptions(name, value) {
    if (value === null) {
      value = -1;
    }
    const optionItems = locList
      .filter((option) => option.id !== item.locationid)
      .map((option) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        );
      });

    return (
      <FormControl className="connection-ssd-options" variant="outlined" sx={{ width: "100%" }}>
        <Select
          value={value}
          onChange={(event) => handleSSDChange(event, item.key)}
          name={name}
        >
          {optionItems}
        </Select>
      </FormControl>
    );
  }
  function handleLSDChange(event) {
    onLocationUpdate(item.locationid, { lsd_id: event.target.value });
  }

  function buildLSDOptions(name, value) {
    if (value === null) {
      value = -1;
    }
    const optionItems = locList
      .filter((option) => option.id !== item.locationid)
      .map((option) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        );
      });

    return (
      <FormControl className="connection-lsd-options" variant="outlined" sx={{ width: "100%" }}>
        <Select
          value={value}
          onChange={(event) => handleLSDChange(event, item.key)}
          name={name}
        >
          {optionItems}
        </Select>
      </FormControl>
    );
  }

  return (
    <TableBody>
      <TableRow className="tutorial-locations-connections connection-table-row" key={item.locationid} {...props}>
        <TableCell sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Box
              className="connection-drag-icon-cell"
              draggable
              sx={{ cursor: "grab" }}
              onDragStart={(event) => {
                event.stopPropagation();
                onDragStart(event, index)
              }}
            >
              <MenuIcon />
            </Box>
            <IconButton
              className="connection-expand-row-button"
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell
          className="connection-loc-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <TextField
            className="connection-loc-text-field"
            placeholder="Loc"
            defaultValue={item.loc_number}
            onBlur={(e) => onLocationUpdate(item.locationid, { loc_number: e.target.value })}
          />
        </TableCell>

        <TableCell
          className="connection-name-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <Typography variant='body2'>{item.name}</Typography>
        </TableCell>
        <TableCell
          className="connection-ssd-cell"
          sx={{ p: 0, mx: 1, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          {buildSSDOptions("ssd_id", item.ssd_id)}
        </TableCell>
        <TableCell
          className="connection-lsd-cell"
          sx={{ px: 1, py: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          {buildLSDOptions("lsd_id", item.lsd_id)}
        </TableCell>
        <TableCell
          className="connection-other-options-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          {buildOtherOptions(item.otherReq)}
        </TableCell>
        <TableCell
          className="connection-delete-cell"
          sx={{ p: 0, alignItems: "center", justifyContent: "center", }}>
          <IconButton className="delete-button" color="secondary" onClick={() => onRowDelete(item.locationid)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="connection-row-select-container-cell" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RowSelectContainerArray
              item={item}
              handleOptionChange={handleOptionChange}
              otherReqKeys={otherReqKeys}
            />
          </Collapse>
        </ TableCell>
      </TableRow>

    </TableBody>
  );

}

export function EquipTableRowSimple({
  id, index, item, onLocationUpdate, onLocationChange, cableLoc = null, targetLoc = null,
}) {

  // simpler table row, 
  // no drag and drop, no delete, or locList
  // the expanded row should always be expanded
  // this is to be used outside of a list of equipment
  const ssdEquip = targetLoc ? `${targetLoc?.loc_number} - ${targetLoc?.name}` : "None";
  const ssdConnector = cableLoc ? `${cableLoc?.loc_number} - ${cableLoc?.name}` : "None";

  function handleOptionChange(event, name) {
    onLocationUpdate(item.locationid, {selections: [{ key: name, selection: event.target.value }]})
  }

  return (
    <TableBody data-testid='equip-table-row-simple'>
      <TableRow sx={{ p: 1, m: 1 }} className="equip-table-row-main" key={item.locationid}>
        <TableCell sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          <TextField
            className="loc-text-field"
            placeholder="Loc"
            defaultValue={item.loc_number}
            onBlur={(e) => onLocationUpdate(item.locationid, { loc_number: e.target.value })}
            sx={{ minWidth: 80 }}
          />
        </TableCell>
        <TableCell
          className="name-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          <Typography variant='body2'>{item.name}</Typography>
        </TableCell>
        <TableCell
          className="ssd-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          <Typography variant='body2'>{ssdEquip}</Typography>
        </TableCell>
        <TableCell
          className="connector-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}>
          <Typography variant='body2'>{ssdConnector}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={"tutorial-locations-properties row-select-container-cell"}
          style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <RowSelectContainerArray
              item={item}
              handleOptionChange={handleOptionChange}
            />
        </ TableCell>
      </TableRow>
    </TableBody>
  );
}


export function EquipTableRowMUI({
  id, index, item, onRowDelete, onLocationUpdate, 
  onDragStart, cableLoc = null, targetLoc = null, children = null, ...props }) {
  // for each key item, add a select element to the row 
  const [open, setOpen] = useState(false);  // for the collapsable row


  const ssdEquip = targetLoc ? `${targetLoc?.loc_number} - ${targetLoc?.name}` : "None";
  const ssdConnector = cableLoc ? `${cableLoc?.loc_number} - ${cableLoc?.name}` : "None";

  const modalMap = {
    'conduit-trace': {
      componentName: ConduitTraceInput,
      name: "Conduit Trace",
      keys: ["conduit_size", "conduit_type", "conduit_horz_rad", "conduit_vert_rad"]
    },
  }

  let otherReqKeys;
  if (item.otherReq.length > 0) {
    otherReqKeys = item.otherReq.map((req) => modalMap[req].keys).flat();
  } else {
    otherReqKeys = [];
  }


  function handleOptionChange(event, name) {
    onLocationUpdate(item.locationid, { selections: [{ key: name, selection: event.target.value }] });
  }


  return (
    <TableBody data-testid='equip-table-row'>
      <TableRow sx={{ p: 1, m: 1 }} className="equip-table-row-main" key={item.locationid} {...props}>
        <TableCell sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Box
              className="drag-icon-cell"
              draggable
              sx={{ cursor: "grab" }}
              onDragStart={(event) => {
                event.stopPropagation();
                onDragStart(event, index)
              }}
            >
              <MenuIcon />
            </Box>
            <IconButton
              className={open ? "expand-row-button" : "tutorial-locations-properties expand-row-button"}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell
          className="loc-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <TextField
            className="loc-text-field"
            placeholder="Loc"
            defaultValue={item.loc_number}
            onBlur={(e) => onLocationUpdate(item.locationid, { loc_number: e.target.value })}
            sx = {{ minWidth: 80 }}
          />
        </TableCell>

        <TableCell
          className="name-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <Typography variant='body2'>{item.name}</Typography>
        </TableCell>
        <TableCell
          className="ssd-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <Typography variant='body2'>{ssdEquip}</Typography>
        </TableCell>
        <TableCell
          className="connector-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <Typography variant='body2'>{ssdConnector}</Typography>
        </TableCell>
        <TableCell
          className="delete-cell"
          sx={{ p: 0, alignItems: "center", justifyContent: "center" }}>
          <IconButton className="delete-button" color="secondary" onClick={() => onRowDelete(item.locationid)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={"tutorial-locations-properties row-select-container-cell"}
          style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RowSelectContainerArray
              item={item}
              handleOptionChange={handleOptionChange}
              otherReqKeys={otherReqKeys}
            />
          </Collapse>
        </ TableCell>
      </TableRow>
      {children}

    </TableBody>
  );

}

export function EquipDepTableRowMUI({
  id, index, item, onRowDelete, onLocationUpdate, onCreateLocation, newBaseList, cableLoc = null, targetLoc = null, children = null, ...props
}) {
  // for each key item, add a select element to the row 
  const [open, setOpen] = useState(false);  // for the collapsable row
  const [newBaseId, setNewBaseId] = useState("");

  const { openSnackbar } = useSnackbarContext();

  const ssdEquip = targetLoc ? `${targetLoc?.loc_number} - ${targetLoc?.name}` : "None";
  const ssdConnector = cableLoc ? `${cableLoc?.loc_number} - ${cableLoc?.name}` : "None";

  const modalMap = {
    'conduit-trace': {
      componentName: ConduitTraceInput,
      name: "Conduit Trace",
      keys: ["conduit_size", "conduit_type", "conduit_horz_rad", "conduit_vert_rad"]
    },
  }

  let otherReqKeys;
  if (item.otherReq.length > 0) {
    otherReqKeys = item.otherReq.map((req) => modalMap[req].keys).flat();
  } else {
    otherReqKeys = [];
  }


  function handleOptionChange(event, name) {
    onLocationUpdate(item.locationid, { selections: [{ key: name, selection: event.target.value }] });
  }  

  const handleCopyLocTo = (newid) => {  
    if (newid === -1) {
      openSnackbar("Invalid Base Location");
      return;
    } else {

      const thisEquipKey = item.equip_key;

      // copy all props, drop loc_number
      const copyProps = {
        base_id: newid,
        selections: item.selections,
      } 

      onCreateLocation(thisEquipKey, copyProps);
    }
  }

  const handleMoveLocTo = (newid) => {
    if (newid === -1) {
      openSnackbar("Invalid Base Location");
      return;
    } else {
      onLocationUpdate(item.locationid, { base_id: newid });
    }
  }

  // Function to build base location options
  const buildBaseLocOptions = () => {
    const defReturn = <MenuItem key={-1} value={-1}>No Valids</MenuItem>;
    
    const theseItems = newBaseList
    .map((loc) => (
      <MenuItem key={loc.id} value={loc.id}>
        {loc.name}
      </MenuItem>
    ));

    return theseItems.length > 0 ? theseItems : defReturn;
  };

  return (
    <>
      <TableRow sx={{ p: 1, m: 1, backgroundColor: "background.secondary" }} className="equip-dep-table-row-row" key={item.locationid} {...props}>
        <TableCell sx={{ p: 0, borderRadius: 1, borderColor: "grey.300" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right", height: "100%" }}>
            <IconButton
              className={open ? "expand-row-button" : "tutorial-locations-properties expand-row-button"}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell
          className="loc-cell"
          sx={{ py: 0, px: 4, borderBottom: 1, borderRadius: 1, justifyContent: "left", borderColor: "grey.300" }} >
          <TextField
            className="loc-text-field"
            placeholder="Loc"
            defaultValue={item.loc_number}
            onBlur={(e) => onLocationUpdate(item.locationid, { loc_number: e.target.value })}
            sx={{ minWidth: 60 }} // Set the minWidth to 120px
          />
        </TableCell>
        <TableCell
          className="name-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <Typography variant='body2'>{item.name}</Typography>
        </TableCell>
        <TableCell
          className="ssd-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <Typography variant='body2'>{ssdEquip}</Typography>
        </TableCell>
        <TableCell
          className="connector-cell"
          sx={{ p: 0, borderBottom: 1, borderRadius: 1, borderColor: "grey.300" }} >
          <Typography variant='body2'>{ssdConnector}</Typography>
        </TableCell>
        <TableCell
          className="delete-cell"
          sx={{ p: 0, alignItems: "center", justifyContent: "center" }}>
          <IconButton className="delete-button" color="secondary" onClick={() => onRowDelete(item.locationid)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: "background.secondary" }}  >
        <TableCell
          className={"tutorial-locations-properties row-select-container-cell"}
          style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2} alignItems="center">
              {/* Grid item for FormControl with manual width */}
              <Grid item sx={{ width: '15%', minWidth: "150px", paddingBottom : 1 }}> {/* Adjust the width as needed */}
                <FormControl fullWidth>
                  <InputLabel>Base Loc</InputLabel>
                  <Select
                    value={item.baseLocId || ''} // Assuming item has a baseLocId property
                    onChange={(event)=> setNewBaseId(event.target.value)}
                    label="Base Loc"
                  >
                    {buildBaseLocOptions()}
                  </Select>
                  <Button 
                    variant='outlined'
                    onClick={()=> handleCopyLocTo(newBaseId)}
                    >Copy To</Button>
                  <Button 
                    variant='outlined'
                    onClick={()=> handleMoveLocTo(newBaseId)}
                    >Move To</Button>
                </FormControl>
              </Grid>
              {/* Grid item for RowSelectContainerArray, taking up the rest of the space */}
              <Grid item xs>
                <RowSelectContainerArray
                  item={item}
                  handleOptionChange={handleOptionChange}
                  otherReqKeys={otherReqKeys}
                />
              </Grid>
            </Grid>
          </Collapse>
        </ TableCell>
      </TableRow>
    </>
  );

}