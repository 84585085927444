import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";

import { PostServerData } from '../../util/dbfetch';

export const useStripeConnect = (connectedAccountId) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = () => {
        return new Promise((resolve, reject) => {
          PostServerData(
            "account_session",
            { "account_id": connectedAccountId },
            (response) => {
              const clientSecret = response.data.client_secret;
              resolve(clientSecret);
            },
            (err) => {
              console.error("Error fetching client secret:", err);
              reject(err);
            }
          );
        });
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUB_API,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};


export const useStripeConnectManage = (connectedAccountId) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = () => {
        return new Promise((resolve, reject) => {
          PostServerData(
            "account-dashboard",
            { "account_id": connectedAccountId },
            (response) => {
              const clientSecret = response.data.client_secret;
              resolve(clientSecret);
            },
            (err) => {
              console.error("Error fetching client secret:", err);
              reject(err.data.detail);
            }
          );
        });
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUB_API,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};
