import React, { useEffect, useRef } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import OneDimensionalLineGraph from '../graphics/oneDimensionalAlignmentGraph.jsx';

export default function AlignmentPointTable({
  renderKey,
  selectedIndex,
  points,
  locationArray,
  onItemClick,
  maxWidthLeft,
  maxWidthRight,
}) {

  return (
    <TableContainer component={Paper}>
      <Table aria-label="alignment point table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "15%" }} >Point</TableCell>
            <TableCell sx={{ width: "79%", minWidth: "240px", pr: 2, mr: 1 }} >Equipment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {points.map((point, index) => {
            const oneDimensionalLineGraph = (
              <OneDimensionalLineGraph
                renderKey={renderKey}
                point={point}
                points={points}
                locationArray={locationArray}
                widthTotal={200}
                onItemClick={(locationid) => onItemClick(index, locationid)}
                maxWidthLeft={maxWidthLeft}
                maxWidthRight={maxWidthRight}
              />
            );

            return (
              <AlignmentPointRow
                key={index}
                index={index}
                label={index}
                isSelected={index === selectedIndex}
                oneDimensionalLineGraph={oneDimensionalLineGraph}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AlignmentPointRow({
  index,
  label,
  isSelected,
  oneDimensionalLineGraph,
  ...props
}) {
  const rowRef = useRef(null);

  useEffect(() => {
    if (isSelected && rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSelected]);

  return (
    <TableRow
      key={index}
      ref={rowRef}
      sx={{
        backgroundColor: isSelected ? "selected.main" : "none",
        border: isSelected ? `2px solid black` : 'none'
      }}
      {...props}
    >
      <TableCell component="th" scope="row">
        {label}
      </TableCell>
      <TableCell>
        {oneDimensionalLineGraph}
      </TableCell>
    </TableRow>
  );
}