import React, { useState, createContext, useContext } from 'react';

import { Snackbar } from '@mui/material';

const SnackbarContext = createContext(); 

export function SnackbarProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  /**
   * Opens the snackbar with a provided message.
   *
   * @param {string} newMessage - Message to show in the snackbar.
   */
  const openSnackbar = (newMessage) => {
    setMessage(newMessage);
    setOpen(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{openSnackbar}}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message={message}
      />
    </SnackbarContext.Provider>
  );
}

export default function useSnackbarContext() {
  return useContext(SnackbarContext);
}