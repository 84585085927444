import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';


import CircularProgress from '@mui/material/CircularProgress';

import TransferResponsibilityOrganization from './transferResponsibilityOrganization';
import TransferInvite from './transferInvite';

import { UseServerData } from '../../util/dbfetch';

// finish passing down organization, do not use orgID here
// Create a new page, when loaded get all transfered initiated by user
export default function OrganizationTransfers() {
  const { orgID } = useParams();

  const [organization, setOrganization] = useState(null);
  const [managedInviteDetails, setManagedInviteDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  useEffect(() => {
    UseServerData(`organizations/${orgID}`, setOrganization, setLoading, () => { });
    UseServerData(`transfers`, setManagedInviteDetails, setLoading, () => { }, { organizationid: orgID });
  }, [orgID]);


  const handleDeleteInvite = (transferCode) => {
    setManagedInviteDetails((prevDetails) => prevDetails.filter((invite) => invite.transfer_code !== transferCode));
  };


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  return (
    <>
      <Dialog maxWidth="lg" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Button variant="outlined" onClick={() =>
        handleOpenDialog(
          <TransferResponsibilityOrganization organization={organization} />
        )}>Create New Transfers</Button>

      {loading ? (
        <CircularProgress />
      ) : managedInviteDetails.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2 }}>You have no transfers in progress!</Typography>
      ) : (
        <>
          {managedInviteDetails.map((invite, index) => (
            <TransferInvite key={index} invite={invite} onDeleteInvite={handleDeleteInvite} />
          ))}
        </>
      )}
    </>
  );
}

