import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import useUserDataContext from '../util/providers/userDataProvider.jsx';
import { withGenericConfirmWrapper } from '../util/withGenericConfirmWrapper';

function OrganizationSelectorComponent({ onSubmit, onClose, onError, ...props }) {
  const [selectedOrg, setSelectedOrg] = useState('');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);


  const { organizations } = useUserDataContext();

  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
  };

  const handleSubmit = () => {
    if (!selectedOrg) {
      onError();
      setShowError(true);

    } else {
      setLoading(true);
      onSubmit(selectedOrg);
    }
  };

  return (
    <Box {...props} >
      <Select value={selectedOrg} onChange={handleOrgChange}>
        <MenuItem key={"demo"} value={"demo"}>Create Demo Org</MenuItem>
        {organizations.map((org) => (
          <MenuItem key={org.organizationid} value={org.organizationid}>{org.organization_name}</MenuItem>
        ))}
      </Select>
      {showError ? <Typography variant="body2" sx={{ pl: 1 }}>Please select an organization</Typography> : null}
      <Button onClick={handleSubmit} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </Box>
  );
}

export const OrganizationSelector = withGenericConfirmWrapper(OrganizationSelectorComponent);