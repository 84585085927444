import React, { useState, useEffect, useMemo, useContext } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import Location from '../../models/location.ts';
import PoleSilhouette from './../graphics/pole/poleSilhouette.jsx';
import PoleSpanner from './../graphics/pole/poleSpanner.jsx';
import JumperValley from './../graphics/pole/jumperValley.jsx';


import { LocationContext } from '../../util/providers/locationContext.jsx';
import { sortNumAlpha } from '../../util/sortNumAlpha.js';
import { EquipmentSubPickerSimple } from '../locationItems/equipmentSubPicker.jsx';
import { EquipmentEdit } from '../locationItems/locationEdit.jsx';



function PoleConnectionManager({  onGenerateEquipment, defaultEquipmentList }) {
  /*

  modal to display a pole 
  should be able to select another pole and see all connections between them
    Display on either side to show two other poles

  */
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  
  const [poleLocs, setPoleLocs] = useState([]);
  const [connectionLocs, setConnectionLocs] = useState([]);

  const [subjectPole, setSubjectPole] = useState('');
  const [subjectDependentLocations, setSubjectDependentLocations] = useState([]);
  const [subjectDependentJumpers, setSubjectDependentJumpers] = useState([]);

  const [leftPole, setLeftPole] = useState('');
  const [leftDependentLocations, setLeftDependentLocations] = useState([]);

  const [rightPole, setRightPole] = useState('');
  const [rightDependentLocations, setRightDependentLocations] = useState([]);

  
  const [rerenderKey, setRerenderKey] = useState(0);  
  const { LocationArray, reRenderLocationArray } = useContext(LocationContext);

  useEffect(() => {
    const poleLocs0 = LocationArray.getAllEquipment().filter((item) => item.equip_key === 'pole');
    setPoleLocs(poleLocs0);

    const connectionLocs0 = LocationArray.getAllConnections(true);
    setConnectionLocs(connectionLocs0);
  }, [LocationArray, rerenderKey]);

  useEffect(() => {
    if (subjectPole) {
      const dependentLocations = LocationArray.getDependents(subjectPole.locationid);

      const DependentEquip = dependentLocations.filter((item) => item.equip_key !== 'jumper')
      const jumperLocations = dependentLocations.filter((item) => item.equip_key === 'jumper');
  
      // Combine sorting logic into a single sort function
      DependentEquip.sort((a, b) => {
        // First, sort by equip_key
        const equipOrder = {
          'pole_pri': 1,
          'pole_tx': 2,
          'pole_sec': 3,
          'pole_meter': 4
        };
  
        const equipA = equipOrder[a.equip_key] || 5;
        const equipB = equipOrder[b.equip_key] || 5;
  
        if (equipA !== equipB) {
          return equipA - equipB;
        }
  
        // If equip_key is the same, sort by sortNumAlpha
        return sortNumAlpha(a.loc_number, b.loc_number);
      });
  
      setSubjectDependentLocations(DependentEquip);
      setSubjectDependentJumpers(jumperLocations);
    } else {
      setSubjectDependentLocations([]);
      setSubjectDependentJumpers([]);
    }

  }, [LocationArray, subjectPole, rerenderKey]);

  useEffect(() => {
    if (leftPole) {
      const dependentLocations = LocationArray.getDependents(leftPole.locationid);

      const DependentEquip = dependentLocations.filter((item) => item.equip_key !== 'jumper')
  
      // Combine sorting logic into a single sort function
      DependentEquip.sort((a, b) => {
        // First, sort by equip_key
        const equipOrder = {
          'pole_pri': 1,
          'pole_tx': 2,
          'pole_sec': 3,
          'pole_meter': 4
        };
  
        const equipA = equipOrder[a.equip_key] || 5;
        const equipB = equipOrder[b.equip_key] || 5;
  
        if (equipA !== equipB) {
          return equipA - equipB;
        }
  
        // If equip_key is the same, sort by sortNumAlpha
        return sortNumAlpha(a.loc_number, b.loc_number);
      });
  
      setLeftDependentLocations(DependentEquip);
    } else {
      setLeftDependentLocations([]);
    }

  }, [LocationArray, leftPole, rerenderKey]);

  useEffect(() => {
    if (rightPole) {
      const dependentLocations = LocationArray.getDependents(rightPole.locationid);

      const DependentEquip = dependentLocations.filter((item) => item.equip_key !== 'jumper')
  
      // Combine sorting logic into a single sort function
      DependentEquip.sort((a, b) => {
        // First, sort by equip_key
        const equipOrder = {
          'pole_pri': 1,
          'pole_tx': 2,
          'pole_sec': 3,
          'pole_meter': 4
        };
  
        const equipA = equipOrder[a.equip_key] || 5;
        const equipB = equipOrder[b.equip_key] || 5;
  
        if (equipA !== equipB) {
          return equipA - equipB;
        }
  
        // If equip_key is the same, sort by sortNumAlpha
        return sortNumAlpha(a.loc_number, b.loc_number);
      });
  
      setRightDependentLocations(DependentEquip);
    } else {
      setRightDependentLocations([]);
    }

  }, [LocationArray, rightPole, rerenderKey]);

  function rerender() {
    setRerenderKey((prevKey) => prevKey + 1);
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const [leftJumpers, rightJumpers] = useMemo(() => {
    const left = [];
    const right = [];
    subjectDependentJumpers.forEach(jumper => {
      const startIndex = subjectDependentLocations.findIndex(loc => loc.locationid === jumper.ssd_id);
      const endIndex = subjectDependentLocations.findIndex(loc => loc.locationid === jumper.lsd_id);
      if (startIndex !== -1 && endIndex !== -1) {
        if (subjectDependentLocations[startIndex].equip_key === 'pole_pri' || subjectDependentLocations[endIndex].equip_key === 'pole_pri') {
          left.push(jumper);
        } else {
          right.push(jumper);
        }
      }
    });
    return [left, right];
  }, [subjectDependentJumpers, subjectDependentLocations]);

  function handleAddDepEquipment(selectedNewEquipment, base_id) {
    const newLocation = onGenerateEquipment(selectedNewEquipment, { base_id });

    if (newLocation !== null) {
      LocationArray.add(newLocation)
      reRenderLocationArray();
    }
  }

  const handleDeleteLocation = (jumper) => {
    // Remove the jumper from the location array
    LocationArray.flagRemove(jumper.locationid);
    rerender();
    setDialogOpen(false);
  };

  // disable for left/rightt
  // left right will  not parse jumpers
  const handleAddJumper = (baseLoc, ssd, lsd) => {
    // Add a jumper between the selected ssd and lsd

    const theseJumpers = LocationArray
      .getDependents(subjectPole.locationid)
      .filter((item) => item.equip_key === 'jumper');

    if (!baseLoc || !ssd || !lsd) {
      console.log('Missing required selections');
      return;
    } else if (ssd.locationid === lsd.locationid) {
      console.log('Cannot connect to self.');
      return;
    }


    // Check if a jumper with the same ssdSelected, lsdSelected, and subjectPole already exists
    const existingJumper = theseJumpers.find(
      (jumper) =>
        jumper.ssd_id === ssd.locationid &&
        jumper.lsd_id === lsd.locationid &&
        jumper.base_id === baseLoc.locationid
    );
    
    if (existingJumper) {
      if (existingJumper.remove === false) {
        console.log('Jumper already exists');
        return;
      }
    }

    // check if connection is invalid
    // cannot connect pole_pri to pole_sec or pole_meter
    if (ssd.equip_key === 'pole_pri') {
      if (lsd.equip_key === 'pole_sec' || lsd.equip_key === 'pole_meter') {
        console.log('Cannot Connect Primary directly to Secondary');
        return;
      }
    } else if (lsd.equip_key === 'pole_pri') {
      if (ssd.equip_key === 'pole_sec' || ssd.equip_key === 'pole_meter') {
        console.log('Cannot Connect Primary directly to Secondary');
        return;
      }
    }

    const newId = LocationArray.generateNewID();
    const newJumper = new Location({
      locationid: newId,
      loc_number: `${baseLoc.loc_number}J-${newId}`,
      ssd_id: ssd.locationid,
      lsd_id: lsd.locationid,
      equip_key: 'jumper',
      name: 'Jumper',
      otherReq: [],
      selections: [],
      base_id: baseLoc.locationid,
    })
  
    LocationArray.add(newJumper);
    rerender();
  };

  const handleAddConnection = (from, to) => {
    // handle creating a connection between the two locations

    const fromType = from.loc.equip_key;
    const toType = to.loc.equip_key;

    // is this the same location?
    if (from.loc.locationid === to.loc.locationid) {
      console.log('Cannot connect to self');
      return;
    }

    // does this already exist?
    const existingConnection = connectionLocs.find(
      (loc) =>
        (loc.ssd_id === from.loc.locationid && loc.lsd_id === to.loc.locationid) ||
        (loc.ssd_id === to.loc.locationid && loc.lsd_id === from.loc.locationid)
    );

    if (existingConnection && existingConnection.remove === false) {
      console.log('Connection already exists');
      return;
    }

    const validConnections = {
      'pole_pri': ['pole_pri', 'pole_tx'],
      'pole_sec': ['pole_sec', 'pole_meter'],
      'pole_tx': ['pole_sec', 'pole_meter'],
    }
    const isValidConnection = validConnections[fromType]?.includes(toType);

    if (!isValidConnection) {
      console.log(`Invalid connection from ${fromType} to ${toType}`);
      return;
    }
    // determine if we are creating a jumper or a connection
    // if the base_id is the same we are creating a jumper
    if (from.loc.base_id === to.loc.base_id) {

      let basePole = null;
      if (from.loc.base_id === subjectPole.locationid) {
        basePole = subjectPole;
      } else if (from.loc.base_id === leftPole.locationid) {
        basePole = leftPole;
      } else if (from.loc.base_id === rightPole.locationid) {
        basePole = rightPole;
      }

      handleAddJumper(basePole, from.loc, to.loc);

    } else if (fromType === 'pole_pri' && toType === 'pole_pri') {
      
      const newConnection = onGenerateEquipment('pspan', { ssd_id: from.loc.locationid, lsd_id: to.loc.locationid });
      LocationArray.add(newConnection);
    } else if (fromType === 'pole_sec' && toType === 'pole_sec') {

      const newConnection = onGenerateEquipment('sspan', { ssd_id: from.loc.locationid, lsd_id: to.loc.locationid });
      LocationArray.add(newConnection);

    } else {
      console.log("invalid connection")
    }
    
    rerender();
  };

  const handleLeftClick = () => {
    setLeftPole(subjectPole);
    setSubjectPole(rightPole);
    setRightPole("");
    rerender();
  };

  const handleRightClick = () => {
    setRightPole(subjectPole);
    setSubjectPole(leftPole);
    setLeftPole("");
    rerender();
  };

  const onSetSubjectPole = (pole) => {
    setSubjectPole(pole);
    rerender();
  }

  return (

    <Paper >
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        {dialogContent}
      </Dialog>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Pole Connection Manager</Typography>
      </Box>
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
      >
        <IconButton onClick={handleLeftClick}>
          <ArrowLeftIcon />
        </IconButton>
        <Typography variant="body1">Move</Typography>
        <IconButton onClick={handleRightClick}>
          <ArrowRightIcon />
        </IconButton>
      </Box>
      <DndProvider backend={HTML5Backend}>
        <Box className = {"pole-container"} sx={{ display: 'flex', flexDirection: 'row' }}>

          <PoleSelectSilhouette
            className = {"pole-left"}
            poleLocs = {poleLocs}
            thisPole = {leftPole}
            setThisPole = {setLeftPole}
            theseDependentLocs = {leftDependentLocations}
            LocationArray={LocationArray}
            handleDeleteLocation={handleDeleteLocation}
            handleOpenDialog = {handleOpenDialog}
          />
          <PoleSpanner 
            connectionLocList = {connectionLocs} 
            leftLocations = {leftDependentLocations} 
            rightLocations = {subjectDependentLocations} 
            leftBase = {leftPole}
            rightBase = {subjectPole}
            LocationArray = {LocationArray}
            handleDeleteLocation = {handleDeleteLocation}
            handleOpenDialog = {handleOpenDialog}
            topGap = {60}
            onDrop={handleAddConnection}
          />
          <JumperValley 
            jumpers = {leftJumpers} 
            dependentLocations={subjectDependentLocations} 
            LocationArray = {LocationArray}
            topGap = {60} 
            handleOpenDialog ={handleOpenDialog} 
            handleDeleteJumper = {handleDeleteLocation}
            cascadeLeft ={true}
          />

          <PoleSelectSilhouette
            className = {"pole-subject"}
            poleLocs = {poleLocs}
            thisPole = {subjectPole}
            setThisPole = {onSetSubjectPole}
            theseDependentLocs = {subjectDependentLocations}
            LocationArray={LocationArray}
            handleDeleteLocation={handleDeleteLocation}
            handleOpenDialog = {handleOpenDialog}
          />
          <JumperValley 
            jumpers = {rightJumpers} 
            dependentLocations={subjectDependentLocations} 
            LocationArray = {LocationArray}
            topGap = {60} 
            handleOpenDialog = {handleOpenDialog}
            handleDeleteJumper = {handleDeleteLocation} 
            cascadeLeft ={false}
          />

          <PoleSpanner 
            connectionLocList = {connectionLocs} 
            leftLocations = {subjectDependentLocations} 
            rightLocations = {rightDependentLocations} 
            leftBase = {subjectPole}
            rightBase = {rightPole}
            LocationArray = {LocationArray}
            handleDeleteLocation = {handleDeleteLocation}
            handleOpenDialog = {handleOpenDialog}
            topGap = {60}
            onDrop={handleAddConnection}
          />
          <PoleSelectSilhouette
            className = {"pole-right"}
            poleLocs = {poleLocs}
            thisPole = {rightPole}
            setThisPole = {setRightPole}
            theseDependentLocs = {rightDependentLocations}
            LocationArray={LocationArray}
            handleDeleteLocation={handleDeleteLocation}
            handleOpenDialog = {handleOpenDialog}
          />
          
        </Box>
      </DndProvider>
      {/* Section for editing Current Pole */}
      <Box sx={{ pt: 2, boxShadow: 5, width: '70%', margin: '0 auto' }}>
        <PoleEditor
          key={rerenderKey} 
          subjectPole = {subjectPole}
          subjectDependentJumpers = {subjectDependentJumpers}
          subjectDependentLocations = {subjectDependentLocations}
          LocationArray={LocationArray}
          defaultEquipmentList={defaultEquipmentList}
          handleAddDepEquipment={handleAddDepEquipment}
          handleAddJumper={handleAddJumper}
          handleDeleteLocation={handleDeleteLocation}
          handleOpenDialog = {handleOpenDialog}
        />

      </Box>

      
    </Paper>

  );
}

function PoleEditor({className, subjectPole, subjectDependentJumpers, subjectDependentLocations, LocationArray, defaultEquipmentList, handleAddDepEquipment, handleAddJumper, handleDeleteLocation, handleOpenDialog}) {


  const [ssdSelected, setSsdSelected] = useState(null);
  const [lsdSelected, setLsdSelected] = useState(null);


  const connectionKeys = ["pcable", "scable", "pspan", "sspan"];

  useEffect(() => {
    setSsdSelected(null);
    setLsdSelected(null);

  }, [subjectPole]);


  return (
    <>
      {subjectPole ? <EquipmentEdit
        location={subjectPole}
        handleDeleteLocation={handleDeleteLocation}
      /> : null}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', p: 2 }}>
        <Typography>Create Jumper</Typography>
        <FormControl>
          <Select
            sx={{ minWidth: 120 }}
            labelId="ssd-select"
            value={ssdSelected || ''}
            onChange={(e) => setSsdSelected(e.target.value)}
          >
            <MenuItem key="" value={""}><em>---</em></MenuItem>
            {subjectDependentLocations.map((item) => (
              <MenuItem key={item.locationid} value={item}>{`${item.loc_number}-${item.name}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            sx={{ minWidth: 120 }}
            labelId="lsd-select"
            value={lsdSelected || ''}
            onChange={(e) => setLsdSelected(e.target.value)}
          >
            <MenuItem key="" value={""}><em>---</em></MenuItem>
            {subjectDependentLocations.map((item) => (
              <MenuItem key={item.locationid} value={item}>{`${item.loc_number}-${item.name}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="secondary" onClick= {() => handleAddJumper(subjectPole, ssdSelected, lsdSelected)}>Jump</Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', p: 2 }}>
        <Typography sx ={{mr:2 }}>New Loc:</Typography>
        <EquipmentSubPickerSimple
          className="equipment-sub-picker"
          baseLoc={subjectPole}
          baseEquipmentState={LocationArray.getFilteredLocations().filter((item) => !connectionKeys.includes(item.equip_key) && item?.base_key === undefined)}
          subEquipmentDefList={defaultEquipmentList.filter((item) => !connectionKeys.includes(item.equip_key) && item.base_key != null)}
          handleSubmitEquipment={handleAddDepEquipment}
        />
      </Box>
    </>
  )
}


function PoleSelectSilhouette({className, poleLocs, thisPole, setThisPole, theseDependentLocs, LocationArray, handleDeleteLocation, handleOpenDialog}) {

  return (
  <Box className = {className}  sx={{ flexGrow: 1, padding: 0, boxShadow: 3 }}>
    <FormControl>
      <Select
        sx={{ minWidth: 120, height: 60 }}
        labelId={`${className}-select`}
        value={thisPole}
        onChange={(e) => setThisPole(e.target.value)}
      >
        <MenuItem key="" value=""><em>---</em></MenuItem>
        {poleLocs.map((item) => (
          <MenuItem key={item.locationid} value={item}>{`${item.loc_number}`}</MenuItem>
        ))}
      </Select>
    </FormControl>

    <PoleSilhouette 
      location={thisPole} 
      dependentLocations={ theseDependentLocs } 
      LocationArray={LocationArray}
      handleDeleteLocation={handleDeleteLocation}
      handleOpenDialog= {handleOpenDialog}
    />
  </Box>

  )
}


export default PoleConnectionManager;