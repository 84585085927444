import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { postRecoverPassword } from "../../util/dbfetch.js";

export default function ResetPasswordDialog({ onClose }) {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);


  function handleSubmitResetEmail() {
    setIsSubmitting(true);
    postRecoverPassword(email,
      (data) => {
        setMessage("Password reset email sent if the email exists.");
      },
      (err) => {
        setMessage("Error sending password reset email.");
        setIsSubmitting(false);
      }
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center">
        <TextField
          sx={{ m: 2 }}
          id="reset-email"
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={handleSubmitResetEmail}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        {message && <Typography color="textSecondary" sx={{ mt: 2 }}>{message}</Typography>}

      </Box>
    </>
  );
}
