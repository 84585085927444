import { bearing } from '@turf/turf';

import ConduitTrace from '../conduitTrace.ts';

interface Position {
    lat: number;
    lng: number;

  }

interface IConduitElement {
  order?: number;
  conduitid?: number;  // these are not unique; conduit redundancy is allowed in API DB
  section_type: string;
  length: number;
  bend: number; // 90, 45, 22, 11, 5
  ccw: boolean;
  grade: number;
}
  

export default class BaseConduitFactory {
  build(path: Position[], scaleFactor: number = 1): ConduitTrace {
    let conduits: IConduitElement[] = [];

    for (let i = 1; i < path.length; i++) {
      const start = path[i - 1];
      const curr = path[i];
      const next = path[i + 1] || null;

      const distPrev = this.distance(start, curr, scaleFactor);
      conduits.push(this.createStraightConduit(distPrev));

      if (next === null) {
        continue;
      }

      const startBearing = bearing([start.lng, start.lat], [curr.lng, curr.lat]);
      const endBearing = bearing([curr.lng, curr.lat], [next.lng, next.lat]);
      let angleDiff = startBearing - endBearing;

      // Normalize the angle to between -180 and 180
      angleDiff = ((angleDiff + 180) % 360) - 180;

      conduits.push(...this.createBendConduits(angleDiff));
    }

    // convert conduits to simple objects
    const simpleConduits = conduits.map((conduit, i) => ({
      order: i,
      conduitid: conduit.conduitid,
      section_type: conduit.section_type,
      length: conduit.length,
      bend: conduit.bend,
      ccw: conduit.ccw,
      grade: conduit.grade,
    }));

    return new ConduitTrace(simpleConduits);
  }

  private createBendConduits(angleDiff: number, maxDeflection = 5): IConduitElement[] {
    let deflectionLeft = angleDiff;
    let bends: IConduitElement[] = [];

    while (Math.abs(deflectionLeft) >= maxDeflection) {
      for (let bend of [90, 45, 22, 11, 5]) {
        if (Math.abs(deflectionLeft) <= maxDeflection) {
          break;
        } else if (Math.abs(deflectionLeft) - bend >= maxDeflection) {
          let ccw = deflectionLeft > 0;
          bends.push(this.createBendConduit(bend, ccw));
          deflectionLeft = ccw ? deflectionLeft - bend : deflectionLeft + bend;
          break;
        } else if (-maxDeflection < Math.abs(deflectionLeft) - bend && Math.abs(deflectionLeft) - bend < maxDeflection) {
          bends.push(this.createBendConduit(bend, deflectionLeft > 0));
          deflectionLeft = deflectionLeft > 0 ? deflectionLeft - bend : deflectionLeft + bend;
        }
      }
    }

    return bends;
  }


  private distance(start: Position, end: Position, scaleFactor: number): number {
    const d_x = (end.lng - start.lng)*scaleFactor;
    const d_y = (end.lat - start.lat)*scaleFactor;

    const distance = Math.sqrt(d_x * d_x + d_y * d_y);
    return parseFloat(distance.toFixed(2));
  }


  private createStraightConduit(length: number): IConduitElement {
    return {
      section_type: 'straight',
      length: length,
      bend: 0,
      ccw: false,
      grade: 0,
    };
  }

  private createBendConduit(bend: number, ccw: boolean): IConduitElement {
    return {
      section_type: "horz",
      length: 0,
      bend: bend,
      ccw: ccw,
      grade: 0,
    };
  }
}