import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

import { Box } from '@mui/material';

import IconFactory from './iconFactory';


function OneDimensionalLineGraph({
  renderKey,
  point,
  points,
  locationArray,
  widthTotal: containerWidth,
  onItemClick,
  maxWidthLeft,
  maxWidthRight,
  sx }) {
  const ref = useRef();

  const heightCL = 20;

  useEffect(() => {
    if (!containerWidth) { return; };

    if (maxWidthLeft === 0 || maxWidthRight === 0) { return; };

    const svg = d3.select(ref.current);
    svg.selectAll("*").remove();

    const scale = d3
      .scaleLinear()
      .domain([-maxWidthLeft, maxWidthRight]) // input domain
      .range([0, containerWidth]);            // output (rendered) domain

    // Create groups for the line and the path elements
    const lineGroup = svg.append('g').attr('id', 'line');
    const iconGroup = svg.append('g').attr('id', 'paths');

    // Append the line to the line group
    lineGroup.append('line')  // this line should start at localLeft and end at localRight
      .attr('x1', scale(-point.width.left))
      .attr('y1', heightCL)
      .attr('x2', scale(point.width.right))
      .attr('y2', heightCL)
      .style('stroke', 'black')
      .style('stroke-width', 2);

    lineGroup.append('line') // CL tick mark this should be at center
      .attr('x1', scale(0))
      .attr('y1', heightCL - 5)
      .attr('x2', scale(0))
      .attr('y2', heightCL + 5)
      .style('stroke', 'black')
      .style('stroke-width', 2);

    point.items.forEach(item => {
      // Find the corresponding equipment from the locationArray
      const equipment = locationArray.find(equip => String(equip.locationid) === String(item.locationid));

      if (!equipment) {
        console.error('Equipment not found in locationArray', item);
        return;
      }

      // Create the icon for the equipment
      const icon = IconFactory.createIcon(equipment.equip_key);


      // Append the icon to the SVG
      const iconElement = iconGroup.append('image')
        .attr('xlink:href', icon.iconUrl)
        .attr('width', 20)
        .attr('height', 20)
        .attr('x', scale(item.offset) - 10)
        .attr('y', heightCL - 10)
        .attr('transform', `rotate(${item.offset < 0 ? Number(item.angle) - 90 : Number(item.angle) + 90}, ${scale(item.offset)}, ${heightCL})`);

      // Add an onClick event listener to the icon
      iconElement.on('click', () => {
        onItemClick(equipment.locationid);
      });


    });  //end of forEach


  }, [locationArray, renderKey, point, points, containerWidth, maxWidthLeft, maxWidthRight, onItemClick]);

  return (
    <Box sx={sx}>
      <svg ref={ref} width="100%" height={heightCL * 2}></svg>
    </Box>
  );
}

export default OneDimensionalLineGraph;