import React, { useState } from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import { RowSelectContainerArray } from "../rowSelectContainer";
import Location from "../../models/location.ts";


function LocationEdit({ location, onLocationUpdate, handleDeleteLocation }) {

  function handleOptionChange(event, name) {
    onLocationUpdate({ selections: [{ key: name, selection: event.target.value }] });
  }

  return (
      <Paper
        sx = {{p: 2}}
      >
        {/* Loc Number */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ px: 2 }}>{location.name}</Typography>
            <TextField
              className="loc-text-field"
              label="Loc"
              placeholder="Loc"
              defaultValue={location.loc_number}
              onBlur={(e) => onLocationUpdate({ loc_number: e.target.value })}
              sx={{ width: 120, minWidth: 80 }}
            />
          </Box>
          <Button sx={{ px: 2 }} onClick={() => handleDeleteLocation(location)} variant="outlined">Delete</Button>
        </Box>
        {/* Props */}
        <RowSelectContainerArray
          item = {location}
          handleOptionChange={handleOptionChange}
          />
      </Paper>
  )
}


export function ConnectionEdit({location, connectionLocList, handleDeleteLocation}) {

  const [loc, setLoc] = useState(new Location(location));

  const onLocationUpdate = (payload) => {
    location.update(payload);

    setLoc(new Location(location));
  }

  function buildSSDOptions(name, value) {
    if (value === null) {
      value = -1;
    }
    const optionItems = connectionLocList
      .filter((option) => option.locationid !== location.locationid)
      .map((option) => (
        <MenuItem key={option.locationid} value={option.locationid}>
          {`${option.loc_number} - ${option.name} ${option.base_loc ? `- Base: ${option.base_loc}` : ''}`}
        </MenuItem>
      ));

    return (
      <FormControl className="connection-ssd-options" variant="outlined" sx={{ width: "100%" }}>
        <Select
          value={value}
          onChange={(event) => onLocationUpdate({ ssd_id: event.target.value })}
          name={name}
        >
          {optionItems}
        </Select>
      </FormControl>
    );
  }


  function buildLSDOptions(name, value) {
    if (value === null) {
      value = -1;
    }
    const optionItems = connectionLocList
      .filter((option) => option.locationid !== location.locationid)
      .map((option) => (
        <MenuItem key={option.locationid} value={option.locationid}>
          {`${option.loc_number} - ${option.name} ${option.base_loc ? `- Base: ${option.base_loc}` : ''}`}
        </MenuItem>
      ));

    return (
      <FormControl className="connection-lsd-options" variant="outlined" sx={{ width: "100%" }}>
        <Select
          value={value}
          onChange={(event) => onLocationUpdate({ lsd_id: event.target.value })}
          name={name}
        >
          {optionItems}
        </Select>
      </FormControl>
    );
  }

  return (
    <>
      <LocationEdit 
        location={loc} 
        onLocationUpdate={onLocationUpdate} 
        handleDeleteLocation={handleDeleteLocation}
      />
      <Box display="flex" flexDirection="column" alignItems="flex-start" p={2} gap={2}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography sx={{pr: 2}} variant="subtitle1">SSD: </Typography>
          {buildSSDOptions('ssd_id', location.ssd_id)}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography sx={{pr: 2}} variant="subtitle1">LSD: </Typography>
          {buildLSDOptions('lsd_id', location.lsd_id)}
        </Box>
      </Box>
    </>
  );
}

export function EquipmentEdit({ location, handleDeleteLocation }) {
  const [loc, setLoc] = useState(new Location(location));

  const onLocationUpdate = (payload) => {
    location.update(payload);

    setLoc(new Location(location));
  }


  return (
    <LocationEdit 
      location={ loc } 
      onLocationUpdate ={ onLocationUpdate } 
      handleDeleteLocation={handleDeleteLocation}
    />
  )
};
