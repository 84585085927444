/**
 * `GenericConfirm` is a higher-order component that wraps another component (`Component` prop) 
 * and provides it with `onSubmit`, `onClose`, and `onError` handlers. 
 * It also passes through any additional props using the spread operator (`...props`).
 *
 * @param {React.Component} Component - The component to be rendered.
 * @param {Function} onSubmit - The function to be called when the submit action is confirmed.
 * @param {Function} [onClose] - Optional. The function to be called when the close action is confirmed.
 * @param {Function} [onError] - Optional. The function to be called when an error occurs.
 * @param {...Object} props - Any additional props to be passed to the `Component`.
 *
 * @returns {React.Component} The `Component` with `onSubmit`, `onClose`, `onError` handlers and additional props.
 */
export function withGenericConfirmWrapper(WrappedComponent) {
  return function({ onSubmit, onClose, onError, ...props }) {
    const handleConfirm = (prop) => {
      onSubmit(prop);
    };

    function handleClose() {
      if (onClose) {
        onClose();
      }
    }

    function handleError(err) {
      if (onError) {
        onError(err);
      }
    }

    return (
      <WrappedComponent
        onSubmit={handleConfirm}
        onClose={handleClose}
        onError={handleError}
        {...props}
      />
    );
  };
}