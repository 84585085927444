import React, { useState, useEffect } from "react";

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';

import useUserDataContext from '../../util/providers/userDataProvider.jsx';
import useSnackbarContext from '../../util/providers/snackbarProvider';

import { UseServerData, PutServerData } from '../../util/dbfetch';

export default function JoinByCode() {
  const [inviteDetails, setInviteDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState('');

  const [code, setCode] = useState('');
  const { organizations } = useUserDataContext();
  const { openSnackbar } = useSnackbarContext();

  const [selectedOrg, setSelectedOrg] = useState("");
  const [isDesigner, setIsDesigner] = useState(false);

  useEffect(() => {
    if (inviteDetails.projects) {
      setIsDesigner(inviteDetails.projects.some((project) => project.roles.includes('designer')));
    } else {
      setIsDesigner(false);
    }
  }, [inviteDetails]);


  const handleLoadTransferCode = () => {
    UseServerData(`transfers/${code}`, setInviteDetails, setLoading, openSnackbar);
  };


  function handleAcceptTransfer() {
    PutServerData(`transfers/${code}/accept`,
      null,
      (response) => setServerResponse(response?.data?.message),
      (response) => setServerResponse(response?.data?.message),
      selectedOrg ? { organizationid: selectedOrg } : {}
    );
  }


  return (
    <>
      <Typography variant="h4">Join By Code</Typography>
      <Typography variant="body1">Enter the transfer code from another user.</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
        <TextField label="Code" variant="outlined" sx={{ mr: 2 }} value={code} onChange={(event) => setCode(event.target.value)} />
        <Button variant="contained" onClick={handleLoadTransferCode}>Load Code</Button>
      </Box>

      {(Object.keys(inviteDetails).length === 0) ? (
        <Typography variant="body1">Enter a code to join a project.</Typography>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h2" sx={{ p: 2, pb: 0, mx: 2, mt: 2 }}>Transfer Details</Typography>
          <Paper elevation={4} sx={{ p: 2, m: 2 }}>
            <Typography sx={{ mb: 1 }} variant="body1">Sending Organization(s): {inviteDetails.organizations.map(org => org.organization_name).join(', ')}</Typography>
            <Typography variant="body2">Sending User: {inviteDetails.user.username}</Typography>
            <Typography variant="body2">Expiration: {new Date(inviteDetails.expiration).toLocaleString()}</Typography>
            <Typography variant="body2">Number of Uses Left: {inviteDetails.useCount}</Typography>
          </Paper>

          {inviteDetails?.organizationTarget && (
            <Paper sx={{ p: 2, m: 2 }}>
              <Typography variant="body1">
                You will join the organization:
              </Typography>
              <Typography variant="body2">
                {inviteDetails.organizationTarget.organization_name} as a member
              </Typography>
            </Paper>
          )}

          <Paper elevation={2} sx={{ p: 2, m: 2 }}>
            <Typography variant="body1">Projects to Transfer:</Typography>
            <Typography variant="body2">You will have the following responsibilities:</Typography>
            <Grid container spacing={2}>
              {inviteDetails.projects.map((project) => (
                <Grid item xs={12} sm={6} md={4} key={project.projectid}>
                  <ProjectCard project={project} />
                </Grid>
              ))}
            </Grid>
          </Paper>


          {/* Submit */}
          {isDesigner && (
            <>
              <Typography variant="body1">Select a designer organization.</Typography>
              <Typography sx={{ pl: 2 }} variant="body2" color="text.secondary">
                You must be the manager of this organization to accept.
              </Typography>
              <Select
                labelId="org-select-label"
                id="org-select"
                value={selectedOrg}
                onChange={(event) => setSelectedOrg(event.target.value)}
                sx={{ mt: 2, width: 360 }}
              >
                <MenuItem key="" value="">
                  Select an Organization
                </MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org.organizationid} value={org.organizationid}>
                    {org.organization_name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          <Button variant="contained" onClick={() => handleAcceptTransfer()} disabled={isDesigner && !selectedOrg}>
            Accept Transfer
          </Button>
          <Paper sx={{ m: 1 }}>
            {serverResponse}
          </Paper>
        </>
      )}
    </>
  );
}

function ProjectCard({ project }) {

  return (
    <Card key={project.projectid} sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="body1">{project.proj_name}</Typography>
        <Typography variant="body2">
          Roles: {project.roles.join(', ')}
        </Typography>
      </CardContent>
    </Card>
  )
}