import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';


import Login from './user/login';
import RegisterUser from './user/registerUser';


export default function NewUserHomePage({ onLogin, onRegister }) {

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setLoginModalOpen(true);
  };

  const handleCloseDialog = (resData) => {
    setLoginModalOpen(false);
  };


  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Dialog open={loginModalOpen} onClose={handleCloseDialog}>
          <Box sx={{ p: 4 }}>{dialogContent}</Box>
        </Dialog>
        <Typography variant="h3" sx={{ mt: 2 }}>
          A web portal for electric utility connections
        </Typography>
        <Typography variant="h3" sx={{ mx: 3 }}>
          And online tools built better designs
        </Typography>
        <Button variant= "outlined"
          component={RouterLink} 
          to="/about" >Tell me more about this app</Button>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography sx={{ mt: 2 }}>
          We are currently in Beta. Login below or register to start connecting to your utilities.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            className="login-button"
            onClick={() =>
              handleOpenDialog(<Login onLogin = {onLogin} onClose={handleCloseDialog} />)
            }
            sx={{ borderRadius: "0.5rem", mr: 2 }}
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="login-button"
            onClick={() =>
              handleOpenDialog(
                <RegisterUser onRegister = {onRegister} onClose={handleCloseDialog}  />
              )
            }
            sx={{ borderRadius: "0.5rem" }}
          >
            Register
          </Button>
        </Box>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography>
          If you are interested and would like to know more, please email us
          at questions@utilitydesign.app for more information.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className="email-button"
          component="a"
          href={`mailto:questions@utilitydesign.app?subject=Utility Design App inquiry`}
          sx={{ borderRadius: "0.5rem", mt: 2 }}
        >
          Email Us
        </Button>
      </Paper>
    </>
  );
}