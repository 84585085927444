import { useNavigate  } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CreateOrganization from "./createOrganization";
import JoinByCode from "../../components/transfers/joinByCode";

export default function OrganizationHeader({onOpenDialog, onCloseDialog}) {
  const navigate = useNavigate();


  const handleNewOrgClose = (resData) => {
    navigate(`/organizations/${resData.data.organizationid}`)
    onCloseDialog();
  };

  return (
    <Box className="create-join-buttons" sx={{ mb: 4 }}>
      {/* Create and Join Organization buttons */}
      <Button variant= "outlined" className="create-org-button" onClick={() => onOpenDialog(<CreateOrganization onClose={handleNewOrgClose} />)}>Create Organization</Button>
      <Button variant= "outlined" className="join-button" onClick={() => onOpenDialog(<JoinByCode />)}>Join By Code</Button>
    </Box>
  );
}