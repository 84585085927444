import { useEffect} from 'react';
import 'react-calendar/dist/Calendar.css';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';

import { TransferProject } from './transferProject';

export default function TransferProjectsList({ selectedProjects, setSelectedProjects, selectOrgChecked, projects }) {

  useEffect(() => {
    let updatedSelectedProjects = [...selectedProjects];
  
    // Remove 'sponsor' and 'designer' roles when selectOrgChecked is true
    if (selectOrgChecked) {
      updatedSelectedProjects = updatedSelectedProjects.map(project => {
        project.removeRoles(['sponsor', 'designer']);
        return project;
      });
    }
  
    // Filter out projects with no roles
    updatedSelectedProjects = updatedSelectedProjects.filter(project => !project.isEmpty());
  
    setSelectedProjects(updatedSelectedProjects);
  }, [selectOrgChecked, selectedProjects, setSelectedProjects]);


  const handleProjectCheckBoxChanged = (projectid, role, proj_name) => (event) => {
    const isChecked = event.target.checked;
    
  
    setSelectedProjects((prevSelectedProjects) => {
      if (prevSelectedProjects.length === 0 && isChecked ) {
        let newProject = new TransferProject(projectid, proj_name, [role]);
        prevSelectedProjects.push(newProject);
      }

      let project = prevSelectedProjects.find((p) => p.projectid === projectid);
      if (!project) {
        project = new TransferProject(projectid, proj_name, []);
        prevSelectedProjects.push(project);
      }
  
      if (isChecked) {
        project.addRole(role);
      } else {
        project.removeRole(role);
      }
  
      return prevSelectedProjects.filter((p) => !p.isEmpty());
    });
  };

  return (
    <Paper sx = {{p: 2, mt: 2}}>
      <FormLabel component="legend">Select Roles to Provide</FormLabel>
      <List>
        {projects.map((project) => (
          <ProjectListItem 
            key={project.projectid} 
            project={project} 
            selectedProjects={selectedProjects} 
            selectOrgChecked={selectOrgChecked}
            handleCheckboxChange={handleProjectCheckBoxChanged}
          />
        ))}
      </List>
    </Paper>
  );
}

function ProjectListItem({ project, selectedProjects, selectOrgChecked, handleCheckboxChange}) {


  return (
    <ListItem key={project.projectid} sx={{ display: 'flex-box', justifyContent: 'space-between', alignItems: 'center', minWidth: 16 }}>
      <Box sx={{ flexGrow: 1, pr: 4 }}>
        <Typography variant="body1">
          {project.proj_name}
        </Typography>
        <Tooltip title={project.proj_desc}>
          <Typography variant="body2" color="text.secondary" sx={{ display: 'inline-block', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {project.proj_desc.substring(0, 32)}
          </Typography>
        </Tooltip>
        <Typography color="text.secondary" variant="body2">
          Your role(s): {project.roles.join(', ')}
        </Typography>
      </Box>
      
      <Box sx={{display: 'flex-box', flexDirection: 'row', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox 
              checked={selectedProjects.some((p) => p.projectid === project.projectid && p.hasRole('editor'))} 
              onChange={handleCheckboxChange(project.projectid, 'editor', project.proj_name)} 
              disabled={!(project.roles.includes('editor') || project.roles.includes('manager'))}
            />
          }
          label="Editor"
        />
        <FormControlLabel
          control={
            <Checkbox 
              checked={selectedProjects.some((p) => p.projectid === project.projectid && p?.roles.includes('viewer'))} 
              onChange={handleCheckboxChange(project.projectid, 'viewer', project.proj_name)} 
              disabled={!(project.roles.length > 0)}
            />
          }
          label="Viewer"
        />
        <FormControlLabel
          control={
            <Checkbox 
              checked={selectedProjects.some((p) => p.projectid === project.projectid && p?.roles.includes('sponsor'))} 
              onChange={handleCheckboxChange(project.projectid, 'sponsor', project.proj_name)} 
              disabled={!(project.roles.includes('sponsor')) || selectOrgChecked} // checks if user is a sponsor
            />
          }
          label="Sponsor"
        />
        <FormControlLabel
          control={
            <Checkbox 
              checked={selectedProjects.some((p) => p.projectid === project.projectid && p?.roles.includes('designer'))} 
              onChange={handleCheckboxChange(project.projectid, 'designer', project.proj_name)} 
              disabled={!(project.roles.includes('manager')) || selectOrgChecked} // checks if user is a manager
            />
          }
          label="Designer"
        />
      </Box>
    </ListItem>
  );
}