import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import { differenceInDays, parseISO } from 'date-fns';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { UseServerData } from "../../util/dbfetch";
import { stateReducer, STATE_ACTION } from '../stateReducer';
import { useTutorial } from '../../util/providers/useTutorialContext';

import ProjectHeader from './projectHeader';
import InstanceLister from './instanceListItem';
import CheckoutFormPrice from '../../pages/stripe/checkoutFormPrice';

function PlanTierLabel({ plan_tier, instances, date_start, projectid, handleOpenRFP }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const proj_prod_id = process.env.REACT_APP_STRIPE_PROJ_PRICE_ID

  const handleUpgrade = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmUpgrade = () => {
    setConfirmDialogOpen(false);
    setDialogOpen(true);
  };

  const onOpenRFP = () => {
    setConfirmDialogOpen(false);
    handleOpenRFP();
  };

  if (plan_tier === 'paid') {
    return null;
  }

  const today = new Date();
  const startDate = parseISO(date_start);
  const daysLeft = 14 - differenceInDays(today, startDate);

  const freeTierMessage = `Free Tier: You have ${instances.length} saved instances of your maximum 6 saves. Consider upgrading.`;

  const demoTierMessage = `Demo: This project will be deleted after ${daysLeft} days. Consider upgrading or creating a new project.\nYou have ${instances.length} saved instances of your maximum 5 saves.`;
  
  const pendingTierMessage = `Pending: Payment is processing`;

  return (
    <Paper sx={{ width: '100%', padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ flexGrow: 1, whiteSpace: 'pre-line' }}>
          {plan_tier === 'free' && freeTierMessage}
          {plan_tier === 'demo' && demoTierMessage}
          {plan_tier === 'pending' && pendingTierMessage}
        </Typography>
        <Button variant="contained" onClick={handleUpgrade}>
          Upgrade
        </Button>
      </Box>
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Upgrade</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Upgrading this project will enable unlimited saves and all website features to design utility infrastructure.
            <Typography component="span" fontWeight="bold">You will still be responsible for submitting your project to your local utility for approval and/or construction.</Typography>
            You will be responsible for all changes needed to meet your utility's standards. 
            <br /><br />
            If you want to find someone to handle communicating with your utility and all revisions, you should use the "Request for Proposal (RFP)" system to find a designer.
            <br /><br />
            contact us at <a href="mailto:questions@utilitydesign.app">questions@utilitydesign.app</a> with any questions. 
            <br /><br />
            Are you sure you want to upgrade this project to work on yourself?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={onOpenRFP} color="primary" variant="outlined">
            Open RFPs
          </Button>
          <Button onClick={handleConfirmUpgrade} color="primary" variant="contained">
            Unlock this Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box sx={{ p: 4 }}>
          <CheckoutFormPrice 
            targetURL='create-payment-session-project' 
            price_id={proj_prod_id} 
            params={{ projectid: projectid }} 
          />
        </Box>
      </Dialog>
    </Paper>
  );
}

function InvoiceLabel({ projectData }) {
  const navigate = useNavigate();

  const handleOpenInvoices = () => {
    navigate("/user/purchases");
  };

  if (projectData?.payment_outstanding) {
    return (
      <Paper sx={{ width: '100%', padding: 2, marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Payment Due
          </Typography>
          <Button variant="contained" onClick={handleOpenInvoices}>
            View Invoices
          </Button>
        </Box>
      </Paper>
    );
  }

  return null;
}


export function ProjectDetails() {
  const { projID } = useParams();
  const navigate = useNavigate();

  // const [projectData, setProjectData] = useState({});
  const [projectData, dispatch] = useReducer(stateReducer, {});
  const [loadingProject, setLoadingProject] = useState(true);

  const [instances, setInstances] = useState([]);
  const [loadingInstance, setLoadingInstance] = useState(true);
  const [errorInstance, setErrorInstance] = useState("");
  const [latestInstance, setLatestInstance] = useState(null);

  const { handleProgress } = useTutorial();

  const setProjectData = (data) => {
    dispatch({
      type: STATE_ACTION.LOAD_INITIAL,
      payload: data,
    });
  };

  // Project Details
  useEffect(() => {
    UseServerData(`project/${projID}`, setProjectData, () => setLoadingProject(false), (resp) => { });
  }, [projID]);

  // Project Instances
  useEffect(() => {
    UseServerData(`project/${projID}/instances`, setInstances, setLoadingInstance, setErrorInstance);
  }, [projID]);


  useEffect(() => {
    if (instances.length > 0) {
      const sortedInstances = instances.sort((a, b) => new Date(b.date_update) - new Date(a.date_update));
      setLatestInstance(sortedInstances[0]);
    }
  }, [instances]);

  function handleEditLocations(event) {
    if (Object.keys(instances).length === 0) {
      navigate(`/project/${projID}/instance/edit`)
    } else if (latestInstance) {
      navigate(`/project/${latestInstance.projectid}/instance/edit?instanceid=${latestInstance.instanceid}`);
    }

    if (latestInstance) {
      // Do we need to use the instanceid in the tutorial?
      handleProgress(event.target.className, { instanceid: latestInstance.instanceid })
    }
  }

  return (
    <>
      {loadingProject ? (
        <div>Loading project...</div>
      ) : (
        <>
          {projectData && Object.keys(projectData).length > 0 && (
            <PlanTierLabel 
              plan_tier={projectData?.plan_tier} 
              instances={instances} 
              date_start={projectData.date_start} 
              projectid={projectData.projectid}
              handleOpenRFP={() => { navigate(`/project/${projID}/rfp`) }}
            />
          )}
          <InvoiceLabel projectData={projectData} />
          <ProjectHeader projectData={projectData} onDispatch={dispatch} />
          <Button className="tutorial-project-rfp-final tutorial-project-rfp-intro" variant="outlined"
            onClick={(event) => {
              navigate(`/project/${projID}/rfp`);
              handleProgress(event.target.className);
            }}
          >Open Requests for Proposals</ Button>
        </>
      )}

      <br />
      <Button className="tutorial-current-locations-button current-locations-button"
        variant="contained" onClick={handleEditLocations}>Edit Current Locations</Button>
      <Button
        className="tutorial-project-price-profiles"
        variant="outlined"
        disabled={Object.keys(instances).length === 0}
        onClick={(event) => {
          navigate(`/project/${projID}/price-profile?instanceid=${latestInstance.instanceid}`)
          handleProgress(event.target.className);
        }}
      >View Price Profile</Button>
      {loadingInstance ? (
        <div>Loading instances...</div>

      ) : errorInstance ? (
        <div>{errorInstance}</div>
      ) : (
        <InstanceLister
          projID={projID}
          instances={instances}
          setInstances={setInstances}
        />
      )}
    </>
  );
};

