import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';

import { Box, Button, IconButton, Typography } from '@mui/material'
import { HelpOutline } from '@mui/icons-material';


function Tooltip(props) {
  const { continuous, index, step, backProps, closeProps, primaryProps, tooltipProps } = props;


  return (
    <Box sx={{backgroundColor: 'background.default', border: '1px 100%', borderRadius: 2, p: 1, maxWidth: `70vh`, zIndex: 15000}} className="tooltip-body" {...tooltipProps}>
      {step.title && <Typography variant="h3" className="tooltip-title">{step.title}</Typography>}
      <Typography variant="body1" className="tooltip-content">{step.content}</Typography>
      <Box className="tooltip-footer">
        {index > 0 && (
          <Button className="back-button" {...backProps}>
            Back
          </Button>
        )}
        {continuous && (
          <Button className="next-button" {...primaryProps}>
            Next
          </Button>
        )}
        {!continuous && (
          <Button className="close-button" {...closeProps}>
            Close
          </Button>
        )}
      </Box>
    </Box>
  );
}

function JoyRideStarter({ steps, width = null, anchorVert = 0, anchorHorz = 0, onStart = null, disableScrolling =  false, className = '', ...props  }) {
  const [tourStarted, setTourStarted] = useState(false);

  const handleStartTour = () => {
    // Start the JoyRide tour
    if (onStart) {
      onStart();
    }
    setTourStarted(true);
  };

  const handleJoyrideCallback = (data) => {
    // Handle JoyRide callbacks here

    // Reset the tourStarted state when the tour ends
    if (data.status === 'finished' || data.status === 'skipped') {
      setTourStarted(false);
    }
  };

  return (
    <>
      <Box 
        className={className}
        {...props} 
      sx={{ 
          height: "0px", 
          width: width,
          display: "flex", 
          justifyContent: "flex-end", 
          ...props.sx 
        }}>
        <IconButton className={`tour-button ${className}`}  onClick={handleStartTour}
        
        sx={{ 

          zIndex: "1",  // set the z-index to a higher value
          top: -anchorVert,
          left: anchorHorz,

        }}>
          <HelpOutline />
        </IconButton>
      </ Box>
      {tourStarted && (
        <ReactJoyride
          continuous
          disableScrolling={disableScrolling}
          styles= {{
            options: {
              zIndex: 15000,
            }
          }}

          steps={steps}
          callback={handleJoyrideCallback}
          tooltipComponent={Tooltip}
        />
      )}
    </>
  );
}

export default JoyRideStarter;