import { lineString, Feature, LineString, Properties } from '@turf/helpers';

import { AlignmentPerpendicular } from './alignmentPerpendicular';

interface IBounds {
  minlat: number;
  minlon: number;
  maxlat: number;
  maxlon: number;
}

type INode = number;

interface ITags {
  [key: string]: string;
}

interface IData {
  type: string | null;
  id: number | null;
  bounds: IBounds;
  nodes: INode[];
  geometry: { lon: number, lat: number }[];
  tags: ITags;
}

export class OverpassData {
  type: string | null;
  id: number | null;
  bounds: IBounds;
  nodes: number[];
  geometry: { lon: number, lat: number }[];
  tags: ITags;
  lineStringFeature: Feature<LineString, Properties>;

  constructor(data: IData) {
    this.type = data.type;
    this.id = data.id;
    this.bounds = data.bounds;
    this.nodes = data.nodes;
    this.geometry = data.geometry;
    this.tags = data.tags;

    // Create lineString feature
    const coordinates = this.geometry.map(point => [point.lon, point.lat]);
    this.lineStringFeature = lineString(coordinates, this.tags);
  }


  static fromAlignmentPoints(newPoints: AlignmentPerpendicular[]): OverpassData {
    
    let bounds = newPoints.reduce(
      (acc, point) => {
        return {
          minlat: Math.min(acc.minlat, point.lat),
          minlon: Math.min(acc.minlon, point.lon),
          maxlat: Math.max(acc.maxlat, point.lat),
          maxlon: Math.max(acc.maxlon, point.lon),
        };
      },
      {
        minlat: 90,
        minlon: 180,
        maxlat: -90,
        maxlon: -180,
      }
    );

    const data: IData = {
      type: 'AlignmentPointsImport',
      id: null,
      bounds: bounds,
      nodes: newPoints.map(point => point.nodeOSM).filter(
        nodeOSM => nodeOSM !== undefined) as number[],      
      geometry: newPoints.map(point => ({ lon: point.lon, lat: point.lat })),
      tags: {} as ITags,
    };

    // Create a new OverpassData object from the IData
    return new OverpassData(data);
  }
  
  append(data: OverpassData) {
    // Update bounds
    this.bounds.minlat = Math.min(this.bounds.minlat, data.bounds.minlat);
    this.bounds.minlon = Math.min(this.bounds.minlon, data.bounds.minlon);
    this.bounds.maxlat = Math.max(this.bounds.maxlat, data.bounds.maxlat);
    this.bounds.maxlon = Math.max(this.bounds.maxlon, data.bounds.maxlon);

    // Combine tags
    for (const key in data.tags) {
      if (this.tags.hasOwnProperty(key)) {
        this.tags[key] += ', ' + data.tags[key];
      } else {
        this.tags[key] = data.tags[key];
      }
    }

    // Append nodes and geometry
    this.nodes = [...this.nodes, ...data.nodes];
    this.geometry = [...this.geometry, ...data.geometry];
  
    // Update lineString feature
    const coordinates = this.geometry.map(point => [point.lon, point.lat]);
    this.lineStringFeature = lineString(coordinates, this.tags);
  }

  toGeoJSON() {
    return this.lineStringFeature;
  }

  getNodeGeometryPairs(): Array<[INode, { lon: number, lat: number }]> {
    return this.nodes.map((node, index) => [node, this.geometry[index]]);
  }
}

