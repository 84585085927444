import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from "react-router-dom"

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import useUserDataContext from '../../util/providers/userDataProvider.jsx';

import TransferResponsibilityConfirm from './transferResponsibilityConfirm';
import TransferProjectsList from './transferProjectsList';

export default function TransferResponsibilityWrapper({ project = null, organization = null }) {

  const { projects: allProjects, organizations: allOrganizations } = useUserDataContext();

  let projects = [];
  let organizations = [];

  if (organization) {
    organizations = [organization];
    projects = allProjects.filter(proj => proj.organizationid === organization.organizationid);
  } else if (project) {
    projects = [project];
    organizations = allOrganizations.filter(org => org.organizationid === project.organizationid);
  } else {
    projects = allProjects;
    organizations = allOrganizations;
  }

  let orgTransferHistoryPath = null;

  if (project && project.organizationid) {
    orgTransferHistoryPath = `/organizations/${project.organizationid}/transfers`;
  } else if (organization && organization.organizationid) {
    orgTransferHistoryPath = `/organizations/${organization.organizationid}/transfers`;
  }


  return <TransferResponsibility projects={projects} organizations={organizations} orgTransferHistoryPath={orgTransferHistoryPath} />;
}


function TransferResponsibility({ projects, organizations, orgTransferHistoryPath = null }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectOrgChecked, setSelectOrgChecked] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const [inviteExpiration, setInviteExpiration] = useState(null);

  const [isRestrictive, setIsRestrictive] = useState(false);
  const [useCount, setUseCount] = useState(1);
  const [userverify, setUserVerify] = useState(false);

  const today = useMemo(() => new Date(), []);
  const tomorrow = useMemo(() => {
    const tomorrowDate = new Date(today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    return tomorrowDate;
  }, [today]);
  const maxDate = useMemo(() => {
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 1);
    return maxDate;
  }, [today]);

  useEffect(() => {
    const threeDaysFromNow = new Date(today);
    threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3);
    setInviteExpiration(threeDaysFromNow);
  }, [today]);

  useEffect(() => {
    // when selectedOrg changes, change to organization invite,
    // selected projects can only be editor and viewers
  }, [selectedOrg]);

  useEffect(() => {
    const isSponsorOrDesigner = selectedProjects.some((project) => project?.roles.includes('sponsor') || project?.roles.includes('designer'));
    setIsRestrictive(isSponsorOrDesigner);
    if (isSponsorOrDesigner) {
      setUseCount(1);
      setUserVerify(true);
    }

  }, [selectedProjects]);

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  const handleSelectOrgChecked = (event) => {
    setSelectOrgChecked(event.target.checked);
    if (!event.target.checked) {
      setSelectedOrg("");
    }
  };


  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
  };

  return (
    <>
      {projects.length === 0 ? (
        <CircularProgress />
      ) : (
        <TransferProjectsList
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          selectOrgChecked={selectOrgChecked}
          projects={projects}
        />
      )}


      <Paper sx={{ p: 2, my: 2 }}>
        <Box display="flex" alignItems="left" flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent="left" >
          <FormControlLabel
            disabled={organizations.length === 0}
            control={
              <Checkbox
                checked={selectOrgChecked}
                onChange={handleSelectOrgChecked}
              />
            }
            label="Invite to Organization:"
          />
          <FormControl disabled={!selectOrgChecked} sx={{ minWidth: 100 }}>
            <Select
              labelId="org-select-label"
              id="org-select"
              displayEmpty
              value={selectedOrg}
              onChange={handleOrgChange}
            >
              <MenuItem key={""} value={""}>No Invite</MenuItem>
              {organizations.map((org) => (
                <MenuItem key={org.organizationid} value={org.organizationid}>{org.organization_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>


      <Box display="flex" flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent="left">
        <Card
          className="invite-uses"
          sx={{ p: 2, m: 2, width: "fit-content", maxWidth: 400 }}
        >
          <FormControlLabel
            sx={{ p: 1 }}
            control={
              <Checkbox
                checked={userverify}
                onChange={() => setUserVerify(!userverify)}
              />
            }
            label='I want to confirm the Recipient(s)'
          />

          <TextField
            label="Number of Uses"
            type="number"
            disabled={isRestrictive}
            InputProps={{ inputProps: { min: 1 } }}
            value={useCount}
            onChange={(event) => setUseCount(event.target.value)}
            sx={{ width: '80%', maxWidth: '16em' }}
          />
          {isRestrictive && (
            <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '16em' }}>
              If transferring sponsorship or designer, only one use is permitted.  For additional security it is recommended to confirm the recipient(s).
            </Typography>
          )}
        </Card>

        <Card className="invite-expiration" sx={{ p: 2, m: 2, width: 'fit-content' }}>
          <Typography >Select expiration date:</Typography>
          <Typography color="text.secondary">Currently: {inviteExpiration ? inviteExpiration.toUTCString() : ""}</Typography>
          <Calendar minDetail="year" maxDetail="month" minDate={tomorrow} maxDate={maxDate} onChange={(resp) => setInviteExpiration(resp)} value={inviteExpiration} />
        </Card>
      </Box>


      <Button
        sx={{ ml: 2, mt: 1 }}
        variant="contained"
        color="primary"
        disabled={selectOrgChecked && selectedOrg === ""}
        onClick={() => {
          handleOpenDialog(
            <TransferResponsibilityConfirm
              onCancel={() => setDialogOpen(false)}
              organizationTargetid={selectOrgChecked ? selectedOrg : null}
              projects={selectedProjects}
              expiration={inviteExpiration}
              useCount={useCount}
              userverify={userverify}
            />
          )
        }}>
        Create Transfer
      </Button>
      {location.pathname !== '/user/transfers' && (
        <Button sx={{ ml: 2, mt: 1 }} variant="outlined" onClick={() => navigate(`/user/transfers`)}>
          Go To User Transfer History
        </Button>
      )}
      {!!orgTransferHistoryPath && (
        <Button sx={{ ml: 2, mt: 1 }} variant="outlined" onClick={() => navigate(orgTransferHistoryPath)}>
          Go To Org Transfer History
        </Button>
      )}

      <Box sx={{ height: 300 }} />
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
    </>
  );
}
