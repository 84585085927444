import React, { useState } from 'react';
import {
  Marker,
  Popup,
  useMapEvents,
  LayersControl,
  FeatureGroup,
} from 'react-leaflet';

import IconFactory from '../../graphics/iconFactory';

export default function EquipmentLayer({
  isLoaded,
  LocationArray,
  editMode,
  onMapClick,
  setSelectedObjects,
  checked, className = "equipment-marker-group",
  name = "Equipment",
  ...props }) {

  const [lastClickedMarker, setLastClickedMarker] = useState(null);

  useMapEvents({
    click(e) {
      onMapClick(e)
    },
  })

  if (!isLoaded) {
    return null;
  }

  const handleMarkerClick = (event, equipment) => {

    setSelectedObjects([equipment]);
    if (editMode) {
      setLastClickedMarker(equipment);
    } else {
      setLastClickedMarker(null);
      return null
    }

    if (equipment === lastClickedMarker) {
      setLastClickedMarker(null);

    } else if (equipment && lastClickedMarker) {

      onMapClick(event, equipment, lastClickedMarker);
      setLastClickedMarker(null);
    }
  };


  return (
    <LayersControl.Overlay checked className={className} name={name} {...props}>
      <FeatureGroup>
        {
          LocationArray.map((equipment) => {
            if (!equipment.position || !equipment.position.lat || !equipment.position.lng) {
              return null;
            }
            const icon = IconFactory.createLeafletIcon(equipment.equip_key);

            if (!icon) {
              return null;
            }

            return (
              <Marker
                icon={icon}
                key={equipment.locationid}
                position={[equipment.position.lat, equipment.position.lng]}
                eventHandlers={{
                  click: (event) => handleMarkerClick(event, equipment),
                }}
              >
                <Popup>{`Loc ${equipment.loc_number}: ${equipment.equip_key}`}</Popup>
              </Marker>
            );
          })
        }
      </FeatureGroup>
    </LayersControl.Overlay>
  );
}