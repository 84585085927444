import React, { useEffect, useState, useReducer } from 'react';
import { useParams } from "react-router-dom"
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';

import { useTutorial } from '../../util/providers/useTutorialContext';
import { stateReducer, STATE_ACTION } from '../stateReducer';
import { UseServerData, PostServerData } from "../../util/dbfetch";

import useSnackbarContext from '../../util/providers/snackbarProvider.jsx';
import useLocationArrayWithOptions from '../../hooksDatabase/useLocationArrayWithOptions.jsx';

import { QuantifiedPriceProfileList } from '../../models/priceProfile.ts';

import { GroupedPriceProfile } from '../priceProfileGroup.jsx';


export default function FindOrganization() {
  // This will query the server for organizations doing work for 'X' utility
  const { projID } = useParams();
  const [project, dispatch] = useReducer(stateReducer, {});
  const [instances, setInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);

  const { handleProgress } = useTutorial();
  const { openSnackbar } = useSnackbarContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const setProjectData = (data) => {
    dispatch({
      type: STATE_ACTION.LOAD_INITIAL,
      payload: data,
    });
  };


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  // Project Details
  useEffect(() => {
    UseServerData(`project/${projID}`, setProjectData, () => { }, () => { });
  }, [projID]);

  // Instances Details
  useEffect(() => {
    UseServerData(`project/${projID}/instances`, setInitialInstances, () => { }, () => { });
  }, [projID]);

  useEffect(() => {
    if (Object.keys(project).length !== 0) {
      const workstreamNames = project.workstreams.map(ws => ws.utility);
      const params = new URLSearchParams();
      workstreamNames.forEach(name => params.append('workstreams', name));
      UseServerData("organizations/work-available?" + params.toString(), setOrganizations, () => { }, () => { });
    }
  }, [project]);


  const setInitialInstances = (instances) => {
    setInstances(instances);
    const sortedInstances = instances.sort((a, b) => new Date(b.date_update) - new Date(a.date_update));
    setSelectedInstance(sortedInstances[0].instanceid);
  }

  const handleCheckboxChange = (event, organization) => {
    if (event.target.checked) {
      setSelectedOrganizations([...selectedOrganizations, organization]);
    } else {
      setSelectedOrganizations(selectedOrganizations.filter((org) => org.organizationid !== organization.organizationid));
    }
  };

  const handleRequestQuote = (event) => {
    const orgIdList = selectedOrganizations.map((org) => org.organizationid);
    const deadline = new Date();
    deadline.setDate(deadline.getDate() + 7);

    const obj = {
      instanceid: selectedInstance,
      organizationid: orgIdList,
      deadline: deadline,
    };

    PostServerData(`project/${project.projectid}/rfp`,
      obj,
      () => { handleProgress(event.target.className); openSnackbar("Quotes Requested") },
      (err) => openSnackbar(err));
  };


  return (
    <>
      <Dialog maxWidth="lg" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <ExistingRequests onOpenDialog={handleOpenDialog} />

      <Paper sx={{ px: 4, py: 2, mb: 2 }}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Request New Proposals
        </Typography>
        <TableContainer component={Paper}>
          {project && organizations && project.workstreams && (<Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Organization</TableCell>
                {project?.workstreams.map((workstream) => (
                  <TableCell key={workstream.workstreamid}>{workstream.utility}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations.map((organization) => (
                <TableRow key={organization.organizationid}>
                  <TableCell>
                    <Checkbox
                      checked={selectedOrganizations.some((org) => org.organizationid === organization.organizationid)}
                      onChange={(event) => handleCheckboxChange(event, organization)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body1">{organization.organization_name}</Typography>
                      <Typography sx={{ color: "text.secondary", px: 2 }} variant="body2" >{organization.organization_desc}</Typography>
                    </Box>
                  </TableCell>
                  {project.workstreams.map((workstream) => {
                    const matchingWorkstream = organization.workstreams.find((orgWorkstream) => orgWorkstream.utility === workstream.utility);
                    return (
                      <TableCell key={organization.organizationid + workstream.workstreamid}>
                        {matchingWorkstream ? <CheckIcon /> : <CloseIcon />}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>)}
        </TableContainer>
        <Box className={"box-select-instance"} sx={{ px: 4, py: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>Select Instance to Price:  </Typography>
          <Select value={selectedInstance} onChange={(event) => setSelectedInstance(event.target.value)} >
            {instances.sort((a, b) => new Date(b.date_update) - new Date(a.date_update))
              .map((instance) => (
                <MenuItem key={instance.instanceid} value={instance.instanceid} >
                  {instance.project_phase} - {new Date(instance.date_update).toLocaleDateString()}
                </MenuItem>
              ))}
          </Select>
          <Button className="tutorial-request-quote" sx={{ px: 2 }} onClick={handleRequestQuote} disabled={selectedOrganizations.length === 0}>Request Quote</Button>
        </Box>

      </Paper>
    </>
  );
}

function ExistingRequests({ onOpenDialog }) {
  const { projID } = useParams();

  const [rfps, setRfps] = useState([]);



  useEffect(() => {
    UseServerData(`project/${projID}/rfp/open`, setRfps, () => { }, () => { });
  }, [projID]);


  // This will show the org, their comments, and their price profile summary
  const handleOpenComments = (rfp) => {
    onOpenDialog(<SelectedRFP rfp={rfp} onOpenDialog={onOpenDialog} />);
  };

  return (
    <Paper sx={{ px: 4, py: 2, mb: 2 }}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Current Requests for Proposals
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Response</TableCell>
              <TableCell>Provided Instance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rfps.map((rfp) => (
              <TableRow key={rfp.instanceid}>
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1">{rfp.organization.organization_name}</Typography>
                    <Typography sx={{ color: "text.secondary", px: 2 }} variant="body2">{rfp.organization.organization_desc}</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  {rfp.organization_comments || rfp.price_profile_group ? (
                    <Button onClick={() => handleOpenComments(rfp)}>
                      View Price Profile
                    </Button>
                  ) : (
                    <Typography value="No Response yet" />
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title={`Desc: ${rfp.instance.instance_desc || '<No Description>'}`} placement="top">
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body1">Phase: {rfp.instance.project_phase}</Typography>
                      <Typography sx={{ color: "text.secondary", px: 2 }} variant="body2">{new Date(rfp.deadline).toLocaleDateString()}</Typography>
                    </Box>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function SelectedRFP({ rfp, onOpenDialog }) {
  const { instanceid } = rfp.instance;
  const [newQuantPPList, setNewQuantPPList] = useState(QuantifiedPriceProfileList.default());
  const [loading, setLoading] = useState(true);

  const [
    LocationArray, ,
    locationArrayLoading,
  ] = useLocationArrayWithOptions(instanceid);

  useEffect(() => {
    if (locationArrayLoading) {
      return;
    }

    const quantPPList0 = QuantifiedPriceProfileList.quantifyFromPriceProfileList(
      rfp.price_profile_group, LocationArray
    );

    setNewQuantPPList(quantPPList0);
    setLoading(false);

  }, [rfp.price_profile_group, LocationArray, locationArrayLoading]);

  return (
    <>
      <Paper sx={{ my: 2, p: 1 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          {rfp.organization.organization_name}
        </Typography>
        <Typography variant="body" sx={{ px: 2, mb: 2 }}>
          {rfp.organization_comments}
        </Typography>
      </Paper>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <GroupedPriceProfile
          priceProfileList={newQuantPPList}
          setPriceProfileList={() => { }}
          handleAddPriceProfile={() => { }}
          onOpenDialog={onOpenDialog}
          disableUpdates={true}
          profileName={rfp.price_profile_group.name}
          profileDescription={rfp.price_profile_group.description}
        />
      )}
    </>
  );
}