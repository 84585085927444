import React, { useMemo, useCallback } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import { ConnectionEdit } from '../../locationItems/locationEdit';

function JumperValley({ jumpers, dependentLocations, LocationArray, topGap, handleOpenDialog, handleDeleteJumper }) {

  const totalHeight = 300;

  const onOpenJumper = useCallback((jumper) => {
    const possibleConnections = LocationArray
      .filterByKey("base_id", jumper.base_id)
      .getFilteredLocations(); // filter removed and jumpers

    const nestedEquipConn = possibleConnections.map((loc) => {
      return {
        ...loc,
        base_loc: LocationArray.getLocation(loc.base_id)
      };
    });

    handleOpenDialog(
      <ConnectionEdit
        location={jumper}
        connectionLocList={nestedEquipConn}
        handleDeleteLocation={handleDeleteJumper}
      />
    );
  }, [LocationArray, handleOpenDialog, handleDeleteJumper]);


  const renderJumpers = useMemo(() => {
    const boxHeight = totalHeight / (dependentLocations.length + 1);

    return jumpers.map((jumper, index) => {
      let startIndex = dependentLocations.findIndex(loc => loc.locationid === jumper.ssd_id);
      let endIndex = dependentLocations.findIndex(loc => loc.locationid === jumper.lsd_id);

      const startLoc = dependentLocations[startIndex];
      const endLoc = dependentLocations[endIndex];

      let backgroundColor = 'black';
      if (endIndex < startIndex) {
        // invert the start and end index
        const temp = startIndex;
        startIndex = endIndex;
        endIndex = temp;

        backgroundColor = 'grey';
      }

      const startY = startIndex * boxHeight + boxHeight / 2;
      const endY = endIndex * boxHeight + boxHeight / 2;

      const tooltipTitle = `Source: ${startLoc?.loc_number || '?'}-${startLoc?.name}\n
      Load: ${endLoc?.loc_number || '?'}-${endLoc?.name}`;

      return (
        <Tooltip key={index} title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipTitle}</span>} arrow>
          <Box 
            className = {`hover-box-loc-${jumper.locationid}`} 
            sx = {{
              px: 1, 
              cursor: 'pointer'
            }}
            onClick={() => onOpenJumper(jumper)}
          >
            <Box
              sx={{
                height: `${Math.abs(endY - startY)}px`,
                alignSelf: 'top',
                width: '2px',
                backgroundColor: backgroundColor,
                mt: `${topGap + startY}px`,
              }}
            />
          </Box>
        </Tooltip>
      );
    });

  }, [jumpers, onOpenJumper, dependentLocations, topGap, totalHeight]);

  return (
    <Box
      sx={{
        mx: 1,
        display: 'flex',
        flexDirection: 'row',
        height: `${totalHeight+topGap}px`,
        position: 'relative',
      }}
    >
      {renderJumpers}
    </Box>
  );
}

export default JumperValley;