
export function sortNumAlpha(a,b) {
    const numA = parseInt(a, 10);
    const numB = parseInt(b, 10);
    if (!isNaN(numA) && !isNaN(numB)) {
      if (numA !== numB) {
        return numA - numB;
      }
      const subA = a.replace(numA.toString(), '');
      const subB = b.replace(numB.toString(), '');
      return subA.localeCompare(subB);
    }
    return a.localeCompare(b);
  }