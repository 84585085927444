import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
 
import { OrganizationIndex } from "./organizationIndex";

import OrganizationHeader from "./organizationHeader";
import OrganizationDetailer from "./OrganizationDetailer";
import InvoiceList from "../../components/invoiceList";
import OrganizationTransfers from "../../components/transfers/organizationTransfers";
import OrganizationProjectPricer from "./organizationProjectPricer";

import PageNotFound from '../pageNotFound';


export default function OrganizationRoute() {
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  }


  return (
    <>
      <OrganizationHeader onOpenDialog = {handleOpenDialog} onCloseDialog = {handleCloseDialog} />
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Routes>
        <Route index element={<OrganizationIndex/>} />
        <Route path=":orgID" element={<OrganizationDetailer onOpenDialog = {handleOpenDialog} onCloseDialog = {handleCloseDialog}/>} />
        <Route path=":orgID/invoices" element={<InvoiceList />} />
        <Route path = ":orgID/transfers" element = {<OrganizationTransfers />} />
        <Route path=":orgID/request-for-proposal/:instanceID" element={<OrganizationProjectPricer />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}