import React, { useState, useMemo } from 'react';
import Calendar from 'react-calendar';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { PostServerData } from "../util/dbfetch.js";


function PostInvoiceDialog({ open, onClose, projectid, organizationid, openSnackbar }) {
  const today = useMemo(() => new Date(), []);
  const tomorrow = useMemo(() => {
    const tomorrowDate = new Date(today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    return tomorrowDate;
  }, [today]);

  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [description, setDescription] = useState("Project Invoice for");
  const [descriptionLine, setDescriptionLine] = useState("Project Progress");
  const [dueDate, setDueDate] = useState(tomorrow);


  const handleInvoiceAmountChange = (e) => {
    let value = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
  
    value = value.replace(/[^0-9.]/g, '');
  
    // Prevent duplicate decimals
    const parts = value.split('.');
    if (parts.length > 2) {
      value = `${parts[0]}.${parts.slice(1).join('')}`;
    }
  
    if (value === '' || regex.test(value)) {
      setInvoiceAmount(value);
    } else if (value.includes('.') && value.split('.')[1].length > 2) {
      setInvoiceAmount(parseFloat(value).toFixed(2));
    } else {
      setInvoiceAmount(value);
    }
  };

  const handleInvoiceSubmit = () => {
    const invoiceBill = { 
      amount: Math.round(invoiceAmount * 100),
      currency: "usd", 
      description: description, 
      description_line: descriptionLine,
      organizationid: organizationid, 
      projectid: projectid,
      due_date: dueDate
    };

    PostServerData(
      `project/${projectid}/invoice`,
      invoiceBill,
      () => { openSnackbar('Invoice Posted'); onClose(); },
      (err) => { 
        openSnackbar(err?.data?.detail || "Error occurred posting invoice");
        console.error('Error posting invoice:', err);}
    );
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <Box className="dialog-content" sx={{ p: 4 }}>
        <Typography>Post Invoice</Typography>
        <Typography>Enter the amount for the invoice:</Typography>
        <TextField
          label="Amount ($)"
          variant="outlined"
          type="text"
          value={invoiceAmount}
          onChange={handleInvoiceAmountChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Description"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          inputProps={{ maxLength: 255 }}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Description Line"
          variant="outlined"
          value={descriptionLine}
          onChange={(e) => setDescriptionLine(e.target.value)}
          inputProps={{ maxLength: 16 }}
          fullWidth
          margin="dense"
        />
        <Typography variant="h4">Select Due Date:</Typography>
        <Typography sx={{pl:2}} >Project progress will be halted after the due date.</Typography>
        <Typography sx={{pl:2}} >Setting this to today will prevent any changes until the invoice is paid.</Typography>
        <Calendar
          minDetail="year"
          maxDetail="month"
          minDate={today}
          onChange={setDueDate}
          value={dueDate}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={onClose} color="primary" variant="outlined" >
            Cancel
          </Button>
          <Button onClick={handleInvoiceSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default PostInvoiceDialog;