import React, {  useEffect, useMemo, useRef, useCallback } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import Box from '@mui/material/Box';

import * as d3 from "d3";

import { ConnectionEdit } from '../../locationItems/locationEdit.jsx';


const ItemTypes = {
  CIRCLE: 'circle',
};

function DraggableCircle({ index, loc, topPad, leftSide, onDrop }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CIRCLE,
    item: { loc, index, leftSide },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.CIRCLE,
    drop: (item) => {
      onDrop(item, { loc, index, leftSide });
    },
  }));

  return (
    <Box
      ref={drop}
      sx={{
        position: 'absolute',
        top: topPad,
        left: leftSide ? '10%' : '90%',
        transform: 'translateX(-50%)',
        height: '30px', // Larger buffer height
        width: '30px', // Larger buffer width
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Transparent background to visualize the buffer area
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        ref={drag}
        sx={{
          height: '10px',
          width: '10px',
          backgroundColor: isDragging ? 'gray' : 'black',
          borderRadius: '50%',
          cursor: 'move',
        }}
      />
    </Box>
  );
}


function PoleSpanner({ connectionLocList, leftLocations, rightLocations, leftBase, rightBase, LocationArray, handleDeleteLocation, handleOpenDialog = () => { }, topGap = 0, onDrop = () => {} }) {
  const svgRef = useRef(null);

  const handleDrop = useCallback((from, to) => {
    onDrop(from, to);
  }, [onDrop]);

  const handleOpenLoc = useCallback((loc) => {
    const thisType = loc.equip_key;

    const validConn = {
      "pspan": 'pole_pri',
      "sspan": 'pole_sec',
    };

    const allPossibleEquipConn = LocationArray
      .filterByKey("equip_key", validConn[thisType])
      .getFilteredLocations();
      
    const nestedEquipConn = allPossibleEquipConn.map((loc) => {
      return {
        ...loc,
        base_loc: LocationArray.getLocation(loc.base_id)
      };
    });

    handleOpenDialog(
      <ConnectionEdit
        location={loc}
        connectionLocList={nestedEquipConn}
        handleDeleteLocation={handleDeleteLocation}
      />
    );
  }, [LocationArray, handleOpenDialog, handleDeleteLocation]);

  const calculatePositions = useCallback((locations) => {
    const totalHeight = 300; // Height of the vertical box
    const boxHeight = totalHeight / (locations.length + 1);

    const positions = locations.map((loc, index) => {
      const topPad = topGap + (boxHeight / 3) + (index * boxHeight);
      return { loc, topPad };
    });

    return positions;
  }, [topGap]);

  const renderCircles = useCallback((positions, leftSide, baseLoc) => {
    return positions.map((pos, index) => (
      <DraggableCircle
        key={`${baseLoc.locationid}-${index}`}
        index={index}
        loc={pos.loc}
        topPad={`${pos.topPad}px`}
        leftSide={leftSide}
        onDrop={handleDrop}
      />
    ));
  }, [handleDrop]);

  const leftPositions = useMemo(() => calculatePositions(leftLocations), [calculatePositions, leftLocations]);
  const rightPositions = useMemo(() => calculatePositions(rightLocations), [calculatePositions, rightLocations]);

  const circlesLeft = useMemo(() => renderCircles(leftPositions, true, leftBase), [renderCircles, leftPositions, leftBase]);
  const circlesRight = useMemo(() => renderCircles(rightPositions, false, rightBase), [renderCircles, rightPositions, rightBase]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear previous connections

    connectionLocList.forEach((loc) => {
      const leftssdMatch = leftPositions.find((pos) => pos.loc.locationid === loc.ssd_id);
      const rightssdMatch = rightPositions.find((pos) => pos.loc.locationid === loc.ssd_id);
      const leftlsdMatch = leftPositions.find((pos) => pos.loc.locationid === loc.lsd_id);
      const rightlsdMatch = rightPositions.find((pos) => pos.loc.locationid === loc.lsd_id);

      // make left element
      let leftStr = "";
      if (leftssdMatch) {
        leftStr = `Left:   SSD - ${leftssdMatch.loc}: Base - ${leftBase}`;
      } else if (leftlsdMatch) {
        leftStr = `Left:   LSD - ${leftlsdMatch.loc}: Base - ${leftBase}`;
      } else {
        leftStr = `Left:   Unk`;
      }

      // make right element
      let rightStr = "";
      if (rightssdMatch) {
        rightStr = `Right: SSD - ${rightssdMatch.loc}: Base - ${rightBase}`;
      } else if (rightlsdMatch) {
        rightStr = `Right: LSD - ${rightlsdMatch.loc}: Base - ${rightBase}`;
      } else {
        rightStr = `Right: Unk`;
      }

      const tooltipTitle = `${leftStr}\n${rightStr}`;

      // Render the corresponding line if both matches are found
      if ((leftssdMatch || rightssdMatch) && (leftlsdMatch || rightlsdMatch)) {
        const matchLeft = leftssdMatch || leftlsdMatch;
        const matchRight = rightssdMatch || rightlsdMatch;

        svg.append('line')
          .attr('x1', '10%')
          .attr('y1', matchLeft.topPad + 15)
          .attr('x2', '90%')
          .attr('y2', matchRight.topPad + 15)
          .attr('stroke', 'purple')
          .attr('stroke-width', 2)
          .style('cursor', 'pointer')
          .on('click', () => handleOpenLoc(loc))
          .append('title')
          .text(tooltipTitle);

        svg.append('line')
          .attr('x1', '10%')
          .attr('y1', matchLeft.topPad + 15)
          .attr('x2', '90%')
          .attr('y2', matchRight.topPad + 15)
          .attr('stroke', 'transparent')
          .attr('stroke-width', 10)
          .style('cursor', 'pointer')
          .on('click', () => handleOpenLoc(loc))
          .append('title')
          .text(tooltipTitle);
      }
    });
  }, [leftPositions, rightPositions, connectionLocList, leftBase, rightBase, handleOpenLoc, svgRef]);

  return (
    <Box sx={{ minWidth: "120px", width: '300px', height: '360px', backgroundColor: 'gray', position: 'relative' }}>
      <svg ref={svgRef} style={{ position: 'absolute', width: '100%', height: '100%' }} />
      {circlesLeft}
      {circlesRight}
    </Box>
  );
}

export default PoleSpanner;