import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import TermsPage from '../termsPage.jsx';
import PrivacyPage from '../privacyPage.jsx';

import useSnackbarContext from '../../util/providers/snackbarProvider';
import { useTutorial } from '../../util/providers/useTutorialContext';
import { PostServerData } from '../../util/dbfetch';


export default function Register({ onRegister, onClose }) {
  // const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [verifiedCode, setVerifiedCode] = useState("");

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [transferCode, setTransferCode] = useState('');
  const [response, setResponse] = useState(<></>);

  const [modalOpen, setModalOpen] = useState(false);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
  const [betaWarningOpen, setBetaWarningOpen] = useState(false);

  const { openSnackbar } = useSnackbarContext();
  const { handleStartTutorial } = useTutorial();

  const handleOpenModal = (content) => {
    setResponse(content);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
    setResponse(<></>);
  };

  const handleOpenBetaWarning = () => {
    setBetaWarningOpen(true);
  };

  const handleCloseBetaWarning = () => {
    setBetaWarningOpen(false);
  };

  const handleConfirmBetaWarning = () => {
    handleCloseBetaWarning();
    completeRegisterRequest();
  };

  function handleRegisterFailure(response) {
    const resp = <Typography variant="body2" sx={{ p: 4 }}>
      {`${response?.data?.detail}` || `${response?.data?.message}` ||
      `There was a problem registering an account.  \n 
      Double check your invite code and try again \n 
      If you continue to have problems, contact the website admin.`}
    </Typography>;
    handleOpenModal(resp);
  }


  function handleRegisterSuccess(response) {
    const resp = <Typography variant="body2" sx = {{ p:4 }} >
      {response?.data?.message || "Account created successfully!"}
    </Typography>;

    handleOpenModal(resp);
    // start tutorial
    handleStartTutorial("initialize-tutorial");
    onRegister();
  }

  function completeRegisterRequest() {
    setResponse(<Typography variant="body2" sx={{ p: 4 }}>Requesting...</Typography>);
    PostServerData('register', 
      { 
        username: username, 
        password: password, 
        email: email, 
        verifiedCode: verifiedCode, 
        transfer_code: transferCode
      },
      handleRegisterSuccess, handleRegisterFailure);
  }


  function handleRegisterRequest() {
    let errorMessage = '';

    if (!verifiedCode) {
      errorMessage = 'Please verify your email';
    } else if (username.length < 1 || password.length < 1) {
      errorMessage = 'Please enter a username and password';
    } else if (password !== confirmPassword) {
      errorMessage = 'Passwords do not match';
    } else if (!acceptedTerms || !acceptedPrivacy) {
      errorMessage = 'Please accept the terms and privacy policy';
    }
  
    if (errorMessage) {
      handleOpenModal(<Typography variant="body2" sx={{ p: 4 }}>{errorMessage}</Typography>);
      return;
    } else {
      handleOpenBetaWarning();
    }
  }


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleRegisterRequest();
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form>
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <br />
        <br />
        <EmailVerification 
          email={email} 
          setEmail={setEmail} 
          setVerifiedCode={setVerifiedCode} 
          onOpenSnack={openSnackbar} 
        />
        <br />
        <br />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <TextField
          id="confirm-password"
          label="Confirm Password"
          variant="outlined"
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <TextField
          id="invite-code"
          label="Invite Code (Optional)"
          variant="outlined"
          value={transferCode}
          onChange={(e) => setTransferCode(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Typography variant="body2" sx={{ mt: 1 }}>
          Another user can provide a transfer code to preload an account with projects.
        </Typography>
        <br />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
          }
          label={
            <>
              I accept the <Button variant= "outlined" onClick={() => handleOpenModal(TermsPage)}>Terms and Conditions</Button>
            </>
          }
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedPrivacy}
              onChange={(e) => setAcceptedPrivacy(e.target.checked)}
            />
          }
          label={
            <>
              I accept the <Button variant= "outlined" onClick={() => handleOpenModal(PrivacyPage)}>Privacy Policy</Button>
            </>
          }
        />
        <br />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleRegisterRequest}>
            Register
          </Button>
        </Box>
        <br />
        <br />
      </form>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        sx={{
            width: '70%',
            maxWidth: 'none',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }}
      >
        {response}
      </Dialog>
      <Dialog
        open={betaWarningOpen}
        onClose={handleCloseBetaWarning}
        maxWidth="sm"
        fullWidth
        sx={{
            width: '50%',
            maxWidth: 'none',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }}
      >
        <Box sx={{ p: 4 }}>
          <Typography variant="h6">Beta Warning</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Please note that this website is currently in beta. Some features may not work as expected, and there may be bugs or other issues. We appreciate your understanding and feedback as we continue to improve the site.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button variant="contained" onClick={handleConfirmBetaWarning}>
              I Understand
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

function EmailVerification({ email, setEmail, setVerifiedCode, onOpenSnack }) {
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);

  const handleSendVerification = () => {
    if (!email) {
      onOpenSnack('Please enter a valid email');
      return;
    }

    PostServerData('send-verification', { verification_email: email }, () => {
      onOpenSnack("Verification email sent!");
      setIsCodeSent(true);
    }, (err) => console.log(err));
  };

  const handleVerifyCode = () => {
    if (!verificationCode) {
      onOpenSnack('Please enter the verification code');
      return;
    }

    PostServerData('verify-email', 
      { verification_email: email, verification_code: verificationCode }, 
      (resp) => {
        onOpenSnack("Email verified successfully!");
        setVerifiedCode(resp.data.verified_code);
      }, 
      (err) => console.log(err));
  };

  return (
    <Box>
      <TextField
        id="email"
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleSendVerification} sx={{ mb: 2 }}>
        Send Verification Email
      </Button>
      {isCodeSent && (
        <>
          <TextField
            id="verification-code"
            label="Verification Code"
            variant="outlined"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleVerifyCode}>
            Verify Email
          </Button>
        </>
      )}
    </Box>
  );
}
