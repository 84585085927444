
import { Feature, LineString, } from 'geojson';
import { point, lineString, rhumbBearing } from '@turf/turf';
import transformRotate from '@turf/transform-rotate';

import { Position } from './pyModels.ts';

// const section_types = {
//   "straight": "Straight",
//   "horz": "Horz. Bend",
//   "vert": "Vert. Bend"
// }

// Constants for conversion
const FEET_PER_DEGREE_LATITUDE = 364567;
// const EARTH_RADIUS_IN_FEET = 20902231; // Approximate Earth radius in feet


export interface IConduitElement {
  order: number;
  conduitid?: number;  // these are not unique; conduit redundancy is allowed in API DB
  section_type: string;
  length: number;
  bend: number;
  ccw: boolean;
  grade: number;
}


export default class ConduitTrace {
  conduits: IConduitElement[];

  constructor(conduitTrace: IConduitElement[] | ConduitTrace) {
    if (conduitTrace instanceof ConduitTrace) {
      this.conduits = conduitTrace.conduits;
    } else {
      this.conduits = conduitTrace;
    }
  }

  map(callbackfn: (value: IConduitElement, index: number, array: IConduitElement[]) => any, thisArg?: any): any {
    return this.conduits.map(callbackfn, thisArg);
  }

  find(predicate: (value: IConduitElement, index: number, obj: IConduitElement[]) => boolean): IConduitElement | undefined {
    return this.conduits.find(predicate);
  }

  push(conduit: IConduitElement) {
    conduit.order = this.conduits.length;
    conduit.conduitid = undefined;
    conduit.bend = conduit.bend || 0;
    conduit.length = conduit.length || 0;
    conduit.ccw = conduit.ccw || false;
    conduit.grade = conduit.grade || 0;
    this.conduits.push(conduit);
  }

  filter(callbackfn: (value: IConduitElement, index: number, array: IConduitElement[]) => boolean): IConduitElement[] {
    this.conduits = this.conduits.filter(callbackfn);
    return this.conduits;
  }

  getConduits(limit: number) {
    return this.conduits.slice(0, limit);
  }

  addConduit(conduit: IConduitElement) {
    conduit.order = this.conduits.length;
    conduit.conduitid = undefined;
    conduit.bend = conduit.bend || 0;
    conduit.length = conduit.length || 0;
    conduit.ccw = conduit.ccw || false;
    conduit.grade = conduit.grade || 0;
    this.conduits.push(conduit);
  }

  deleteConduit(order: number) {
    this.conduits = this.conduits.filter(conduit => conduit.order !== order);
    this.reindexConduits();
  }

  updateConduit(order: number, field: string, value: any) {
    const conduit = this.conduits.find(conduit => conduit.order === order);
    if (conduit) {
      conduit[field] = value;
    }
  }

  reindexConduits() {
    this.conduits = this.conduits.map((conduit, index) => ({
      ...conduit,
      order: index
    }));
  }

  reorderConduits(newOrder: number[]) {
    const newConduits = newOrder.map(order => {
      const conduit = this.conduits.find(conduit => conduit.order === order);
      if (!conduit) {
        throw new Error(`Conduit with order ${order} not found`);
      }
      return conduit;
    });
    this.conduits = newConduits;
    this.reindexConduits();
  }

  toElements() {
    return this.conduits;

  }

  toGeoJSON(startCoord: Position, finalCoord: Position | null = null): Feature<LineString, Properties> | null{

    let coords: Position[] = [startCoord];
    let currentAngle = 90;

    if (this.conduits.length === 0) {
      return null;
    }

    for (let conduit of this.conduits) {
      if (conduit.section_type === 'straight') {
        // straight section, get next endPoint
        // Calculate the end point of this section
        // assume lengths are in feet
        let dx = Math.cos(currentAngle * Math.PI / 180) * (conduit.length / FEET_PER_DEGREE_LATITUDE);
        let dy = Math.sin(currentAngle * Math.PI / 180) * (conduit.length / (
          FEET_PER_DEGREE_LATITUDE * Math.cos(coords[coords.length - 1].lat * Math.PI / 180)
          ));
        let endCoord: Position = { lat: coords[coords.length - 1].lat + dx, lng: coords[coords.length - 1].lng + dy };
    
        coords.push(endCoord);
      } else {
        if (conduit.section_type === 'vert') {
          // Needs grades implemented to handle verts
        } else {
          const angleDiff = conduit.ccw ? -conduit.bend : conduit.bend;
          currentAngle = currentAngle + angleDiff;
        }
      }
    }

    if (coords.length < 2) {
      return null;
    }

    let conduitLineString = lineString(coords.map(coord => [coord.lng, coord.lat]));
    let lastPoint = point([coords[coords.length-1].lng, coords[coords.length-1].lat]);
    let startPoint = point([startCoord.lng, startCoord.lat]);
    
    let currentBearing = rhumbBearing(startPoint, lastPoint);
    
    if (finalCoord) {
      let finalPoint = point([finalCoord.lng, finalCoord.lat]);
      let targetBearing = rhumbBearing(startPoint, finalPoint);
    
      let rotationAngle = targetBearing - currentBearing;
      conduitLineString = transformRotate(conduitLineString, rotationAngle, { pivot: [startCoord.lng, startCoord.lat] });
    }
    
    return conduitLineString;
  }
}