
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';

import { UseServerData, PostServerData } from '../../util/dbfetch.js';
import useSnackbarContext from '../../util/providers/snackbarProvider';

function SubscriptionHandler() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);
  const [subscriptionsError, setSubscriptionsError] = useState(null);

  const [stripeId, setStripeId] = useState(null);
  const [managementUrl, setManagementUrl] = useState(null);
  const [stripeLoading, setStripeLoading] = useState(true);

  const { openSnackbar } = useSnackbarContext();

  useEffect(() => {
    const handleSubscriptionsError = (err) => {
      if (err.status === 309) {
        setSubscriptionsError("Please verify an email address to your account in user settings.");
      } else {
        setSubscriptionsError("An unexpected error occurred. Please try again later.");
      }
      setSubscriptionsLoading(false);
    };

    if (!subscriptionsLoading) {
      return;
    }

    UseServerData('subscriptions', setSubscriptions, setSubscriptionsLoading, handleSubscriptionsError);
  }, [setSubscriptions, subscriptionsLoading, setSubscriptionsLoading, setSubscriptionsError]);

  useEffect(() => {
    const handleStripeError = (err) => {
      openSnackbar(err?.detail || "Error fetching Stripe account management link");
      console.error('Error fetching Stripe account management link:', err);
    };

    const handleOpenPortal = (responseData) => {
      setStripeId(responseData.stripe_id);
      setManagementUrl(responseData?.management_url);
    };

    if (!stripeLoading) {
      return;
    }

    UseServerData('stripe_id', handleOpenPortal, setStripeLoading, handleStripeError);
  }, [openSnackbar, setStripeId, setManagementUrl, stripeLoading, setStripeLoading]);

  const handleCancelSubscription = (subscriptionId) => {
    const handleError = (err) => {
      if (err.status === 309) {
        openSnackbar("Please verify an email address to your account in user settings.");
      } else {
        openSnackbar(err?.data?.detail || "An unexpected error occurred. Please try again later.");
      }
    };

    const handleChangeSubscriptionList = (altSubscription) => {
      // Create a new list of subscriptions excluding the canceled subscription
      const updatedSubscriptions = subscriptions.filter(sub => sub.id !== subscriptionId);

      // Update the state with the new list to force a rerender
      setSubscriptions([...updatedSubscriptions, { ...altSubscription, id: `${altSubscription.id}-updated` }]);
    };

    PostServerData(`subscriptions/${subscriptionId}/cancel`, null, handleChangeSubscriptionList, handleError);
  };

  const formatDateRange = (start, end) => {
    const startDate = new Date(start).toLocaleDateString();
    const endDate = end ? new Date(end).toLocaleDateString() : 'N/A';
    return `${startDate} - ${endDate}`;
  };

  if (subscriptionsLoading || stripeLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (subscriptionsError) {
    return <Typography color="error">{subscriptionsError}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h2">Subscriptions</Typography>
      {managementUrl && stripeId ? (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(managementUrl, '_blank')}
          >
            Open Stripe Account Management
          </Button>
        </Box>
      ) : (
        <Tooltip title="Stripe account management is not available">
          <span>
            <Button variant="outlined" disabled>
              Stripe Account Management
            </Button>
          </span>
        </Tooltip>
      )}
      {subscriptions.length === 0 ? (
        <Typography>No subscriptions found.</Typography>
      ) : (
        <Grid container spacing={2}>
          {subscriptions.map(subscription => (
            <Grid item xs={12} md={6} lg={4} key={subscription.id}>
              <Card>
                <CardHeader title={`Sub: ${subscription.status}`} />
                <CardContent>
                  <Typography>Customer Name: {subscription.customer.name}</Typography>
                  <Typography>Customer Email: {subscription.customer.email}</Typography>
                  <Typography>Current Period: {formatDateRange(subscription.current_period_start, subscription.current_period_end)}</Typography>
                  <Box>
                    <Typography>Organization Name: {subscription.organization?.organization_name}</Typography>
                  </Box>
                  <Box>
                    <Typography>Project Name: {subscription.project?.proj_name}</Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button variant="contained" color="secondary" onClick={() => handleCancelSubscription(subscription.id)} disabled={subscription.status === "canceled"}>
                    Cancel
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}


export default SubscriptionHandler;