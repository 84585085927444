import { useState, useEffect, useCallback } from 'react';

import Location from '../models/location.ts';
import LocationArrayClass from '../models/locationArrayClass.ts';
import { UseServerData, PostServerData } from "../util/dbfetch.js";


const dummyFunction = (obj) => ({});
/**
 * A custom hook that manages the state of equipment options for a given instance.
 * @param {number} instanceid - The ID of the instance to fetch equipment options for.
 * @param {Array} defaultEquipmentList - An array of default equipment options.
 * @param {Function} getDefaultCopy - A function that returns a copy of the default equipment options.
 * @returns {Array} An array containing the equipment state, a dispatch function, and a loading state.
 */

export default function useLocationArrayWithOptions(instanceid, projectid = null, defaultEquipmentList = null, getDefaultCopy = dummyFunction) {
  const [LocationArray, setLocationArray] = useState(new LocationArrayClass([]));
  const [locationArrayLoading, setLocationArrayLoading] = useState(true);

  /**
   * Updates the selections in the selected default equipment object based on the properties object.
   * @param {Array} defaultSelections - The default selections to update.
   * @param {Array} properties - An array of properties to update the selections with.
   * @returns {Object} The updated selected default equipment object.
   */
  function updateSelections(defaultSelections, properties) {
    if (defaultSelections === undefined) {
    }

    if (defaultSelections.length === 0) {
    }

    let newSelections = defaultSelections.map((selection) => {
      const property = properties.find((prop) => prop.key === selection.key);
      if (property) {
        return {
          ...selection,
          selection: property.value
        }
      } else {
        return {
          ...selection
        }
      }
    });

    return newSelections;
  }

  /**
  * Sets the initial state of the equipment options.
  * @param {Array} data - An array of equipment options to set the initial state with.
  * 
  * makes a copy of the default location and updates the selections
  */
  const setInitialState = useCallback((data) => {
    // We do this to reduce the overhead for importing many locations
    // location selections are duplicated
    const selectedObjects = data.map((equipment) => {
      const selectedDefaultLocationData = getDefaultCopy({ "equip_key": equipment.equip_key });

      if (selectedDefaultLocationData.length === 0) {
        Error("No default location found for equipment key: " + equipment.equip_key);
      }

      selectedDefaultLocationData.equip_key = equipment.equip_key;
      selectedDefaultLocationData.locationid = equipment.locationid;
      selectedDefaultLocationData.loc_number = equipment.loc_number;
      selectedDefaultLocationData.position = {
        lat: equipment.position?.lat || null,
        lng: equipment.position?.lng || null
      };
      selectedDefaultLocationData.conduit_trace = equipment?.conduit_trace;
      selectedDefaultLocationData.ssd_id = equipment.ssd_id;
      selectedDefaultLocationData.lsd_id = equipment.lsd_id;
      selectedDefaultLocationData.base_id = equipment?.base_id | selectedDefaultLocationData.base_id;

      selectedDefaultLocationData.position_calcs = equipment.calculations?.position_calcs;

      let newProps = updateSelections(selectedDefaultLocationData.selections, equipment.property_list);

      selectedDefaultLocationData.selections = newProps;

      selectedDefaultLocationData.calculations = equipment.calculations;

      // Create a new Location instance
      return new Location(selectedDefaultLocationData);
    });


    const newLocationArray = new LocationArrayClass([]);
    selectedObjects.forEach(equipment => newLocationArray.add(equipment));
    setLocationArray(newLocationArray);
  }, [getDefaultCopy]);



  function handleSaveEquipmentState(onSaveSuccess, onSaveError = dummyFunction) {
    // onSaveSuccess(response)
    if (projectid === null) {
      console.log("projectid is null; cannot save.")
      return null;
    }

    const locationModel = LocationArray.getAllLocations().map((location) => {
      const { selections, ...rest } = location;
      const property_list = Object.entries(selections).map(([key, { key: selectionKey, selection }]) => ({ key: selectionKey, value: selection }));
      return { property_list, ...rest };
    });

    const liableParty = "Client";
    const parameters = {
      liableParty: liableParty,
      instanceid: instanceid
    }

    // This endpoint has a 304 response, so it will not update unless the instanceid changes
    // need to handle that response
    // returns {
    // instanceidold : instanceidnew,
    // loc_map : [(locNew, locOld), ... ]
    // }
    PostServerData(`${projectid}/instance/new`, locationModel, onSaveSuccess, onSaveError, parameters);
  }

  const setInitialStateWithoutDefaults = useCallback((data) => {
    const selectedObjects = data.map((equipment) => {
      // Create a new Location instance
      return new Location({
        equip_key: equipment.equip_key,
        locationid: equipment.locationid,
        loc_number: equipment.loc_number,
        position: {
          lat: equipment.position?.lat || null,
          lng: equipment.position?.lng || null
        },
        conduit_trace: equipment.conduit_trace ?
          equipment.conduit_trace : null,
        ssd_id: equipment.ssd_id,
        lsd_id: equipment.lsd_id,
        selections: equipment.property_list,
        position_calcs: equipment.calculations?.position_calcs,
        base_id: equipment?.base_id | null,

        calculations: equipment.calculations
      });
    });

    // Create a new LocationArrayClass instance
    const newLocationArray = new LocationArrayClass(selectedObjects);
    setLocationArray(newLocationArray);
  }, []);

  const reRenderLocationArray = () => {
    const currSortKey = LocationArray.getSortKey();
    const currSortAsc = LocationArray.getSortAsc();

    // TODO: This is inefficient, but it works for now
    setLocationArray(new LocationArrayClass(LocationArray.getAllLocations(), currSortKey, currSortAsc));
  }

  useEffect(() => {
    if (!locationArrayLoading) {
      return;
    }

    if (instanceid === null) {
      setLocationArray(new LocationArrayClass([]));
      setLocationArrayLoading(false);

    } else if (defaultEquipmentList === null) {
      // not loading selection props
      UseServerData(`instance/${instanceid}/locations`,
        setInitialStateWithoutDefaults,
        setLocationArrayLoading,
        (err) => { }
      )

    } else if (defaultEquipmentList.length > 0) {
      UseServerData(`instance/${instanceid}/locations`,
        setInitialState,
        setLocationArrayLoading,
        (err) => { }
      )
    } else {
      //the default equipmentlist is still loading, wait
    }

  }, [defaultEquipmentList, setInitialState, setInitialStateWithoutDefaults, instanceid, locationArrayLoading, getDefaultCopy]);

  return [LocationArray, reRenderLocationArray, locationArrayLoading, handleSaveEquipmentState];
}