import React, { createContext } from "react";
import { createTheme } from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Cookies from "js-cookie";

import { getLightDarkTokens } from "./theme";

export const ThemeContext = createContext();

class ThemeManager extends React.Component {
  constructor(props) {
    super(props);
    const mode = Cookies.get("colorMode") || "light";
    this.state = {
      mode: mode,
      theme: this.createTheme(mode),
    };
  }

  createTheme(mode) {
    // getDesignTokens and themeBase logic here
    return createTheme(getLightDarkTokens(mode));
  }

  toggleColorMode = () => {
    this.setState(prevState => {
      const newMode = prevState.mode === "light" ? "dark" : "light";
      const newTheme = this.createTheme(newMode);
      Cookies.set("colorMode", newMode, { expires: 365, sameSite: "Lax" });
      return { mode: newMode, theme: newTheme };
    });
  }

  render() {
    return (
      <ThemeContext.Provider value={{ mode: this.state.mode, toggleColorMode: this.toggleColorMode }}>
        <ThemeProvider theme={this.state.theme}>
          {this.props.children}
        </ThemeProvider>
      </ThemeContext.Provider>
    );
  }
}

export default ThemeManager;