import React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


function TransactionComplete() {
  const navigate = useNavigate();

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h3">Transaction completed</Typography>
      <Typography variant="body1">You can review your purchases in the transaction history page.</Typography>
      <Button variant="contained" onClick={() => navigate('/user/transaction-history')} sx={{ mt: 2 }}>
        View Transaction History
      </Button>
    </Paper>
  );
}

export default TransactionComplete;