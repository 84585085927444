import { sessionStorageExpiryBase } from "./sessionStorageExpiryBase";

/**
 * The sessionStorageBounded object that extends sessionStorageExpiryBase 
 * and provides a method to retrieve existing, larger data sets
 * @type {Object}
 */
export const sessionStorageBounded = {
  ...sessionStorageExpiryBase,

  /**
   * Retrieves the value associated with the specified keyObject, but performs bounds checking if the value is not found.
   *
   * @param {Object} keyObject - The key object to retrieve the value for.
   * @returns {*} The value associated with the keyObject, or null if not found.
   */
  getWithBoundsCheck: function(keyObject) {
    const obj0 = this.get(keyObject);
    if (obj0) {
      return obj0;
    }

    for (let i = 0; i < sessionStorage.length; i++) {
      try {
        const existingKeyObject = JSON.parse(sessionStorage.key(i));
        const bounds = this._isWithinBounds(keyObject, existingKeyObject);
        if (bounds) {
          const newObj = {
            ...keyObject,
            ...bounds,
          };
          
          const value = this.get(newObj);

          if (value) {
            return value;
          }
        }
      } catch (error) {
        // Error caught, skipping to the next iteration
        console.error("Error processing sessionStorage item:", error);
        continue;
      }
    }

    return null;
  },

  /**
   * Checks if the newKeyObject is within the bounds of the existingKeyObject.
   *
   * @param {Object} newKeyObject - The new key object to check.
   * @param {Object} existingKeyObject - The existing key object to check against.
   * @returns {Object|null} An object with the bounds if newKeyObject is within the bounds of existingKeyObject, or null if not.
   */
  _isWithinBounds: function(newKeyObject, existingKeyObject) {
    if (
      newKeyObject.south >= existingKeyObject.south &&
      newKeyObject.west >= existingKeyObject.west &&
      newKeyObject.north <= existingKeyObject.north &&
      newKeyObject.east <= existingKeyObject.east
    ) {
      return {
        south: existingKeyObject.south,
        west: existingKeyObject.west,
        north: existingKeyObject.north,
        east: existingKeyObject.east,
      };
    }

    return null;
  },
};