import axios from 'axios';
import Cookies from 'js-cookie';
const serverRoot = process.env.REACT_APP_SERVER_ROOT || "https://api.utilitydesign.app/";

/**
 * #TODO:
 * Should be able to pass this a function to parse the data as.  
 * I should be able to call .fromPydantic() on the data to convert it to a pydantic object
 * Or map it over the entire array
*/

// Refer to loginradius.com for authentication
export function UseServerData(urlPath, setData, setLoading, setError, parameters = null) {
  const token = Cookies.get("jwtToken");

  const configOptions = {
    headers: {
      "X-Authorization": `Bearer ${token}`,
    },
  };

  if (parameters) {
    configOptions.params = parameters;
  }
  
  const urlFull = `${serverRoot}${urlPath}`

  axios
    .get(urlFull, configOptions)
    .then((res) => {
      
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (err.response) {
          // The client was given an error response (5xx, 4xx)
        if (err.response.status === 401) {
          redirectLogin()
        } else {
          setError(`Error ${err.response.status}: ${err.response.data.detail}`);
        }
      } else if (err.request) {
          // The client never received a response, and the request was never left
        setError(`Error ${err.request.status}`);
      } else {
          // Anything else
          setError(`Error ${err}`);
      }
    });
}

export function UseServerDataResponse(urlPath, setResponse, setLoading, setError, parameters = null) {
  // Send a simple get but return the response instead of the data
  // Specifically zip files need some additional handling for the options, headers, etc.
  const token = Cookies.get("jwtToken");

  const configOptions = {
    responseType: 'arraybuffer',
    headers: {
        'Content-Type': 'multipart/form-data',
        "X-Authorization": `Bearer ${token}`,
    }
  }

  if (parameters) {
    configOptions.params = parameters;
  }
  
  const urlFull = `${serverRoot}${urlPath}`

  axios
    .get(urlFull, configOptions)
    .then((res) => {
      setResponse(res);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      console.warn('Error in PostServerData: ', err);
      if (err.response) {
          // The client was given an error response (5xx, 4xx)
        if (err.response.status === 401) {
          redirectLogin()
        } else {
          setError(err);
        }
      } else if (err.request) {
          // The client never received a response, and the request was never left
        setError(`Error ${err.request.status}`);
      } else {
          // Anything else
          setError(`Error ${err}`);
      }
    });
}

export function DeleteServerData(urlPath, handleResponse, setError, parameters = null) {

  const token = Cookies.get("jwtToken");

  const configOptions = {
    headers: {
      "X-Authorization": `Bearer ${token}`,
    },
  };

  if (parameters) {
    configOptions.params = parameters;
  }
  const urlFull = `${serverRoot}${urlPath}`
  axios.delete(urlFull, configOptions)
  .then(response => {
    handleResponse(response);
  })
  .catch((err) => {
    if (err.response) {
        // The client was given an error response (5xx, 4xx)
      if (err.response.status === 401) {
        redirectLogin()
      } else {
        setError(`Error ${err.response.status}: ${err.response.data.detail}`);
      }
    } else if (err.request) {
        // The client never received a response, and the request was never left
      setError(`Error ${err.request.status}`);
    } else {
        // Anything else
        setError(`Error ${err}`);
    }
  });
}

export function PostServerData(urlPath, data, handleResponse, handleError, parameters = null) {
  const token = Cookies.get("jwtToken");

  const configOptions = {
    headers: {
      "X-Authorization": `Bearer ${token}`,
    },
  };

  if (parameters) {
    configOptions.params = parameters;
  }

  const urlFull = `${serverRoot}${urlPath}`

  axios.post(urlFull, data, configOptions)
  .then(response => {
    handleResponse(response);
  })
  .catch((err) => {
    console.warn('Error in PostServerData: ', err);
    if (err.response) {
        // The client was given an error response (5xx, 4xx)
      if (err.response.status === 401) {
        redirectLogin()
      } else {
        // handleError(`Error ${err.response.status}: ${err.response.data.detail}`);
        handleError(err.response);
      }
    } else if (err.request) {
        // The client never received a response, and the request was never left
        handleError(err.request);
    } else {
        // Anything else
        handleError(err);
    }
  });
}

export function PutServerData(urlPath, data, handleResponse, handleError, parameters = null) {
  const token = Cookies.get("jwtToken");

  const configOptions = {
    headers: {
      "X-Authorization": `Bearer ${token}`,
    },
  };

  if (parameters) {
    configOptions.params = parameters;
  }

  const urlFull = `${serverRoot}${urlPath}`

  axios.put(urlFull, data, configOptions)
  .then(response => {
    handleResponse(response);
  })
  .catch((err) => {
    if (err.response) {
        // The client was given an error response (5xx, 4xx)
      if (err.response.status === 401) {
        redirectLogin()
      } else {
        // handleError(`Error ${err.response.status}: ${err.response.data.detail}`);
        handleError(err.response);
      }
    } else if (err.request) {
        // The client never received a response, and the request was never left
        handleError(err.request);
    } else {
        // Anything else
        handleError(err);
    }
  });
}

function redirectLogin() {
  // Create a div to darken the screen
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "100%";
  overlay.style.height = "100%";
  // overlay.style.backgroundColor = "rgba(0, 0, 0, 0.75)";
  document.body.appendChild(overlay);

  // Display dialog saying "Logging out..."
  const dialog = document.createElement("div");
  dialog.textContent = "Session Expired, \nLogging out...";
  dialog.style.position = "fixed";
  dialog.style.top = "50%";
  dialog.style.left = "50%";
  dialog.style.transform = "translate(-50%, -50%)";
  dialog.style.backgroundColor = "white";
  dialog.style.color = "black";
  dialog.style.padding = "20px";
  dialog.style.borderRadius = "10px";
  document.body.appendChild(dialog);

  // Count down to three before removing token
  let count = 5;
  const intervalId = setInterval(() => {
    count--;
    if (count === 0) {
      clearInterval(intervalId);
      // Cannot delete expired token, it is ignored
      Cookies.remove('jwtToken');
      window.location.href = '/';
    } else {
      dialog.textContent = `Session Expired, \nLogging out... (${count})`;
    }
  }, 1000);
}

function storeJWT(token) {
  let dataStore = token;
  dataStore = dataStore.replace(/['"]+/g, '');

  // Set expiry time for the cookie (e.g., 1 days from now)
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 1);

  // Set the cookie with the expiry time
  Cookies.set("jwtToken", dataStore, { expires: expiryDate, secure: true, sameSite: 'Strict' });
}


export async function postTest(username, password, stateHandler) {
  // Log in user and get user info
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userName: username, password: password })
  };
 
  let response = await fetch(serverRoot + 'postTest', requestOptions)
    .then(response => response.json())
    .then(data => {
      return data;
    });
  stateHandler(response);
}

export async function postLogin(username, password, successHandler, failHandler) {

  const requestData = {
    username: username,
    password: password  
  }

  const configOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  let loginReturn = "Logging in...";

  let response = await axios.post(serverRoot + 'login', requestData, configOptions)
    .then(data => {
      // storeCookie(data.data, 'token', 'jwtToken');
      storeJWT(data.data.token)
      loginReturn = "Login successful, redirecting...";
      successHandler(data);
      return loginReturn;
    })
    .catch(err => {
      console.error('Error logging in: ', err);
      loginReturn = "Error logging in";
      failHandler(err);
      return loginReturn
    });

  return response;
}

export async function postRecoverPassword(email, successHandler, failHandler) {

  const configOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  let loginReturn = "Requesting Reset...";

  let response = await axios.post(`${serverRoot}recover-password?email=${encodeURIComponent(email)}`, {}, configOptions)
    .then(data => {
      loginReturn = "Password reset email sent if there is an account registered";
      successHandler(loginReturn);
      return loginReturn;
    })
    .catch(err => {
      loginReturn = "Error sending password reset email";
      failHandler(loginReturn);
      return loginReturn;
    });

  return response;
}

export async function postResetPassword(resetCode, email, password, successHandler, failHandler) {

  const requestData = {
    reset_code: resetCode,
    email: email,
    password: password
  }

  const configOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  let loginReturn = "Resetting Password...";

  let response = await axios.post(`${serverRoot}reset-password`, requestData, configOptions)
    .then(data => {
      loginReturn = "Password reset successfully";
      successHandler(loginReturn);
      return loginReturn;
    })
    .catch(err => {
      loginReturn = "Error resetting password";
      failHandler(loginReturn);
      return loginReturn;
    });

  return response;
}