import Location, { LocationData, Position } from './location.ts';


type SortKey = 'locationid' | 'loc_number' | 'equip_key';

// hack to allow LocationLike to be a Location, Location[], or a LocationData object with a toGeoJSON method
type LocationLike = Location | Location[] | { toGeoJSON: () => GeoJSON.FeatureCollection };

export default class LocationArrayClass {
  static connectionKeys = ["pcable", "scable", "pspan", "sspan"];
  LocationArray: Location[];
  sortKey: SortKey;
  sortAsc: boolean;

  constructor(equipmentDataArray: LocationData[], sortKey: SortKey = "locationid", sortAsc: boolean = true) {
    this.LocationArray = equipmentDataArray.map(data => new Location(data));
    this.sortKey = sortKey; 
    this.sortAsc = sortAsc;
  }

  map(callbackfn: (value: Location, index: number, array: Location[]) => any, thisArg?: any): any {
    return this.LocationArray.map(callbackfn, thisArg);
  }


  find(predicate: (value: Location, index: number, obj: Location[]) => boolean): Location | undefined {
    return this.LocationArray.find(predicate);
  }
  
  getLocation(locationid: string): Location | undefined {
    return this.LocationArray.find(loc => loc.locationid === locationid);
  }

  getAllLocations() : Location[] {
    return this.LocationArray;
  }


  getFilteredLocations(includeJumpers: boolean = false): Location[] {
    if (includeJumpers) {
      return this.LocationArray.filter((equipment) => equipment?.remove !== true);
    } else {
      return this.LocationArray.filter(
        (equipment) => equipment?.remove !== true && equipment.equip_key !== "jumper"
      );
    }
  }

  getDependents(locationid: string): Location[] {
    return this.LocationArray.filter((item) => item.base_id === locationid && item.remove === false);
  }

  getAllEquipment(filtered: boolean = false) : Location[] {
    if (filtered) {
      return this.getFilteredLocations().filter((item) => !LocationArrayClass.connectionKeys.includes(item.equip_key));
    } else {
      return this.LocationArray.filter((item) => !LocationArrayClass.connectionKeys.includes(item.equip_key));
    }
  }

  getAllConnections(filtered: boolean = false) : Location[] {
    if (filtered) {
      return this.getFilteredLocations().filter((item) => LocationArrayClass.connectionKeys.includes(item.equip_key));
    } else {
      return this.LocationArray.filter((item) => LocationArrayClass.connectionKeys.includes(item.equip_key));
    }
  }

  getJumpers(base_id: string | null) : Location[] {
    if (base_id) {
      return this.LocationArray.filter((item) => item.base_id === base_id && item.equip_key === "jumper" && item.remove === false);
    }
    return this.LocationArray.filter((item) => item.equip_key === "jumper" && item.remove === false);
  }

  contains(location: any): boolean {
    if (!(location instanceof Location)) {
      return false;
    }
  
    return this.LocationArray.some(loc => loc.locationid === location.locationid);
  }

  toGeoJSON(locations: LocationLike | null = null): GeoJSON.FeatureCollection {
    // If locations is null, process this.LocationArray
    if (locations === null) {
      locations = this.LocationArray;
    }
  
    // If locations is a single location, convert it to an array
    if (!Array.isArray(locations)) {
      if (locations instanceof Location) {
        locations = [locations];
      } else {
        return locations.toGeoJSON();
      }
    }
  
    // Convert each location to GeoJSON
    let features = (locations.map(location => {
      if (location.isConnection(LocationArrayClass.connectionKeys)) {
        let ssd = this.getSideDevice(location, false);
        let lsd = this.getSideDevice(location, true);
        if (ssd.length === 0 || lsd.length === 0) return null;

        // get the true position, tracing and base_ref from the ssd/lsd
        let ssdPos: Position | null = ssd[0].position;
        if (ssd[0].base_id !== null) {
          const ssdBase = this.LocationArray.find(loc => loc.locationid === ssd[0].base_id);
          ssdPos = ssdBase?.position || null;
        }
        
        let lsdPos: Position | null  = lsd[0].position;
        if (lsd[0].base_id !== null) {
          const lsdBase = this.LocationArray.find(loc => loc.locationid === lsd[0].base_id);
          lsdPos = lsdBase?.position || null;
        }

        const nullPosition = (pos: Position | null): Position | null => {
          if (!pos || pos.lat === null || pos.lng === null) {
            return null;
          }
          return pos;
        };

        return location.toGeoJSON(nullPosition(ssdPos), nullPosition(lsdPos));
      } else {
        return location.toGeoJSON();
      }
    }).filter(feature => feature !== null) as GeoJSON.Feature[]);

    // Return a GeoJSON FeatureCollection object
    return {
      type: 'FeatureCollection',
      features: features
    };
  }

  filterByKey(key: string, value: string | number): LocationArrayClass {
    const filteredLocationData = this.LocationArray.filter(equipment => equipment.isKey(key, value));
    return new LocationArrayClass(filteredLocationData.map(equipment => equipment.toLocationData()));
  }

  getSideDevice(locSource: Location, lsd: boolean = false): Location[] {
    let targetID: string | number | null = null;
    if (lsd) {
      targetID = locSource.lsd_id;
    } else {
      targetID = locSource.ssd_id;
    }

    // if still none, check if equipSource.locationid is the lsd/ssd of another location
    if (targetID) {
      return this.LocationArray.filter(loc => loc.locationid === targetID);

    } else if (!targetID) {
      const lsdEquip = this.LocationArray.filter(loc => loc.lsd_id === locSource.locationid);
      const ssdEquip = this.LocationArray.filter(loc => loc.ssd_id === locSource.locationid);
  
      if (lsd && lsdEquip.length > 0) {
        return lsdEquip;
      } else if (!lsd && ssdEquip.length > 0) {
        return ssdEquip;
      } else {
        // There is no lsd/ssd for this location
        return [];
      }
    } else {
      // There is no lsd/ssd for this location
      return [];
    }
  }
 
  generateNewID(): string {
    let idList = this.getAllLocations()
                     .filter((loc) => typeof loc.locationid === 'string')
                     .map((loc) => loc.locationid.toString())
                     .sort((a, b) => Number(a.match(/\d*$/)) - Number(b.match(/\d*$/)));

    if (idList.length === 0) {
      idList.push('new0');
    }

    let lastEl = idList[idList.length - 1];

    var match = lastEl.match(/\d*$/);
    if ((match) && (match[0] !== '')) {
      var num = Number(match[0]) + 1;
      var len = match[0].length;
      var paddedNum = num.toString().padStart(len, '0');
      return lastEl.slice(0, -len) + paddedNum;

    } else {
      return lastEl + '1';
    }
  }


  getMaxLocOfType(equip_key: string): string {
    // get a list of loc numbers from the state
    let locList = this.LocationArray.map((loc) => loc.loc_number.toString());
    locList.sort((a, b) => Number(a) - Number(b));
  
    let maxLoc = "0";
    for (var i in this.LocationArray) {
      if (this.LocationArray[i].equip_key === equip_key && Number(this.LocationArray[i].loc_number) > Number(maxLoc)) {
        maxLoc = this.LocationArray[i].loc_number.toString();
      }
    }
  
    maxLoc = (Number(maxLoc) + 1).toString();
  
    // Stringify because handles change the states reference, so it's not the same object
    while (JSON.stringify(locList).includes(JSON.stringify(maxLoc))) {
      maxLoc = (Number(maxLoc) + 1).toString();
    }
  
    return maxLoc;
  }

  add(locData: LocationData): void {
    if (!this.LocationArray.some(loc => loc.locationid === locData.locationid)) {
      this.LocationArray.push(new Location(locData));
    }
  }

  remove(locationid: string): void {
    this.LocationArray = this.LocationArray.filter(loc => loc.locationid !== locationid);
  }

  removeAll(): void {
    this.LocationArray = [];
  }

  flagRemove(locationid: string): void {
    const location = this.LocationArray.find(loc => loc.locationid === locationid);
    if (location) {
      location.remove = true;
    }
  }

  update(locationid: string, payload: any): void {
    const index = this.LocationArray.findIndex(loc => loc.locationid === locationid);
    if (index !== -1) {
      let newLocation = this.LocationArray[index].update(payload);
      // Remove the old equipment and replace it with the updated one
      this.LocationArray = [
        ...this.LocationArray.slice(0, index),
        newLocation,
        ...this.LocationArray.slice(index + 1)
      ];
    } else {
    console.warn("Location not found, not updated");
    }
  }

  replaceField(locationid: string, key: string, value: any): void {
    const location = this.LocationArray.find(loc => loc.locationid === locationid);
    if (location) {
      location.replaceField(key, value);
    }
  }

  reorder(newOrder: number[]): void {
    const newArray = new Array(this.LocationArray.length);
    newOrder.forEach((oldIndex, newIndex) => {
      newArray[newIndex] = this.LocationArray[oldIndex];
    });
    this.LocationArray = newArray;
  }

  getSortKey(): string | null {
    return this.sortKey;
  }

  getSortAsc(): boolean {
    return this.sortAsc;
  }


  sortBy(sortKey: SortKey, asc: boolean = true): void {
    this.sortKey = sortKey;
    this.sortAsc = asc;
    this.LocationArray.sort(this.getSortFunction());
  }

  getSortFunction(): (a: Location, b: Location) => number {
    return (a, b) => {
      const valueA = isNaN(Number(a[this.sortKey])) ? a[this.sortKey] : Number(a[this.sortKey]);
      const valueB = isNaN(Number(b[this.sortKey])) ? b[this.sortKey] : Number(b[this.sortKey]);

      if (typeof valueA === 'string' || typeof valueB === 'string') {
        return this.sortAsc ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
      }

      return this.sortAsc ? (valueA > valueB ? 1 : -1) : (valueA < valueB ? 1 : -1);
    };
  }
}