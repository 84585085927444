import { useState, useEffect, useCallback } from 'react';
import { UseServerData } from "../util/dbfetch.js";

export default function useEquipmentDefaults() {
  const [defaultEquipmentList, setDefaultEquipmentList] = useState([]);
  const [equipmentOptionsLoading, setEquipmentOptionsLoading] = useState(true);

  useEffect(() => {
    UseServerData("defaults", 
    setDefaultEquipmentList, 
    setEquipmentOptionsLoading, 
    ()=> {})
  }, []); 

  const getDefaultCopy = useCallback((obj) => {
    const [key, value] = Object.entries(obj)[0] || [];

    if (defaultEquipmentList.length === 0) {
      return {};
    }
    const defaultEquipment = defaultEquipmentList.find((equipment) => equipment[key] === value) || {};

    if (defaultEquipment.length === 0) {
    } else if (defaultEquipment.selections === undefined) {
      defaultEquipment.selections = [];
    }

    return { ...defaultEquipment };
  }, [defaultEquipmentList]);

  return [defaultEquipmentList, equipmentOptionsLoading, getDefaultCopy];
}