import React, { useState, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import PinDrop from '@mui/icons-material/PinDrop';

export default function ConnectionMaker({ className, LocationArray, selectedObjects, onSwapDevices, handleFlyTo, handleOpenPoleConnectionManager }) {
  const defaultObject = useMemo(() => ({
    name: "N/A",
    locationid: null,
    loc_number: "N/A",
    position: {
      lat: null,
      lng: null
    }
  }), []);

  const [selectedCable, setSelectedCable] = useState('');
  const [ssd, setSsd] = useState(defaultObject);
  const [lsd, setLsd] = useState(defaultObject);

  const cableLocs = LocationArray.getAllConnections(true)

  useEffect(() => {
    const selectedCableIds = selectedObjects
      .filter(selectedLocation => LocationArray.contains(selectedLocation))
      .map(selectedLocation => selectedLocation.locationid);

    if (selectedCableIds.length === 1) {
      const selectedCableId = selectedCableIds[0];
      const connection = LocationArray.getAllConnections(true).find((item) => item.locationid === selectedCableId);
      if (connection) {
        setSelectedCable(connection);
      }
    } else {
      setSelectedCable('');
    }
  }, [LocationArray, selectedObjects]);

  useEffect(() => {
    if (selectedCable) {
      const cable = LocationArray.getAllLocations().find((item) => item.locationid === selectedCable.locationid);
      if (cable?.ssd_id) {
        setSsd(LocationArray.getAllLocations().find((item) => item.locationid === cable.ssd_id));
      } else {
        setSsd({ ...defaultObject });
      }
      if (cable?.lsd_id) {
        setLsd(LocationArray.getAllLocations().find((item) => item.locationid === cable.lsd_id));
      } else {
        setLsd({ ...defaultObject });
      }
    } else {
      setSsd({ ...defaultObject });
      setLsd({ ...defaultObject });
    }
  }, [selectedCable, LocationArray, defaultObject]);

  return (
    <Box className={className}>
      <FormControl>
        <Select
          sx={{ minWidth: 120 }}
          labelId="cable-select"
          value={selectedCable}
          onChange={(e) => setSelectedCable(e.target.value)}
        >
          <MenuItem key="" value=""><em>---</em></MenuItem>
          {cableLocs.map((item) => (
            <MenuItem key={item.locationid} value={item}>{`${item.loc_number}-${item.name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', borderBottom: "1" }}>
        <Typography variant="body1" sx={{ marginRight: '10px' }}>SSD:</Typography>
        <Typography variant="body2" sx={{ marginRight: '10px', minWidth: 140 }}>{`${ssd.loc_number} - ${ssd.name}`}</Typography>
        {ssd.position.lat && ssd.position.lng && (
          <IconButton onClick={() => handleFlyTo(ssd.position.lat, ssd.position.lng)}><PinDrop /></IconButton>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px' }}>
        <Typography variant="body1" sx={{ marginRight: '10px' }}>LSD:</Typography>
        <Typography variant="body2" sx={{ marginRight: '10px', minWidth: 140 }}>{`${lsd.loc_number} - ${lsd.name}`}</Typography>
        {lsd.position.lat && lsd.position.lng && (
          <IconButton onClick={() => handleFlyTo(lsd.position.lat, lsd.position.lng)}><PinDrop /></IconButton>
        )}
      </Box>
      <Button onClick={(ev) => onSwapDevices(ev, selectedCable)}>Reverse SSD/LSD</Button>

      <Box sx={{ marginTop: '10px' }}>
        <Typography variant="body1" sx={{ marginRight: '10px' }}>Cable ID:</Typography>
        <Button variant="outlined" onClick={() => handleOpenPoleConnectionManager()}>Pole Connector</Button>

      </Box>
    </Box>
  );
}
