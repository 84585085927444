abstract class Point {
  abstract distanceTo(point: Point): number;
}

export class XYPoint extends Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    super();
    this.x = x;
    this.y = y;
  }

  distanceTo(pointOther: Point): number {
    if (!(pointOther instanceof XYPoint)) {
      throw new Error("Incompatible point type");
    }
    return Math.sqrt(Math.pow(pointOther.x - this.x, 2) + Math.pow(pointOther.y - this.y, 2));  }
}

export class GeoPoint extends Point {
  lon: number;
  lat: number;

  constructor(lon: number, lat: number) {
    super();
    this.lon = lon; // longitude decdeg
    this.lat = lat; // latitude decdeg
  }

  distanceTo(pointOther: Point): number {
    if (!(pointOther instanceof GeoPoint)) {
      throw new Error("Incompatible point type");
    }
    // this equation does not apply for long distances
    // TODO correct this
    return Math.sqrt(Math.pow(pointOther.lon - this.lon, 2) + Math.pow(pointOther.lat - this.lat, 2));  
  }
}

export function simplifyPointsWithError<T extends { distanceTo: (point: T) => number }>(listOfPoints: T[], tolerance: number = 0.000001): T[] {
  // A new list to hold the simplified points, starting with the first point
  const simplifiedPoints: T[] = [listOfPoints[0]];

  // Iterate over each point in the list, starting from the second point
  for (let i = 1; i < listOfPoints.length; i++) {
    // Get the current point and the last point added to the simplified list
    const currentPoint = listOfPoints[i];
    const lastSimplifiedPoint = simplifiedPoints[simplifiedPoints.length - 1];

    // Calculate the distance between the current point and the last simplified point
    const distance = currentPoint.distanceTo(lastSimplifiedPoint);

    // If the distance is greater than the tolerance, add the current point to the simplified list
    if (distance > tolerance) {
      simplifiedPoints.push(currentPoint);
    }
  }

  // Return the simplified list of points
  return simplifiedPoints;
}

export function combineAndOrderPoints<T extends { distanceTo: (point: T) => number }>(listsOfPoints: T[][]): T[] {
  let combinedList: T[] = [];

  listsOfPoints.forEach((currentList, index) => {
    if (index === 0) {
      combinedList = [...currentList];
      return;
    }

    const previousList = combinedList;
    const distStartStart = previousList[0].distanceTo(currentList[0]);
    const distStartEnd = previousList[0].distanceTo(currentList[currentList.length - 1]);
    const distEndStart = previousList[previousList.length - 1].distanceTo(currentList[0]);
    const distEndEnd = previousList[previousList.length - 1].distanceTo(currentList[currentList.length - 1]);

    const minDist = Math.min(distStartStart, distStartEnd, distEndStart, distEndEnd);

    if (minDist === distStartStart || minDist === distEndEnd) {
      currentList.reverse();
    }

    if (minDist === distStartStart || minDist === distStartEnd) {
      combinedList = [...currentList, ...combinedList];
    } else {
      combinedList = [...combinedList, ...currentList];
    }
  });

  // finally simplify 
  return simplifyPointsWithError(combinedList);
}
