import React, { useMemo, useCallback } from 'react';


import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import { EquipmentEdit } from '../../locationItems/locationEdit.jsx';

function PoleSilhouette({ location, dependentLocations, LocationArray, handleDeleteLocation, handleOpenDialog = () => { }, silhouetteChildren = null }) {
  const totalHeight = 300; // Height of the vertical box
  const poleWidth = 30; // Width of the vertical box


  const openLoc = useCallback(() => (loc) => {
    handleOpenDialog(
      <EquipmentEdit
        location={loc}
        handleDeleteLocation={handleDeleteLocation}
      />
    );
  }, [handleOpenDialog, handleDeleteLocation]);

  const dependentFraming = useMemo(() => {
    const boxHeight = totalHeight / (dependentLocations.length + 1); 
    const dependentpoleWidth = 120;
    const innerBoxHeight = 20;
  
    return [...dependentLocations, {}].map((depLoc, index) => {
      // Create the specific element for the location

      const renderedElement = () => {
        if (Object.keys(depLoc).length === 0) {
          return null;
        } 

        const elementProps = {
          sx: {
            height: `${innerBoxHeight}px`,
            width: '100%',
            cursor: 'pointer',
            backgroundColor: depLoc.equip_key === 'pole_pri' ? 'red' : depLoc.equip_key === 'pole_sec' ? 'green' : 'yellow',
            ...(depLoc.equip_key === 'pole_tx' && {
              width: '30px',
              clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
              transform: 'translateX(-130%)',
            }),
            ...(depLoc.equip_key === 'pole_meter' && {
              width: '30px',
              backgroundColor: 'white',
              border: '1px solid black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'purple',
              fontWeight: 'bold',
              transform: 'translateX(130%)',
            }),
          },
          onClick: () => openLoc(depLoc),
        };

        const tooltip = depLoc.loc_number ? `${depLoc.loc_number} - ${depLoc.name}` : depLoc.name;

        return (
          <Tooltip title={tooltip} arrow placement="top" >
            <Box {...elementProps}>
              {depLoc.equip_key === 'pole_meter' && 'M'}
            </Box>
          </Tooltip>
        );
      };
  
      return (
        <Box
          name={`dependent-location-${index}`}
          key={index}
          sx={{
            position: 'absolute',
            top: `${index * boxHeight}px`,
            height: `${boxHeight}px`,
            width: `${dependentpoleWidth}px`,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {renderedElement()}
        </Box>
      );
    });
  }, [dependentLocations, openLoc]);

  return (
    <Box
      name="silhouette-container"
      sx={{
        position: 'relative',
        height: `${totalHeight}px`,
        width:'100%',
      }}
    >
      <Box
        name="pole"
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          height: `${totalHeight}px`,
          width: `${poleWidth}px`,
          backgroundColor: 'blue',
          border: '1px solid black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      {dependentFraming}
      {silhouetteChildren}
    </Box>
  );
}


export default PoleSilhouette;