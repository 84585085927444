import { useNavigate, useLocation } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

export default function PageNotFound() {
  const navigate = useNavigate();
  const location = useLocation();

  const parentPath = location.pathname.split('/').slice(0,-1).join('/');

  const handleGoToParent = () => {
    navigate(parentPath);
    return parentPath;
  };

  return (
    <Paper sx ={{ p:2, m:2 }}>
      <Typography variant="h2" sx ={{ py:2 }}>
        Page Not Found
      </Typography>
      <Typography variant="body1" sx ={{ px:2, mb:2 }}> 
        The page you are looking for does not exist.
      </Typography>
      <Tooltip title={<Typography variant ="body2">{`Index='${parentPath}'`}</Typography>} placement="right">
        <Button variant="contained" color="primary" onClick={handleGoToParent} sx ={{ mx:2 }}>
          Go To Local Index
        </Button>
      </Tooltip>
      <Button variant="contained" color="primary" onClick={() => navigate(-1)} sx ={{ mx:2 }}>
        Go Back
      </Button>
    </Paper>
  )
}