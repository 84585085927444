import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

import { sortNumAlpha } from '../../util/sortNumAlpha';

export function EquipmentSubPickerSimple({ className, baseLoc, baseEquipmentState, subEquipmentDefList, handleSubmitEquipment }) {

  const [selectedNewEquipment, setSelectedNewEquipment] = useState("");
  const [depSubEquipmentDeflist, setDepSubEquipmentDeflist] = useState([]);

  useEffect(() => {
    const baseKey = baseLoc.equip_key;

    const depSubEquipmentDeflist = subEquipmentDefList.filter((equipment) => equipment.base_key === baseKey);
    if (depSubEquipmentDeflist.length === 0) {
      setDepSubEquipmentDeflist([]);
      return;

    }

    setDepSubEquipmentDeflist(depSubEquipmentDeflist);
  }, [baseLoc, baseEquipmentState,  subEquipmentDefList]);

  function handleEquipChange(event) {
    setSelectedNewEquipment(event.target.value);
  }

  function onSubmitEquipment(selectedNewEquipment, locationid) {
    if (selectedNewEquipment === "") {
      return;
    }
    handleSubmitEquipment(selectedNewEquipment, locationid);
    setSelectedNewEquipment("");
  }

  return (
    <Box className={className} display="flex" flexDirection="row" sx={{ p: 1 }}>
      <FormControl className="sub-options-input" variant="standard" sx={{ m: 1, minWidth: 160 }}>
        <InputLabel id="sub-equipment-select">Sub Equipment:</InputLabel>
        <Select
          sx={{ width: 200 }}
          labelId="equipment-sub-select"
          label="Select Type"
          value={selectedNewEquipment}
          onChange={handleEquipChange}
        >
          {depSubEquipmentDeflist.length === 0 ? (
            <MenuItem key="no-dependents" value="" disabled>
              No Dependents
            </MenuItem>
          ) : (
            <MenuItem key="select-type" value="">
              Select an Equipment Type
            </MenuItem>
          )}
          {depSubEquipmentDeflist.map((equipment) => (
            <MenuItem key={equipment.equip_key} value={equipment.equip_key}>
              {equipment.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        className="create-sub-location-button"
        variant="contained"
        sx={{ display: 'inline-block', marginLeft: '8px' }}
        onClick={() => onSubmitEquipment(selectedNewEquipment, baseLoc.locationid)}
      >
        Create Loc
      </Button>
    </Box>
  );
}


export default function EquipmentSubPicker({
  className,
  baseEquipmentState,
  subEquipmentDefList,
  selectedNewEquipment,
  setSelectedNewEquipment,
  handleSubmitEquipment,
  baseKeyMap, 
}) {

  const [selectedBase, setSelectedBase] = useState('');

  const [reducedEquipment, setReducedEquipment] = useState([]);
  const [depSubEquipmentDeflist, setDepSubEquipmentDeflist] = useState([]);

  useEffect(() => {

    const redEquip = baseEquipmentState
      .filter((equipment) => {
        // Convert baseKeyMap values to an array and check if it includes the equipment's equip_key
        const baseKeys = Array.from(baseKeyMap.values());
        return baseKeys.includes(equipment.equip_key);
      })
      .sort((a, b) => sortNumAlpha(a.loc_number, b.loc_number));
  
    setReducedEquipment(redEquip);

    // get the selected Equipment
    const thisEquip = baseEquipmentState.find((equipment) => equipment.locationid === selectedBase);

    if (!thisEquip) {
      setDepSubEquipmentDeflist([]);
      return;
    }
    const baseKey = thisEquip.equip_key;

    // define the valid sub equipment list
    const depSubEquipmentDeflist = subEquipmentDefList.filter((equipment) => 
      equipment.base_key === baseKey && equipment.equip_key !== "jumper"
    );
    setDepSubEquipmentDeflist(depSubEquipmentDeflist);
    
  }, [selectedBase, baseKeyMap, baseEquipmentState, subEquipmentDefList]);


  function handleEquipChange(event) {
    setSelectedNewEquipment(event.target.value);
  }

  return (
    <Box className={className} display="flex" flexDirection="column" sx={{ p: 1 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        <FormControl className="base-options-input" variant="standard" sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="base-select">Select Base:</InputLabel>
          <Select
            sx={{ width: 160 }}
            value={selectedBase}
            onChange={(event) => setSelectedBase(event.target.value)}
            data-testid="base-equip-select"
          >
            <MenuItem value="">Select Loc</MenuItem>
            {reducedEquipment.map((option) => (
              <MenuItem key={option.locationid} value={option.locationid}>
                {`${option.loc_number}-${option.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <FormControl className="sub-options-input" variant="standard" sx={{ m: 1, minWidth: 160 }}>
          <InputLabel id="sub-equipment-select">Sub Equipment:</InputLabel>
          <Select
            sx={{ width: 200 }}
            labelId="equipment-sub-select"
            label="Select Type"
            value={selectedNewEquipment}
            onChange={handleEquipChange}
          >
            {depSubEquipmentDeflist.length === 0 ? (
              <MenuItem key="no-dependents" value="" disabled>
                No Dependents
              </MenuItem>
            ) : (
              <MenuItem key="select-type" value="">
                Select an Equipment Type
              </MenuItem>
            )}

            {/* Render the rest of the MenuItems */}
            {depSubEquipmentDeflist.map((equipment) => (
              <MenuItem key={equipment.equip_key} value={equipment.equip_key}>
                {equipment.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Button
        className="create-sub-location-button"
        variant="contained"
        sx={{ display: 'inline-block', marginLeft: '8px' }}
        onClick={() => handleSubmitEquipment(selectedBase)}
      >
        Create Loc
      </Button>
    </Box>
  );
}