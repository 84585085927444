
// {ssdType: {lsdType: connectionType}}
const combinations = {
  "pad_tx" : {
    "pad_tx": "pcable", 
    "sub_tx": "pcable", 
    "meter": "scable",
    "pbox": "pcable",
    "sbox": "scable",
    "pole": null,
    "pole_tx": "pcable",
    "pole_meter": null,
    "pole_pri": "pcable",
    "pole_sec": "scable",
  },
  "sub_tx" : {
    "pad_tx": "pcable", 
    "sub_tx": "pcable", 
    "meter": "scable",
    "pbox": "pcable",
    "sbox": "scable",
    "pole": null,
    "pole_tx": "pcable",
    "pole_meter": null,
    "pole_pri": "pcable",
    "pole_sec": "scable",
  },
  "meter" : { // meters are source to nothing
    "pad_tx": null, 
    "sub_tx": null, 
    "meter": null,
    "pbox": null,
    "sbox": null,
    "pole": null,
    "pole_tx": null,
    "pole_meter": null,
    "pole_pri": null,
    "pole_sec": null,
  },
  "pbox" : {
    "pad_tx": "pcable", 
    "sub_tx": "pcable", 
    "meter": null,
    "pbox": "pcable",
    "sbox": null,
    "pole": null,
    "pole_tx": null,
    "pole_meter": null,
    "pole_pri": "pcable",
    "pole_sec": null,
  },
  "sbox" : {
    "pad_tx": null, 
    "sub_tx": null, 
    "meter": "scable",
    "pbox": null,
    "sbox": "scable",
    "pole": null,
    "pole_tx": null,
    "pole_meter": "scable",
    "pole_pri": null,
    "pole_sec": "scable",
  },
  "pole" : { // should parse the dep to connect
    "pad_tx": null, 
    "sub_tx": null, 
    "meter": null,
    "pbox": null,
    "sbox": null,
    "pole": null,
    "pole_tx": null,
    "pole_meter": null,
    "pole_pri": null,
    "pole_sec": null,
  },
  "pole_tx" : {
    "pad_tx": null, 
    "sub_tx": null, 
    "meter": null,
    "pbox": null,
    "sbox": null,
    "pole": null, // Guying?
    "pole_tx": null,
    "pole_meter": null,
    "pole_pri": null,
    "pole_sec": "jumper",
  },
  "pole_meter" : {
    "pad_tx": null, 
    "sub_tx": null, 
    "meter": null,
    "pbox": null,
    "sbox": null,
    "pole": null,
    "pole_tx": null,
    "pole_meter": null,
    "pole_pri": null,
    "pole_sec": null,
  },
  "pole_pri" : {
    "pad_tx": "pcable", 
    "sub_tx": "pcable", 
    "meter": null,
    "pbox": "pcable",
    "sbox": null,
    "pole": null,  // Guying?
    "pole_tx": "jumper", 
    "pole_meter": null,
    "pole_pri": "pspan", // same Pole jumper
    "pole_sec": null,
  },
  "pole_sec" : {
    "pad_tx": null, 
    "sub_tx": null, 
    "meter": "sspan",
    "pbox": null,
    "sbox": "scable",
    "pole": null, // Guying?
    "pole_tx": null,
    "pole_meter": "sspan",
    "pole_pri": null,
    "pole_sec": "sspan",
  },
}

/**
 * Determines the type of connection between two equipment types.
 *
 * @param {string} ssdType - The type of the source equipment.
 * @param {string} lsdType - The type of the destination equipment.
 * @returns {string|null} The type of connection ("pcable" or "scable") or null if no connection is possible.
 *
 * @example
 * // Returns "pcable"
 * connectionType("pad_tx", "sub_tx");
 *
 * @example
 * // Returns "scable"
 * connectionType("sbox", "meter");
 *
 * @example
 * // Returns null
 * connectionType("pbox", "meter");
 */
export function connectionType(object1, object2) {
  const ssdType = object1.equip_key;
  const lsdType = object2.equip_key;

  if (!ssdType || !lsdType) return null;

  const corrConn = combinations[ssdType][lsdType];

  if (corrConn === "jumper") {
    return null;
  } else {
    return corrConn;
  }
}