import React, { useState } from 'react';
import {
  Popup,
  FeatureGroup,
  GeoJSON,
  LayersControl,
} from 'react-leaflet';
import 'leaflet-control-geocoder';

import Button from '@mui/material/Button';

import { OverpassData } from '../../../models/overpassData.ts';
import { loadRoadDataFromOverpass } from '../../../util/overpassFetch.js'

export function CreateRoadLayer({ className, parentMap, isLoaded, setRoadState }) {
  const handleLoadRoadDataClick = () => {
    loadRoadDataFromOverpass(parentMap,
      (res) => {
        // Create Road Layer using data
        const overpassData = res.data.elements.map(element => new OverpassData(element));
        setRoadState(overpassData);
      },
      (error) => {
        console.error(error?.response?.data || error);
      }
    );
  };

  if (!isLoaded) {
    return (
      <div className={className} >
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <Button className={className} variant="contained" onClick={handleLoadRoadDataClick}>Load Road Data</Button>
  );
}


export function RoadLayer({ roadState, onMapClick, checked, className = "road-maker-group", name = "Roads", ...props }) {
  const [roadPopups, setRoadPopups] = useState([]);

  const handlePopupClick = (e, tags) => {
    const latlng = e.latlng;
    const tagsString = Object.keys(tags).map((key, index) => {
      return `${key}: ${tags[key]}`;
    }).join("\n");

    const content = `Information about road: \n${tagsString}`;
    const popup = (
      <Popup key={Math.random()} position={latlng} onClose={() => setRoadPopups(roadPopups.filter(p => p !== popup))}>
        {content}
      </Popup>
    );
    setRoadPopups([...roadPopups, popup]);
  };

  const roads = roadState.map((roadElement, index) => {
    const geojson = roadElement.toGeoJSON();

    return (
      <GeoJSON
        key={index}
        data={geojson}
        style={{ color: "red" }}
        eventHandlers={{
          click: (e) => {
            onMapClick(e, roadElement);
            handlePopupClick(e, geojson.properties);
          }
        }}
      />
    );
  });

  return (
    <LayersControl.Overlay checked className={className} name={name} {...props}>
      <FeatureGroup>{roads}</FeatureGroup>
      {roadPopups}
    </LayersControl.Overlay>
  );
}