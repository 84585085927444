import React from 'react';
import {
  FeatureGroup,
  GeoJSON,
  LayersControl,
} from 'react-leaflet';


export default function ConnectionLayer({
  isLoaded, LocationArray, editMode, onMapClick,
  checked, className = "connection-line-group",
  name = "Connections",
  ...props
}) {

  if (!isLoaded) {
    return null;
  }

  const geoJSON = LocationArray.toGeoJSON();
  // Filter out non-polyline features
  const polylineFeatures = geoJSON.features.filter(feature => feature.geometry.type === 'LineString');

  const handleMapClick = (e, feature) => {
    const equip = LocationArray.find(location => String(location.locationid) === String(feature.properties.locationid));
    if (equip) {
      onMapClick(e, equip);
    }
  }



  return (
    <LayersControl.Overlay checked className={className} name={name} {...props}>
      <FeatureGroup>
        {polylineFeatures.map((feature, index) => (
          <React.Fragment key={index}>
            <GeoJSON
              data={feature}
              {...props}
            />
            <GeoJSON
              data={feature}
              style={{ color: 'transparent', weight: 10 }}
              eventHandlers={{ click: (e) => handleMapClick(e, feature) }}
            />
          </React.Fragment>
        ))}
      </FeatureGroup>
    </LayersControl.Overlay>
  );
}