import React from "react"

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

function TermsPage() {

  return (
    <Paper sx={{ my: 4, px: 2, justifyContent: 'left', textAlign : 'left' }}>
      <Typography variant="h1">
        Terms and Conditions
      </Typography>
      <Typography variant="body2"  sx = {{px: 2}} paragraph >
        Last updated: September 10, 2024
        </Typography>
      <Typography variant="h2" paragraph>
        AGREEMENT TO OUR LEGAL TERMS
        </Typography>
      <Typography variant="body1" sx = {{px: 2}} paragraph>
      We are Sherman Procedural Designs LLC ("Company," "we," "us," "our"), a company registered in Calfornia, United States at 548 market St, PMB 286161, San Francisco, CA 94104.
      <br/><br/>
        We operate the website utilitydesign.app (the "Site"), as well as any other related products and services that refer or link to these legal termsn ( the "Legal Terms") (collectively, the "Services").
        <br/><br/>
        We Provide tools and calculators for drafting designs for public utility networks.
        <br/><br/>
        You can Contact us by email at shermanproceduraldesigns@gmail.com or by mail to 548 Market st, PMB 286161, San Francisco, CA 94104, United States.
        <br/><br/>
        These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and the Company concerning your access to and use of the Services. 
        You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal terms. 
        IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        <br/><br/>
        We reserve the right to modify or terminate the Services for any reason, without notice at any time.
        <br/><br/>
        The Services are intended for users who are at least 18 years old.  Persons under the age of 18 are not permitted to use the Services.
        <br/><br/>
        We recommend that you print a copy of these Legal Terms for your records.
      </Typography>

      {/* Table of Contents Start */}
      <Typography variant="h2" >
        TABLE OF CONTENTS
      </Typography>
      <Typography variant="body2" sx = {{px: 4}}  >
        <Link href="#services">1. Our Services</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#ip">2. Intellectual Property Rights</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#representations">3. User Representations</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#registration">4. User Registration</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#purchase">5. Purchase and Payment</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#prohibited">6. Prohibited Activities</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#contributions">7. User Generated Contributions</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#contribution_license">8. Contribution License</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#third_party">9. Third-Party Websites and Content</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#services_management">10. Services Management</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#privacy">11. Privacy Policy</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#terms">12. Term and Termination</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#modifications">13. Modifications and Interruptions</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#govern">14. Governing Law</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#dispute">15. Dispute Resolution</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#corrections">16. Corrections</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#disclaimer">17. Disclaimer</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#liability">18. Limitations of Liability</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#indemn">19. Indemnification</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#data">20. User Data</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#electronic">21. Electric Communications, transactions, and Signatures</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#california">22. California Users and Residents</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#misc">23. Miscellaneous</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#contact">24. Contact Us</Link>
      </Typography>
      {/* End ToC links */}
      <Box>
        <Typography variant="h3" paragraph id="services">
          1. OUR SERVICES
        </Typography>
          <Typography variant="body1" paragraph>
            The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where 
            such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
            Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance 
            with local laws, if and to the extend local laws are applicable.
            <br/><br/>
            The services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), 
            Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services.
            You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="ip">
          2. INTELLECTUAL PROPERTY RIGHTS
        </Typography>
          <Typography variant="h4" paragraph>
            Our intellectual property
          </Typography>
            <Typography variant="body1" sx = {{px: 2}} paragraph>
              We are the owner or the licensee of all intellectual property rights in our services, including all source code, database, functionality, 
              software, website designers, and reports in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").
              <br/><br/>
              Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) 
              and treaties in the United States and around  the world.
              <br/><br/>
              The Content and marks are provided in or through the Services "AS IS' for your personal, non-commercial or internal business purpose only.
              <br/><br/>
              The Content are for generalized information purposes only and should be interpreted with a professional consultation for applying in your local jurisdiction.  
              <br/><br/>
              The Content should not be the sole basis for making decisions and should not be used `as-is` for construction or design purposes.
            </Typography>
          <Typography variant="h4" paragraph>
            Your use of our Services
          </Typography>
            <Typography variant="body1" sx={{ px: 2 }} >
              Subject to your compliance with these Legal Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to:
            </Typography>
            <List sx={{ px: 6, listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body2">
                  access the Services; and
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body2">
                  download or print a copy of any portion of the Content to which you have properly gained access.
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body1" sx={{ px: 2 }} paragraph>
              Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
            </Typography>
            <Typography variant="body1" sx={{ px: 2 }} paragraph>
              If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: shermanproceduraldesigns@gmail.com.
              If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
            </Typography>
            <Typography variant="body1" sx={{ px: 2 }} paragraph>
              We reserve all rights not expressly granted to you in and to the Service, Content, and Marks.
            </Typography>
            <Typography variant="body1" sx={{ px: 2 }} paragraph>
              Any breach of these Intellectual Property Rights will constitute a material breach of these Legal Terms and may result in the termination of your access to the Services.
            </Typography>
          <Typography variant="h4" paragraph id="Your submissions">
            Your submissions
          </Typography>
            <Typography variant="body1" sx={{ px: 2 }} paragraph>
              Please review this section and the "<Link href="#prohibited">PROHIBITED ACTIVITIES</Link>" section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you havee when you post or upload any content through the Services.
              <br/><br/>
              Submissions: By posting or uploading any content to the Services (collectively, "Submissions"), you grant us a non-exclusive, 
              royalty-free, irrevocable, sub-licensable, perpetual, worldwide license to use, reproduce, modify, adapt, publish, translate, 
              create derivative works from, distribute, and display such Submissions in any media.  You agree that we shall own this Submission and 
              be entitled to its unrestricted use and dissemination for any purpose, commercial or otherwise, without acknowledgment or 
              compensation to you.
              <br/><br/>
              You are responsible for what you post or upload:  By sending us Submissions through any part of the services you:
            </Typography>
            <List sx={{ px: 6, listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body2">
                  confirm that you have read and understood the "<Link href="#prohibited">PROHIBITED ACTIVITIES</Link>" section and post, send, publish,
                  upload or transmit through the Services any content that is prohibited as defined in these terms;
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body2">
                  to the extent permissible by applicable law, waive any and all moral rights to any such Submission;
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body2">
                  warrant that any such Submission are original to you or that you have the necessary rights to submit such Submissions and you have the full authority
                  to grant us the above-mentioned rights in relation to your Submissions; and 
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body2">
                  warrant and represent that your Submissions do not constitute confidential information.
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body1" sx={{ px: 2 }} paragraph>
              You are responsible for any Submissions you make and you agree to reimburse us for any and all losses that we may suffer as a result of 
              any Submissions in breach of (a) this section, (b) any third party's intellectual property rights, (c) applicable law.
            </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="representations">
          3. USER REPRESENTATIONS
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            By using the Services, you represent and warrant that: 
            (1) all registration information you submit will be true, accurate, current and complete;
            (2) you will maintain the accuracy of such information and promptly update such registration information as necessary;
            (3) you have the legal capacity and you agree to comply with these Legal Terms;
            (4) you are not a minor in the jurisdiction in which you reside;
            (5) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise;
            (6) you will not use the Services for any illegal or unauthorized purpose; and
            (7) your use of the Services will not violate any applicable law or regulation.
          </Typography>

      </Box>
      <Box>
        <Typography variant="h3" paragraph id="registration">
          4. USER REGISTRATION
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            You may be required to register to use the services. You agree to keep your password confidential and will be responsible for all use of your account and password.
            we reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
          </Typography>

      </Box>
      <Box>
        <Typography variant="h3" paragraph id="purchase">
          5. PURCHASE AND PAYMENT
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          All purchases are non-refundable. For refund requests, please contact us at <Link href="mailto:refunds@utilitydesign.app" underline="none" sx={{ color: 'inherit' }}>refunds@utilitydesign.app</Link>.
          <br/><br/>
          We use Stripe as our payment processor. By making a purchase, you agree to Stripe's 
          <Link href="https://stripe.com/legal" target="_blank" rel="noopener noreferrer" underline="none" sx={{ color: 'inherit' }}>Terms of Service</Link> and 
          <Link href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer" underline="none" sx={{ color: 'inherit' }}>Privacy Policy</Link>.
          <br/><br/>
          You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, 
          and you authorize us to charge your chosen payment provider for any such amounts upon placing your order.
          We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
          <br/><br/>
          We reserve the right to refuse any order placed through the Services. 
          We may, in our sole discretion, limit or cancel quantities purchased per person, per organization, or per order.
          These restrictions may include orders placed by or under the same customer account, the same payment method, and/or 
          orders that use the same billing or shipping address.
          We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
        </Typography>
        
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="prohibited">
          6. PROHIBITED ACTIVITIES
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            You may not access or use the Services for any purpose other than that for which we make the Services available.
            The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
            <br/><br/>
            As a user of the Services, you agree not to:
          </Typography>
          <List sx={{ px: 6, listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict use or copying of any Content or enforce limitations on use of the Services and/or the Content contained therein.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Disparaeg, tarnish, or otherwise harm, in our opinion, us and/or the Services.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Use any information obtained from the Services in order to harass, abuse, or harm another person.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Make improper use of our support services or submit false reports of abuse or misconduct.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Use the Services in a manner inconsistent with any applicable laws or regulations.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Engage in unauthorized framing of or linking to the Services.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party's uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Delete the copyright or other proprietary rights notice from any Content. 
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Attempt to impersonate another user or person or use the username of another user.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1x1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms").
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.
              </Typography> 
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or using or launching any unauthorized script or other software.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Use a buying agent or purchasing agent to make purchases on the Services.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial enterprise.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Use the Services to advertise or offer to sell goods and services.
              </Typography>
            </ListItem>
          </List>

      </Box>
      <Box>
        <Typography variant="h3" paragraph id="contributions">
          7. USER GENERATED CONTRIBUTIONS
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            The Services provides you with the opportunity to create, submit, post, display transmit, perform, publish, distribute or broadcast content and materials to us or on the Services, 
            including but not limited to text, writings, comments, suggestions or personal information or other material (collectively, "Contributions").
            Contributions may be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated in accordance with the Services' Privacy Policy.
            When you create or make available any Contributions, you thereby represent and warrant that:
          </Typography>
          <List sx={{ px: 6, listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions are not false, inaccurate, or misleading.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions do not violate any applicable law, regulation, or rule.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions do not violate the privacy or publicity rights of any third party.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.\
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body2">
                Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights to use the Services.
          </Typography> 
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="contribution_license">
          8. CONTRIBUTION LICENSE
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          You and Services agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).
        </Typography>

        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          By submitting suggestions, feedback, or other contributions regarding the Services, you agree that we can use and share such contributions for any purpose, including but not limited to, improving our services, and developing new features, without compensation to you.

          <br/><br/>
          You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. 
          However, by submitting Contributions, you grant us a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, transferable, and sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and 
          display your Contributions in connection with the Services and our business, including without limitation for promoting and redistributing part or all of the Services (and derivative works thereof) in any media formats and through any media channels.
          Any user Contributions used in the way described above will be anonymous. 
          <br/><br/>
          We do not assert any ownership over your Contributions. This means you retain full ownership of your Contributions, but you grant us the rights to use them as described above.
          <br/><br/>
          We are not liable for any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="third_party">
          9. THIRD-PARTY WEBSITES AND CONTENT.
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            The Services may contain (or you may be sent via the Site) links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content").
            Such Third-Party Websites and Third-party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.
            Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. 
            If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Legal Terms no longer govern.
            You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating to any applications you use or install from the Services.
            Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.
            You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such products or services.
            Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="services_management">
          10. SERVICES MANAGEMENT
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            We reserve the right, but not the obligation, to:
            (1) monitor the Services for violations of these Legal Terms;
            (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities;
            (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion of the Services;
            (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and
            (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="privacy">
          11. PRIVACY POLICY
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          We care about data privacy and security.  Please review our Privacy Policy: <Link href="/privacy_policy">Privacy Policy</Link>. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms.
          Please be advised the Services are hosted in the United States.  If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, 
          then through your continued use of the Services, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="terms">
          12. TERM AND TERMINATION
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          These Legal Terms shall remain in full force and effect while you use the Services.
          WITHOUT LIMITING ANY PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION.
          WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
          <br/><br/>
          If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.
          In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="modifications">
          13. MODIFICATIONS AND INTERRUPTIONS
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. 
            However, we have no obligation to update any information on our Services. We also reserve the right to modify or discontinue all or part of the Services without notice at any time. 
            We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
            <br/><br/>
            We cannot guarantee the Services will be available at all times. 
            We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors.
            We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you.
            You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services.
            Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="govern">
          14. GOVERNING LAW
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the State of California applicable to agreements made and to be entirely performed within the State of California, without regard to its conflict of law principles.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="dispute">
          15. DISPUTE RESOLUTION
        </Typography>
          <Typography variant="h4" paragraph>
            Binding Arbitration
          </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those Disputes expressly excluded below) will be finally and exclusively resolved by binding arbitration. 
            YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. 
            The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American Arbitration Association ("AAA") and, where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of which are available at the AAA website <Link href="https://www.adr.org" target="_blank" rel="noopener noreferrer">www.adr.org</Link>. 
            Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. 
            The arbitration may be conducted in person, through the submission of documents, by phone, or online.  
            The arbitrator will make a decision in writing, but need not provide a statement of reasons unless requested by either Party. 
            The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so. 
            Except where otherwise required by the applicable AAA rules or applicable law, the arbitration will take place in United States, California. 
            Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
            <br/><br/>
            If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or prosecuted in the state and federal courts located in United States, California, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens with respect to venue and jurisdiction in such state and federal courts. 
            Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Legal Terms.
            <br/><br/>
            In No event shall any Dispute brought by either Party related in any way to the Services be commenced more than two (2) years after the cause of action arose.
            If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
          </Typography>
          <Typography variant="h4" paragraph>
            Restrictions
          </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
          </Typography>
          <Typography variant="h4" paragraph>
            Exceptions to Arbitration
          </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            The Parties agree that the following Disputes are not subject to the above provisions concerning binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="corrections">
          16. CORRECTIONS
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            There may be information on the Services that contains typographical errors, inaccuracies, or omissions that may relate to the Services, including descriptions, pricing, availability, and various other information.
            We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="disclaimer">
          17. DISCLAIMER
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. 
            YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. 
            TO THE FULLEST EXTEND PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, 
            IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED 
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO 
            WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACIES OR COMPLETENESS OF THE SERVICES` CONTENT OR THE 
            CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO 
            LIABILITY OF RESPONSIBILITY FOR ANY 
            (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, 
            (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, 
            (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, 
            (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, 
            (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR 
            (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED 
            AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. 
            WE DO NOT WARRANT, ENDORSE, GUARANTEE , OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED 
            OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE 
            APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY 
            BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.  
            AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, 
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="liability">
          18. LIMITATIONS OF LIABILITY
        </Typography>
          <Typography variant="body1" sx={{ px: 2 }} paragraph>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOUR OR ANY THIRD PARTY FOR ANY DIRECT, 
            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, 
            LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND 
            REGARDLESS OF THE FROM OF THE ACTION, WILL AT ALL TEEMS BE LIMITED TO THE AMOUNT PAID, IF ANY, 
            BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING.  
            CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR 
            LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATION MAY NOT APPLY TO YOU, 
            AND YOU MAY HAVE ADDITIONAL RIGHTS.
          </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="indemn">
          19. INDEMNIFICATION
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and 
          all of our respective officers, agents, partners, and employees, from and against any loss, damage, 
          liability, claim, or demand, including reasonable attorney’s fees and expenses, made by any third party due to or arising out of: 
          (1) use of the services; 
          (2) breach of these Legal Terms; 
          (3) any breach of your representations and warranties set forth in these legal Terms; 
          (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or 
          (5) any overt harmful act toward any other user of the Services with whom you connected via the Services.  
          Notwithstanding the forgoing, we reserve the right, at your expense, to assume the exclusive defense and 
          control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, 
          with out defense of such claims.  We will use reasonable efforts to notify you of any such claim, action, or 
          proceeding which is subject to this indemnification upon becoming aware of it.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="data">
          20. USER DATA
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, 
          as well as data relating to your use of the Services.  Although we perform regular routine backups of data, you are solely 
          responsible for all data that you transmit or that relates to any activity you have undertaken using the Services.  
          You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right 
          of action against us arising from any such loss or corruption of such data.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="electronic">
          21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          Visiting the Services, sending us emails, and completing online forms constitute electronic communications.  
          You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing.  
          YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES.  
          You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="california">
          22. CALIFORNIA USERS AND RESIDENTS
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 Northern Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="misc">
          23. MISCELLANEOUS
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. 
          Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. 
          These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. 
          We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. 
          If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. 
          There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services. 
          You agree that these Legal Terms will not be construed against us by virtue of having drafted them. 
          You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" paragraph id="contact">
          24. CONTACT US
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:
        </Typography>
        <Typography variant="body1" sx={{ px: 2 }} paragraph>
          Sherman Procedural Designs LLC<br/>
          548 Market Street<br/>
          PMB 286161<br/>
          San Francisco, CA 94101 <br/>
          United States<br/><br/>

          shermanproceduraldesigns@gmail.com
        </Typography>
      </Box>
    </Paper>
  );
}

export default TermsPage;