// Creating a component which will load the location data and display in a table
// Will need to trace SSD's and map each SSD to their LSD ( PRI, and SEC )
// Note that this is dead and should be rebuilt from scratch
// Generally replaced with serverside map rendering
import React, { useState, useEffect, useRef } from "react";

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import Grid from '@mui/material/Unstable_Grid2';


const GridLinerComponent = ({ position, heightTotal, heightRight, tooltipText }) => {

  let props = { width: 50, tooltipText };

  switch (position) {
    case 'only':
      props.left = true;
      props.right = true;
      break;
    case 'first':
      props.left = true;
      props.right = true;
      props.bottom = true;
      break;
    case 'last':
      props.top = true;
      props.right = true;
      break;
    case 'middle':
      props.top = true;
      props.right = true;
      props.bottom = true;
      break;
    case 'vertical':
      props.top = true;
      props.bottom = true;
      props.height = heightTotal;
      break;

    default:
      break;

  }

  const { height, width, top, right, bottom, left } = props;
  const h = height || '100%';
  const topHeight = heightRight > 0 ? heightRight : h / 2;
  const bottomHeight = heightRight > 0 ? `calc(${h} - ${heightRight}px)` : h / 2;

  return (
    <Tooltip title={tooltipText}>
      <Grid container sx={{ height: h, width: width || '100%' }} name="BORDER_ELEMENT">
        <Grid container sx={{ width: width, height: topHeight }} direction="row">
          <Grid sx={{ width: width / 2, height: topHeight, borderBottom: left ? '1px solid black' : 'none', borderRight: top ? '1px solid black' : 'none' }} />
          <Grid sx={{ width: width / 2, height: topHeight, borderBottom: right ? '1px solid black' : 'none' }} />
        </Grid>
        <Grid container sx={{ width: width, height: bottomHeight }} direction="row">
          <Grid sx={{ width: width / 2, height: "100%", m: 0, p: 0, borderRight: bottom ? '1px solid black' : 'none' }} />
          <Grid sx={{ width: width / 2, height: "100%", m: 0, p: 0 }} />
        </Grid>
      </Grid>
    </Tooltip>
  );
};

function Element({ ssd, lsdMap, gridType = null }) {
  const [elementHeight, setElementHeight] = useState(0);
  const selfDeviceRef = useRef(null);
  const childrenRef = useRef(null);

  const { loc, key, lsdSecondary, lsdPrimary } = ssd;

  function childrenElements(Elements) {
    return Elements.map((id, index) => {
      let gridType;
      if (Elements.length === 1) {
        gridType = 'only';
      } else if (index === 0) {
        gridType = 'first';
      } else if (index === Elements.length - 1) {
        gridType = 'last';
      } else {
        gridType = 'middle';
      }
      return <Element ssd={lsdMap.find((lsd) => lsd.id === id)} lsdMap={lsdMap} gridType={gridType} key={id} />;
    });
  }

  useEffect(() => {
    if (selfDeviceRef.current) {
      const selfDeviceRefHeight = selfDeviceRef.current.offsetHeight;
      setElementHeight(selfDeviceRefHeight);
    }
  }, [selfDeviceRef]);

  if (lsdPrimary.length === 1) {
    return (
      <>
        <Grid container direction="row" >
          {gridType ? (<GridLinerComponent position={gridType} heightTotal={0} heightRight={elementHeight / 2} />) : (null)}
          <Grid container direction="column" sx={{ minHeight: 2 * elementHeight }}>
            <Grid ref={selfDeviceRef}>
              {loc} - {key}
            </Grid>
            {gridType ? (<GridLinerComponent position={"vertical"} heightTotal={elementHeight} hightRight={0} />) : (null)}
          </Grid>
        </Grid>
        <Element ssd={lsdMap.find((lsd) => lsd.id === lsdPrimary[0])} lsdMap={lsdMap} key={lsdPrimary[0]} gridType={"vertical"} />

      </>
    );
  }

  return (
    <Grid container direction="row"  >
      <GridLinerComponent position={gridType} heightTotal={0} heightRight={elementHeight / 2} />
      <Grid container direction="column" name={"AllCases"}>
        <Grid ref={selfDeviceRef}>
          {loc} - {key}
        </Grid>
      </Grid>
      <Grid container direction="column" ref={childrenRef}>
        {childrenElements([...lsdSecondary, ...lsdPrimary])}
      </Grid>
    </Grid>
  );
};
export default function LocationSummary({ locationList }) {
  const extendedCableMap = locationList.reduce((acc, location) => {
    if (location.ssd_id !== null || location.lsd_id !== null) {
      const ssd = locationList.filter((loc) => loc.id === location.ssd_id);
      const lsd = locationList.filter((loc) => loc.id === location.lsd_id);
      acc.push({ ...location, ssd, lsd });
    }
    return acc;
  }, []);

  const lsdPCable = (id) =>
    extendedCableMap.filter((loc) => loc.ssd_id === id && loc.key === 'pcable')

  const lsdSCable = (id) =>
    extendedCableMap
      .filter((loc) => loc.ssd_id === id && loc.key === 'scable')



  const lsdMap = locationList.reduce((acc, location) => {
    const { id, loc, ssd_id, lsd_id, key } = location;

    if (key === 'pcable' || key === 'scable') {
      return acc;
    }

    const lsdPCableList = lsdPCable(id);
    const lsdSCableList = lsdSCable(id);

    const lsdPrimary = lsdPCableList
      .filter((lsd) => lsd.ssd_id === id);


    const lsdSecondary = lsdSCableList
      .filter((lsd) => lsd.ssd_id === id);

    // locationList
    //   .filter((loc) => lsdPCableList.includes(loc.id) || ssdPCableList.includes(loc.id) || loc.ssd_id === id)
    // .filter((loc) => ['pad_tx', 'sub_tx', 'pbox'].includes(loc.key));

    // const lsdSecondary = locationList
    //  .filter((loc) => lsdSCableList.includes(loc.id) || ssdSCableList.includes(loc.id) || loc.ssd_id === id)
    // .filter((loc) => ['sbox', 'meter'].includes(loc.key));

    acc.push({ id, loc, key, ssd_id, lsd_id, lsdPrimary, lsdSecondary });

    return acc;
  }, []);

  // const topSSDList = lsdMap.filter((loc) => loc.ssd_id === null || loc.ssd_id === -1);
  const topSSDList = [];
  return (
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid black',
      height: '300px',
      width: '90%',
      overflow: 'auto',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 2,
    }}>
      <Grid container direction='column' sx={{ p: 2, height: '1000px', width: '1000px' }}>
        {topSSDList.map((ssd) => (
          <Element ssd={ssd} lsdMap={lsdMap} key={ssd.id} />
        ))}
      </Grid>
    </Box>
  );
};