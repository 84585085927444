import { useState } from 'react';


// to-do drag and drop is broken.
// splitting equipment from cable makes its so the indices do not match with the total equipment state
// should look only at the local equipmentState for each.
// Should find match then drop before it?


/**
 * A custom React hook for handling drag and drop functionality.
 *
 * @param {function} onDropAction - A callback function to be executed when an item is dropped.
 * @returns {object} An object containing the `handleDragStart`, `handleDrop`, and `draggedIndex` functions.
 */

function useDragDrop(onDropAction = null, dragID = 'loc12345') {
  const [draggedIndex, setDraggedIndex] = useState(null);

  /**
   * Handles the drag start event.
   *
   * @param {object} event - The drag start event object.
   * @param {number} index - The index of the dragged item.
   */
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', dragID)
    event.dataTransfer.setDragImage(event.target.parentElement.parentElement, 20, 20)
    setDraggedIndex(index);
  }

  /**
   * Handles the drop event.
   *
   * @param {object} event - The drop event object.
   * @param {number} index - The index of the dropped item.
   * @param {array} totalPayload - An total array of items to be reordered.
   * @param {array} localPayload - A reduced array of items from totalPayload; presumed to be rendered.
   */
  const handleDrop = (event, index, totalPayload = [], localPayload = []) => {
    event.preventDefault();

    const draggedType = event.dataTransfer.getData('text/plain')
    if (draggedType !== dragID) {
      return;
    }

    if (onDropAction) {
      let droppedItemIndex;
      let targetIndex;

      if (localPayload.length === 0) {
        // If localPayload is empty, ignore it and only reorder totalPayload
        droppedItemIndex = draggedIndex;
        targetIndex = index;
      } else {
        // If there is a local payload, get the item from the local payload
        // then update the totalPayload relative to the local Payload

        // Find the index of the dropped item in the totalPayload
        droppedItemIndex = totalPayload.findIndex(item => item === localPayload[draggedIndex]);

        // Find the index of the target location in the totalPayload
        targetIndex = totalPayload.findIndex(item => item === localPayload[index]);
      }

      // Create a new order for the totalPayload
      const newOrder = [...totalPayload.keys()].filter(k => k !== droppedItemIndex);

      // Insert the dropped item at the target location
      newOrder.splice(targetIndex, 0, droppedItemIndex);

      onDropAction(newOrder);
    }
  }

  return { handleDragStart, handleDrop, draggedIndex };
}

export default useDragDrop;

