import React, { useState, useEffect } from "react";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import CircularProgress from '@mui/material/CircularProgress';

import TransferResponsibilityUser from './transferResponsibilityUser';
import TransferInvite from './transferInvite';

import { UseServerData } from '../../util/dbfetch';


// Create a new page, when loaded get all transfered initiated by user
export default function UserTransfers() {
  const [inviteDetails, setInviteDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  useEffect(() => {
    UseServerData(`transfers`, setInviteDetails, setLoading, () => { });
  }, []);


  const handleDeleteInvite = (transferCode) => {
    setInviteDetails((prevDetails) => prevDetails.filter((invite) => invite.transfer_code !== transferCode));
  };


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  return (
    <>
      <Dialog maxWidth="lg" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Button variant="outlined" onClick={() =>
        handleOpenDialog(
          <TransferResponsibilityUser />
        )}>Create New Transfers</Button>

      {loading ? (
        <CircularProgress />
      ) : inviteDetails.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2 }}>You have no transfers in progress!</Typography>
      ) : (
        <>
          {inviteDetails.map((invite, index) => (
            <TransferInvite key={index} invite={invite} onDeleteInvite={handleDeleteInvite} />
          ))}
        </>
      )}
    </>
  );
}