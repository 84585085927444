import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

function ModalSelector({ modalRef, items, itemRenderer, onSubmit, onClose, open }) {

  const [selectedElement, setSelectedElement] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedObject = items.find((item) => item.id === selectedElement);

    onSubmit(selectedObject);
  };

  function handleElementRender(item) {
    let element = null;
    let makeGrey = false;
    
    try {
      element = itemRenderer(item);
    } catch (error) {
      element = <div>"Error: Failed to render item"</div>;
    }
    
    // set notSelected based on whether the current element is selected or not
    if ( (selectedElement !== null) && (selectedElement !== item.id) ) {
      makeGrey = true;
    } else {
      makeGrey = false;
    }

    return (
      <FormControl key={item.id}>
        <FormControlLabel
          sx={{
            m:1,
            color: (makeGrey) ? 'grey' : 'primary.main',
          }}
          value={item.id}
          control={<Radio />}
          label={element}
        />
      </FormControl>
    );
  }


  return (
    <Dialog ref={modalRef} onClose={onClose} open = {open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Select an Element:</DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-label="element"
            value={selectedElement}
            onChange={(e) => setSelectedElement(parseInt(e.target.value))}
          >
            {items.map((item) => handleElementRender(item))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button variant= "outlined" type="submit">Zoom To</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ModalSelector;