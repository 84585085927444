import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"

import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';

import useLocationArrayWithOptions from "../../hooksDatabase/useLocationArrayWithOptions.jsx";
import { useOrgPriceProfile } from "../../hooksDatabase/priceProfileList.jsx";

import { MyMapContainerReadOnly } from '../../components/map/map.jsx';
import { GroupedPriceProfile } from '../../components/priceProfileGroup.jsx'

import { PriceProfileList, QuantifiedPriceProfileList } from '../../models/priceProfile.ts';

// A page which will show the map of a instance, 
// Show the price profile below the map
export default function OrganizationProjectPricer() {
  const { orgID, instanceID } = useParams();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [loading, setLoading] = useState(true);

  const [selectedPriceProfileName, setSelectedPriceProfileName] = useState("default");

  const [originalPPList, setOriginalPPList] = useState(PriceProfileList.default());
  const [newQuantPPList, setNewQuantPPList] = useState(QuantifiedPriceProfileList.default());

  const [overwriteExistingProfile, setOverwriteExistingProfile] = useState(false);
  const [saveToOrganization, setSaveToOrganization] = useState(false);

  const [
    LocationArray, ,
    locationArrayLoading,
  ] = useLocationArrayWithOptions(instanceID);

  const {
    priceProfileList,
    PPloading,
    savePriceProfileToOrg,
  } = useOrgPriceProfile(orgID, instanceID);

  useEffect(() => {
    if (PPloading || locationArrayLoading) {
      return;
    }
    const quantPPList = QuantifiedPriceProfileList.quantifyFromPriceProfileList(
      priceProfileList[0], LocationArray
    );

    setOriginalPPList(quantPPList);
    setNewQuantPPList(quantPPList.copy());
    setLoading(false);

  }, [priceProfileList, LocationArray, PPloading, locationArrayLoading]);


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleAddPriceProfile = (newPP) => {
    // handle adding the newPP to the newQuantPPList, then calling quantify again

    const newPPs = [...newQuantPPList.price_profiles, newPP];
    const newPPList = new PriceProfileList(
      newQuantPPList.priceprofilegroupid,
      newQuantPPList.name,
      // newQuantPPList.description,
      newPPs
    );

    const newQuantPPList2 = QuantifiedPriceProfileList.quantifyFromPriceProfileList(
      newPPList, LocationArray
    );

    setNewQuantPPList(newQuantPPList2);
  }

  const handleSetOriginalPriceProfile = (event) => {
    setOverwriteExistingProfile(false);
    setSaveToOrganization(false);

    const selected = priceProfileList.find((ppGroup) => ppGroup.name === event.target.value);
    const newQuantPPList = QuantifiedPriceProfileList.quantifyFromPriceProfileList(
      selected, LocationArray
    );

    setSelectedPriceProfileName(newQuantPPList.name);

    setOriginalPPList(newQuantPPList);
    setNewQuantPPList(newQuantPPList.copy());
  }

  const handleSaveNewPriceProfile = () => {
    savePriceProfileToOrg(
      newQuantPPList,
      saveToOrganization,
      overwriteExistingProfile ? originalPPList.priceprofilegroupid : null
    );
  };


  return (
    <>
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Box className="map-container" sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
        <MyMapContainerReadOnly LocationArray={LocationArray} selectedObjects={null} />
      </Box>

      <Paper sx={{ py: 2 }}>
        <Typography variant="subtitle1" sx={{ mr: 2 }}>Load Price Profile</Typography>
        {loading ? (
          <Box>Loading...</Box>
        ) : (
          <>
            <Box sx={{ display: "inline-flex", alignItems: 'left' }}>
              <Button onClick={() => console.log(selectedPriceProfileName)}>Add new price profile</Button>
              <FormControl variant="outlined" sx={{ mr: 2 }}>
                <InputLabel id="price-profile-select-label">Price Profile</InputLabel>
                <Select
                  labelId="price-profile-select-label"
                  id="price-profile-select"
                  value={selectedPriceProfileName}
                  onChange={(e) => handleSetOriginalPriceProfile(e)}
                  sx={{ minWidth: '25ch' }}
                >
                  {priceProfileList.map((ppGroup) => (
                    <MenuItem key={ppGroup.priceprofilegroupid} value={ppGroup.name}>
                      {ppGroup?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={<Checkbox checked={overwriteExistingProfile} onChange={(event) => setOverwriteExistingProfile(event.target.checked)} />}
                label="Overwrite existing profile"
                sx={{ mr: 2 }}
              />

              <FormControlLabel
                control={<Checkbox checked={saveToOrganization} onChange={(event) => setSaveToOrganization(event.target.checked)} />}
                label="Save to organization"
                sx={{ mr: 2 }}
              />

              <Button variant="contained" onClick={handleSaveNewPriceProfile}>Save</Button>
            </Box>

            <Box sx={{ p: 2, display: 'block' }}>
              <TextField
                id="price-profile-name"
                label="Name"
                variant="outlined"
                value={newQuantPPList.name || ""}
                onChange={(event) => setNewQuantPPList({ ...newQuantPPList, name: event.target.value })}
                sx={{
                  py: 2,
                  mr: 2,
                  '& label': {
                    lineWidth: '5rem', // Adjust the line height to make the notch bigger
                  },
                }}
              />

              <TextField
                multiline
                fullWidth
                id="price-profile-description"
                label="Description"
                variant="outlined"
                value={newQuantPPList.description || ""}
                onChange={(event) => setNewQuantPPList({ ...newQuantPPList, description: event.target.value })}
                sx={{
                  py: 2,
                  mr: 2,
                  '& label': {
                    lineWidth: '5rem', // Adjust the line height to make the notch bigger
                  },
                }}
              />
            </Box>
          </>
        )}
      </Paper>

      {!loading &&
        <GroupedPriceProfile
          priceProfileList={newQuantPPList}
          setPriceProfileList={setNewQuantPPList}
          handleAddPriceProfile={handleAddPriceProfile}
          onOpenDialog={handleOpenDialog}
        />}
      <Box sx={{ p: 10 }}>

      </Box>
    </>
  );
}