import { useState, useEffect } from 'react';

import { tutorials } from '../components/tutorial/tutorials';

import TutorialState from '../components/tutorial/tutorialState';

export function useTutorialState() {
  const [tutorialState, setTutorialState] = useState(TutorialState.fromLocalStorage);
  const [currentTutorial, setCurrentTutorial] = useState({name: '', phases: []});


  useEffect(() => {
    if (currentTutorial?.phases.length === 0) {
      return;
    }
  }, [currentTutorial]);


  useEffect(() => {
    if (!tutorialState) { return; }
    if (tutorialState?.phase === '') { return; 
    }

    // Finally, update the tutorialState in local storage
    if (tutorialState) {
      tutorialState.saveToLocalStorage();
    }

    // Set currentTutorial if a new tutorial is pushed
    if (tutorialState?.tutorialName !== currentTutorial.name) {
      const tutorial = findTutorialByName(tutorialState.tutorialName);
      if (tutorial) {
        setCurrentTutorial(tutorial);
      }
    }

  }, [tutorialState, currentTutorial.name]);


  const findTutorialByName = (name) => {
    return tutorials.find(t => t.name === name);
  };


  const handleStartTutorial = (selectedTutorialName) => {
    const tutorial = findTutorialByName(selectedTutorialName);
    if (!tutorial) return;
  
    const phase = tutorial?.phases?.length > 0 ? tutorial.phases[0].name : "";
    setTutorialState(new TutorialState(tutorial.name, phase, [], [], 0, {}));
  }


  const handleStopTutorial = () => {
    localStorage.removeItem('tutorialState');
    setTutorialState(new TutorialState('', '', [], [], 0, {}));
    setCurrentTutorial({name: '', phases: []});
  };


  const updateTutorialStateParameters = (params) => {
    setTutorialState(prev => {
      const newState = new TutorialState(prev.tutorialName, prev.phase, prev.phasesComplete, prev.completedSteps, prev.currentStep, prev.parameters);
      newState.updateParameters(params);
      return newState;
    });
  };


  const handleProgress = (callingClassName, params, removeStepIndex = null) => {
    const matchingPhase = currentTutorial.phases.find(phase => phase.name === tutorialState.phase);
    if (!matchingPhase) return;

    const matchingStep = matchingPhase.steps.find(step => callingClassName.includes(step.className));
    if (!matchingStep) return;


    setTutorialState(prev => {
      const newState = new TutorialState(prev.tutorialName, prev.phase, prev.phasesComplete, prev.completedSteps, prev.currentStep, prev.parameters);
      const currentStepIndex = matchingPhase.steps.indexOf(matchingStep);
  
      if (removeStepIndex !== null) {
        const removeStep = matchingPhase.steps[removeStepIndex];
        if (removeStep) {
          newState.completedSteps = newState.completedSteps.filter(step => step !== removeStep.name);
          newState.updateCurrentStep(newState.getFirstIncompleteStepIndex(matchingPhase));
        }
      } else if (matchingPhase.steps.every(step => [...newState.completedSteps, matchingStep.name].includes(step.name))) {
        newState.updatePhasesComplete(matchingPhase.name);
        const nextPhase = currentTutorial.phases[currentTutorial.phases.findIndex(phase => phase.name === matchingPhase.name) + 1];
        newState.phase = nextPhase ? nextPhase.name : '';
        newState.completedSteps = [];
        newState.updateCurrentStep(0);
      } else if (currentStepIndex === newState.currentStep) {
        newState.updateCompletedSteps(matchingStep.name);
        newState.updateCurrentStep(newState.getFirstIncompleteStepIndex(matchingPhase));
      }
  
      newState.updateParameters(params);
      return newState;
    });
  };


  return [tutorialState, currentTutorial, handleStartTutorial, handleProgress, handleStopTutorial, updateTutorialStateParameters];
}

