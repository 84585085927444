import React from 'react';

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

export default function EquipmentPicker({ className, defaultEquipmentList, selectedNewEquipment, setSelectedNewEquipment, handleSubmitEquipment }) {

  function handleEquipChange (event) {
    setSelectedNewEquipment(event.target.value);
  }

  return (
    <Box className={className} display="flex" flexDirection="column" sx = {{p:1}} >
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <Box className="select-picker-container"  sx ={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <InputLabel id="equipment-select">Add Equipment:</InputLabel>
          <Select
            sx={{ width: 200 }}
            labelId="equipment-select"
            label="Select Type"
            value={selectedNewEquipment}
            onChange={handleEquipChange}
          >
            <MenuItem key="" value="">Select an Equipment Type</MenuItem>
            {defaultEquipmentList.map((equipment) => (
              <MenuItem key={equipment.equip_key} value={equipment.equip_key}>
                {equipment.name}
              </MenuItem>
            ))}
          </Select>
          <Button className = "tutorial-locations-create-location" variant="contained" sx={{ display: 'inline-block', marginLeft: '8px' }} onClick={handleSubmitEquipment}>
            Create Loc
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
}
