import React, { useState, useRef, useEffect, useCallback } from 'react';
import L from 'leaflet';
import 'leaflet-control-geocoder';

import ModalSelector from '../../modalSelector'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function ZoomToInputController({ className, parentMap, isLoaded, inputHeight }) {
  const [searchAddress, setSearchAddress] = useState('');
  const [addressOptions, setAddressOptions] = useState([]);
  const modalRef = useRef(null);

  const [open, setOpen] = useState(false);

  const zoom = 15

  const itemRenderer = (item) => {
    return (
      item.name
    )
  }

  // Only function that does actual work on the map
  const zoomToLatLong = useCallback((latLong, zoomLevel) => {
    parentMap.flyTo(latLong, zoomLevel);
  }, [parentMap]);

  useEffect(() => {
    // add the geocoder to the map
    if (addressOptions.length === 1) {
      // if there is only one address, zoom to it
      zoomToLatLong([addressOptions[0].center.lat, addressOptions[0].center.lng], zoom);
    } else if (addressOptions.length > 1) {
      // if there are multiple addresses, show the modal to select one
      // using a custom function to get the ID for each item
      setOpen(true);
    }
  }, [addressOptions, zoomToLatLong])

  const handlePickItem = (item) => {
    const lat = item.center.lat.toFixed(6);
    const long = item.center.lng.toFixed(6);
    const searchAddress = `${lat}, ${long}`;
    setSearchAddress(searchAddress);

    zoomToLatLong([lat, long], zoom);
    // modalRef.current.close();
    setOpen(false);
  }

  if (!isLoaded) {
    return (
      <div className={className}>
        <p>Loading...</p>
      </div>
    );
  }




  const handleInputChange = (e) => {
    setAddressOptions([]);
    setSearchAddress(e.target.value);
  }

  function handleGoToAddress() {
    if (searchAddress === '') {
      return;
    }

    const latLongRegex1 = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
    const latLongRegex2 = /^\s*-?\d{1,3}(?:\.\d+)?°(?:\s*\d{1,2}'(?:\d{1,2}(?:\.\d+)")?[NS]){0,1}\s*-?\d{1,3}(?:\.\d+)?°(?:\s*\d{1,2}'(?:\d{1,2}(?:\.\d+)")?[WE]){0,1}\s*$/

    if (latLongRegex1.test(searchAddress)) {
      // searchAddress is in the format of a lat long
      var result = searchAddress.split(",").map(function (value) {
        return value.trim();
      });
      let lat = parseFloat(result[0]).toFixed(6);
      let long = parseFloat(result[1]).toFixed(6);
      setSearchAddress(`${lat}, ${long}`)
      zoomToLatLong([lat, long], zoom)
    } else if (latLongRegex2.test(searchAddress)) {
      const [lat, long] = parseLatLong(searchAddress);

      setSearchAddress(`${lat}, ${long}`)
      zoomToLatLong([lat, long], zoom)
    } else {
      handleGetAddresses(searchAddress);
    }
  }

  function handleGetAddresses(address) {
    const geocoder = L.Control.Geocoder.nominatim();
    const options = { maxResults: 5 };

    geocoder.geocode(address, (results) => {
      // for each we need to expose the ID to the root object
      if (results.length === 0) {
        alert("No addresses found for the provided location");
        return;
      }

      results.map((result) => {
        result.id = result.properties.place_id;
        return result;
      });
      setAddressOptions(results);
    }, (error) => {
      console.error("Error geocoding address:", error);
      alert("There was an issue with geocoding the provided address. Please try providing a latitude and longitude instead.");
    }, options);
  }

  function parseLatLong(latLongString) {
    let matcher = latLongString.match(/^\s*(-?\d+(?:\.\d+)?)°\s*(\d+(?::\d+(?:\.\d+)?)?)'?\s*(\d+(?:\.\d+)?)?"?\s*([NS])\s*(\d+(?:\.\d+)?)°\s*(\d+(?::\d+(?:\.\d+)?)?)'?\s*(\d+(?:\.\d+)?)?"?\s*([WE])\s*$/);
    if (!matcher) {
      matcher = latLongString.match(/^-?\d{1,3}(?:\.\d+)?,\s*-?\d{1,3}(?:\.\d+)?$/)
    }

    const lat = parseCoord(matcher[1], matcher[2], matcher[3], matcher[4])
    const long = parseCoord(matcher[5], matcher[6], matcher[7], matcher[8])
    return [lat, long];
  }

  function parseCoord(degreesStr, minutesStr, secondsStr, NSWE) {
    let sign = 1;
    if (NSWE === 'S' || NSWE === 'W') {
      sign = -1;
    }
    const degrees = parseFloat(degreesStr);
    const minutes = parseFloat(minutesStr) || 0;
    const seconds = parseFloat(secondsStr) || 0;
    const decimalDegrees = sign * (degrees + minutes / 60 + seconds / 3600);
    return decimalDegrees.toFixed(6);
  }

  return (
    <Box className={className} sx={{
      p: 2,
    }}>
      <TextField
        type="text"
        size='small'
        id="searchAddress"
        label="Address"
        value={searchAddress}
        onChange={handleInputChange} />
      <Button variant="outlined" size="large" onClick={handleGoToAddress}>Go to Address</Button>
      <ModalSelector
        modalRef={modalRef}
        open={open}
        items={addressOptions}
        itemRenderer={itemRenderer}
        onSubmit={handlePickItem}
        onClose={() => setOpen(false)}
      />
    </Box >

  )
}
