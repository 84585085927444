import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

function SampleTheme() {

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h4" gutterBottom>
        Sample Theme
      </Typography>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Paper Component
        </Typography>
        <Typography variant="body1" gutterBottom>
          Body1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada arcu eget fermentum vehicula. Sed euismod eu lectus a efficitur.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Body2 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada arcu eget fermentum vehicula. Sed euismod eu lectus a efficitur.
        </Typography>


        <Typography color="text.primary">remember me</Typography>
        <Typography color="text.secondary">as I fade a way.</Typography>


        <Typography variant="h1" >
          h1 Text
        </Typography>
        <Typography variant="h2" >
          h2 Text
        </Typography>
        <Typography variant="h3" >
          h3 Text
        </Typography>

        <Grid container spacing={2} mb={2}>
          <Grid item>
            <Button variant="contained" color="primary">
              Button contained Primary
            </Button>
            <Button variant="outlined" color="primary">
              Button outlined Primary
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary">
              Button contained Secondary
            </Button>
            <Button variant="outlined" color="secondary">
              Button outlined Secondary
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            TextField Component
          </Typography>
          <TextField label="Standard" variant="standard" />
        </Grid>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            Checkbox Component
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Checkbox />
            <Typography variant="body1">Remember me</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SampleTheme;
