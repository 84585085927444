/**
 * @class
 * @classdesc Represents the state of a tutorial.
 */
export default class TutorialState {
  /**
   * @constructor
   * @param {string} tutorialName - The name of the current tutorial.
   * @param {string} phase - The name of the current phase.
   * @param {Array<string>} phasesComplete - An array of completed phase names.
   * @param {Array<string>} completedSteps - An array of completed step names.
   * @param {number} currentStep - The index of the current step.
   * @param {Object} parameters - An object of parameters.
   */
  
  constructor(tutorialName, phase, phasesComplete, completedSteps, currentStep, parameters) {
    this.tutorialName = tutorialName;
    this.phase = phase;
    this.phasesComplete = phasesComplete;
    this.completedSteps = completedSteps;
    this.currentStep = currentStep;
    this.parameters = parameters;
  };

  isEmpty() {
    return this.tutorialName === '' && this.phase === '' && this.phasesComplete.length === 0 && this.completedSteps.length === 0 && this.currentStep === 0 && Object.keys(this.parameters).length === 0;
  };

  getFirstIncompleteStepIndex(phase) {
    for (let i = 0; i < phase.steps.length; i++) {
      const step = phase.steps[i];
      if (!this.completedSteps.includes(step.name)) {
        return i;
      }
    }
    return -1;
  };


  updateCurrentStep(stepIndex) {
    this.currentStep = stepIndex;
  };


  updateCompletedSteps(stepName) {
    if (!this.completedSteps.includes(stepName)) {
      this.completedSteps.push(stepName);
    }
  };


  updatePhasesComplete(phaseName) {
    if (!this.phasesComplete.includes(phaseName)) {
      this.phasesComplete.push(phaseName);
    }
  };


  updateParameters(params) {
    this.parameters = { ...this.parameters, ...params };
  };

  static fromLocalStorage() {
    const savedState = localStorage.getItem('tutorialState');
    if (savedState) {
      const state = JSON.parse(savedState);
      return new TutorialState(
        state.tutorialName,
        state.phase,
        state.phasesComplete,
        state.completedSteps,
        state.currentStep,
        state.parameters
      );
    } else {
      return new TutorialState('', '', [], [], 0, {});
    }
  }

  saveToLocalStorage() {
    localStorage.setItem('tutorialState', JSON.stringify(this));
  }
}