import React, { useState, useContext } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';

import useDragDrop from '../../util/useDragDrop';
import ConfirmDialog from '../confirmDialog';
import { LocationContext } from '../../util/providers/locationContext';

import BaseConduitFactory from '../../models/factory/conduitFactory.ts';
import ConduitTrace from '../../models/conduitTrace.ts';


const section_types = {
  "straight": "Straight",
  "horz": "Horz. Bend",
  "vert": "Vert. Bend"
}

export default function ConduitTraceInput({ item, modalKeys, onClose }) {
  // Note sectionState is local, and does not change item state until submit
  const [sectionState, setSectionState] = useState(item?.conduit_trace || new ConduitTrace([]));

  const { LocationArray, reRenderLocationArray } = useContext(LocationContext);

  const position_calcs = item.position_calcs || [];

  const initialOptionState = {
    ...modalKeys.reduce((acc, curr) => {
      const selectionObject = item.selections.find((selection) => selection.key === curr);
      acc[curr] = selectionObject ? { options: selectionObject.options, selection: selectionObject.selection } : { options: [], selection: "N/A" };
      return acc;
    }, {})
  };

  const [optionState, setOptionState] = useState(initialOptionState);

  function handleSubmit() {
    const selections = []
    for (const key in optionState) {
      if (optionState.hasOwnProperty(key)) {
        const sectionName = key
        const selectionValue = optionState[key]

        selections.push({
          key: sectionName,
          selection: selectionValue.selection
        })

      }
    }

    // Selection Changes
    LocationArray.update(item.locationid, { selections: selections })

    // Section Changes
    LocationArray.replaceField(item.locationid, "conduit_trace", sectionState)
    reRenderLocationArray();

    onClose();
  }

  return (
    <form>
      <Grid container direction="column">
        <Grid container direction="row">
          {Object.keys(optionState).map((key, index) =>
            <FormControl
              key={index}>
              <InputLabel>{key}</InputLabel>
              <Select
                sx={{
                  minWidth: 160,
                  margin: '6px 10px'
                }}
                label={key}
                name={key}
                value={optionState[key]?.selection}
                onChange={(e) => {
                  const newOptionState = { ...optionState };
                  newOptionState[key].selection = e.target.value;
                  setOptionState(newOptionState);
                }}
              >

                {optionState[key]?.options.map((option) =>
                  <MenuItem value={option} key={`${key}_${option}`}>{option}</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        </Grid>
        {/* Element to table ConduitTrace and change */}

        <SectionBuilder sectionState={sectionState} setSectionState={setSectionState} position_calcs={position_calcs} />

        <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
        <Button variant="outlined" onClick={() => onClose()}>Cancel</Button>
      </Grid>
    </form>
  )
}


function SectionBuilder({ sectionState, setSectionState, position_calcs }) {
  const [rowsShowing, setRowsShowing] = useState(20);

  const [sectionType, setSectionType] = useState("straight");
  const [bend, setBend] = useState(0);
  const [length, setLength] = useState(0);
  const [grade, setGrade] = useState(0);

  const [ccw, setCcw] = useState(true);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  // dropped dragged index
  const { handleDragStart, handleDrop } = useDragDrop((newOrder) => {
    sectionState.reorderConduits(newOrder);
    setSectionState(new ConduitTrace(sectionState.conduits));
  }, "conduit12345");

  function handleAddSection(newSection) {
    sectionState.addConduit(newSection);
    setSectionState(new ConduitTrace(sectionState.conduits));
  }

  function handleDeleteSection(id) {
    sectionState.deleteConduit(id);
    setSectionState(new ConduitTrace(sectionState.conduits));
  }

  function handleSectionChange(id, field, value) {
    sectionState.updateConduit(id, field, value);
    setSectionState(new ConduitTrace(sectionState.conduits));
  }

  function confirmClearSections(setSectionState) {
    handleOpenDialog(
      <ConfirmDialog
        message="Are you sure you want to clear all of these conduits?"
        onConfirm={() => {
          setSectionState(new ConduitTrace([]));
          setDialogOpen(false)
        }}
        onCancel={() => setDialogOpen(false)}
      />
    );
  }

  function confirmGenerateConduitTrace() {
    handleOpenDialog(
      <ConfirmDialog
        message="Are you sure you want to generate a conduit trace?"
        onConfirm={() => {
          const FEET_PER_DEGREE_LATITUDE = 364567

          const conduitFactory = new BaseConduitFactory();
          const c_trace = conduitFactory.build(position_calcs, FEET_PER_DEGREE_LATITUDE);
          setSectionState(c_trace);
          setDialogOpen(false)
        }}
        onCancel={() => setDialogOpen(false)}
      />
    );
  }


  function handleShowMore() {
    setRowsShowing(rowsShowing + 20);
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  return (
    <Box sx={{ border: 1, borderColor: "secondary.main", borderRadius: 2, m: 1, p: 1 }}>
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Grid container>
        <Grid item xs={4} sx={{ px: 1 }}  >
          <Grid container direction="column">
            <Grid item >
              <TextField
                value={sectionType}
                onChange={(event) => setSectionType(event.target.value)}
                select
                label="Section Type"
                sx={{ minWidth: 11 * 14, width: "12rem", pt: 1 }}
              >
                {Object.entries(section_types).map(([key, value]) => (
                  <MenuItem key={key} value={key}>{value}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item >
              <Box display="flex" justifyContent="left" alignItems="center" height="100%"
                sx={{ pt: 1 }}>
                <TextField
                  value={bend}
                  onChange={(event) => setBend(event.target.value)}
                  select
                  label="Bend"
                  sx={{ minWidth: 5 * 14, width: "6rem" }}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </TextField>
                <Button variant="outlined" onClick={() => setCcw(!ccw)} sx={{ width: "4rem", height: "100%" }}>{ccw ? "CCW" : "CW"}</Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{ px: 1 }}>
          <Grid container direction="column" sx={{ width: "8rem" }}>
            <Grid item>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Length"
                value={length}
                onChange={(event) => setLength(parseFloat(event.target.value))}
                sx={{ py: 1 }}
              />
            </Grid>
            <Grid item>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Grade"
                value={grade}
                onChange={(event) => setGrade(parseFloat(event.target.value))}
                sx={{ py: 1 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="column">
            <Grid item>
              <Button variant="contained" onClick={() => handleAddSection({ section_type: sectionType, bend: bend, length: length, grade: grade })}>Add Section</Button>
              <Button variant="outlined" onClick={() => confirmClearSections(setSectionState)}>Clear All Sections</Button>
              <Button
                variant="outlined"
                disabled={position_calcs.length < 1}
                title={position_calcs.length < 1 ? "No calculated route to genenerate from." : "Generate a conduit trace from a calculated route."}
                onClick={() => confirmGenerateConduitTrace()}
              >
                Generate Conduit Trace
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer
        sx={{ width: "100%" }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ py: 0 }}>
              <TableCell sx={{ width: "9%" }}></TableCell>
              <TableCell sx={{ width: "20%" }}>Section Type</TableCell>
              <TableCell sx={{ width: "30%" }}>Bend</TableCell>
              <TableCell sx={{ width: "15%" }}>Length</TableCell>
              <TableCell sx={{ width: "15%" }}>Grade</TableCell>
              <TableCell sx={{ width: "10%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sectionState.getConduits(rowsShowing)
              .map((section, index) =>
                <SectionRow
                  key={index}
                  section={section}
                  index={index}
                  handleSectionChange={handleSectionChange}
                  handleDeleteSection={handleDeleteSection}
                  onDragStart={handleDragStart}
                  onDrop={(e) => handleDrop(e, index,
                    sectionState.getConduits(rowsShowing),
                  )}
                  onDragOver={(e) => e.preventDefault()}
                />
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        sx={{ width: "36rem" }} onClick={handleShowMore}>Show More</Button>
    </Box>
  )
}


function SectionRow({
  section,
  index,
  handleSectionChange,
  handleDeleteSection,
  onDragStart, ...props
}) {

  return (
    <TableRow
      key={index}
      {...props}
    >
      <TableCell>
        <Box
          sx={{ p: 0, cursor: 'grab', minWidth: "4rem" }}
          component="span"
          draggable
          onDragStart={(event) => {
            event.stopPropagation();
            onDragStart(event, index)
          }}
        >
          <MenuIcon />
        </Box>
      </TableCell>
      <TableCell>
        <Select
          value={section.section_type}
          onChange={(event) => handleSectionChange(index, 'section_type', event.target.value)}
          sx={{ minWidth: "12rem" }}
        >
          {Object.entries(section_types).map(([key, value]) => (
            <MenuItem key={key} value={key}>{value}</MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="left" alignItems="center" height="100%">
          <Select
            value={section.bend}
            onChange={(event) => handleSectionChange(index, 'bend', event.target.value)}
            sx={{ minWidth: "5rem" }}
            disabled={section.section_type === 'straight'}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={90}>90</MenuItem>
            <MenuItem value={45}>45</MenuItem>
            <MenuItem value={22}>22</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
          <IconButton
            onClick={() => handleSectionChange(index, "ccw", !section.ccw)}
            disabled={section.section_type === 'straight'}
          >
            <Typography>{section.ccw ? "CCW" : "CW"}</Typography>
          </IconButton>
        </Box>
      </TableCell>
      <TableCell>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={section.length}
          onChange={(event) => handleSectionChange(index, 'length', parseFloat(event.target.value))}
          sx={{ minWidth: "6.5rem" }}
          disabled={section.section_type !== 'straight'}
        />
      </TableCell>
      <TableCell>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={section.grade}
          onChange={(event) => handleSectionChange(index, 'grade', parseFloat(event.target.value))}
          sx={{ minWidth: "6.5rem" }}
          disabled={section.section_type !== 'straight'}
        />
      </TableCell>
      <TableCell>
        <IconButton className="delete-button" color="secondary" onClick={() => handleDeleteSection(index)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
