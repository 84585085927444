import React, { useState } from "react"

import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

export default function HomePage() {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
    <Paper elevation = {2}>
      <Typography variant ="h2" sx ={{my: 4}} >About This App*</Typography>
      <Typography variant ="body1" sx ={{my: 4}} >
        This app is designed to streamline the process of connecting new businesses to public utilities.  We have two users:
        <List>
          <ListItem>
            <ListItemText primary="Utility Designers" secondary = "Utility Designers are engineers who design the connection from a business development to existing public infrastructure"/>
          </ListItem>
          <ListItem>
            <ListItemText primary="Business Developers" secondary = "New Business Developers are people who are starting or upgrading a business and need to connect to the grid"/>
          </ListItem>
        </List>

        Our portal provides an interface to draft a business's design requirements and get quotes directly from utility designers to complete them.  
      </Typography>

        <Box sx ={{m: 2}}>
          <Typography variant ="body1">Who are You? </Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
            <InputLabel>I am a:</InputLabel>

              <Select sx = {{minWidth: 300}}
                value={selectedOption}
                onChange={handleOptionChange}
                inputProps={{ id: 'select-option' }}
              >
                <MenuItem value="utilityDesigner">Utility Designer</MenuItem>
                <MenuItem value="newBusinessDeveloper">New Business Developer</MenuItem>
              </Select>
            </FormControl>
          </Box>

    </Paper>
      {selectedOption === 'newBusinessDeveloper' && <NewBusinessDeveloperPage />}
      {selectedOption === 'utilityDesigner' && <UtilityDesignerPage />}

    <EmailPlug/>
    </>
  );
}

function UtilityDesignerPage() {

  return (        
  <>
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography sx ={{my: 4}} variant = 'h1'>For Utility Designers</Typography>
      <Typography sx ={{my: 4}} variant = 'body1'>
        Our principle tool is a single equipment input to add and define equipment properties.  Add connections between each location by selecting a single Source Side Device (SSD). 

        Alternatively, there is a map to add equipment directly onto.  

        For Underground work, define the cable and conduit trace between each location.  

        With your design is complete and connected, you can submit it to be calculated to generate voltage drop calculations, conduit pull calculations, and a bill of materials(WIP).
      </Typography>
    </Paper>
  </>
  )
}


function NewBusinessDeveloperPage() {

  return (        
  <>
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography sx ={{my: 4}} variant = 'h1'>Define Your Business Needs</Typography>
      <Typography sx ={{my: 4}} variant = 'body1'>
        We provided a portal to define your project requirements and run 

        You can define a rudimentary project and parameters using the location builder.  You should have some number of service points and a required load.  
        
        There is a map to add equipment directly onto.  You can place you service point onto a map and place any equipment you know you need where you prefer to have them.  

        You can then share your project with utility designers to get quotes. The more information you can provide, the more accurate of a quote you will get.
      </Typography>
    </Paper>
  </>
  )
}

function EmailPlug(){
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography  variant ="body1" >
        If you are interested and would like to know more, please email us
        at shermanproceduraldesigns@gmail.com for more information.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        className="email-button"
        component="a"
        href={`mailto:shermanproceduraldesigns@gmail.com?subject=Utility Design App Inquiry`}
        sx={{ borderRadius: "0.5rem", mt: 2 }}
      >
        Email Us
      </Button>
      <Typography variant ="body2" sx ={{my: 4}} >
        *This app is in alpha and some features are not yet implemented.  Please contact us if you are interested in using some of our features now or in the near future.
      </Typography>
    </Paper>
  )

}