import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { sortNumAlpha } from '../../../util/sortNumAlpha';

export default function EquipmentPlacer({ className, LocationArray, selectedObjects, setSelectedObjects }) {
  const [localSelect, setLocalSelect] = useState('');

  const equipmentState = LocationArray.getAllEquipment(true).filter((equipment) => equipment.base_id === null);

  const options = equipmentState
    .sort((a, b) => sortNumAlpha(a.loc_number, b.loc_number))
    .map((equipment) => {
      return { key: equipment.locationid, value: `${equipment.loc_number}: ${equipment.name}` };
    });

  useEffect(() => {
    const selectedEquipmentIds = selectedObjects
      .filter(selectedEquipment => LocationArray.contains(selectedEquipment))
      .map(selectedEquipment => selectedEquipment.locationid);

    if (selectedEquipmentIds.length === 1) {
      setLocalSelect(selectedEquipmentIds[0]);
    }

  }, [LocationArray, selectedObjects]);

  const handleChange = (event) => {
    const equipmentId = event.target.value;
    const newSelection = LocationArray.getAllEquipment(true).find((equipment) => equipment.locationid === equipmentId) || {};
    setSelectedObjects([newSelection]);
  };

  const optionItems = options.map((option) => (
    <MenuItem key={option.key} value={option.key}>{option.value}</MenuItem>
  ));

  return (
    <div className={className}>
      <FormControl sx={{ m: 1, minWidth: 240 }}>
        <InputLabel id="Select-Loc">Loc</InputLabel>
        <Select
          labelId="Select-Loc"
          id="Select-Loc"
          label="Loc"
          value={localSelect}
          onChange={handleChange}
        >
          <MenuItem key="" value=""><em>---</em></MenuItem>
          {optionItems}
        </Select>
      </FormControl>
    </div>
  );
}
