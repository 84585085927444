/*
Build a generalize module to load data from Overpass API
with a loaded map, we need to get the bounding coordinates, then use them to query the overpassAPI
*/

import axios from "axios";

import { sessionStorageBounded } from "../models/sessionStorageBounded";

export function loadRoadDataFromOverpass(map, onSuccess, onError) {
  const zoomLevel = map.getZoom();
  if (zoomLevel < 15) {
    onError("Zoom level is too low");
    return;
  }

  const bounds = map.getBounds();
  const boundString = `${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()}`;

  const url = "https://overpass-api.de/api/interpreter";
  const roadTypes = ["motorway", "trunk", "primary", "secondary", "tertiary", "residential"];
  const query = roadTypes.map((roadType) => `way["highway"="${roadType}"]`).join(";");
  const totalQuery = `[out:json][timeout:25][bbox:${boundString}];(${query};);out geom;`;

  const keyObject = {
    query: query,
    south: bounds.getSouth(),
    west: bounds.getWest(),
    north: bounds.getNorth(),
    east: bounds.getEast()
  };

  fetchDataFromOverpass(url, totalQuery, onSuccess, onError, keyObject);
}
export function loadBuildingDataFromOverpass(map, onSuccess, onError) {
  const zoomLevel = map.getZoom();
  if (zoomLevel < 15) {
    onError("Zoom level is too low");
    return;
  }

  const bounds = map.getBounds();
  const boundString = `${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()}`;

  const url = "https://overpass-api.de/api/interpreter";
  const query = `\nway["building"]`;
  const totalQuery = `[out:json][timeout:25][bbox:${boundString}];\n(${query};);\nout geom;`;

  const keyObject = {
    query: query,
    south: bounds.getSouth(),
    west: bounds.getWest(),
    north: bounds.getNorth(),
    east: bounds.getEast()
  };

  fetchDataFromOverpass(url, totalQuery, onSuccess, onError, keyObject);
}

function fetchDataFromOverpass(url, query, onSuccess, onError, object) {
  const cachedData = sessionStorageBounded.getWithBoundsCheck(object);

  if (cachedData) {
    onSuccess({ data: cachedData });
  } else {
    axios.post(url, query)
      .then((response) => {
        sessionStorageBounded.set(object, response.data, 60 * 60 * 1000 );
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
}