import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

import { useTutorial } from '../util/providers/useTutorialContext.jsx';

import { QuantifiedPriceProfile, QuantifiedPriceProfileList } from '../models/priceProfile.ts';

const equipmentProper = {
  "pad_tx": "Pad TX",
  "sub_tx": "Sub TX",
  "meter": "Meter",
  "pbox": "Pri Box",
  "sbox": "Sec Box",
  "pcable": "Pri Cable",
  "scable": "Sec Cable",
  "pole": "Pole",
  "pole_tx": "TX Pole",
  "pole_meter": "Meter Pole",
  "pole_pri": "Pri Pole",
  "pole_sec": "Sec Pole",
  "sspan": "Sec Span",
  "pspan": "Pri Span",

}

/**
 * 
 * Interact with the quantified price profile list
 * children should be able to change their local price profiles
 * and update the parent price profile list
 * 
 * 
 * @typedef {Object} GroupedPriceProfileProps
 * @property {any} equipmentState - replace 'any' with the actual type
 * @property {QuantifiedPriceProfileList} priceProfileList
 * @property {Function} onOpenDialog - replace 'Function' with the actual type or signature
 * @property {Function} setPriceProfileList - replace 'Function' with the actual type or signature
 * @property {boolean} [disableUpdates=false]
 * @property {string} [profileName=null]
 * @property {string} [profileDescription=null]
 */


export function GroupedPriceProfile({
  priceProfileList,
  setPriceProfileList,
  handleAddPriceProfile,
  onOpenDialog,
  disableUpdates = false,
  profileName = null,
  profileDescription = null
}) {

  const [collapsed, setCollapsed] = useState(disableUpdates ? true : false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  function updatePriceProfile(pp) {
    const ppEquip = pp.equip_key
    const ppProps = pp.property_list

    const thisPPIndex = priceProfileList.price_profiles.findIndex((orig_pp) => {
      const qPP = new QuantifiedPriceProfile(orig_pp);
      // this incorrectly matches blank properties,
      // we want o only match where
      if (qPP.equip_key === ppEquip && qPP.hasProperties(ppProps)) {
        return true;
      }
      return false
    });

    let updatedPriceProfiles = priceProfileList.price_profiles;
    if (thisPPIndex !== -1) {
      // update the priceProfile at the index with the obj properties
      updatedPriceProfiles[thisPPIndex] = { ...pp };
      setPriceProfileList(
        new QuantifiedPriceProfileList(
          priceProfileList.priceprofilegroupid,
          priceProfileList.name,
          updatedPriceProfiles
        )
      );
    } else {
      // Append a new priceProfile object
      // we also need to requantify the price profile list
      handleAddPriceProfile(pp);
    }
  }

  const groups = (() => {
    if (Object.keys(priceProfileList).length === 0) {
      return [];
    }

    const allEquipKeys = [...new Set(priceProfileList?.price_profiles.map((pp) => pp.equip_key))];

    return allEquipKeys.map((equipKey) => {
      const thesePriceProfiles = priceProfileList?.price_profiles.filter(
        (pp) => pp.equip_key === equipKey
      );
      return new QuantifiedPriceProfileList(equipKey, equipKey, thesePriceProfiles);
    });
  })();

  return (
    <>
      {(
        <Paper sx={{ my: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "left" }}>
            <Typography variant="h3" sx={{ px: 2, py: 1 }}>
              Price Profile:
            </Typography>
            <Button variant={"outlined"} onClick={toggleCollapsed} sx={{ px: 2 }} >{collapsed ? "Expand" : "Collapse"}</Button>
          </Box>
          <Box style={{ display: collapsed ? "none" : "block" }}>
            {(profileName || profileDescription) &&
              <Paper sx={{ mx: 2, my: 2, p: 1, elevation: 4 }}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  {profileName || "Default Profile"}
                </Typography>
                <Typography variant="body" sx={{ px: 2, mb: 2 }}>
                  Description: {profileDescription || "Default Description"}
                </Typography>
              </Paper>}
            {groups.map((group, index) => (
              <RowEquipProfiles
                key={index}
                key0={group.price_profiles[0].equip_key}
                group={group}
                updatePriceProfile={updatePriceProfile}
                handleAddPriceProfile={handleAddPriceProfile}
                onOpenDialog={onOpenDialog}
                disableUpdates={disableUpdates}
              />
            ))}
          </Box>
        </Paper>
      )}

      <Summary groups={groups} />
    </>
  );
}

/**
 * @typedef {Object} RowEquipProfilesProps
 * @property {string} key0 - equip_key for group
 * @property {QuantifiedPriceProfileList} group - PriceProfiles with same equip_key
 * @property {Function} updatePriceProfile - params: (PriceProfile) => update total price profile list
 * @property {Function} onOpenDialog
 * @property {boolean} [disableUpdates=false]
 * 
 */

function RowEquipProfiles({ key0, group, updatePriceProfile, handleAddPriceProfile, onOpenDialog, disableUpdates = false }) {
  // all groups have the same equipment id
  const equipment = group.price_profiles.map(group => group.equipment).flat(Infinity);


  const equipName = equipmentProper[key0];

  if (equipName === undefined) {
    console.warn("Equipment key not found in equipmentProper: ", key0);
  }


  // if there is no updatePriceProfile, do not create new profiles
  const addNewProfile = () => {
    if (disableUpdates) {
      return;
    }
    // get property keys from all equipment in group
    let properties = equipment.map(equip => equip.selections).flat(Infinity);

    onOpenDialog(
      <CreatePriceProfile
        equip_key={key0}
        equipName={equipmentProper[key0]}
        properties={properties}
        handleAddPriceProfile={handleAddPriceProfile} />
    )
  }

  return (
    <Paper sx={{ m: 2 }} elevation={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column">
          {equipmentProper[key0]}
          {!disableUpdates && <Button
            className="tutorial-project-price-profile-add"
            variant="outlined"
            onClick={addNewProfile}
          >Add New Profile</Button>}

        </Box>

        <TableContainer >
          <Table sx={{ m: 0, p: 0 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Properties</TableCell>
                <TableCell>
                  Quantity
                </TableCell>
                <TableCell></TableCell>
                <TableCell>Price/per</TableCell>
                <TableCell>Price High/per</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Total High</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group.price_profiles.map((priceProfile, index) => (
                <PriceProfileRow key={index} priceProfile={priceProfile} updatePriceProfile={updatePriceProfile} disableUpdates={disableUpdates} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Box>
    </Paper>
  );
}

/**
 * @typedef {Object} PriceProfileRowProps
 * @property {QuantifiedPriceProfile} priceProfile
 * @property {Function} updatePriceProfile - params: (PriceProfile) => update total price profile list
 * @property {boolean} [disableUpdates=false]
 * 
 */
function PriceProfileRow({ priceProfile, updatePriceProfile, disableUpdates }) {
  const [localPriceProfile, setLocalPriceProfile] = useState(new QuantifiedPriceProfile(priceProfile));

  useEffect(() => {
    setLocalPriceProfile(new QuantifiedPriceProfile(priceProfile));
  }, [priceProfile]);

  useEffect(() => {
    const timeoutRef = { current: null };
    if (JSON.stringify(localPriceProfile) !== JSON.stringify(priceProfile)) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        updatePriceProfile(localPriceProfile);
      }, 1000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [localPriceProfile, priceProfile, updatePriceProfile]);

  const handlePriceChange = (field, value) => {
    if (disableUpdates) {
      return;
    }
    setLocalPriceProfile((prevPriceProfile) => {
      prevPriceProfile.copy()
      const updatedProfile = prevPriceProfile.copy()
      updatedProfile[field] = Number(value);
      return updatedProfile;
    });
  };



  const renderProperties = (properties) => {
    if (properties.length === 0) {
      return <Typography key={100}>&lt;All Else&gt;</Typography>;
    }
    return properties.map((property, index) => {
      return (
        <Box key={index}>
          <Typography sx={{ display: 'inline-block' }}>{property.key}:</Typography>
          <br />
          <Typography sx={{ textAlign: 'right' }}>{property.value}</Typography>
        </Box>
      );
    });
  };

  return (
    <React.Fragment>
      <TableRow key={`${priceProfile.equip_key}-${JSON.stringify(priceProfile.property_list)}-quantity`}>
        <TableCell>{renderProperties(priceProfile.property_list)}</TableCell>
        <TableCell>
          Quantity:
        </TableCell>
        <TableCell>{(priceProfile.quantity).toFixed(2)}</TableCell>
        <TableCell>
          <TextField
            type={disableUpdates ? "" : "number"}
            value={localPriceProfile.quantity_price || 0}
            onChange={(event) => handlePriceChange("quantity_price", event.target.value)}
            disabled={disableUpdates}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: {
                style: { textAlign: 'left' },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            type={disableUpdates ? "" : "number"}
            value={localPriceProfile.quantity_price_high || 0}
            onChange={(event) => handlePriceChange("quantity_price_high", event.target.value)}
            disabled={disableUpdates}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: {
                style: { textAlign: 'left' },
              },
            }}
          />
        </TableCell>
        <TableCell>${(priceProfile.quantity * priceProfile.quantity_price).toFixed(2)}</TableCell>
        <TableCell>${(priceProfile.quantity * priceProfile.quantity_price_high).toFixed(2)}</TableCell>
      </TableRow>

      {/* Render cable length row */}
      {priceProfile.cable_length > 0 && (
        <TableRow key={`${priceProfile.equip_key}-${JSON.stringify(priceProfile.property_list)}-cable`}>
          <TableCell></TableCell>
          <TableCell>Cable(ft): </TableCell>
          <TableCell>{(priceProfile.cable_length || 0).toFixed(2)}</TableCell>
          <TableCell>
            <TextField
              type={disableUpdates ? "" : "number"}
              value={localPriceProfile.cable_price || 0}
              onChange={(event) => handlePriceChange("cable_price", event.target.value)}
              disabled={disableUpdates}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  style: { textAlign: 'left' },
                },
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              type={disableUpdates ? "" : "number"}
              value={localPriceProfile.cable_price_high || 0}
              onChange={(event) => handlePriceChange("cable_price_high", event.target.value)}
              disabled={disableUpdates}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  style: { textAlign: 'left' },
                },
              }}
            />
          </TableCell>
          <TableCell>${(priceProfile.cable_length * priceProfile.cable_price).toFixed(2)}</TableCell>
          <TableCell>${(priceProfile.cable_length * priceProfile.cable_price_high).toFixed(2)}</TableCell>
        </TableRow>
      )}
      {/* Render conduit length row */}
      {priceProfile.conduit_length > 0 && (
        <TableRow key={`${priceProfile.equip_key}-${JSON.stringify(priceProfile.property_list)}-conduit`}>
          <TableCell></TableCell>
          <TableCell>Conduit(ft): </TableCell>
          <TableCell>{(priceProfile.conduit_length || 0).toFixed(2)}</TableCell>
          <TableCell>
            <TextField
              type={disableUpdates ? "" : "number"}
              value={localPriceProfile.conduit_price || 0}
              onChange={(event) => handlePriceChange("conduit_price", event.target.value)}
              disabled={disableUpdates}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  style: { textAlign: 'left' },
                },
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              type={disableUpdates ? "" : "number"}
              value={localPriceProfile.conduit_price_high || 0}
              onChange={(event) => handlePriceChange("conduit_price_high", event.target.value)}
              disabled={disableUpdates}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  style: { textAlign: 'left' },
                },
              }}
            />
          </TableCell>
          <TableCell>${(priceProfile.conduit_length * priceProfile.conduit_price).toFixed(2)}</TableCell>
          <TableCell>${(priceProfile.conduit_length * priceProfile.conduit_price_high).toFixed(2)}</TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
/**
 * `CreatePriceProfile` is a React component that provides a form for creating a price profile.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.equip_key - The equipment key for the price profile.
 * @param {string} props.equipName - The name of the equipment.
 * @param {Array} props.properties - An array of property objects, each with a key and value.
 * @param {Function} props.updatePriceProfile - A function to update the price profile.
 *
 * @returns {JSX.Element} A form for creating a price profile. The form includes a list of key-value pairs,
 * where each pair represents a property. The user can add or delete pairs. On form submission, the
 * `updatePriceProfile` function is called with the new price profile.
 */
function CreatePriceProfile({ equip_key, equipName, properties, handleAddPriceProfile }) {
  const [selectedPairs, setSelectedPairs] = useState([{ key: '', value: '' }]);

  const { handleProgress } = useTutorial();

  const keys = [...new Set(properties.map((property) => property.key))].sort();

  const handleKeyChange = (index, event) => {
    const newSelectedPairs = [...selectedPairs];
    newSelectedPairs[index].key = event.target.value;
    setSelectedPairs(newSelectedPairs);
  };

  const handleValueChange = (index, event) => {
    const newSelectedPairs = [...selectedPairs];
    newSelectedPairs[index].value = event.target.value;
    setSelectedPairs(newSelectedPairs);
  };

  const handleAddPair = () => {
    setSelectedPairs([...selectedPairs, { key: '', value: '' }]);
  };

  const handleDeletePair = (index) => {
    const newSelectedPairs = [...selectedPairs];
    newSelectedPairs.splice(index, 1);
    setSelectedPairs(newSelectedPairs);
  };

  const handleSubmit = (event) => {
    const property_list = selectedPairs.filter((pair) => pair.key !== '' && pair.value !== '');
    const obj = {
      equip_key,
      property_list,
    };
    const newQPP = new QuantifiedPriceProfile(obj);

    handleAddPriceProfile(newQPP);
    setSelectedPairs([{ key: '', value: '' }]);
    handleProgress(event.target.className)

  };

  return (
    <Box >
      <Typography>Equipment: {equipName}</Typography>
      <Box sx={{ py: 1 }}>
        {selectedPairs.map((pair, index) => (
          <Box key={index} sx={{ py: 1, display: 'flex', alignItems: 'center' }}>
            <Select sx={{ width: '10ch' }} value={pair.key} onChange={(event) => handleKeyChange(index, event)}>
              <MenuItem value="">Select Key</MenuItem>
              {keys.map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
            <Select
              sx={{ width: '10ch', ml: 1 }}
              disabled={pair.key === ''}
              value={pair.value}
              onChange={(event) => handleValueChange(index, event)}
            >
              <MenuItem value="">Select Value</MenuItem>
              {[...new Set(properties
                .filter((property) => property.key === pair.key)
                .flatMap((property) => property.value)
              )].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {index === 0 ? (
              <IconButton sx={{ ml: 1 }} onClick={handleAddPair}>
                <AddIcon />
              </IconButton>
            ) : (
              <IconButton sx={{ ml: 1 }} onClick={() => handleDeletePair(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Box>
      <Button className="tutorial-project-price-profile-add" variant="contained" onClick={handleSubmit}>Add Profile</Button>
    </Box>
  );
}

export function Summary({ groups }) {
  const totals = {};

  Object.values(groups).forEach((group) => {
    const equipKey = group.price_profiles[0].equip_key;
    totals[equipKey] = {
      quantity: 0,
      cable_length: 0,
      conduit_length: 0,
      quantity_total: 0,
      quantity_total_high: 0,
      cable_total: 0,
      cable_total_high: 0,
      conduit_total: 0,
      conduit_total_high: 0,
    };

    // parse each priceProfile into the totals object
    group.price_profiles.forEach((priceProfile) => {
      totals[equipKey].quantity += priceProfile.quantity;
      totals[equipKey].cable_length += priceProfile.cable_length;
      totals[equipKey].conduit_length += priceProfile.conduit_length;

      totals[equipKey].quantity_total += priceProfile.quantity * priceProfile.quantity_price;
      totals[equipKey].quantity_total_high += priceProfile.quantity * priceProfile.quantity_price_high;

      totals[equipKey].quantity_total += priceProfile.cable_length * priceProfile.cable_price;
      totals[equipKey].quantity_total_high += priceProfile.cable_length * priceProfile.cable_price_high;

      totals[equipKey].quantity_total += priceProfile.conduit_length * priceProfile.conduit_price;
      totals[equipKey].quantity_total_high += priceProfile.conduit_length * priceProfile.conduit_price_high;
    });
  });

  const totalFinal = Object.values(totals).reduce(
    (acc, curr) => {
      acc.quantity += curr.quantity;
      acc.cable_length += curr.cable_length;
      acc.conduit_length += curr.conduit_length;
      acc.quantity_total += curr.quantity_total;
      acc.quantity_total_high += curr.quantity_total_high;
      acc.cable_total += curr.cable_total;
      acc.cable_total_high += curr.cable_total_high;
      acc.conduit_total += curr.conduit_total;
      acc.conduit_total_high += curr.conduit_total_high;
      return acc;
    },
    {
      quantity: 0,
      cable_length: 0,
      conduit_length: 0,
      quantity_total: 0,
      quantity_total_high: 0,
      cable_total: 0,
      cable_total_high: 0,
      conduit_total: 0,
      conduit_total_high: 0,
    }
  );

  const formatDollar = (value) => {
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  return (
    <Paper>
      <Typography variant="h3" sx={{ px: 2, py: 1 }}>Summary:</Typography>
      <TableContainer sx={{ p: 2, m: 2 }} component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Equipment Key</TableCell>
              <TableCell align="right">Count</TableCell>
              <TableCell align="right">Quantity Total</TableCell>
              <TableCell align="right">Quantity Total High</TableCell>
              <TableCell align="right">Cable Total</TableCell>
              <TableCell align="right">Cable Total High</TableCell>
              <TableCell align="right">Conduit Total</TableCell>
              <TableCell align="right">Conduit Total High</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(totals).map(([equipKey, total]) => (
              <TableRow key={equipKey}>
                <TableCell>{equipmentProper[equipKey]}</TableCell>
                {((total.cable_length !== 0) || (total.conduit_length !== 0)) ? (
                  <Tooltip title={<Box sx={{ justify: "right" }}>Cable length: {(total.cable_length).toFixed(2)}<br />Conduit length: {(total.conduit_length).toFixed(2)}</Box>}>
                    <TableCell align="right">
                      {total.quantity} *
                    </TableCell>
                  </Tooltip>
                ) : (
                  <TableCell align="right">
                    {total.quantity}
                  </TableCell>
                )}
                <TableCell align="right">{formatDollar(total.quantity_total)}</TableCell>
                <TableCell align="right">{formatDollar(total.quantity_total_high)}</TableCell>
                <TableCell align="right">{formatDollar(total.cable_total)}</TableCell>
                <TableCell align="right">{formatDollar(total.cable_total_high)}</TableCell>
                <TableCell align="right">{formatDollar(total.conduit_total)}</TableCell>
                <TableCell align="right">{formatDollar(total.conduit_total_high)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={7}>
                <Divider />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">{formatDollar(totalFinal.quantity_total)}</TableCell>
              <TableCell align="right">{formatDollar(totalFinal.quantity_total_high)}</TableCell>
              <TableCell align="right">{formatDollar(totalFinal.cable_total)}</TableCell>
              <TableCell align="right">{formatDollar(totalFinal.cable_total_high)}</TableCell>
              <TableCell align="right">{formatDollar(totalFinal.conduit_total)}</TableCell>
              <TableCell align="right">{formatDollar(totalFinal.conduit_total_high)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ textAlign: 'right', mx: 2 }}>Total: {formatDollar(totalFinal.quantity_total + totalFinal.cable_total + totalFinal.conduit_total)}</Typography>
      <Typography sx={{ textAlign: 'right', mx: 2 }}>Total(High): {formatDollar(totalFinal.quantity_total_high + totalFinal.cable_total_high + totalFinal.conduit_total_high)}</Typography>
    </Paper>
  );
}