import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";

export function RowSelectContainerArray({item, handleOptionChange, otherReqKeys = [], disabled = false }) {
  /**
   * This component renders a container with select inputs based on the provided item object. 
   * It filters out the keys specified in the otherReqKeys array and renders a numeric input if the options array is empty, 
   * or renders a select input if the options array is not empty. 
   * It invokes the handleOptionChange function when a select value is changed.
   * 
   * @param {Object} item - The item object containing the selections and options.
   * @param {Array} otherReqKeys - The keys to be filtered out from the item object.
   * @param {Function} onRowDispatch - The function to be called when a select value is changed.
   * @param {Function} handleOptionChange - The function to be called when a select value is changed.
   * 
   * @returns {JSX.Element} - The JSX element representing the container with select inputs.
   * 
   * @example
   * const item = {
   *   selections: {
   *     key1: {
   *       selection: "value1",
   *       options: ["value1", "value2"]
   *     },
   *     key2: {
   *       selection: "value1",
   *       options: []
   *     }
   *   }
   * };
   * 
   * const otherReqKeys = ["key2"];
   * 
   * function onRowDispatch() {
   *   // handle select value changed
   * }
   * 
   * function handleOptionChange(event, key) {
   *   // handle select value change logic
   * }
   * 
   * // Renders a container with a select input for "key1" and a numeric input for "key2"
   * <RowSelectContainer 
   *   item={item} 
   *   otherReqKeys={otherReqKeys} 
   *   handleOptionChange={handleOptionChange} 
   * />
   */
  
  const selectOptions = item.selections
    .filter((selectObject) => !otherReqKeys.includes(selectObject.key))
    .map((selectObject, index) => {
      const value = selectObject.selection;
      const options = selectObject.options;

      if (options.length === 0) {
        return (
          <FormControl key={index} variant="outlined" sx={{ mx: 2, my: 1, width: "fit-content" }}>
            <InputLabel shrink>{selectObject.key}</InputLabel>
            <TextField
              type="number"
              value={value}
              onChange={(event) => handleOptionChange(event, selectObject.key)}
              label={selectObject.key}
              name={selectObject.key}
              sx={{ minWidth: selectObject.key?.length* 16 }}
              disabled={disabled}
            />
          </FormControl>
        );
      } else {
        return (
          <FormControl key={index} variant="outlined" sx={{ mx: 2, my: 1,  width: "fit-content"  }}>
            <InputLabel>{selectObject.key}</InputLabel>
            <Select
              value={value}
              onChange={(event) => handleOptionChange(event, selectObject.key)}
              label={selectObject.key}
              name={selectObject.key}
              sx={{ minWidth: selectObject.key?.length* 16 }}
              disabled={disabled}
            >
              {options.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }
    });

  // We want a select for each option in the item
  return (
    <>
      <Grid container direction="row">
        {selectOptions}
      </Grid>
    </>
  );
}

export function RowSelectContainerObject({item, otherReqKeys, handleOptionChange, disabled = false }) {
  /**
   * This component renders a container with select inputs based on the provided item object. 
   * It filters out the keys specified in the otherReqKeys array and renders a numeric input if the options array is empty, 
   * or renders a select input if the options array is not empty. 
   * It invokes the handleOptionChange function when a select value is changed.
   * 
   * @param {Object} item - The item object containing the selections and options.
   * @param {Array} otherReqKeys - The keys to be filtered out from the item object.
   * @param {Function} onRowDispatch - The function to be called when a select value is changed.
   * @param {Function} handleOptionChange - The function to be called when a select value is changed.
   * 
   * @returns {JSX.Element} - The JSX element representing the container with select inputs.
   * 
   * @example
   * const item = {
   *   selections: {
   *     key1: {
   *       selection: "value1",
   *       options: ["value1", "value2"]
   *     },
   *     key2: {
   *       selection: "value1",
   *       options: []
   *     }
   *   }
   * };
   * 
   * const otherReqKeys = ["key2"];
   * 
   * function onRowDispatch() {
   *   // handle select value changed
   * }
   * 
   * function handleOptionChange(event, key) {
   *   // handle select value change logic
   * }
   * 
   * // Renders a container with a select input for "key1" and a numeric input for "key2"
   * <RowSelectContainer 
   *   item={item} 
   *   otherReqKeys={otherReqKeys} 
   *   handleOptionChange={handleOptionChange} 
   * />
   */
  
  const selectOptions = Object.entries(item.selections)
    .filter(([key, selectObject]) => !otherReqKeys.includes(key))
    .map(([key, selectObject], index) => {
      const value = selectObject.selection;
      const options = selectObject.options;

      if (options.length === 0) {
        return (
          <FormControl key={index} variant="outlined" sx={{ mx: 2, my: 1, width: "fit-content" }}>
            <InputLabel shrink>{key}</InputLabel>
            <TextField
              type="number"
              value={value}
              onChange={(event) => handleOptionChange(event, key)}
              label={key}
              name={key}
              sx={{ minWidth: key?.length* 14 }}
              disabled={disabled}
            />
          </FormControl>
        );
      } else {
        return (
          <FormControl key={index} variant="outlined" sx={{ mx: 2, my: 1,  width: "fit-content"  }}>
            <InputLabel>{key}</InputLabel>
            <Select
              value={value}
              onChange={(event) => handleOptionChange(event, key)}
              label={key}
              name={key}
              sx={{ minWidth: key?.length* 14 }}
              disabled={disabled}
            >
              {options.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }
    });

  // We want a select for each option in the item
  return (
    <>
      <Grid container direction="row">
        {selectOptions}
      </Grid>
    </>
  );
}