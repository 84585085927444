export class TransferProject {
  constructor(projectid, proj_name, roles) {
    this.projectid = projectid;
    this.proj_name = proj_name;
    this.roles = roles;
  }

  addRole(role) {
    if (!this.roles.includes(role)) {
      this.roles.push(role);
    }
  }

  removeRole(role) {
    const index = this.roles.indexOf(role);
    if (index > -1) {
      this.roles.splice(index, 1);
    }
  }

  hasRole(role) {
    return this.roles.includes(role);
  }

  removeRoles(rolesToRemove) {
    this.roles = this.roles.filter(role => !rolesToRemove.includes(role));
  }

  isEmpty() {
    return this.roles.length === 0;
  }
}