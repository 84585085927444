import { React, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';


export default function LocationOptionTabler({ choice, onSelection }) {
  const { equip_key, loc_number, selection, selectionid, table_name, rows } = choice;
  const [useRedundantColumns, setUseRedundantColumns] = useState(true);

  const columnOrder = Object.keys(rows[Object.keys(rows)[0]]);

  const redundantColumns = columnOrder.reduce((acc, columnName) => {
    const columnValues = Object.values(rows).map((row) => row[columnName]);
    const isRedundant = columnValues.every((value) => value === columnValues[0]);
    if (isRedundant) {
      return [...acc, columnName];
    }
    return acc;
  }, []);

  const columnsToShow = useRedundantColumns && Object.keys(rows).length > 1 ? columnOrder.filter((column) => !redundantColumns.includes(column)) : columnOrder;

  const handleRowClick = (row) => {
    onSelection({
      selectionid,
      index: row.index
    });
  }

  const handleToggleRedundantColumns = () => {
    setUseRedundantColumns(!useRedundantColumns);
  }

  return (
    <Box className="location-choices-container" 
    sx={{p:1}}>
      <Typography variant="body1" component="p">Table: {table_name} , Location: {loc_number} , Equipment: {equip_key}
      </Typography>
      {Object.keys(rows).length > 1 ? (
      <Button 
        className="redundant-columns-button"
        variant = "outlined" 
        onClick={handleToggleRedundantColumns} 
        sx={{mb:1}}>
        {useRedundantColumns ? 'Show' : 'Hide'} redundant columns
      </Button>
      ) : (
        null
      )}
      <TableContainer className="location-choices-table-container">
        <Table className="location-choices-table">
          <TableHead>
            <TableRow>
              {columnsToShow.map((header) => (
                <TableCell key={header}
                className="location-choices-table-header">{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(rows).map((row) => (
              <TableRow 
                key={row.index} 
                onClick={() => handleRowClick(row)} 
                sx={{ 
                  backgroundColor: selection === row.index ? "selected.main" : "background.default", 
                  border: selection === row.index ? `2px solid black` : 'none' 
                }}>                
              {columnsToShow.map((header) => (
                  <TableCell
                    className="location-choices-table-cell" 
                    key={header}>
                    {row[header]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}