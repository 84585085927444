// CheckoutFormPrice.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { PostServerData } from "../../util/dbfetch.js";
import { Typography } from "@mui/material";

const stripe_pub_api = process.env.REACT_APP_STRIPE_PUB_API;
const stripePromise = loadStripe(stripe_pub_api);

export function CheckoutFormPrice({ targetURL, price_id, params}) {
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const baseUrl = window.location.origin;
    const paymentRequest = {
      price_id: price_id,
      return_url: `${baseUrl}/payment-details`,
      ...params,
    };

    function  handleClientSecret(req) {
      setClientSecret(req.data.client_secret);
    }

    function handleSetError(err) {
      if (err.status === 309) {
        setError("Please verify an email address to your account in user settings.");
        setErrorCode("MISSING_EMAIL");
      } else {
        setError(err?.data?.detail || "An unexpected error occurred. Please try again later.");
        setErrorCode("GENERAL_ERROR");
      }
    }

    PostServerData(targetURL, paymentRequest, handleClientSecret, handleSetError);
  }, [targetURL, price_id, params]);

  const options = { clientSecret };

  if (error) {
    return (
      <Box>
        <Typography>Error: {error}</Typography>
        {errorCode === "MISSING_EMAIL" && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
            <Button variant="outlined" onClick={() => navigate("/user")}>Settings</Button>
          </Box>
        )}
      </Box>
    );
  }

  if (!clientSecret) {
    return <div>Loading...</div>;
  }


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minWidth: 500,
        height: "100%",
        maxWidth: 400,
        padding: 2,
        boxSizing: 'border-box',
      }}
    >
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Box>
  );
}

export default CheckoutFormPrice;