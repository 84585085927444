import React, { useEffect, useState, } from 'react';
import { useSearchParams } from "react-router-dom"

import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import useLocationArrayWithOptions from "../../hooksDatabase/useLocationArrayWithOptions.jsx";

import { useInstancePriceProfile } from "../../hooksDatabase/priceProfileList.jsx";

import { PriceProfileList, QuantifiedPriceProfileList } from '../../models/priceProfile.ts';

import { GroupedPriceProfile } from '../../components/priceProfileGroup.jsx'

export default function ProjectPriceProfile() {
  const [searchParams] = useSearchParams(); // setSearchParams
  let instanceid = searchParams.get("instanceid")

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [loading, setLoading] = useState(true);

  // TODO: Should check the origPPList when saving
  const [, setOriginalPPList] = useState(PriceProfileList.default());
  const [newQuantPPList, setNewQuantPPList] = useState(QuantifiedPriceProfileList.default());

  const [selectedPriceProfileID, setSelectedPriceProfileID] = useState("");

  const [
    LocationArray, ,
    locationArrayLoading,
  ] = useLocationArrayWithOptions(instanceid);

  const {
    priceProfileList,
    loadingPPList,
    savePriceProfileToInstance,
  } = useInstancePriceProfile(instanceid);

  useEffect(() => {
    if (loadingPPList || locationArrayLoading) {
      return;
    }

    const quantPPList = QuantifiedPriceProfileList.quantifyFromPriceProfileList(
      priceProfileList[0], LocationArray
    );

    setOriginalPPList(quantPPList);
    setNewQuantPPList(quantPPList.copy());
    setLoading(false);

  }, [priceProfileList, LocationArray, loadingPPList, locationArrayLoading]);


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleAddPriceProfile = (newPP) => {
    // handle adding the newPP to the newQuantPPList, then calling quantify again

    const newPPs = [...newQuantPPList.price_profiles, newPP];
    const newPPList = new PriceProfileList(
      newQuantPPList.priceprofilegroupid,
      newQuantPPList.name,
      // newQuantPPList.description,
      newPPs
    );

    const newQuantPPList2 = QuantifiedPriceProfileList.quantifyFromPriceProfileList(
      newPPList, LocationArray
    );

    setNewQuantPPList(newQuantPPList2);
  }

  const handleSetOriginalPriceProfile = (event) => {

    const selected = priceProfileList.find((ppGroup) => ppGroup.priceprofilegroupid === event.target.value);
    setSelectedPriceProfileID(selected.priceprofilegroupid);

    const newQuantPPList = QuantifiedPriceProfileList.quantifyFromPriceProfileList(
      selected, LocationArray
    );

    setOriginalPPList(newQuantPPList);
    setNewQuantPPList(newQuantPPList.copy());
  }

  return (
    <>
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ mr: 2 }}>Load Price Profile</Typography>
        {loading ? (
          <Box>Loading...</Box>
        ) : (
          <FormControl variant="outlined" sx={{ mr: 2 }}>
            <InputLabel id="price-profile-select-label">Price Profile</InputLabel>
            <Select
              labelId="price-profile-select-label"
              id="price-profile-select"
              value={selectedPriceProfileID}
              onChange={handleSetOriginalPriceProfile}
              label="Price Profile"
              sx={{ minWidth: '25ch' }}
            >
              {priceProfileList.map((ppGroup) => (
                <MenuItem key={ppGroup.priceprofilegroupid} value={ppGroup.priceprofilegroupid}>
                  {ppGroup?.name ?? ppGroup.priceprofilegroupid}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {!loading && !locationArrayLoading &&
        <GroupedPriceProfile
          priceProfileList={newQuantPPList}
          setPriceProfileList={setNewQuantPPList}
          handleAddPriceProfile={handleAddPriceProfile}
          onOpenDialog={handleOpenDialog}
        />}

      <Button variant="contained" onClick={() => { savePriceProfileToInstance(newQuantPPList) }}>Save Price Profile to Instance</Button>
      <Box sx={{ height: '100px' }} />
    </>
  );
}