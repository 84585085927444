import React from "react";
import L from "leaflet";

import 'leaflet-rotatedmarker';

export const iconPaths  = {
  pad_tx: "/icons/pad_tx.svg",
  sub_tx: "/icons/sub_tx.svg",
  // pad_sw: "/icons/pad_sw.svg",
  // sub_sw: "/icons/sub_sw.svg",
  // pad_j: "/icons/pad_j.svg",
  // sub_j: "/icons/sub_j.svg",
  meter: "/icons/meter.svg",
  pbox: "/icons/pbox.svg",
  sbox: "/icons/sbox.svg",
  // pad: "/icons/pad.svg",
  // sub: "/icons/sub.svg",
  // add more icons here
  pole: "/icons/pole.svg",
}

class IconFactory {
  static createReactIcon(iconName) {
    const iconPath = iconPaths[iconName];
  
    if (!iconPath) {
      return null;
    }

    return (
      <img className = "icon-equipment" src={iconPath} alt={iconName} />
    );
  }

  static createIcon(iconName) {
    const iconPath = iconPaths[iconName];
  
    if (!iconPath) {
      return null;
    }

    return {
      iconUrl: iconPath,
      iconSize: [25, 25],
      iconAnchor: [12.5, 12.5],
      popupAnchor: [0, -12.5],
    };
  }

  static createLeafletIcon(iconName, className = "") {
    const icon = this.createIcon(iconName);
    if (!icon) {
      return null;
    }

    icon.className = className;
    return L.icon(icon);
  }
}

export default IconFactory;