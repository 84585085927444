import React, { useState, useEffect } from 'react';
import { FeatureGroup, GeoJSON, Marker } from 'react-leaflet';

import IconFactory from '../../graphics/iconFactory.js';

/**
 * SelectedFeatures component is responsible for rendering selected features on the map.
 * It takes an array of selected objects and a LocationArray as props, and renders a semi-transparent version of each selected object.
 * Should extend this to allow for additional objectArrayChecks, and to allow for custom styling.
 * 
 * @component
 * @param {Object[]} selectedObjects - An array of selected objects. Each object should be a valid GeoJSON feature.
 * @param {Object} LocationArray - An object that contains methods for working with locations.
 * @param {Object} props - Additional props to be passed to the GeoJSON component.
 * 
 * @example
 * <SelectedFeatures selectedObjects={selectedObjects} LocationArray={LocationArray} />
 */
export default function SelectedFeatures({ selectedObjects, LocationArray, ...props }) {
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey(prevRenderKey => prevRenderKey + 1);
  }, [selectedObjects])


  /**
   * This function is used to handle each feature in the GeoJSON data.
   * It sets up a click event listener for each feature.
   * 
   * @param {Object} feature - The current feature being processed.
   * @param {Object} layer - The layer that the feature belongs to.
   */
  const onEachFeature = (feature, layer) => {
    layer.on({
      click: (event) => {
        // Handle click event here
        console.log('Clicked on:', feature);
      },
    });
  };

  const style = {
    fillColor: '#0000ff',
    fillOpacity: 0.5,
    color: '#0000ff',
    opacity: 0.5,
  };

  /**
   * This function is used to convert an object to GeoJSON data.
   * If the object is contained in LocationArray, it uses the LocationArray's toGeoJSON method.
   * If the object has its own toGeoJSON method, it uses that.
   * If the object cannot be converted to GeoJSON data, it logs an error and returns the object as is.
   * 
   * @param {Object} object - The object to be converted to GeoJSON data.
   * @returns {Object} The GeoJSON data for the object, or the object itself if it cannot be converted.
   */
  const getGeoJSONData = (object) => {
    if (LocationArray.contains(object)) {
      return LocationArray.toGeoJSON(object);
    } else if (typeof object.toGeoJSON === 'function') {
      return object.toGeoJSON();
    } else {
      // render nothing
      console.log('Object a not toGeoJSON parsable', object)
      return object
    }
  };

  return (
    <FeatureGroup>
      {selectedObjects.map((object, index) => {
        // Check if the object is in LocationArray and has an icon
        const icon = IconFactory.createLeafletIcon(object?.equip_key, 'leaflet-icon-purple');
        if (LocationArray.contains(object) && icon !== null) {
          if (!object.position || object.position.lat == null || object.position.lng == null) {
            return null; // Cannot render empty object
          }
          return (
            <Marker
              icon={icon}
              key={`marker-${object.locationid}`}
              position={[object.position?.lat, object.position?.lng]}
            />
          );
        } else {
          // Render the object as GeoJSON
          return (
            <GeoJSON
              key={`geojson-${index}, ${renderKey}`}
              data={getGeoJSONData(object)}
              style={style}
              onEachFeature={onEachFeature}
              {...props}
            />
          );
        }
      })}
    </FeatureGroup>
  );

}