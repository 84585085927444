import React, {useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';



import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


export default function TutorialBody({currentTutorial, tutorialState, onProgress, previewTutorial = null}) {
  const navigate = useNavigate();

  const tutorialDisplay = previewTutorial || currentTutorial;
 
  const currentPhase = currentTutorial?.phases.find(phase => phase.name === tutorialState.phase);
  const currentStep = currentPhase?.steps[tutorialState.currentStep];
  const currentPhaseRef = useRef(null);

  useEffect(() => {
    if (currentPhaseRef.current) {
      currentPhaseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [tutorialState.currentStep]);

  const handleJumpTo = () => {
    if (previewTutorial) {
      return;
    }
  
    let path;
    if (currentStep.pathStep) {
      if (typeof currentStep.pathStep === 'function') {
        path = currentStep.pathStep(tutorialState.parameters);
      } else {
        path = currentStep.pathStep;
      }
    } else if (currentPhase?.path) {
      if (typeof currentPhase.path === 'function') {
        path = currentPhase.path(tutorialState.parameters);
      } else {
        path = currentPhase.path;
      }
    }
  
    if (path) {
      const currentUrl = new URL(path, window.location.origin);
      const currentPathWithoutParams = currentUrl.pathname;
  
      if (currentPathWithoutParams !== window.location.pathname) {
        navigate(currentUrl.pathname + currentUrl.search);
        return;
      }
    }
  
    const element = document.querySelector(`.${currentStep.className}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center'});
  
      element.style.animation = 'none';
      void element.offsetWidth;
  
      element.style.animation = 'pulse 1s 3'; // Apply the pulse animation
    }
  };
  

  if (!currentPhase) {
    return (
      <>
        <Typography variant="h2" sx ={{p:3}}>Tutorial Complete</Typography>
        <Typography variant="body1" sx ={{p:3}}>{currentTutorial.closingComment}</Typography>
      </>
      )
  }

  return (
    <Paper sx={{maxHeight: 500, overflow: 'auto', pb:1}}>
      <Box display="flex" justifyContent="left" sx ={{width: "100%"}}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            maxWidth: 350,
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {tutorialDisplay.phases.map((phase, index) => (
            <li key={`phase-${index}`} ref={phase.name === tutorialState.phase ? currentPhaseRef : null}>
              <ul>
                <ListSubheader><Typography variant="h4" color="text.primary">{phase.displayName}</Typography></ListSubheader>
                {phase.steps.map((step, stepIndex) => (
                  <Tooltip key={`tooltip-${stepIndex}`} title={step.description} placement="right" arrow>
                    <ListItemButton sx ={{ml:2}}key={`step-${stepIndex}`} selected={phase.name === tutorialState.phase && stepIndex === tutorialState.currentStep}>
                      <Typography color="text.secondary" variant="body2">{step.displayName}</Typography> {phase.name === tutorialState.phase && stepIndex < tutorialState.currentStep ?  <CheckCircleOutlineIcon/> : phase.name === tutorialState.phase && stepIndex === tutorialState.currentStep ? <ArrowRightIcon /> : null}
                    </ListItemButton>
                  </Tooltip>
                ))}
              </ul>
            </li>
          ))}
        </List>
        <Box className ="StepActioner" sx={{px:2, width: '100%', display: 'flex', flexDirection: 'column', flexShrink: 1, justifyContent: 'space-between'}}>
          {!currentStep ? <Typography variant="h5">Step {tutorialState.currentStep + 1}: {currentStep?.displayName}</Typography> : null}
          <Typography sx={{ py:2 }}>{currentStep?.description || 'None'}</Typography>
          {currentStep?.action ? <Typography sx={{p: 2}}>{currentStep.action}</Typography> : null}
          <Box display="flex"  justifyContent="space-between" sx ={{mb:2}}>
            <Button variant = "outlined" disabled = {previewTutorial ? true : false} onClick={handleJumpTo}>Jump To</Button>
            {currentStep?.action ? null : <Button variant="contained" disabled = {previewTutorial ? true : false} onClick={() => onProgress(currentStep.className)}>Next</Button>}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}