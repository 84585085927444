import Location from './location.ts';
import LocationArrayClass from './locationArrayClass.ts';


type Property = {
  key: string;
  value: string;
};

type PriceProfileItem = {
  priceprofileid?: string;
  equip_key: string;
  description?: string;
  property_list: Property[];
  quantity_price?: number;
  quantity_price_high?: number;
  cable_price?: number;
  cable_price_high?: number;
  conduit_price?: number;
  conduit_price_high?: number;
};
  
type PriceProfileQuantizedItem = PriceProfileItem & {
  quantity?: number;
  cable_length?: number;
  conduit_length?: number;
  equipment?: Location[];
};

export class PriceProfile {
  priceprofileid?: string = "";
  equip_key: string;
  description: string="";
  property_list: Property[];

  quantity_price: number = 0;
  quantity_price_high: number = 0;
  cable_price: number = 0;
  cable_price_high: number = 0;
  conduit_price: number = 0;
  conduit_price_high: number = 0;

  constructor(item: PriceProfileItem) {
    this.priceprofileid = item.priceprofileid || "";
    this.equip_key = item.equip_key;
    this.description = item.description || "";
    this.property_list = item.property_list || [];

    this.quantity_price = item.quantity_price || 0;
    this.quantity_price_high = item.quantity_price_high || 0;
    this.cable_price = item.cable_price || 0;
    this.cable_price_high = item.cable_price_high || 0;
    this.conduit_price = item.conduit_price || 0;
    this.conduit_price_high = item.conduit_price_high || 0;
  }

  copy() {
    return new PriceProfile({
      priceprofileid: this.priceprofileid,
      equip_key: this.equip_key,
      description: this.description,
      property_list: this.property_list,
      quantity_price: this.quantity_price,
      quantity_price_high: this.quantity_price_high,
      cable_price: this.cable_price,
      cable_price_high: this.cable_price_high,
      conduit_price: this.conduit_price,
      conduit_price_high: this.conduit_price_high,
    });
  }

  hasProperties(properties: Property[]): boolean {
    return this.property_list.every(prop => {
      return properties.some(p => p.key === prop.key && p.value === prop.value);
    });
  }

  updatePrice(field: string, value: number) {
    const allowedFields = [
      'quantity_price',
      'quantity_price_high',
      'cable_price',
      'cable_price_high',
      'conduit_price',
      'conduit_price_high'
    ];
  
    if (allowedFields.includes(field)) {
      this[field] = value;
    } else {
      console.error(`Cannot update field ${field}. It is not a price field.`);
    }
  }
}

export class QuantifiedPriceProfile extends PriceProfile {
  equipment: Location[] = [];
  quantity: number = 0;
  cable_length: number = 0;
  conduit_length: number = 0;

  constructor(
    item: PriceProfileQuantizedItem, 
  ) {
    super(item);
    this.quantity = item.quantity || 0;
    this.cable_length = item.cable_length || 0;
    this.conduit_length = item.conduit_length || 0;
    this.equipment = item.equipment || [];
  }

  copy() {
    return new QuantifiedPriceProfile({
      priceprofileid: this.priceprofileid,
      equip_key: this.equip_key,
      description: this.description,
      property_list: this.property_list,
      quantity_price: this.quantity_price,
      quantity_price_high: this.quantity_price_high,
      cable_price: this.cable_price,
      cable_price_high: this.cable_price_high,
      conduit_price: this.conduit_price,
      conduit_price_high: this.conduit_price_high,
      quantity: this.quantity,
      cable_length: this.cable_length,
      conduit_length: this.conduit_length,
      equipment: [...this.equipment],
    });
  }

  addLocation(location: Location) {
    this.equipment.push(location);
    this.quantity += 1;
    this.cable_length += location?.calculations?.cable_conduit?.cable_length || 0;
    this.conduit_length += location?.calculations?.cable_conduit?.conduit_length || 0;
  }
  
  clearLocations() {
    this.equipment = [];
    this.quantity = 0;
    this.cable_length = 0;
    this.conduit_length = 0;
  }
}

export class PriceProfileList {
  priceprofilegroupid: string;
  name: string;
  price_profiles: PriceProfile[];

  constructor(
    priceprofilegroupid: string = "", 
    name: string= "default", 
    price_profiles: PriceProfile[] = []
  ) {
    this.priceprofilegroupid = priceprofilegroupid;
    this.name = name;

    this.price_profiles = price_profiles;
    this.sortProfilesByProperties();
  }

  get length() {
    return this.price_profiles.length;
  }
  

  static default() {
    return new PriceProfileList("", "default", [
      new PriceProfile({ equip_key: "pad_tx", property_list: [] }),
      new PriceProfile({ equip_key: "sub_tx", property_list: [] }),
      new PriceProfile({ equip_key: "meter", property_list: [] }),
      new PriceProfile({ equip_key: "pbox", property_list: [] }),
      new PriceProfile({ equip_key: "sbox", property_list: [] }),
      new PriceProfile({ equip_key: "pcable", property_list: [] }),
      new PriceProfile({ equip_key: "scable", property_list: [] }),
    ]);
  }

  copy() {
    return new PriceProfileList(this.priceprofilegroupid, this.name, this.price_profiles.map(profile => profile.copy()));
  }

  sortProfilesByProperties() {
    // Should parse restrictive properties first
    this.price_profiles.sort((a, b) => {
      return b.property_list.length - a.property_list.length;
    });
  }
}

export class QuantifiedPriceProfileList extends PriceProfileList {
  price_profiles: QuantifiedPriceProfile[];

  constructor(priceprofilegroupid: string = "", name: string= "default", price_profiles: QuantifiedPriceProfile[] = []) {
    super(priceprofilegroupid, name, price_profiles);
    
    // redefine the price_profiles and sort 
    // TODO: this sorts the list twice
    this.price_profiles = price_profiles;
    this.sortProfilesByProperties();
  }  
  
  static default() {
    return new QuantifiedPriceProfileList("", "default", [
      new QuantifiedPriceProfile({ equip_key: "pad_tx", property_list: [] }),
      new QuantifiedPriceProfile({ equip_key: "sub_tx", property_list: [] }),
      new QuantifiedPriceProfile({ equip_key: "meter", property_list: [] }),
      new QuantifiedPriceProfile({ equip_key: "pbox", property_list: [] }),
      new QuantifiedPriceProfile({ equip_key: "sbox", property_list: [] }),
      new QuantifiedPriceProfile({ equip_key: "pcable", property_list: [] }),
      new QuantifiedPriceProfile({ equip_key: "scable", property_list: [] }),
    ]);
  }
  

  static quantifyFromPriceProfileList(priceProfileList: PriceProfileList, locations: LocationArrayClass) {
    const quantifiedPriceProfiles = priceProfileList.price_profiles.map(profile => {
      return new QuantifiedPriceProfile(profile);
    });

    const quantifiedPriceProfileList = new QuantifiedPriceProfileList(priceProfileList.priceprofilegroupid, priceProfileList.name, quantifiedPriceProfiles);
    quantifiedPriceProfileList.quantifyLocations(locations);

    return quantifiedPriceProfileList;
  }

  copy() {
    const newPPs = this.price_profiles.map(profile => profile.copy());

    return new QuantifiedPriceProfileList(this.priceprofilegroupid, this.name, newPPs);
  }

  quantifyLocations(locations: LocationArrayClass) {
    /*
    Take the location array, and populate them into the price profiles
    if the equip_key matches, and
    if all of the priceprofile's properties are met by the location's properties,
      that location and quantities should be added to the price profile

    else there is no property matches, quantify into the price profile with no properties
      but matching equip_key
    */

    // parse each price_profileQuantifed inot a price_profile
    
    this.price_profiles.forEach(profile => {
      profile.clearLocations()
    });

    locations.getAllLocations().forEach(location => {

      const equip_key = location.equip_key;
      // get props and parse into key value pairs
      const properties = location.selections.map(selection => {
        return {
          key: selection.key,
          value: selection.selection || selection.value,
        };
      });

      let profile = this.price_profiles.find(profile => {
        return profile.equip_key === equip_key && profile.hasProperties(properties);
      });

      if (!profile) {
        profile = this.price_profiles.find(profile => {
          return profile.equip_key === equip_key && profile.property_list.length === 0;
        });
      }

      if (!profile) {
        profile = new QuantifiedPriceProfile({
          equip_key: equip_key,
          property_list: [],
          quantity_price: 0,
          quantity_price_high: 0,
          cable_price: 0,
          cable_price_high: 0,
          conduit_price: 0,
          conduit_price_high: 0,
        });
        this.price_profiles.push(profile);
      }

      profile.addLocation(location);
    });

    this.sortProfilesByProperties();
  }
}
