
import { Navigate, Route, Routes } from "react-router-dom";

import UserSettings from "./userSettings";

import StripePurchases from '../stripe/userStripePurchases.jsx';
import UserPurchaseHistory from './userPurchaseHistory.jsx';
import StripeCreate from '../stripe/userStripeCreate.jsx';
import TransferResponsibilityUser from '../../components/transfers/transferResponsibilityUser';
import UserTransfers from '../../components/transfers/userTransfers';

export default function UserRoute() {
  // root/project

  return (
    <Routes>
      <Route index element={<UserSettings />} />
      <Route path= "/purchases" element={<StripePurchases />} />
      <Route path="/transaction-history" element= {<UserPurchaseHistory />} />
      <Route path= "/stripe-portal/create" element={<StripeCreate />} />
      <Route path = "/transfer" element = {<TransferResponsibilityUser />} />
      <Route path = "/transfers" element = {<UserTransfers />} />
      <Route path="*" element={<Navigate to="/user" />} />
    </Routes>
  );
}