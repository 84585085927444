

import React from 'react';

import {
  FeatureGroup,
  GeoJSON,
  LayersControl,
} from 'react-leaflet';

// eslint-disable-next-line no-unused-vars
import LocationArrayClass from '../../../models/locationArrayClass.ts';

/**
 * `ConduitLayer` is a React component that renders a layer of conduits on a map.
 *
 * Each conduit is represented as a GeoJSON line string, which is created from the conduit trace of a location.
 * The start and end coordinates of the line string are determined by the positions of the side devices of the location.
 *
 * The conduits are grouped into a feature group and added to a layers control overlay.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {LocationArrayClass} props.locationArray - An instance of the LocationArrayClass that contains the locations to be rendered.
 * @param {boolean} props.checked - A boolean that determines whether the layer is initially visible on the map.
 * @param {string} [props.className="conduit-group"] - The CSS class to apply to the layer.
 * @param {string} [props.name="Conduits"] - The name of the layer.
 * @returns {JSX.Element} A LayersControl.Overlay element that contains the conduit features.
 */
export default function ConduitLayer({ locationArray, checked, className = "conduit-group", name = "Conduits", ...props }) {

  const conduitFeatures = locationArray.getAllConnections(true)
    .map((location, index) => {
      const conduitTrace = location.conduit_trace;
      const conduitPosition_calcs = location?.calculations?.position_calcs

      let conduitLineString;
      if (conduitPosition_calcs && conduitPosition_calcs.length > 0) {
        conduitLineString = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: conduitPosition_calcs.map(calc => [calc.lng, calc.lat])
          }
        };
      } else if (conduitTrace) {
        const ssdLocs = locationArray.getSideDevice(location);
        const lsdLocs = locationArray.getSideDevice(location, true);

        // consider base equipment for positions
        let ssdCoord = null
        if (ssdLocs[0].base_id) {
          ssdCoord = locationArray.getLocation(ssdLocs[0].base_id).position;
        } else if (ssdLocs[0].position) {
          ssdCoord = ssdLocs[0].position;
        } else {
          ssdCoord = null;
        }

        let lsdCoord = null
        if (lsdLocs[0].base_id) {
          lsdCoord = locationArray.getLocation(lsdLocs[0].base_id).position;
        } else if (lsdLocs[0].position) {
          lsdCoord = lsdLocs[0].position;
        } else {
          lsdCoord = null;
        }

        if (ssdCoord === null) {
          // cannot place without ssd position
          return null;
        }

        conduitLineString = conduitTrace.toGeoJSON(ssdCoord, lsdCoord);
      } else {
        // no positions
        return null;
      }

      if (!conduitLineString) {
        return null;
      }

      return (
        <GeoJSON
          key={index}
          data={conduitLineString}
          style={() => ({
            color: 'black',
          })}
          onEachFeature={(feature, layer) => {
            layer.on('click', () => {
              console.log('clicked on conduit', feature);
            });
          }}
        />
      )
    });

  return (
    <LayersControl.Overlay checked className={className} name={name} {...props}>
      <FeatureGroup>{conduitFeatures}</FeatureGroup>
    </LayersControl.Overlay>
  )
}