
// https://github.com/RidhwanDev/mui-darkmode


/* 
Mui Selects do not allow custom variants; you can only modify the existing ones

Some component(like Select) (are) not possible to extend the variant because 
the variant is bound to the component's logic. The Button variant can be extended 
because its variants are used in styling only.
*/

export const themeBase = () => ({
  // generics
  spacing: 8,

  typography: {
    fontFamily: 'sans-serif',
    fontSize: 16,
    h1: {
      fontSize: '2.4rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 200,
    },

    body1: {
      fontSize: '1.4rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '1.2rem',
      lineHeight: 1.2,
    },

    sub1: {
      fontSize: '0.8rem',
      lineHeight: 0.8,
    },
  },
  shape: {
    borderRadius: 8,
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // fontSize: '1rem',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: '1rem',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflowX: 'auto',
        },
      },
    },
  },
});

// Check out https://zenoo.github.io/mui-theme-creator/
export const getLightDarkTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
        primary: {
          main: "#b200a9",
          light: '#3385ff',
          dark: '#0047b3',
        },
        secondary: {
          main: '#ff9900',
          light: '#ffc233',
          dark: '#b26500',
        },
        divider: '#dfe3e8',
        background: {
          default: '#f5f5f5',
          paper: '#fff',
          secondary: "#e2e2e2"
        },
        text: {
          primary: '#1a1a1a',
          secondary: '#4d4d4d',
        },
        selected: {
          main: '#e0e0e0', // A darker shade for light mode
        },
      }
      : {
        primary: {
          main: "#ff9900",
          light: '#ffc233',
          dark: '#b26500',
        },
        secondary: {
          main: '#0066cc',
          light: '#3385ff',
          dark: '#0047b3',
        },
        divider: '#4d5055',
        background: {
          default: '#1a1a1a',
          paper: '#2e2e2e',
          secondary: "#4d4d4d"
        },
        text: {
          primary: "#fff",
          secondary: '#bfbfbf',
        },
        selected: {
          main: '#4d4d4d', // A lighter shade for dark mode
        },
      }),
  },
  ...themeBase()
});
