import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import SubscriptionHandler from "../stripe/subscriptionHandler.jsx";
import useSnackbarContext from '../../util/providers/snackbarProvider';

import { PostServerData, UseServerData } from "../../util/dbfetch";


function UserPurchaseHistory() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [lastInvoiceId, setLastInvoiceId] = useState(null);

  const { openSnackbar } = useSnackbarContext();


  const fetchTransactions = async (startingAfter = null) => {
    const parseData = (data) => {
      const { transactions, has_more, last_invoice_id } = data;

      const uniquifyTransactions = (prev, newTransactions) => {
        const prevIds = new Set(prev.map((item) => item.id));
        return [...prev, ...newTransactions.filter((item) => !prevIds.has(item.id))];
      };

      setTransactions((prev) => uniquifyTransactions(prev, transactions));
      setHasMore(has_more);
      setLastInvoiceId(last_invoice_id);
    };

    const params = {
      starting_after: startingAfter,
      limit: 5,
    }

    UseServerData("purchase_history", parseData, setLoading, (err) => { }, params);

  }

  useEffect(() => {
    fetchTransactions();
  }, []);

  function handleCancel(transactionId) {
    PostServerData(
      `cancel-payment/${transactionId}`, 
      {}, 
      () => {openSnackbar("Payment canceled successfully, refresh for changes")}, 
      (err) => {openSnackbar(`Error canceling payment: ${err?.data?.detail} Contact an Admin.`)}
    );
  }


  return (
    <Box>
      <Typography variant="h4" sx={{ py: 2 }}>
        Questions? Please Contact us at : <Link href="mailto:inquiries@utilitydesign.app" >inquiries@utilitydesign.app</Link>
      </Typography>
      <Typography variant="h2">User Transaction History</Typography>
      <Paper>
        <Typography variant="h4">Purchases Made</Typography>
        <Box>
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : transactions.length === 0 ? (
            <Typography variant="body1">No transactions found.</Typography>
          ) : (
            transactions.map((item) => (
              <Card key={item.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1">Transaction ID: {item.id}</Typography>
                      <Typography variant="body2">Date: {new Date(item.created * 1000).toLocaleString()}</Typography>
                      <Typography variant="body2">Amount: ${(item.amount / 100).toFixed(2)} {item.currency.toUpperCase()}</Typography>
                      <Typography variant="body2">Status: {item.status}</Typography>
                      {item.status === 'succeeded' ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => window.open(item.receipt_url, '_blank', 'noopener,noreferrer')}
                          sx={{ mt: 2 }}
                        >
                          View Receipt
                        </Button>
                      ) : item.status === 'pending' ? (
                        <Tooltip title="The order has been sent to your purchase method. Intercept the charge there or wait for completion to start a refund.">
                          <Button
                            variant="outlined"
                            color="warning"
                            sx={{ mt: 2 }}
                            disabled
                          >
                            Payment Pending (Contact your bank)
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleCancel(item.id)}
                          sx={{ mt: 2 }}
                        >
                          Cancel Payment
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Product: {item.product_name}</Typography>
                      <Typography variant="body2" sx={{pl:2}}>{item.product_description}</Typography>
                      {item.project && (
                        <>
                          <Divider sx={{ my: 1 }} />
                          <Typography variant="body1">Project: {item.project.proj_name}</Typography>
                          <Typography variant="body2" sx={{pl:2}}>{item.project.proj_desc}</Typography>
                        </>
                      )}
                      {item.organization && (
                        <>
                          <Divider sx={{ my: 1 }} />
                          <Typography variant="body1">Organization: {item.organization.organization_name}</Typography>
                          <Typography variant="body2" sx={{pl:2}}>{item.organization.organization_desc}</Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
          )}
        </Box>
        {hasMore && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" color="primary" onClick={() => fetchTransactions(lastInvoiceId)}>
              Load More Transactions
            </Button>
          </Box>
        )}
      </Paper>

      <SubscriptionHandler />
    </Box>
  );
}

export default UserPurchaseHistory;