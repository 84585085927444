/**
 * @class
 * @classdesc Represents a phase in a tutorial.
 */
export default class TutorialPhase {
  constructor(phase) {
    Object.assign(this, phase);
  }

  overrideStep(stepName, newProperties) {
    this.steps = this.steps.map(step => 
      step.name === stepName
        ? { ...step, ...newProperties }
        : step
    );
    return this;
  }

  overridePath(newPath) {
    this.path = newPath;
    return this;
  }
}