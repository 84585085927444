import React, { useState, useEffect, useRef } from 'react';
import {
  FeatureGroup,
} from 'react-leaflet';

import { EditControl } from "react-leaflet-draw";

import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';

import { AlignmentItemized } from "../../models/alignment.ts"
import { AlignmentPerpendicularItemized } from "../../models/alignmentPerpendicular.ts"


export default function AlignmentFeatureEditControl({ originalAlignment, handleSaveEditChanges }) {
  const [editedPoints, setEditedPoints] = useState(originalAlignment.points);
  const editedPointsRef = useRef(editedPoints);
  const ref = useRef(null);

  useEffect(() => {
    const editLayers = {
      type: "FeatureCollection",
      features: [originalAlignment.createCenterLine()],
    }

    if (ref.current?.getLayers().length === 0 && editLayers) {
      L.geoJSON(editLayers).eachLayer((layer) => {
        if (
          layer instanceof L.Polyline ||
          layer instanceof L.Polygon ||
          layer instanceof L.Marker
        ) {
          if (layer?.feature?.properties.radius && ref.current) {
            new L.Circle(layer.feature.geometry.coordinates.slice().reverse(), {
              radius: layer.feature?.properties.radius,
            }).addTo(ref.current);
          } else {
            ref.current?.addLayer(layer);
          }
        }
      });
    }
  }, [originalAlignment]);

  useEffect(() => {
    editedPointsRef.current = editedPoints;
  }, [editedPoints]);

  const _onEditSave = (e) => {
    const updatedAlignment = new AlignmentItemized(
      [...editedPointsRef.current],
      originalAlignment.name,
      originalAlignment.alignmentid,
      originalAlignment.items,
      originalAlignment.bends
    );
    handleSaveEditChanges(updatedAlignment)
  };

  function _onEditVertex(e) {
    const geo = ref.current?.toGeoJSON();
    const newPoints = geo.features[0].geometry.coordinates;
    const currentEditedPoints = editedPointsRef.current;

    const newArray = [...currentEditedPoints];

    if (newPoints.length > currentEditedPoints.length) {
      // new point added
      const firstNewPointIndex = newPoints.findIndex((newPoint, index) => {
        const oldPoint = currentEditedPoints[index];
        if (!oldPoint || !oldPoint.isEqualLatLon(newPoint[1], newPoint[0])) {
          return true;
        }
        return false;
      });

      const origPoint = currentEditedPoints[firstNewPointIndex];
      const firstNewPoint = newPoints[firstNewPointIndex];

      const newAlignmentPoint = new AlignmentPerpendicularItemized(
        firstNewPoint[1],
        firstNewPoint[0],
        origPoint.width,
        origPoint.bearingMode
      );

      newArray.splice(firstNewPointIndex, 0, newAlignmentPoint);

    } else if (newPoints.length < currentEditedPoints.length) {
      // Point deleted
      const deletedPointIndex = currentEditedPoints.findIndex(oldPoint => {
        return !newPoints.some(newPoint => oldPoint.isEqualLatLon(newPoint[1], newPoint[0]));
      });

      if (deletedPointIndex !== -1) {
        newArray.splice(deletedPointIndex, 1);
      }

    } else {
      // new point Modified
      const misMatchIndex = currentEditedPoints.findIndex((oldPoint, index) => {
        const newPoint = newPoints[index];
        return oldPoint && !oldPoint.isEqualLatLon(newPoint[1], newPoint[0]);
      });

      if (misMatchIndex !== -1) {
        const newPoint = newPoints[misMatchIndex];
        const oldPoint = currentEditedPoints[misMatchIndex];

        const updatedPoint = new AlignmentPerpendicularItemized(
          newPoint[1],
          newPoint[0],
          oldPoint.width,
          oldPoint.bearingMode,
          oldPoint.alignmentpointid,
          oldPoint.nodeOSM,
          oldPoint.items,
          oldPoint.order,


        );
        newArray[misMatchIndex] = updatedPoint;
      }
    }
    // finally update the state
    setEditedPoints(newArray);
  }

  return (
    <FeatureGroup ref={ref}>
      <EditControl
        position='topright'
        onEdited={_onEditSave}
        onEditVertex={_onEditVertex}
        draw={{
          rectangle: false,
          circle: false,
          circlemarker: false,
          marker: false,
          polyline: false,
          polygon: false,
        }}
        edit={{
          edit: true,
          remove: false,
        }}
      />
    </FeatureGroup>
  );
}