import React from "react"

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

function PrivacyPage() {

  return (
    <Paper sx={{ my: 4, px: 2, justifyContent: 'left', textAlign : 'left' }}>
      <Typography variant="h1">
        Privacy Notice
      </Typography>
      <Typography variant="body2"  sx = {{px: 2}} paragraph >
        Last updated: September 10, 2024
        </Typography>

      {/* Table of Contents Start */}
      <Typography variant="h2" >
        TABLE OF CONTENTS
      </Typography>
      <Typography variant="body2" sx = {{px: 4}}  >
        <Link href="#collect">1. What information do we collect?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#process">2. How do we process your information</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#share">3. When and with whom do we share your personal information</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#cookies">4. Do we use cookies and other tracking technologies</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#international">6. Is your information transferred internationally?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#keep">7. How long do we keep your information?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#minors">8. do we collect information from minors?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#rights">8. what are your privacy rights?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#controls">8. Controls for do-not-track features?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#updates">9. Do we make updates to this notice?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#contact">10. How can you contact us about this notice?</Link>
      </Typography>
      <Typography variant="body2" sx = {{px: 4}} >
        <Link href="#requests">11.How can you review update or delete the data we collect from you?</Link>
      </Typography>
      {/* End ToC links */}


      <Box>
        <Typography variant="h2" id="collect">
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          We collect personal information that you voluntarily provide to us when you register on the Services, 
          express an interest in obtaining information about us or our products and Services, 
          when you participate in activities on the Services, or otherwise when you contact us.
          <br/><br/>
          All personal information that you provide to us must be true, complete, and accurate, 
          and you must notify us of any changes to such personal information.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="process">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          We process your information to facilitate the delivery of our Services to you.
          Any information used for business development will be anonymized and aggregated.
          We will use and store this information for security, fraud prevention, and any other legal obligations.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="share">
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          We may need to share your personal information in the following situations:
        </Typography>
        <List sx={{ px: 6, listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="body2">
              For Purchases. We use Stripe for payment processing, and Stripe will collect your payment information.
              You can review Stripe's privacy policy here: 
              <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. 
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="body2">
              Business Transfers. We may share or transfer your information in connection with, or during negotiations of, 
              any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="body2">
              Affiliates. We may share your information with our affiliates, in which case we will require those affiliates 
              to honor this Privacy Notice. Affiliates include our parent company and any subsidiaries, joint venture partners, 
              or other companies that we control or that are under common control with us.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="body2">
              Business Partners. We may share your information with our business partners to offer you certain products, 
              services, or promotions.
            </Typography>
          </ListItem>
        </List>
      </Box>
      <Box>
        <Typography variant="h2" id="cookies">
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          We may use cookies and similar tracking technologies to access or store information.
          All Cookies are used to provide you with a more personalized experience and to improve our Services.
          No cookies are used for advertising purposes.
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          Stripe uses cookies faciliate payment processing. You can review Stripe's privacy policy here:
          <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="international">
          5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          Our servers are located in the United States. If you are accessing our Services from outside the United States, 
          please be aware that your information may be transferred to, stored, and processed by us in our facilities and by 
          those third parties with whom we may share your personal information.
        <br/><br/>
          If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as 
          comprehensive as those in your country. We will however take all necessary measures to protect your personal information 
          in accordance with this privacy notice and applicable law.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="keep">
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, 
          unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
          <br/><br/>
          When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, 
          or, if this is not possible (for example, because your personal information has been stored in backup archives), 
          then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="minors">
          7. DO WE COLLECT INFORMATION FROM MINORS?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          We do not knowingly solicit data from or market to children under 18 years of age. 
          By using the Services, you represent that you are at least 18 or that you are the parent or guardian of 
          such a minor and consent to such minor dependent’s use of the Services. 
          If we learn that personal information from users less than 18 years of age has been collected, 
          we will deactivate the account and take reasonable measures to promptly delete such data from our records. 
          If you become aware of any data we have collected from children under age 18, please contact us at
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="rights">
          8. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          You may review, change, or terminate your account at any time.
          You may also request to see the data we have collected from you.
          If you would like to review, change, or delete your data, please contact us 
          at the <Link variant= "body2" color= "primary" href= "#contact"> email address provided</Link> in the contact section below.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="controls">
          9. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or 
          setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.
          <br/><br/>
          At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. 
          As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates 
          your choice not to be tracked online.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="updates">
          10. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          We may update this privacy notice from time to time. 
          The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. 
          If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or 
          by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are 
          protecting your information.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="contact">
          11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          If you have questions or comments about this notice, you may 
          email us at <Link href="mailto:inquiries@utilitydesign.app">inquiries@utilitydesign.app</Link>
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" id="requests">
          12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </Typography>
        <Typography variant="body1" sx = {{px: 2}} paragraph>
          Based on the laws of some countries, you may have the right to request access to the personal information we collect from you, 
          change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, 
          please submit a request by emailing us at <Link href="mailto:inquiries@utilitydesign.app">inquiries@utilitydesign.app</Link>
        </Typography>
      </Box>
    </Paper>
  )
}

export default PrivacyPage