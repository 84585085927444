
enum PlanTiers {
  FREE = "free",
  PAID = "paid",
  DEMO = "demo"
}

interface IProjectPermissions {
  plan_tier: PlanTiers;
  saves_left: number | null;
  other_permissions: string[];
}

class ProjectPermissions implements IProjectPermissions {
  plan_tier: PlanTiers;
  saves_left: number | null;
  other_permissions: string[];

  constructor(plan_tier: PlanTiers = PlanTiers.FREE, saves_left: number | null = null, other_permissions: string[] = []) {
    this.plan_tier = plan_tier;
    this.saves_left = saves_left;
    this.other_permissions = other_permissions;
  }
}

export default ProjectPermissions;