import { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { postLogin } from "../../util/dbfetch.js";
import ConfirmDialog from '../../components/confirmDialog.jsx';

import RegisterUser from "./registerUser.jsx";
import ResetPasswordDialog from "./requestResetPassword.jsx";


export default function Login({ onLogin, onClose }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [userID, setUserID] = useState("");
  const [userPass, setUserPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  function handleLoginFailure(response) {
    handleOpenDialog(
      <ConfirmDialog
        message={response?.data?.detail || response?.data?.message ||
          "Something went wrong logging in! Try again or contact the website admin."
        }
        onConfirm={() => {
          // Other Functions
          setDialogOpen(false)
        }}
        onCancel={() => setDialogOpen(false)}
        delayTime={0}
      />
    )
  }

  function handleResetPasswordModalOpen() {
    handleOpenDialog(
      <ResetPasswordDialog onClose={() => setDialogOpen(false)} />
    );
  };

  function handleLoginSuccess(response) {
    // to-do: if new user, redirect to a new user page
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
      onLogin();
      onClose();
    }

  }

  function handleLoginRequest() {
    postLogin(userID, userPass, handleLoginSuccess, handleLoginFailure)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLoginRequest();
    }
  };

  function handleRegisterModalOpen() {
    handleOpenDialog(
      <RegisterUser onRegister={onLogin} />
    )
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center">
        <TextField
          sx={{ m: 2 }}
          id="username"
          label="Username"
          variant="outlined"
          value={userID}
          onChange={(e) => setUserID(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <TextField
          sx={{ m: 2 }}
          id="password"
          label="Password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          value={userPass}
          onChange={(e) => setUserPass(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" onClick={handleLoginRequest}>
          Log In
        </Button>
        <Button variant="outlined" onClick={handleRegisterModalOpen}>
          Register
        </Button>
        <Button variant="outlined" onClick={handleResetPasswordModalOpen} sx={{ mt: 2 }}>
          Reset Password
        </Button>
      </Box>

      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
    </>
  );
}