export const STATE_ACTION = {
  LOAD_INITIAL: 'load_initial',
  ADD: 'add',
  REMOVE: 'remove',
  REMOVE_ALL: 'remove_all',
  FLAG_REMOVE: 'flag_remove',
  UPDATE: 'update',
  REPLACE_FIELD: 'replace_field',
  REORDER: 'reorder',
  SORT_BY: 'sort_by'
};

/*
# Sample Total State
[ {
    "id": 78,
    "loc": "1",
    "ssd": "79",
    "key": "pad_tx",
    "name": "Pad TX",
    "otherReq": [],
    "selections": {
      "equip_style": {
        "options": [
          "CLAM SHELL",
          "..."
        ],
        "selection": "LB DF"
      },
      "volt_pri": {
        "options": [
          "12000/20780GrdY",
          "..."
        ],
        "selection": "12000/20780GrdY"
      },
      "volt_sec": {
        "options": [
          "240/120",
          "..."
        ],
        "selection": "240/120"
      },
      "equip_size": {
        "options": [
          "50",
          "..."
        ],
        "selection": "50"
      }
    },
    "conduit_trace": []
  },
  {
    "id": 79,
    "loc": "2",
    "ssd": "78",
    "key": "sbox",
    "name": "Sec Box",
    "otherReq": [],
    "selections": {
      "encl": {
        "options": [
          "2",
          "..."
        ],
        "selection": "2"
      },
      "traffic_rating": {
        "options": [
          "IVT",
          "..."
        ],
        "selection": "IVT"
      }
    },
    "conduit_trace": []
  }
]

# Sample Add Action; Will simply append the payload to the state
{
  "type": "add",
  "payload": {
    "id": 78,
    "loc": "1",
    "ssd": null,
    "key": "pad_tx",
    "name": "Pad TX",
    "otherReq": [],
    "selections": {...},
    "conduit_trace": []
  }
}

// All other states will have a root id
// Will look for the 'id' in the state, then drill down to the relevant property to change
// Update selection by default drills down to the state.selections*
{
  "type": "update-ssd", / "update-selection", / "update", / "remove"
  "id": 78,
  "payload": {
    "ssd": "79"  /  {selection} "equip_style": { "selection": "LB DF" } /  "loc": "3"
  }
}
*/

// Pydantic models does not like nested objects
// interpret the model with nested objects instead of nested arrays



/*
  These are generalized stateListReducers
  When further complexity is added to these states, consider creating Classes


*/

export function stateListReducer(state, action, idKey = "locationid") {
  // Note: the initial state should be
  switch (action.type) {
    case STATE_ACTION.LOAD_INITIAL:
      return action.payload;


    case STATE_ACTION.ADD:
      if (state.some(item => item[idKey] === action.payload[idKey])) {
        return state;
      }

      return [...state, action.payload];

    case STATE_ACTION.REMOVE:
      return state.filter((item) => item[idKey] !== action[idKey]);

    case STATE_ACTION.REMOVE_ALL:
      return []

    case STATE_ACTION.FLAG_REMOVE:
      return state.map((item) =>
        item[idKey] === action[idKey] ? { ...item, remove: true } : item
      );

    case STATE_ACTION.UPDATE:
      // Drills down to the nested options that changed
      // should* update items at different levels as well
      const updateNestedObject = (obj, payload) => {
        for (let key in payload) {
          if (obj.hasOwnProperty(key)) {
            if (Array.isArray(obj[key]) && Array.isArray(payload[key])) {
              // if the payload is an array, update the selection where the key matches
              obj[key] = obj[key].map((selection) => {
                const matchingPayload = payload[key].find((payloadSelection) => payloadSelection.key === selection.key);
                if (matchingPayload) {
                  return { ...selection, selection: matchingPayload.selection };
                } else {
                  return selection;
                }
              });
            } else if (typeof obj[key] === 'object' && typeof payload[key] === 'object') {
              // if the payload is an object, recursively call the function on the payload
              obj[key] = updateNestedObject(obj[key], payload[key]);
            } else {
              // otherwise, update the value
              obj[key] = payload[key];
            }
          } else {
            obj = payload;
          }
        }
        return obj;
      };
      // find the object within state that needs to be updated based on the id
      const updatedObject = state.map(item => item[idKey] === action[idKey] ? (
        updateNestedObject(item, action.payload)
      ) : item);

      return updatedObject;

    case STATE_ACTION.REPLACE_FIELD:
      // Replace the field with the payload
      // payload should be an object with the key and value
      const replacedField = state.map(item => item[idKey] === action[idKey] ? (
        {
          ...item,
          [action.payload.key]: action.payload.value
        }
      ) : item);
      return replacedField;


    case STATE_ACTION.REORDER:
      // Reorder the state based on the new order
      // payload should be an array of indexes in the new order
      const reorderedState = action.payload.map(id => (
        state.find((item, index) => (
          index === id
        ))
      ));
      return reorderedState;


    case STATE_ACTION.SORT_BY:

      const sortedState = [...state].sort((a, b) => {
        const sortValueA = isNaN(a[action.sortKey]) ? a[action.sortKey] : Number(a[action.sortKey]);
        const sortValueB = isNaN(b[action.sortKey]) ? b[action.sortKey] : Number(b[action.sortKey]);

        // Sort by numbers first
        if (!isNaN(sortValueA) && !isNaN(sortValueB)) {
          return action.asc ? sortValueA - sortValueB : sortValueB - sortValueA;
        }

        // Sort by order in payload if specified
        if (action.payload && action.payload.length > 0) {
          const aIndex = action.payload.indexOf(sortValueA);
          const bIndex = action.payload.indexOf(sortValueB);
          if (aIndex !== -1 && bIndex !== -1) {
            return action.asc ? aIndex - bIndex : bIndex - aIndex;
          }
          if (aIndex !== -1) {
            return -1;
          }
          if (bIndex !== -1) {
            return 1;
          }
        }

        // Sort by strings last
        if (sortValueA < sortValueB) {
          return action.asc ? -1 : 1;
        }
        if (sortValueA > sortValueB) {
          return action.asc ? 1 : -1;
        }
        return 0;
      });
      return sortedState;
    default:
      return state;
  }
}


export function stateReducer(state, action) {
  switch (action.type) {
    case STATE_ACTION.LOAD_INITIAL:
      return action.payload;

    case STATE_ACTION.UPDATE:
      const updateNestedObject = (obj, payload) => {
        for (let key in payload) {
          if (obj === null) {
            obj = payload;
          } else if (obj.hasOwnProperty(key)) {
            if (Array.isArray(obj[key]) && Array.isArray(payload[key]) && obj[key].length !== payload[key].length) {
              obj[key] = payload[key];
            } else if (typeof obj[key] === 'object' && typeof payload[key] === 'object') {
              obj[key] = updateNestedObject(obj[key], payload[key]);
            } else {
              obj[key] = payload[key];
            }
          } else {
            obj = payload;
          }
        }
        return obj;
      };

      let newObj = updateNestedObject({ ...state }, action.payload);
      return newObj

    case STATE_ACTION.REPLACE_FIELD:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
}